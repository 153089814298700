import { Routes, Route } from "react-router-dom";
import HospitalDepartments from "pages/Services/HospitalServices/HospitalDepartments";
import HospitalDetail from "pages/Services/HospitalServices/HospitalDetail";
import HospitalServices from "pages/Services/HospitalServices/HospitalServices";
import React from "react";

const HospitalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HospitalServices />} />
      <Route path="/HospitalDetail/:id" element={<HospitalDetail />} />

      <Route
        path="/HospitalDetail/:id/HospitalDepartments"
        element={<HospitalDepartments  />}
      />
    </Routes>
  );
};

export default HospitalRoutes;
