// import React, { useEffect, useState } from "react";
// import NavBarr from "../NavBarr";
// import classNames from "classnames";
// import style from "../AboutUs/aboutUs.module.css";
// import commonstyles from "shared/utils/common.module.css";
// import { IoIosArrowForward } from "react-icons/io";
// import styles from "./Appointment.module.css";
// import Footerr from "../Footer";
// import { useNavigate } from "react-router-dom";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import DoneImageDeativate from "../../../../assets/images/success.png";

// const validationSchema = Yup.object().shape({
//   email: Yup.string()
//     .email("Invalid email address")
//     .required("Email is required"),
// });

// const DeleteAccount = React.memo((props) => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [showConfirmation, setShowConfirmation] = useState(false);
//   const [formVisible, setFormVisible] = useState(true);
//   const [showImage, setShowImage] = useState(false);

//   const handleDeactivateAccount = () => {
//     setShowConfirmation(true);
//   };
//   const handleConfirmDeactivate = () => {
//     setShowConfirmation(false);
//     setFormVisible(false);
//     setShowImage(true);
//   };
//   const handleCancelDeactivate = () => {
//     setShowConfirmation(false);
//   };

//   return (
//     <div>
//       <div className={style.navIMG}>
//         <NavBarr />
//         <p
//           className={classNames(
//             commonstyles.fs48,
//             commonstyles.semiBold,
//             style.mianheading
//           )}
//         >
//           Delete Account
//         </p>
//         <div className={style.title}>
//           <p
//             className={classNames(
//               commonstyles.fs16,
//               commonstyles.semiBold,
//               style.mianheading22
//             )}
//           >
//             Home
//           </p>
//           <IoIosArrowForward
//             className={classNames(commonstyles.fs16, style.mianheading)}
//           />
//           <p
//             className={classNames(
//               commonstyles.fs16,
//               commonstyles.semiBold,
//               style.mianheading
//             )}
//           >
//             Delete Account
//           </p>
//         </div>
//       </div>

//       {formVisible && (
//         <Formik
//           initialValues={{ email: "" }}
//           validationSchema={validationSchema}
//           onSubmit={(values) => {
//             handleDeactivateAccount();
//           }}
//         >
//           {({ handleSubmit, isValid, dirty }) => (
//             <Form
//               className={styles.deleteAccountContainer}
//               onSubmit={handleSubmit}
//             >
//               <p className={classNames(styles.heading, commonstyles.fs24)}>
//                 Enter your email to delete account
//               </p>
//               <Field
//                 type="email"
//                 name="email"
//                 placeholder="Enter your email"
//                 className={styles.emailInput}
//               />
//               <ErrorMessage
//                 name="email"
//                 component="div"
//                 className={styles.errorMessageConformDelete}
//               />
//               <button
//                 type="submit"
//                 className={styles.deactivateBtn}
//                 disabled={!isValid || !dirty}
//               >
//                 Submit
//               </button>
//             </Form>
//           )}
//         </Formik>
//       )}

//       {showConfirmation && (
//         <div className={styles.overlayDelete}>
//           <div className={styles.confirmationContainer}>
//             <p className={styles.confirmationText}>
//               Are you sure you want to deactivate your account?
//             </p>
//             <div className={styles.confirmationButtons}>
//               <button
//                 className={styles.yesBtn}
//                 onClick={handleConfirmDeactivate}
//               >
//                 Yes
//               </button>
//               <button className={styles.noBtn} onClick={handleCancelDeactivate}>
//                 No
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {showImage && (
//         <div className={styles.requestImageContainer}>
//           <img
//             src={DoneImageDeativate}
//             alt="Request Sent"
//             className={styles.requestImage}
//           />
//           <p className={styles.requestSentText}>
//             Account deleted successfully!
//           </p>
//         </div>
//       )}

//       <Footerr />
//     </div>
//   );
// });

// export default DeleteAccount;

import React from "react";
import style from "../../../../shared/components/PrivacyPolicy/Policy.module.css";
import commonstyle from "shared/utils/common.module.css";
import styles from "../AboutUs/aboutUs.module.css";

import classNames from "classnames";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from ".";
export default function DeleteAccount() {
  return (
    <div>
      <div className={styles.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyle.fs48,
            commonstyle.semiBold,
            styles.mianheading
          )}
        >
          Delete Account
        </p>
        <div className={styles.title}>
          <p
            className={classNames(
              commonstyle.fs16,
              commonstyle.semiBold,
              styles.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyle.fs16, styles.mianheading)}
          />
          <p
            className={classNames(
              commonstyle.fs16,
              commonstyle.semiBold,
              styles.mianheading
            )}
          >
            Delete Account
          </p>
        </div>
      </div>
      <div className={classNames(styles.mainDelCard)}>
        <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>Account and Data</span>{" "}
            <span className={style.colorOrange}>Deletion</span>{" "}
          </p>

          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            You have the choice to permanently delete your account and all
            associated data from the MediTour system, through mobile
            application. This will include Service requests, treatment history,
            and cost estimates Healthcare provider interactions (clinics,
            hospitals, doctors) Insurance billing records Reservations (hotels,
            flights, cars, ambulances, tours).
          </p>
        </div>
        <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>What Happens After</span>{" "}
            <span className={style.colorOrange}>Deletion?</span>{" "}
          </p>

          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            All your personal data, including medical and booking records, will
            be permanently removed from MediTour system. You will no longer have
            access to any past transactions, service requests, or bookings. This
            action cannot be restored.
          </p>
        </div>

        <div className={style.mt40}>
          <p
            className={classNames(
              style.fs24,
              style.mt16,
              style.colorBlue,
              commonstyle.semiBold
            )}
          >
            <span className={style.colorBlue}>How to Delete</span>{" "}
            <span className={style.colorOrange}>Your Account:</span>{" "}
          </p>

          <p
            className={classNames(
              style.mt24,
              style.textcolor,
              commonstyle.fs16
            )}
          >
            Login at mobile application, with your registered email ID and
            password. Go to profile section. Then Press button: Delete Account A
            warning message/screen will be appear at your screen for
            confirmation of your account deletion. Your account will be
            permanently deleted within 7 business days. If you need assistance,
            please email at{" "}
            <span className={style.colorOrange}>info@meditour.global.com</span>{" "}
            or contact us at{" "}
            <span className={style.colorOrange}>111-111-864</span>
          </p>
        </div>
      </div>
    </div>
  );
}
