import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./Insuredperson.module.css";
import { IoArrowBack } from "react-icons/io5";
import { Avatar } from "@mui/material";
import User from "assets/images/Userr2.jpeg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { insuranceGETINSURANCEDETAILS } from "shared/services/Insurance";
import { LoadingModal } from "shared/components";
import Downloader from "shared/components/Downloader";
import "./InsuredpersonFile.css";
import { AiOutlineUpload } from "react-icons/ai";
import { PiDownloadSimpleBold } from "react-icons/pi";
import CustomLoader from "shared/components/New_Loader/Loader";
interface Details {
  cnic: string;
  amount: number;
}
interface UserDetails {
  email: string;
  mrNo: string;
  name: string;
  phone: string;
  gender: string;
  dateOfBirth: string;
}
interface locDetails {
  address: string;
  city: string;
}
interface insuranceDetails {
  packageName: string;
  perYear: string;
}
export default function InsuredpersonDetail() {
  const [detail, setdetail] = useState<any>(null);
  const [userdetail, setUserdetail] = useState<UserDetails | null>(null);
  const [location, setLocation] = useState<locDetails | null>(null);
  const [insuranceID, setInsuranceID] = useState<insuranceDetails | null>(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleGoToBack = () => {
    navigate("/insurance/insuredperson");
  };
  const { id } = useParams();
  const handleImageClick = (url: any) => {
    const fileContent = "Hello, this is a text file content.";
    const blob = new Blob([fileContent], { type: "text/plain" });
    // const link = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = "Download ";
    a.click();
  };
  const fetchdetails = () => {
    setLoading(true);
    const insuranceId = id || "";
    insuranceGETINSURANCEDETAILS(insuranceId)
      .then((res: any) => {
        console.log("CONSOLE", res?.data?.insured);
        setdetail(res?.data?.insured);
        setUserdetail(res?.data?.insured?.userId);
        setLocation(res?.data?.insured?.location);
        setInsuranceID(res?.data?.insured?.insuranceId);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchdetails();
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
          <SearchBar />
          <div className={classNames(commonstyles.mr87)}>
            <div className={classNames(commonstyles.outerContainer)}>
              <div className={classNames(commonstyles.flx)}>
                <IoArrowBack className={styles.back} onClick={handleGoToBack} />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Back
                </p>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold,
                    styles.ml56
                  )}
                >
                  Insured ID :
                </p>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.semiBold,
                    styles.ml16
                  )}
                >
                  {userdetail?.mrNo}
                </p>
              </div>
              <div>
                <Avatar src={User} className={styles.profile} />
              </div>
              <div className={classNames(styles.flx)}>
                <div className={classNames(commonstyles.col5)}>
                  <p
                    className={classNames(
                      commonstyles.fs20,
                      commonstyles.semiBold,
                      commonstyles.mb32
                    )}
                  >
                    Basic info
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div className={classNames(commonstyles.col6)}>
                      <p className={styles.mb8}>Insured Person Name:</p>
                      <p className={styles.mb8}>CNIC:</p>
                      <p className={styles.mb8}>Date of Birth:</p>
                      <p className={styles.mb8}>Contact No:</p>
                      <p className={styles.mb8}>Email:</p>
                      <p className={styles.mb8}>Gender:</p>
                      <p className={styles.mb8}>City:</p>
                      <p className={styles.mb8}>Main Address:</p>
                    </div>
                    <div className={classNames(commonstyles.col6)}>
                      <p className={styles.mb8}>{userdetail?.name}</p>
                      <p className={styles.mb8}>34104-1234567-1</p>

                      <p className={styles.mb8}>{userdetail?.dateOfBirth}</p>
                      <p className={styles.mb8}>{userdetail?.phone}</p>
                      <p className={styles.mb8}>{userdetail?.email}</p>

                      <p className={styles.mb8}>{userdetail?.gender}</p>
                      <p className={styles.mb8}>{location?.city}</p>
                      <p className={styles.mb8}>{location?.address}</p>
                    </div>
                  </div>
                </div>
                <div className={classNames(commonstyles.col5)}>
                  <p
                    className={classNames(
                      commonstyles.fs20,
                      commonstyles.semiBold,
                      commonstyles.mb32
                    )}
                  >
                    Package info
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div className={classNames(commonstyles.col6)}>
                      <p className={styles.mb8}>Package Name:</p>
                      <p className={styles.mb8}>Package Amount:</p>
                      <p className={styles.mb8}>Package Duration:</p>
                    </div>
                    <div className={classNames(commonstyles.col6)}>
                      <p className={styles.mb8}>{insuranceID?.packageName}</p>
                      <p className={styles.mb8}>{detail?.amount}</p>
                      <p className={styles.mb8}>{insuranceID?.perYear}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="insured-person-file">
                <div className="file-header">
                  <h4>Insured Person File</h4>
                  <p>Insured person complete details</p>
                </div>
                <div className="file-upload">
                  <div className="file-box">
                    <PiDownloadSimpleBold
                      className="upload-icon"
                      onClick={() => handleImageClick(detail?.insuranceFile)}
                    />
                    <span>File name.png</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
