
import DeleteAccount from "pages/Home/HomeNavBar/NavBarr/DeleteAccount";
import React from "react";
import { Routes, Route } from "react-router-dom";

const DeleteAccountRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<DeleteAccount />} />
    </Routes>
  );
};

export default DeleteAccountRoute;
