import React from "react";
import { Routes, Route } from "react-router-dom";
import DonationServices from "pages/Services/DonationServices";
import PharmacyService from "pages/Services/Pharmacy/PharmacyService";
import PharmacyCart from "pages/Services/Pharmacy/PharmacyCart";
import PharmacyBooking from "pages/Services/Pharmacy/PharmacyBooking";

const PharmacyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PharmacyService />} />
      <Route path="/PharmacyCart" element={<PharmacyCart />} />
      <Route path="/PharmacyBooking" element={<PharmacyBooking />} />

    </Routes>
  );
};

export default PharmacyRoutes;
