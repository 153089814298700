import React, { useState } from "react";

import { Doctor_Login } from "shared/components";
import source1 from "assets/images/physiotherapy 1.png";
import source2 from "assets/images/Doctor 1.png";
import source3 from "assets/images/Phsycologist 1.png";
import source4 from "assets/images/Nutrationist 1.png";

import { useSelector } from "react-redux";

const DoctorLogin = () => {
  const { systemType } = useSelector((state: any) => state.root.common);

  console.log("syes....ss....", systemType);

  const sourceToShow =
    systemType == "physiotherapist"
      ? source1
      : systemType == "doctor"
      ? source2
      : systemType == "psychologist"
      ? source3
      : systemType == "nutritionist"
      ? source4
      : "";

  return (
    <>
      <Doctor_Login
        dashboard_url={`/${systemType}/dashboard`}
        type={systemType}
        image_source={sourceToShow}
      />
    </>
  );
};

export default DoctorLogin;
