import DoctorServices from "pages/Services/DoctarServices";
import DoctorAppoinmentPay from "pages/Services/DoctarServices/DoctorAppoinment";
import DoctorDetails from "pages/Services/DoctarServices/DoctorDetails";
import Nav_SelectionCards from "pages/Services/Nav_SelectionCard/Nav_SelectionCard";
import { Routes, Route } from "react-router-dom";

const Doc_Cards = [
  {
    title: "Doctors",
    content: "Verified Doctors",
    color: "#FF8A8A",
    icon: "🩺",
  },

  {
    title: "Hospital",
    content: "24/7  Service Available",
    color: "#FF8A8A",
    icon: "🏥",
  },
];

const DoctorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DoctorServices />} />
      <Route
        path="/doctor-services/:serviceName"
        element={<DoctorServices />}
      />
      <Route path="/DoctorDetail" element={<DoctorDetails />} />
      <Route
        path="/Selection"
        element={<Nav_SelectionCards data={Doc_Cards} />}
      />
      {/* <Route path="doctor/:type" element={<DoctorServices />} /> */}
      {/* <Route path="/DoctorDetail" element={<DoctorDetail />} /> */}
      <Route
        path="/DoctorAppoinmentPay/:id"
        element={<DoctorAppoinmentPay />}
      />
    </Routes>
  );
};

export default DoctorRoutes;
