import React, { FC } from 'react';
import styles from './PaymentModal.module.css';
import cardIcon from '../../../../assets/images/PayImage.png'; 

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PaymentMyAppointMent: FC<PaymentModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlayPayment}>
      <div className={styles.modalPayment}>
          <button onClick={onClose} className={styles.closeButtonPayment} aria-label="Close">×</button>
        <h2>Payment Details</h2>
        <p>Enter your card information below to proceed with the payment</p>
        <div className={styles.cardDetailsPayment}>
    <img src={cardIcon} alt="Credit Card" className={styles.cardIconPayment} />
    <input type="text" placeholder="4848 4848 4848 4848" className={styles.inputFieldPayment} />
    <input type="text" placeholder="MM/DD" className={styles.inputFieldPayment} />
    <input type="text" placeholder="CVV" className={styles.inputFieldPayment} />  {/* CVV input */}
</div>

        <button onClick={() => {}} className={styles.paymentButtonDetail}>Payment →</button>

      </div>
    </div>
  );
};

export default PaymentMyAppointMent;
