import React, { useEffect, useState } from "react";
import styles from "./freeopd.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "../HomeNavBar/NavBarr";
import Footerr from "../HomeNavBar/Footer";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import DynamicCard from "shared/components/DynamicCard";
import { opdDoctors } from "shared/services";
import { LoadingModal } from "shared/components";
import { useNavigate } from "react-router-dom";
import CustomLoader from "shared/components/New_Loader/Loader";

export default function FreeOpd() {
  const [activeTab, setActiveTab] = useState("Free OPD");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "MediTour Global | Free Opd";
    window.scrollTo(0, 0);
  });

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    OPD();
  }, [activeTab]);

  const OPD = () => {
    setLoading(true);
    let params = {
      searchValue: "",
      isMeditour: activeTab === "Free OPD" ? "true" : "false",
    };

    opdDoctors(params)
      .then((res: any) => {
        console.log("🚀 ~ OPD ~ res:", res?.data);
        setData(res?.data);
      })
      .catch((err: any) => {
        console.log("🚀 ~ OPD ~ err:", err);
      })
      .finally(() => setLoading(false));
  };

  const handleViewMoreClick = (doc: any) => {
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName: "doctor", opdType: activeTab, doc },
    });
  };

  return (
    <div>
      <div className={styles.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            styles.fs48,
            commonstyles.semiBold,
            styles.mianheading
          )}
        >
          Free Outpatient Department
        </p>

        <div className={styles.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, styles.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              styles.mianheading
            )}
          >
            Free OPD
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <DoubleButton
          tab1Label="Free OPD"
          tab2Label="Paid OPD"
          shift={activeTab}
          onTabChange={handleTabChange}
        />
        <DynamicCard data={data} onPressDetail={handleViewMoreClick} />
      </div>

      {loading && <CustomLoader />}

      <Footerr />
    </div>
  );
}

{
  /* <div>
             
             <p
               className={classNames(
                 styles.fs24,
                 styles.mt16,
                 styles.colorBlue,
                 commonstyles.semiBold
               )}
             >
               <span className={styles.colorBlue}>OPD Patient </span>
               <span className={styles.colorOrange}>Services</span>{" "}
             </p>
             <p
               className={classNames(
                 commonstyles.fs16,
                 styles.mt16,
                 styles.textcolor
               )}
               style={{ textAlign: "justify" }}
             >
               At MediTour Global, we are committed to providing accessible,
               high-quality healthcare services to both international and local
               patients through our innovative OPD (Outpatient Department)
               services. Leveraging the power of modern technology, we offer
               comprehensive OPD consultations via video calls, ensuring you
               receive expert medical advice from the comfort of your home.
               <br />
               In our commitment to making healthcare accessible to all,
               MediTour Global offers free OPD services for general check-ups
               and patient screening. These services are designed to provide
               initial medical assessments, take patient history, and help
               identify any underlying health concerns that may require further
               attention. Our free services include:
               <br /> <br /> <strong>General Health Check-Ups:</strong> Basic
               health assessments to monitor your overall well-being.
               <br /> <strong>Patient Screening:</strong> Initial screenings to
               identify potential health issues that need physical check-ups.
               Your case will be forwarded to the right consultant after
               discussing it with you.
               <br /> <strong>Book an Appointment:</strong> Schedule your video
               consultation through our user-friendly online platform. Choose
               between our Paid OPD (Consultants) and free OPD services based
               on your needs.
               <br /> <strong>Connect with a Doctor: </strong>At your scheduled
               time, connect with a doctor through our secure video calling
               system. Discuss your health concerns, symptoms, and medical
               history.
               <br /> <strong>Receive Expert Care:</strong> Get professional
               medical advice, diagnoses, and treatment recommendations from
               experienced healthcare providers.
               <br /> <strong>Follow-Up:</strong> Schedule follow-up
               appointments as needed to ensure continuous care and monitoring
               of your health condition.
             </p>
           </div>{" "}
           <div>
             <p
               className={classNames(
                 styles.fs24,
                 styles.mt16,
                 styles.colorBlue,
                 commonstyles.semiBold
               )}
             >
               <span className={styles.colorBlue}>
                 Why Choose MediTour Global{" "}
               </span>
               <span className={styles.colorOrange}>OPD Services</span>{" "}
             </p>
             <p
               className={classNames(
                 commonstyles.fs16,
                 styles.mt16,
                 styles.textcolor
               )}
               style={{ textAlign: "justify" }}
             >
               <br /> <strong>Convenience: </strong> Access top-notch medical
               care from the comfort of your home.
               <br /> <strong>Expertise:</strong> Consult with leading
               specialists and consultants from Pakistan.
               <br /> <strong>Accessibility:</strong> Benefit from both paid
               and free OPD services tailored to your healthcare needs.
               <br /> <strong>Technology: </strong>Utilize our secure and
               reliable video calling platform for seamless consultations.
               <br /> <br />
               Experience the future of healthcare with MediTour Global’s OPD
               patient services. Whether you’re seeking specialized medical
               advice or a general health check-up, we are here to support your
               health journey every step of the way. Book your video
               consultation today and take a proactive step towards better
               health.
             </p>
           </div>{" "}
         </div>
         <div
           className={classNames(
             commonstyles.col5,
             commonstyles.colsm12,
             styles.mtsm24,
             styles.mtmd32,
             commonstyles.colmd12
           )}
         >
           <div className={classNames(styles.ConsultancyBox, styles.mtsm32)}>
             <p
               className={classNames(
                 styles.fs24,
                 commonstyles.medium,
                 styles.colorBlue
               )}
             >
               Get Free OPD
             </p>
             <p
               className={classNames(
                 commonstyles.fs16,
                 styles.colorGray,
                 styles.mt8
               )}
             >
               Please fill out the form below and we will contact you shortly
             </p>
             <div className={classNames(styles.flexCol, styles.mt24)}>
               <label className={styles.labels}>Name:*</label>
               <input className={styles.inputs} />
             </div>{" "}
             <div className={classNames(styles.flexCol, styles.mt24)}>
               <label className={styles.labels}>Phone Number:*</label>
               <input className={styles.inputs} />
             </div>{" "}
             <div className={classNames(styles.flexCol, styles.mt24)}>
               <label className={styles.labels}>Email:*</label>
               <input className={styles.inputs} />
             </div>
             <div className={classNames(styles.flexCol, styles.mt24)}>
               <label className={styles.labels}>Treatment:*</label>
               <select className={styles.select}>
                 <option></option> <option>ABCD</option> <option>ABCD</option>
               </select>
             </div>
             <div className={classNames(styles.flexCol, styles.mt24)}>
               <label className={styles.labels}>Message:*</label>
               <textarea className={styles.textaera} />
             </div>
             <button className={styles.btnSubmit}>Submit</button>
           </div> */
}
