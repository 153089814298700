import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "./style.module.css";
import logo from "assets/images/screenlogo.png";
import cardimg from "assets/images/celebrate 1.png";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setUser } from "shared/redux";
import { activationAccount } from "shared/services/AuthService";
import CustomLoader from "shared/components/New_Loader/Loader";
import { useNavigate } from "react-router-dom";

const CongratsScreen = () => {
  const { user, paymentParams, systemType } = useSelector(
    (state: any) => state?.root?.common
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    activateVendor();
  }, []);

  const activateVendor = () => {
    setLoading(true);
    let params = {
      vendorType: systemType,
      vendorId: user?._id,
      paymentId: paymentParams?.paymentId,
      gatewayName: "blinq",
    };

    console.log("🚀 ~ activateVendor ~ params:", params);

    activationAccount(params)
      .then((res: any) => {})
      .catch((err: any) => {
        console.log("err while activation......", err?.response?.data.message);
      })
      .finally(() => setLoading(false));
  };

  const removeStorage = () => {
    dispatch(setUser(null));
    dispatch(setIsLoggedIn(false));
    navigate("/");
  };

  return (
    <div className={classNames(style.parent)}>
      <div className={classNames(style.logocontainer)}>
        <img src={logo} alt="Logo" />
      </div>
      <div className={style.Containermain}>
        <div className={style.centerContent}>
          {/* Center Image */}
          <img
            src={cardimg}
            alt="Appointment Done"
            style={{
              width: "480px",
              height: "480px",
              margin: "0 auto",
              display: "block",
            }}
          />

          <h1 className={style.heading}>Congratulations!</h1>

          <p className={style.description}>
            Your appointment has been successfully scheduled. <br />
            Check progress in the appointments section.
          </p>

          <button
            className={style.cardbutton}
            style={{
              backgroundColor: "#001F54",
              color: "#fff",
              padding: "12px 24px",
              fontSize: "16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "20px",
            }}
          >
            Back to Home
          </button>
        </div>
        '
      </div>
      '
    </div>
  );
};

export default CongratsScreen;
