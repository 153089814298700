import React, { useState, useEffect } from "react";
import classNames from "classnames";
import MainhomeStyles from "../../../mainHomeServices.module.css";
import commonStyles from "shared/utils/common.module.css";
import Check from "assets/check.png";
import MainMedicalstyle from "../../../../../pages/MedicalServices/mainMedicalService.module.css";
import { ambulanceVerifySchema } from "shared/utils";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  ambSendCodeToEmail,
  ambVerifyEmail,
  ambCompleteSignup,
} from "shared/services/Ambulance";
import { setIsAmbulanceEmailVerified } from "shared/redux/reducers/ambulance/ambulanceReducer";
import * as Yup from "yup";
import { SuccessModal, VerifyModal } from "shared/components";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { CustomInput, PrimaryButton } from "shared/components";
import CustomSelect from "shared/components/CustomSelect";
import PasswordInput from "shared/components/PasswordInput";
import Modal from "shared/components/ModelTermsAndCondition/Model";
import TermsAndConditions from "shared/components/ModelTermsAndCondition";
const countryCodes = [
  "+92",
  "+1", // United States
  "+1", // Canada
  "+44", // United Kingdom
  "+49", // Germany
  "+33", // France
  "+39", // Italy
  "+61", // Australia
  "+55", // Brazil
  "+91", // India
  "+81", // Japan
  "+86", // China
  "+82", // South Korea
  "+52", // Mexico
  "+7", // Russia
  "+27", // South Africa
  "+234", // Nigeria
  "+54", // Argentina
  "+34", // Spain
  "+90", // Turkey
  "+966", // Saudi Arabia
  // ... add more country codes as needed
];

interface Props {
  handleClickNext: any;
}
const AmbulanceVerification = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const { isAmbulanceEmailVerified, AmbulanceUser_id } = useSelector(
    (state: any) => state.root.ambulance
  );
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = React.useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      ambulancePhoneNo: "",
      ambulanceEmail: "",
      ambulancePassword: "",
      ambulanceConfirmPassword: "",
    },
    validationSchema: Yup.object(ambulanceVerifySchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
  };
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [verifyPassword, setVerifyPassword] = useState("");
  const [repassword, setRePassword] = useState("");
     const [isModalOpen, setModalOpen] = useState(false);


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleCloseModalTerms = () => {
    setModalOpen(false);
  };

  const inputType = passwordVisible ? "text" : "password";
  const inputPassword = showPassword ? "text" : "password";

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const handleSendCodeToEmail = () => {
    setVerifyLoading(true);
    let params = {
      email: formik.values.ambulanceEmail,
    };

    ambSendCodeToEmail(params)
      .then((res: any) => {
        if (res.status == "200" && res.statusText == "OK") {
          setMinutes(1);
          setSeconds(59);
          setShowVerifyModal(true);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
        setVerifyError(err.response.data.message);
      })
      .finally(() => {
        setVerifyLoading(false);
      });
  };

  const handleSubmitCode = () => {
    let params = {
      email: formik.values.ambulanceEmail,
      code: code,
    };

    if (code.length == 6) {
      ambVerifyEmail(params)
        .then((res: any) => {
          if (res.data.status) {
            setSuccessMessage(res.data.message);
            dispatch(setIsAmbulanceEmailVerified(true));
            setVerifyError("");
          }
        })
        .catch((err: any) => {
          setCodeError(err.response.data.message);
        })
        .finally(() => {
          setVerifyLoading(false);
        });
    }
  };

  const handleSubmit = async () => {
    let values = formik.values;
    let params = {
      phoneNumber: values.ambulancePhoneNo,
      email: values.ambulanceEmail,
      password: values.ambulancePassword,
      confirmPassword: values.ambulanceConfirmPassword,
    };

    if (isAmbulanceEmailVerified) {
      ambCompleteSignup(params, AmbulanceUser_id)
        .then((res: any) => {
          console.log("pas....", res);
          if (res.status == "200" && res.statusText == "OK") {
            setShowSuccessModal(true);
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setVerifyLoading(false);
          dispatch(setIsAmbulanceEmailVerified(false));
        });
    } else {
      setVerifyError("Please verify email and phone number first.");
    }
  };
  const handleCloseModal = () => {
    setShowVerifyModal(false);
  };
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={classNames(
            commonStyles.col9,
            commonStyles.colsm12,
            commonStyles.mt56
          )}
        >
          <div className={classNames(commonStyles.mb16, MainhomeStyles.flx)}>
            <div className={classNames(commonStyles.col2)}>
              <div>
                <div>
                  <CustomSelect
                    options={countryCodes}
                    placeholder="+92"
                    onSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(commonStyles.col10)}>
              <CustomInput
                placeholder="Phone Number"
                id="ambulancePhoneNo"
                name="ambulancePhoneNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulancePhoneNo}
              />
              {formik.touched.ambulancePhoneNo &&
              formik.errors.ambulancePhoneNo ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulancePhoneNo}
                </div>
              ) : null}
            </div>
            <button className={MainMedicalstyle.verfybtn}>Verify</button>
            
          </div>
          <div className={classNames(commonStyles.mb16, MainhomeStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <CustomInput
                placeholder="Email Address"
                id="ambulanceEmail"
                name="ambulanceEmail"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.ambulanceEmail}
              />
              {formik.touched.ambulanceEmail && formik.errors.ambulanceEmail ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceEmail}
                </div>
              ) : null}
            </div>
            {isAmbulanceEmailVerified ? (
              <button
                type="button"
                disabled={true}
                className={commonStyles.Verified}
                onClick={handleSendCodeToEmail}
              >
                Verified
              </button>
            ) : (
              <button
                type="button"
                disabled={
                  !verifyLoading &&
                  formik.touched.ambulanceEmail &&
                  !formik.errors.ambulanceEmail
                    ? false
                    : true
                }
                className={
                  formik.touched.ambulanceEmail && !formik.errors.ambulanceEmail
                    ? commonStyles.VerifyActive
                    : commonStyles.Verify
                }
                onClick={handleSendCodeToEmail}
              >
                {verifyLoading ? "loading..." : "Verify"}
              </button>
            )}
          </div>
          <div className={classNames(commonStyles.mb16, MainhomeStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col10,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <PasswordInput
                type={inputType}
                placeholder="Password"
                id="ambulancePassword"
                name="ambulancePassword"
                onChange={formik.handleChange}
                value={formik.values.ambulancePassword}
              />
              {formik.touched.ambulancePassword &&
              formik.errors.ambulancePassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulancePassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainhomeStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col10,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <PasswordInput
                type={inputType}
                placeholder="Re-Password"
                id="ambulanceConfirmPassword"
                name="ambulanceConfirmPassword"
                onChange={formik.handleChange}
                value={formik.values.ambulanceConfirmPassword}
              />
              {formik.touched.ambulanceConfirmPassword &&
              formik.errors.ambulanceConfirmPassword ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.ambulanceConfirmPassword}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.col12)}>
          <div className={classNames(commonStyles.flx)}>
            <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            />

            <div>
               <p className={classNames(commonStyles.regular, commonStyles.fs10)}>
        I agree meditour{" "}
        <span 
          className={classNames(commonStyles.colorOrange)} 
          onClick={handleOpenModal} 
          style={{ cursor: 'pointer' }} 
        >
          Terms & Conditions
        </span>{" "}
        and{" "}
       <a
  className={classNames(commonStyles.colorOrange)}
  href="/privactpolicys"
  target="_blank" 
  rel="noopener noreferrer"
>
  Privacy policy
</a>

      </p>
       {/* Modal Component */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModalTerms}>
        <TermsAndConditions />
      </Modal>
            </div>
          </div>
          {/* <div className={classNames(commonStyles.flx, commonStyles.mb28)}>
            <Checkbox
              size="small"
              style={{
                color: "#ff7631",
                marginLeft: -10,
              }}
            /> */}

            {/* <div>
              <p
                className={classNames(commonStyles.regular, commonStyles.fs10)}
              >
                Please contact via phone or email to assist my inquery
              </p>
            </div> */}
          {/* </div> */}
        </div>
        <div className={MainhomeStyles.buttonWidth}>
          <PrimaryButton
            children={"Submit"}
            colorType={"Ambulance"}
            type="submit"
          />
        </div>
      </form>
      <VerifyModal
        showModal={showVerifyModal}
        handleSubmit={handleSubmitCode}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        setCode={setCode}
        minutes={minutes}
        seconds={seconds}
        handleSendCodeToEmail={handleSendCodeToEmail}
        successMessage={successMessage}
        hanldeCloseModal={handleCloseModal}
      />
      <SuccessModal
        showModal={showSuccessModal}
        successMessage={"Signup Completed Successfully!"}
        hanldeCloseModal={handleCloseSuccessModal}
      />
    </div>
  );
};

export default AmbulanceVerification;
