import React, { useState } from "react";
import { Doctor_Login } from "shared/components";
import Logo from "assets/images/Pharmacy  1.png";
import { useDispatch, useSelector } from "react-redux";
import { pharmaceutical_Login } from "shared/services";
import { set_User } from "shared/redux";
const Pharmaceutical_Login = () => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { user } = useSelector((state: any) => state.root.common);
  const dispatch = useDispatch();
  console.log("....systemType....", systemType);

  const handle_login = (
    body: any,
    setLoading: any,
    setError: any,
    handleDispatchData: any
  ) => {
    pharmaceutical_Login(body)
      .then((res: any) => {
        console.log("....pharmaceutical_Login res....", res?.data);
        handleDispatchData(res?.data?.token, res?.data?.pharmacuetical);
        dispatch(set_User(res?.data?.pharmacuetical));
      })
      .catch((err: any) => {
        setError(err?.response?.data?.message);
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Doctor_Login
        dashboard_url={`/${systemType}/dashboard`}
        type={systemType}
        image_source={Logo}
        handle_login={handle_login}
      />
    </>
  );
};

export default Pharmaceutical_Login;
