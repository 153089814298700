import React, { useEffect } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "./aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../Footer";
import Facilities from "assets/images/World-Class Medical Facilities.png";
import Professionals from "assets/images/Highly Skilled Medical Professionals.png";
import Treatments from "assets/images/Cost-Effective Treatments.png";
import Services from "assets/images/Comprehensive Range of Services.png";
import Times from "assets/images/Shorter Waiting Times.png";
import Language from "assets/images/Cultural and Language Compatibility.png";
import Destinations from "assets/images/Stunning Travel Destinations.png";
import Attention from "assets/images/Personalized Care and Attention.png";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { ABOUTUS } from "shared/utils/mainHeaderQuery";

const AboutUs = React.memo((props) => {
  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBreadCrumbs {...ABOUTUS} />

      <div className={classNames(style.outer, style.mt24)}>
        <p
          className={classNames(
            commonstyles.fs16,
            style.textcolor,
            commonstyles.medium
          )}
          style={{ textAlign: "justify" }}
        >
          MediTour Global is Pakistan's Premier Medical Tourism Company
          dedicated to providing world-class healthcare solutions to patients
          from all over the world. MediTour provides access to Plethora of
          medical treatments available in the most advanced hospitals equipped
          with state-of-the-art equipment and a pool of internationally
          qualified doctors. As pioneers in the medical tourism industry in
          Pakistan, we take pride in connecting you with the country's leading
          hospitals, clinics, and medical professionals, ensuring high-quality
          and affordable medical care. We offer exceptional medical services
          while creating a seamless and stress-free experience for patients. We
          are committed to facilitating access to top-tier healthcare, combining
          medical excellence with personalized care to meet the unique needs of
          each patient.
        </p>
        <div className={classNames(style.flx, commonstyles.col12)}>
          <div className={style.col5}>
            <img alt="" src={Facilities} className={style.IMages} />
          </div>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}>World-Class Medical</span>{" "}
              <span className={style.colorOrange}> Facilities</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              Pakistan is home to state-of-the-art hospitals and medical centers
              that adhere to international standards. Equipped with the latest
              medical technologies and staffed by highly trained healthcare
              professionals, these facilities provide top-notch medical care
              comparable to any leading global institution.
            </p>
          </div>
        </div>
        <div className={classNames(style.flx2, commonstyles.col12)}>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}>Highly Skilled Medical</span>
              <span className={style.colorOrange}> Professionals</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              Pakistani doctors and surgeons are renowned for their expertise
              and experience, many of whom have received training and
              certifications from prestigious institutions worldwide. Their
              dedication to excellence ensures that you receive the best
              possible care and treatment outcomes
            </p>
          </div>{" "}
          <div className={style.col5}>
            <img alt="" src={Professionals} className={style.IMages} />
          </div>
        </div>
        <div className={classNames(style.flx, commonstyles.col12)}>
          <div className={style.col5}>
            <img alt="" src={Treatments} className={style.IMages} />
          </div>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}>Cost-Effective</span>
              <span className={style.colorOrange}> Treatments</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              One of the most significant advantages of choosing Pakistan for
              medical tourism is the cost savings. Medical treatments in
              Pakistan can be up to 70% more affordable compared to Western
              countries, without compromising on quality. This
              cost-effectiveness extends across a wide range of medical
              services, including surgeries, diagnostics, and rehabilitation.
            </p>
          </div>{" "}
        </div>
        <div className={classNames(style.flx2, commonstyles.col12)}>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}> Comprehensive Range of</span>
              <span className={style.colorOrange}> Services</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              Pakistan offers a diverse array of medical treatments and
              procedures, from routine health check-ups and dental care to
              complex surgeries and advanced fertility treatments. The country's
              healthcare system is well-equipped to handle various medical
              needs, ensuring you can find the right care for your condition.
            </p>
          </div>{" "}
          <div className={style.col5}>
            <img alt="" src={Services} className={style.IMages} />
          </div>
        </div>
        <div className={classNames(style.flx, commonstyles.col12)}>
          <div className={style.col5}>
            <img alt="" src={Times} className={style.IMages} />
          </div>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}> Shorter Waiting </span>
              <span className={style.colorOrange}> Times</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              Unlike many countries where patients face long waiting periods for
              medical procedures, Pakistan provides timely access to treatments.
              This promptness is crucial for those seeking urgent care or
              looking to avoid prolonged waiting times for elective surgeries.
            </p>
          </div>{" "}
        </div>
        <div className={classNames(style.flx2, commonstyles.col12)}>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}> Cultural and Language </span>
              <span className={style.colorOrange}> Compatibility</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              Pakistan's hospitable culture and multilingual population make it
              an inviting destination for international patients. English is
              widely spoken in medical settings, ensuring clear communication
              between patients and healthcare providers. Additionally,
              Pakistan's rich cultural heritage and friendly locals enhance the
              overall experience for medical tourists.
            </p>
          </div>{" "}
          <div className={style.col5}>
            <img alt="" src={Language} className={style.IMages} />
          </div>
        </div>
        <div className={classNames(style.flx, commonstyles.col12)}>
          <div className={style.col5}>
            <img alt="" src={Destinations} className={style.IMages} />
          </div>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}> Stunning Travel </span>
              <span className={style.colorOrange}> Destinations</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular,
                style.textjustify
              )}
            >
              Beyond medical care, Pakistan offers breathtaking travel
              destinations that can turn your medical trip into a memorable
              experience. From the majestic mountains of the north to the
              historic sites and vibrant cities, there is much to explore and
              enjoy during your stay.
            </p>
          </div>{" "}
        </div>
        <div className={classNames(style.flx2, commonstyles.col12)}>
          <div className={style.col6}>
            <p
              className={classNames(
                style.fs24,
                style.mt16,
                style.colorBlue,
                commonstyles.semiBold
              )}
            >
              <span className={style.colorBlue}>Personalized Care and</span>
              <span className={style.colorOrange}> Attention</span>{" "}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                style.textcolor,
                commonstyles.regular
              )}
            >
              Medical tourism companies in Pakistan, like MediTour, prioritize
              personalized care and attention. From the initial consultation to
              post-treatment recovery, dedicated teams ensure that every aspect
              of your journey is tailored to your needs, providing comprehensive
              support and assistance throughout.
              <br /> <br />
              Choosing Pakistan for medical tourism means accessing
              high-quality, affordable healthcare while experiencing the warmth
              and beauty of a culturally rich country. At MediTour, we are
              committed to making your medical journey smooth, comfortable, and
              successful, offering you the best of both healthcare and
              hospitality.
            </p>
          </div>{" "}
          <div className={style.col5}>
            <img alt="" src={Attention} className={style.IMages} />
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
});

export default AboutUs;
