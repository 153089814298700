import React, { useEffect, useState, useRef } from "react";
import searchBar from "./search.module.css";
import commomstyle from "../../utils/common.module.css";
import classNames from "classnames";
import Avatar from "@mui/material/Avatar";
import { FaClipboardList } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import { PiChatsCircle } from "react-icons/pi";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { TbAlertSquareFilled } from "react-icons/tb";
import { GoDotFill } from "react-icons/go";
import Menu from "../Menu";
import { style } from "@mui/system";
import { FiFilter } from "react-icons/fi";
import { common } from "@mui/material/colors";
import ActivationCard from "../ActivationCard";
import commonstyles from "shared/utils/common.module.css";

function SearchBar(props: any) {
  const { stripeOpen } = props;
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { labNotification } = useSelector((state: any) => state.root.lab);
  const { pharmacyNotifications } = useSelector(
    (state: any) => state.root.pharmacy
  );
  const handleToggleNotification = () => {
    setIsNotificationOpen((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const handleGoToChat = () => {
    navigate("/chat/message");
  };

  const { lab } = useSelector((state: any) => state.root.lab);
  const { pharmacy } = useSelector((state: any) => state.root.pharmacy);
  const { doctor } = useSelector((state: any) => state.root.doctor);
  const { hospital } = useSelector((state: any) => state.root.hospital);

  const { systemType, user } = useSelector((state: any) => state.root.common);
  let name =
    systemType == "lab"
      ? lab?.labFirstName
      : systemType == "pharmacy"
      ? pharmacy?.pharmFirstName
      : systemType == "doctor"
      ? doctor?.name
      : systemType == "hospital"
      ? hospital?.hospitalFirstName
      : "";

  let notificationData =
    systemType == "lab"
      ? labNotification
      : systemType == "pharmacy"
      ? pharmacyNotifications
      : "No Notifications";

  const notificationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleListItemClick = () => {
    setIsMenuOpen(false); // Close the menu when a list item is clicked
  };

  console.log("......user....... ///", user);

  return (
    <div className={searchBar.sticky}>
      <div>
        {/* <div
        className={classNames(
          searchBar.col7,
          searchBar.colsm6,
          commomstyle.regular,
          searchBar.colmd7,
          searchBar.inputs
        )}
      >
        <input
          type="search"
          placeholder="Search"
          className={classNames(
            searchBar.inputt,
            searchBar.col12,
            commomstyle.light
          )}
        />
        <div
          className={classNames(
            searchBar.flx,
            commomstyle.fs12,
            commomstyle.regular,
            searchBar.postion
          )}
        >
          <FiFilter className={searchBar.filter} onClick={handleMenuToggle} />
          {isMenuOpen && (
            <div className={searchBar.FilterOuter}>
              <div
                className={searchBar.Filterlist}
                onClick={handleListItemClick}
              >
                Filter By Day
              </div>
              <div
                className={searchBar.Filterlist}
                onClick={handleListItemClick}
              >
                Filter By Date
              </div>
            </div>
          )}
          {/* <p>Sort by : </p>
          <select name="cars" id="cars" style={{ color: "black" }}>
            <option value="volvo">Filter By Day</option>
            <option value="saab">Filter By Date</option>
          </select> */}
        {/* </div> */}
        {/* </div>  */}
        <div
          className={classNames(
            searchBar.col12,
            searchBar.colmd2,
            searchBar.colsm4,
            searchBar.flxend
          )}
        >
          {/* <div className={searchBar.yellow}>
          <PiChatsCircle className={searchBar.mesg} onClick={handleGoToChat} />
        </div> */}
          <div className={searchBar.yellow}>
            <IoNotifications
              className={classNames(searchBar.mesg)}
              onClick={handleToggleNotification}
            />
          </div>
          <div className={classNames(searchBar.flx, searchBar.mr87)}>
            <Menu />
          </div>
          {isNotificationOpen && (
            <div className={searchBar.notificationTab} ref={notificationRef}>
              {Array.isArray(notificationData) &&
              notificationData.length > 0 ? (
                notificationData.map((n: any) => (
                  <div className={searchBar.notificationcard} key={n.id}>
                    <div
                      className={classNames(commomstyle.col2, searchBar.Avatar)}
                    >
                      <img
                        src={n?.senderId?.userImage}
                        className={searchBar.Round}
                      />
                    </div>
                    <div
                      className={classNames(commomstyle.col8, searchBar.mr16)}
                    >
                      <p
                        className={classNames(
                          commomstyle.fs16,
                          commomstyle.semiBold
                        )}
                      >
                        {n?.senderId?.name}
                      </p>
                      <p
                        className={classNames(commomstyle.fs14, searchBar.mt8)}
                      >
                        {n?.message}
                      </p>
                    </div>
                    <div className={commomstyle.col2}>
                      <GoDotFill className={searchBar.dot} />
                      <p>
                        {" "}
                        {n?.updatedAt
                          ? new Date(n.updatedAt).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : ""}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className={searchBar.emptynotyification}>
                  <p
                    className={classNames(
                      commomstyle.fs16,
                      commomstyle.semiBold,
                      commomstyle.colorBlue
                    )}
                    style={{ margin: "auto " }}
                  >
                    No Notifications
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {user?.paidActivation === true ? null : (
        <div className={commonstyles.mr87}>
          <ActivationCard />
        </div>
      )}
    </div>
  );
}

export default SearchBar;
