
import BookingRentACarFullPaymentDetail from "pages/Home/HomeNavBar/NavBarr/BookingMenu/BookingRentACarFullPaymentDetail";
import React from "react";
import { Routes, Route } from "react-router-dom";

const BookingRentACarFullPaymentDetailRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<BookingRentACarFullPaymentDetail />} />
    </Routes>
  );
};

export default BookingRentACarFullPaymentDetailRoute;
