import React, { useState, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import empty from "assets/images/empty2.png";
import style from "./InsuranceBB.module.css";
import { FaRegCheckCircle } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import styles from "./InsuranceBB.module.css";
import { insuranceGetAllREQUEST } from "shared/services/Insurance";
import { LoadingModal, RingLoader } from "shared/components";
import { LuCalendarDays, LuUser2 } from "react-icons/lu";
import { BsClock } from "react-icons/bs";
import { TbRefresh } from "react-icons/tb";
import InsuranceEmpty from "shared/components/InsuranceEmpty";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setInsuranceRequestFlag,
  setInsuranceRequestLength,
  setInsuranceRequests,
} from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import moment from "moment";
import CustomLoader from "shared/components/New_Loader/Loader";

interface Insurancess {
  userName: string;
  insuranceFor: string;
  createdAt: string;
}

function InsuranceRequest() {
  const { insuranceRequests, insuranceRequestFlag, insuranceRequestLength } =
    useSelector((state: any) => state.root.insurance);

  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = insuranceRequestLength;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoToDetail = (id: string, item: any) => {
    navigate(`/insurance/request/Detail/${id}`, { state: { item } });
  };
  const [loading, setLoading] = useState(false);
  const getWeekDay = (dateString: string) => {
    return moment(dateString).format("dddd");
  };
  const GetAllRequest = (pageno: number) => {
    setLoading(true);
    insuranceGetAllREQUEST(pageno)
      .then((res: any) => {
        console.log("RESPONSE.......................", res);
        dispatch(setInsuranceRequests(res?.data?.insurances));
        dispatch(setInsuranceRequestLength(res?.data?.totalLength));
      })
      .catch((err: any) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    GetAllRequest(currentPage);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (insuranceRequestLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setInsuranceRequestFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setInsuranceRequestFlag(true));
    }
  };
  useEffect(() => {
    if (insuranceRequestFlag) {
      setLoading(true);
      GetAllRequest(currentPage);
      dispatch(setInsuranceRequestFlag(false));
    }
  }, [insuranceRequestFlag, currentPage]);

  useEffect(() => {
    GetAllRequest(currentPage);
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.outerContainer)}>
          <div
            className={classNames(commonstyles.flxBetween, commonstyles.mb32)}
          >
            <div className={commonstyles.flx}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Requests
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={styles.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={handleRotate}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {insuranceRequests && insuranceRequests.length > 0 ? (
            insuranceRequests.map((data: any) => (
              <div
                className={styles.card}
                key={data._id}
                onClick={() => handleGoToDetail(data._id, data)}
              >
                <div className={classNames(commonstyles.flx)}>
                  <div className={commonstyles.flx} style={{ width: "20%" }}>
                    <LuUser2 className={styles.icon} />
                    <p className={classNames(commonstyles.fs16)}>
                      {data.userId?.name || "__"}
                    </p>
                  </div>
                  <div style={{ width: "20%" }}>
                    <div className={commonstyles.flx}>
                      <LuCalendarDays className={styles.icon} />
                      <p className={classNames(commonstyles.fs16)}>
                        {data?.createdAt
                          ? new Date(data.createdAt).toLocaleDateString(
                              "en-US",
                              {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              }
                            )
                          : "__"}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: "32px", width: "20%" }}
                    className={commonstyles.flx}
                  >
                    {/* <BsClock className={styles.icon} /> */}
                    <p className={classNames(commonstyles.fs16)}>
                      {getWeekDay(data?.createdAt)}
                      {/* {" "}
                      {data &&
                        data.createdAt &&
                        new Date(data.createdAt).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })} */}
                    </p>
                  </div>
                  <div
                    style={{ marginLeft: "32px", width: "20%" }}
                    className={styles.mt32}
                  >
                    <p className={classNames(commonstyles.fs16)}>
                      {data.insuranceFor}
                    </p>
                  </div>
                  <div
                    style={{ marginLeft: "32px", width: "20%" }}
                    className={styles.mt32}
                  >
                    <p className={classNames(commonstyles.fs16)}>
                      {data.status}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <InsuranceEmpty />
          )}
        </div>
      </div>
    </div>
  );
}

export default InsuranceRequest;
