import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const insuranceSignup = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_SIGNUP, params);
};

export const insuranceSendCodeToEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_SEND_CODE_TO_EMAIL, params);
};

export const insuranceConfrimEmail = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_CONFIRM_EMAIL, params);
};
export const insuranceCompleteSignup = (params: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.INSURANCE_COMPLETE_SIGNUP}?id=${id}`,
    params
  );
};

export const insuranceLogin = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_LOGIN, params);
};

export const resetLinkInsurance = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_RESET_LINK, params);
};

export const newPasswordInsurance = (params: any, token: string) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.INSURANCE_NEWPASSWORD}?token=${token}`,
    params
  );
};

export const insuranceLogout = () => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_LOGOUT);
};

export const getHosiptalInsurance = (searchInput: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.INSURANCE_GETHOSPITAL}?search=${searchInput}`
  );
};

export const getLabInsurance = (searchInput: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.INSURANCE_GETLABS}?search=${searchInput}`
  );
};
export const insuranceAddHealth_Individual = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_ADDINDIVIDUALHEALTH, params);
};
export const insuranceAddHealth_Family = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_ADDFAMILYHEALTH, params);
};
export const insuranceAddHealth_Parents = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_ADDPARENTSHEALTH, params);
};

export const insuranceGetAllIndividualINSURANCE = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_GETALLINDIVIDUALINSURANCE);
};
export const insuranceGetAllREQUEST = (pageno: number) => {
  console.log("PAgeno in service", pageno);
  return HTTP_CLIENT.get(
    `${ENDPOINTS.INSURANCE_GETALLREQUEST}?pageno=${pageno}`
  );
};

export const insuranceUpdateProfile = (params: any) => {
  return HTTP_CLIENT.put(ENDPOINTS.INSURANCE_UPDATEPROFILE, params);
};

export const insuranceSingleREQUEST = (id: string) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.INSURANCE_GETREQUEST}?requestId=${id}`);
};

export const insuranceGetInsuredPersons = (pageno: number) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.INSURANCE_INSURREDPERSOSN}?page=${pageno}`
  );
};

export const insuranceDashDetails = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_DASHDETAILS);
};

export const insuranceMonthsGraph = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_GRAPH);
};
export const insuranceAcceptRequest = (params: any) => {
  return HTTP_CLIENT.post(
    ENDPOINTS.INSURANCE_ACCEPTREQUEST,
    params
  );
};

export const insuranceAddTravel_Individual = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_ADDINDIVIDUALTRAVEL, params);
};

export const insuranceGETNDIVIDUALTRAVEL = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_GETNDIVIDUALTRAVEL);
};

export const insuranceADDFAMILYTRAVEL = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.INSURANCE_ADDFAMILYTRAVEL, params);
};

export const insuranceGETFAMILYTRAVEL = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_GETFAMILYTRAVEL);
};

export const insuranceGETFAMILYINSURANCES = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_GETFAMILYINSURANCES);
};

export const insuranceGETPARENTSINSURANCES = () => {
  return HTTP_CLIENT.get(ENDPOINTS.INSURANCE_GETPARENTSINSURANCES);
};

export const insuranceGETINSURANCEDETAILS = (id: string) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.INSURANCE_GETINSUREDPERSONDETAILS}?insuranceBookingId=${id}`
  );
};
