import React, { useEffect, useState } from "react";
import Tableorder from "../../../shared/components/Tables/Tableorder/index";
import styles from "./order.module.css";
import commonstyles from "../../../shared/utils/common.module.css";
import commomstyles from "../../../shared/utils/common.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import { getAllOrders } from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal, RingLoader } from "shared/components";
import { setOrder, setOrderLength, setrenderLabOrderFlag } from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import CustomLoader from "shared/components/New_Loader/Loader";

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const { orders, orderLength } = useSelector((state: any) => state.root.lab);
  const itemsPerPage = 10;
  const totalItems = orderLength;

  const fetchOrders = (pageno: number) => {
    setLoading(true);
    getAllOrders(pageno)
      .then((res: any) => {
        dispatch(setOrder(res?.data?.orders));
        dispatch(setOrderLength(res?.data?.totalOrders));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (orderLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      fetchOrders(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      fetchOrders(currentPage - 1);
    }
  };

  const handleStatusChange = () => {
    fetchOrders(currentPage);
  };

  useEffect(() => {
    setLoading(true);
    fetchOrders(1);
    setCurrentPage(1);
    dispatch(setrenderLabOrderFlag(false));
  }, []);

  return (
    <div className={classNames(commomstyles.col12)}>
      <SearchBar />
      <div className={commomstyles.mr87}>
        <div className={styles.outerContainer}>
          <div className={classNames(styles.flxBetween, commomstyles.mb32)}>
            <div className={classNames(commomstyles.flx)}>
              <p
                className={classNames(
                  commomstyles.fs24,
                  commomstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Order
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={styles.outerRefresh}>
                  <TbRefresh
                    className={styles.RefreshIcon}
                    onClick={() => {
                      fetchOrders(1);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          {/* {loading ? (
            <CustomLoader />
          ) : ( */}
          <div className={commomstyles.mb32}>
            <Tableorder orders={orders} onStatusChange={handleStatusChange} />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
export default Orders;
