import { useEffect } from "react";
import classNames from "classnames";
import ContactUs from "../HomeNavBar/ContactUs";
import styles from "./contactUsPage.module.css";
import Footerr from "../HomeNavBar/Footer";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { CONTACT_US } from "shared/utils/mainHeaderQuery";

export default function ContactUsPage() {
  useEffect(() => {
    document.title = "MediTour Global | Contact Us";
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <NavBreadCrumbs {...CONTACT_US} />
      <div className={classNames(styles.Heading)}>
        <ContactUs />
      </div>
      <Footerr />
    </div>
  );
}
