import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
interface Props {
  placeholder?: string;
  setData?: any;
  onTimeSelect?: (time: string) => void;
  onTimeChange?: (newTime: any) => void;
  defaultValue?: any;
  value?: any;
}

const CustomTimePicker = (props: Props) => {
  const {
    placeholder,
    setData,
    onTimeSelect,
    onTimeChange,
    defaultValue,
    value,
  } = props;

  const handleTimeSelect = (time: string | null) => {
    if (time !== null) {
      setData(time);
      if (onTimeSelect) {
        onTimeSelect(time);
      }
    }
  };

  const handleTimeChange = (newTime: any) => {
    const formattedTime = dayjs(newTime).format("HH:mm");
    setData(formattedTime);
    if (onTimeChange) {
      onTimeChange(formattedTime);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        value={value ? dayjs(value) : null}
        ampm={false}
        label={placeholder}
        sx={{
          width: "100%",

          "& .MuiStack-root": {
            overflow: "visible",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            width: "100%",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            borderRadius: "0px",
            borderBottom: "1px solid",
            padding: "10px 0px",
            fontStyle: "italic",
            fontSize: "14px",
            fontFamily: '"Poppins", sans-serif',
          },
          "& .MuiIconButton-root": {
            padding: "0px",
            color: "#00276d",
          },
          "& .MuiOutlinedInput-input": {
            height: "12px",
            color: "#00276d",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            fontSize: "14px",
            paddingLeft: "0px",
          },
          "& .MuiInputLabel-root": {
            fontSize: "14px",
            fontStyle: "italic",
            fontFamily: '"Poppins", sans-serif',
            color: "#00276d",
            position: "absolute",
            left: "-13px",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px",
            fontSize: "14px",

            "&:hover": {
              borderColor: "#6F6F72 !important",
            },
          },
        }}
        onChange={handleTimeChange}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
