import React, { useState, useEffect } from "react";
import SearchBar from "shared/components/Searchbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonstyle from "shared/utils/common.module.css";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import styles from "../../HomeServices/AmbulanceServices/Ambulances/ambulances.module.css";
import style from "./donationcriteria.module.css";
import { useParams } from "react-router-dom";
import commonstyles from "shared/utils/common.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import {
  donationEditCriteria,
  donationGetCriteria,
  donationdeleteCriteria,
} from "shared/services/Donation";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import { donationAddCriteriaSchema } from "shared/utils";
import CustomSelect from "shared/components/CustomSelect";
import ImagePickerNew from "shared/components/FilePickeInsurance/ImagePickerNew";
import { setCriterionRenderFlag } from "shared/redux";
import { useDispatch } from "react-redux";
import CustomLoader from "shared/components/New_Loader/Loader";

const DonationCriteria = ["Food ", "Education", "Health"];
interface Props {
  setShowAddModal: any;
}
const CriteriaConfirmDelete = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const deleteCriteria = () => {
    setLoading(true);
    const criteriaId = id || "";
    donationdeleteCriteria(criteriaId)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(setCriterionRenderFlag(true));
          navigate("/donation/criteria");
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const { setShowAddModal } = props;
  return (
    <>
      <div className={classNames(styles.modalBackdrop)}>
        <div className={classNames(styles.modalContainer)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IoClose
              className={styles.closeicon}
              // onClick={handleCancel}

              onClick={() => setShowAddModal(false)}
            />
          </div>
          <div className={classNames(commonstyle.flx, commonstyle.flxCol)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              Are you sure ?
            </p>
            <p
              className={classNames(
                commonstyle.colorGray,
                commonstyle.fs16,
                commonstyle.semiBold
              )}
            >
              You want to delete this criteria?
            </p>
            <div
              className={classNames(commonstyle.flx, commonstyle.flxBetween)}
            >
              <button
                className={styles.cancelbtn}
                onClick={() => setShowAddModal(false)}
              >
                No Cancel
              </button>
              <button className={styles.dltbtn} onClick={deleteCriteria}>
                {loading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  "Yes Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface CriDetailsss {
  criteriaName: string;
  description: string;
  _id: string;
  image: string;
}
export default function CriteriaDetail() {
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [Details, setDetails] = useState<CriDetailsss | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleGoToBack = () => {
    navigate("/donation/criteria");
  };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const handleEditModel = () => {
    setShowEditModal(true);
  };
  const GetCriteriaDetails = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      donationGetCriteria(id)
        .then((res: any) => {
          if (res?.status === 200) {
            console.log("CONSOLE", res);
            setDetails(res.data.criteria);
          }
        })
        .catch((err: any) => {
          console.log("send error.nnnnn..", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    GetCriteriaDetails();
  }, []);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />

      {loading ? (
        <CustomLoader />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={commonstyles.outerContainer}>
            <div className={commonstyles.flx}>
              <IoArrowBack
                className={style.ArrowBack}
                onClick={handleGoToBack}
              />
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                {Details?.criteriaName}
              </p>
              <div className={classNames(commonstyles.flx, style.end)}>
                <FaEdit className={style.edit} onClick={handleEditModel} />
                <MdDeleteOutline
                  className={style.Delete}
                  onClick={handleOpenModal}
                />
              </div>
            </div>
            <div
              className={style.criteriaCard}
              style={{
                background: `url(${Details?.image}) no-repeat center center`,
                backgroundSize: "cover",
              }}
            ></div>
            <div className={classNames(commonstyles.col4, style.mt32)}>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.colorBlue
                )}
              >
                {Details?.description}
              </p>
            </div>
          </div>
        </div>
      )}

      {showAddModal && (
        <div>
          <CriteriaConfirmDelete setShowAddModal={setShowAddModal} />
        </div>
      )}

      <CustomModal
        showModal={showEditModal}
        children={
          <Criteria
            setShowEditModal={setShowEditModal}
            Details={Details}
            GetCriteriaDetails={GetCriteriaDetails}
          />
        }
      />
    </div>
  );
}

const Criteria = (props: any) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(false);
  const navigate = useNavigate();
  const handleGoToNextscreen = () => {
    navigate("/donation/Categories");
  };

  const { Details, setShowEditModal, GetCriteriaDetails } = props;
  console.log("Setting", Details);
  const id = Details?._id;
  console.log("ID is", id);
  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const formik = useFormik({
    initialValues: {
      criteriaName: Details.criteriaName || "",
      description: Details.description || "",
      image: Details.image || "",
    },
    validationSchema: Yup.object(donationAddCriteriaSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleImageUrl = (url: any) => {
    setImage(false);
    formik.setFieldValue("image", url);
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("criteriaName", selectedOption);
  };
  const handleSubmit = async () => {
    const currentData = formik.values;
    let params = {
      criteriaName: currentData.criteriaName,
      description: currentData.description,
      image: currentData.image,
    };
    donationEditCriteria(id, params)
      .then((res: any) => {
        console.log("Edit Done", res);
        GetCriteriaDetails();
        dispatch(setCriterionRenderFlag(true));
        setShowEditModal(false);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        console.log(".....");
      });
  };

  useEffect(() => {
    if (Details.image) {
      handleImageUrl(Details.image);
    }
  }, []);

  return (
    <div style={{ width: "400px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div className={commonstyles.flx}>
          <div className={classNames(style.modelHeader, style.mb32)}>
            <p style={{ textAlign: "center" }}>Edit Criteria </p>
            <IoClose className={style.close} onClick={handleCloseModal} />
          </div>
        </div>
        <div className={classNames(style.mb32)}>
          <CustomInput
            placeholder="Criteria Name"
            id="criteriaName"
            name="criteriaName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.criteriaName}
          />

          {/* {formik.touched.criteriaName && formik.errors.criteriaName ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.criteriaName}
            </div>
          ) : null} */}
        </div>
        <div>
          <div className={classNames(commonstyles.col12, style.ImgPicker)}>
            <ImagePickerNew setData={handleImageUrl} />
            {/* {formik.touched.image && formik.errors.image ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.image}
              </div>
            ) : null} */}
          </div>
          <div className={classNames(commonstyles.col12, style.description)}>
            <textarea
              placeholder="Description"
              style={{ resize: "none" }}
              onChange={formik.handleChange}
              id="description"
              name="description"
              value={formik.values.description}
            ></textarea>
            {formik.touched.description &&
            typeof formik.errors.description === "string" ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.description}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <PrimaryButton children={"Edit"} type="submit" colorType={"green"} />
        </div>
      </form>
    </div>
  );
};
