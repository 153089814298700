import React, { useState, useEffect } from "react";
import classNames from "classnames";
import phrMainStyles from "../../pharmacyMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import { useFormik } from "formik";
import { pharmacyInfoSchema } from "shared/utils";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import { CustomInput } from "shared/components";
import ImgPicker from "shared/components/Img-picker";
import { useDispatch, useSelector } from "react-redux";
import { setPharmacyUserFormData } from "shared/redux";
import Datepicker from "shared/components/DatePicker";
import LocationInput from "shared/components/LocationInput";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import dayjs from "dayjs";

interface Props {
  handleClickNext: any;
}
const PharmacyInfo = (props: Partial<Props>) => {
  const { handleClickNext } = props;
  const [logoError, setLogoError] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [cnicError, setCnicError] = useState(false);
  const { pharmacyUserFormData } = useSelector(
    (state: any) => state.root.pharmacy
  );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      pharmacyLogo: "",
      pharmacyLicenseNumber: "",
      pharmacyLicenseImg: "",
      pharmacyLicenseExpiry: "",
      pharmacyOwnerFirstName: "",
      pharmacyOwnerLastName: "",
      pharmacyEmergencyNumber: "",
      pharmacyCnicNumber: "",
      pharmacyCnicImg: "",
      pharmacyCnicExpiry: "",
      address: "",
      lat: "",
      lng: "",
      city: "",
      pharmacyDescription: "",
      pharamcyOpenTime: "",
      pharmacyCloseTime: "",
    },
    validationSchema: Yup.object(pharmacyInfoSchema),
    onSubmit: (values) => {
      console.log(values?.pharamcyOpenTime, "...pharamcyOpenTime");
      handleSubmit();
    },
  });

  useEffect(() => {
    formik.setValues(pharmacyUserFormData);
  }, []);

  const handlePharmacyLogoUrl = (url: any) => {
    setLogoError(false);
    formik.setFieldValue("pharmacyLogo", url);
  };
  const handlePharmacyLicenseUrl = (url: any) => {
    setLicenseError(false);

    formik.setFieldValue("pharmacyLicenseImg", url);
  };
  const handlePharmacyCnicUrl = (url: any) => {
    setCnicError(false);
    formik.setFieldValue("pharmacyCnicImg", url);
  };

  const handleLicenseExpiry = (date: any) => {
    const formattedDate = date.toISOString().split("T")[0];
    console.log("handleLicenseExpiry", formattedDate);
    formik.setFieldValue("pharmacyLicenseExpiry", formattedDate);
  };
  // const handleCnicExpiry = (date: any) => {
  //   const formattedDate = date.toISOString().split("T")[0];
  //   console.log("pharmacyCnicExpiry", formattedDate);
  //   formik.setFieldValue("pharmacyCnicExpiry", formattedDate);
  // };
  const handleSetAddressData = (value: any) => {
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };

  const handlePharmacyOpenTime = (time: any) => {
    console.log(time, ".....time");

    const parsedTime = dayjs(time, "HH:mm", true);
    if (parsedTime.isValid()) {
      const formattedTime = parsedTime.format("hh:mm A");
      console.log("Setting", formattedTime);
      formik.setFieldValue("pharamcyOpenTime", formattedTime);
    } else {
      console.error("Invalid time format:", time);
    }
  };

  const handlePharmacyCloseTime = (time: any) => {
    console.log(time, ".....close time");

    const parsedTime = dayjs(time, "HH:mm", true);
    if (parsedTime.isValid()) {
      const formattedTime = parsedTime.format("hh:mm A");
      console.log("Setting", formattedTime);
      formik.setFieldValue("pharmacyCloseTime", formattedTime);
    } else {
      console.error("Invalid time format:", time);
    }
  };

  const handleSubmit = async () => {
    if (!formik.values.pharmacyLogo) {
      setLogoError(true);
    } else if (!formik.values.pharmacyLicenseImg) {
      setLicenseError(true);
    } else if (!formik.values.pharmacyCnicImg) {
      setCnicError(true);
    } else {
      await dispatch(setPharmacyUserFormData(formik.values));
      handleClickNext();
      setLogoError(false);
      setLicenseError(false);
      setCnicError(false);
    }
  };
  const handleSetData = () => {
    // Handle the selected location data
    console.log();
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col12)}>
          <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col8,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Pharmacy Name"
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                {formik.touched.name && formik.errors.name ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.name}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker
                  placeholder="Pharmacy Logo"
                  setData={handlePharmacyLogoUrl}
                />
                {formik.touched.pharmacyLogo && formik.errors.pharmacyLogo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLogo}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Pharmacy License Number"
                  id="pharmacyLicenseNumber"
                  name="pharmacyLicenseNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyLicenseNumber}
                />
                {formik.touched.pharmacyLicenseNumber &&
                formik.errors.pharmacyLicenseNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLicenseNumber}
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <ImgPicker
                  placeholder="License Image"
                  setData={handlePharmacyLicenseUrl}
                />
                {formik.touched.pharmacyLicenseImg &&
                formik.errors.pharmacyLicenseImg ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLicenseImg}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <Datepicker
                  placeholder="License Expiry "
                  setData={handleLicenseExpiry}
                />
                {formik.touched.pharmacyLicenseExpiry &&
                formik.errors.pharmacyLicenseExpiry ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyLicenseExpiry}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Owner First Name"
                  id="pharmacyOwnerFirstName"
                  name="pharmacyOwnerFirstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyOwnerFirstName}
                />
                {formik.touched.pharmacyOwnerFirstName &&
                formik.errors.pharmacyOwnerFirstName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyOwnerFirstName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Owner Last Name"
                  id="pharmacyOwnerLastName"
                  name="pharmacyOwnerLastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyOwnerLastName}
                />
                {formik.touched.pharmacyOwnerLastName &&
                formik.errors.pharmacyOwnerLastName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyOwnerLastName}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Emergency No."
                  id="pharmacyEmergencyNumber"
                  name="pharmacyEmergencyNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyEmergencyNumber}
                />
                {formik.touched.pharmacyEmergencyNumber &&
                formik.errors.pharmacyEmergencyNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyEmergencyNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, phrMainStyles.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="CNIC / Passport Number (Optional)"
                  id="pharmacyCnicNumber"
                  name="pharmacyCnicNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyCnicNumber}
                />
                {/* {formik.touched.pharmacyCnicNumber && */}
                {/* formik.errors.pharmacyCnicNumber ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.pharmacyCnicNumber} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker
                  placeholder="CNIC / Passport Image (Optional)"
                  setData={handlePharmacyCnicUrl}
                />

                {/* {formik.touched.pharmacyCnicImg && */}
                {/* formik.errors.pharmacyCnicImg ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.pharmacyCnicImg} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <Datepicker
                  placeholder="CNIC / Passport Expiry (Optional)"
                  // setData={handleCnicExpiry}
                />
                {/* {formik.touched.pharmacyCnicExpiry && */}
                {/* formik.errors.pharmacyCnicExpiry ? ( */}
                <div className={classNames(commonStyles.error)}>
                  {/* *{formik.errors.pharmacyCnicExpiry} */}
                </div>
                {/* ) : null} */}
              </div>
            </div>
          </div>

          <div
            className={classNames(
              commonStyles.mb16,
              phrMainStyles.flx,
              commonStyles.col12
            )}
          >
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <LocationInput
                  placeholder="Pharmacy Address"
                  setData={handleSetAddressData}

                  // setLoc={handleSetLatLong}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.address}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.flx,
              commonStyles.mb16,
              commonStyles.col12
            )}
          >
            <div
              className={classNames(
                commonStyles.col12,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <CustomInput
                  placeholder="Description"
                  id="pharmacyDescription"
                  name="pharmacyDescription"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.pharmacyDescription}
                />
                {formik.touched.pharmacyDescription &&
                formik.errors.pharmacyDescription ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyDescription}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.mb16,
              phrMainStyles.flx,
              commonStyles.col12
            )}
          >
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <CustomTimePicker
                  placeholder="Pharmacy Open Time"
                  setData={handlePharmacyOpenTime}
                  value={formik.values.pharamcyOpenTime}
                />

                {formik.touched.pharamcyOpenTime &&
                formik.errors.pharamcyOpenTime ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharamcyOpenTime}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.colsm12,
                commonStyles.mtsm28
              )}
            >
              <div className={classNames(commonStyles.mr32)}>
                <CustomTimePicker
                  placeholder="Pharmacy Close Time"
                  setData={handlePharmacyCloseTime}
                  value={formik.values.pharmacyCloseTime}
                />
                {formik.touched.pharmacyCloseTime &&
                formik.errors.pharmacyCloseTime ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.pharmacyCloseTime}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className={phrMainStyles.buttonWidth}>
          <PrimaryButton
            children={"Next Step"}
            type="submit"
            colorType={"SkyBlueOutine"}
          />
        </div>
      </form>
    </div>
  );
};

export default PharmacyInfo;
