// Gynecologists
import PREGNANCYLOSS from "assets/TreamentImages/RECURRENTPREGNANCYLOSS.jpg";
import HIGHRISK from "assets/TreamentImages/HIGH-RISK-PREGNANCY.png";
import OBSTETRICS from "assets/TreamentImages/OBSTETRICS.jpg";
import Hysteroscopy from "assets/TreamentImages/HYSTEROSCOPY.jpg";
import Laparotomy from "assets/TreamentImages/LAPAROTOMY.jpg";

// COSMETIC SURGERY
import Liposuction from "assets/TreamentImages/LIPOSUCTION.jpg";
import Cosmetic from "assets/TreamentImages/Cosmetic-Rhinoplasty.jpg";
import Breast from "assets/TreamentImages/Breast-Augmentation.jpg";
import Abdominoplasty from "assets/TreamentImages/Abdominoplasty.jpg";

// BARIATRIC SURGERY
import Gastrectomy from "assets/TreamentImages/SleeveGastrectomy.jpg";
import Gastric from "assets/TreamentImages/AdjustableGastricBand.jpg";
import BPD from "assets/TreamentImages/BPDDS.jpg";

// TRANSPLANTS
import Liver from "assets/TreamentImages/LIVERTRANSPLANT.jpg";
import Kideny from "assets/TreamentImages/KIDNEYTRANSPLANT.jpg";
import BoneMarrow from "assets/TreamentImages/BONEMARROWTRANSPLANT.jpg";
import Hair from "assets/TreamentImages/HairTransplant.jpg";

// OPTHALMOLOGY
import Refractive from "assets/TreamentImages/Refractiveerrors.jpg";
import Cataracts from "assets/TreamentImages/cataracts.jpg";
import Glaucoma from "assets/TreamentImages/GLAUCOMA.jpg";
import AGERELATED from "assets/TreamentImages/AGERELATEDMACULARDEGENERATION.jpg";
import Diabetic from "assets/TreamentImages/DiabeticRetinopathy.jpg";
import DryEye from "assets/TreamentImages/DryEyeSyndrome.jpg";

// Cancer
import Hemicolectomy from "assets/TreamentImages/Hemicolectomy.jpg";
import LungCancer from "assets/TreamentImages/LUNGCANCERTREATMENT.jpg";
import ProstateCancer from "assets/TreamentImages/Prostatecancertreatment.jpg";
import StomachCancer from "assets/TreamentImages/Stomachcancertreatment.jpg";
import BreastCancer from "assets/TreamentImages/Breast-cancer-treatment.jpg";
import CervicalCancer from "assets/TreamentImages/Cervicalcancertreatment.jpg";

// ORTHOPEDICS
import TotalHip from "assets/TreamentImages/TOTALHIPREPLACEMENT.jpg";
import TotalKnee from "assets/TreamentImages/TOTALKNEEREPLACEMENT.jpg";
import AnteriorCruciate from "assets/TreamentImages/ANTERIORCRUCIATELIGAMENT.jpg";
import DiscReplacement from "assets/TreamentImages/LUMBAR.jpg";
import KneeArthroscopy from "assets/TreamentImages/KNEEARTHROSCOPY.jpg";

// STEM CELL
import Testicular from "assets/TreamentImages/STEMCELLTHERAPY.jpg";
import Ovarian from "assets/TreamentImages/STEMCELLTHERAPYinOvarianRejuvenation.jpg";
import Erectile from "assets/TreamentImages/Erectile-Dysfunction.jpg";
import Osteoarthritis from "assets/TreamentImages/Osteoarthritis.jpg";
import Cartilage from "assets/TreamentImages/CartilageRegenerationStemCellTherapy.jpg";
import Rheumatoid from "assets/TreamentImages/RheumatoidArthritis.jpg";

// PEDIATRICS
import Respiratory from "assets/TreamentImages/RESPIRATORINFECTIONS.jpg";
import Earinfections from "assets/TreamentImages/EARINFECTIONS.jpg";
import Gastrointestinal from "assets/TreamentImages/GASTROINTESTIALDISORDERS.jpg";
import SkinConditions from "assets/TreamentImages/SKINCONDITIONS.jpg";
import Allergic from "assets/TreamentImages/ALLERGIESANDASTHMA.jpg";
import CHILDHOOD from "assets/TreamentImages/CHILDHOODIMMUNIZATIONS.jpg";
import Developmental from "assets/TreamentImages/DEVELOPMENTALANDBEHAVIORAL.jpg";
import GeneticDisorders from "assets/TreamentImages/GENETICDISORDERS.jpg";

// DENTAL
import Cavities from "assets/TreamentImages/CAVITIESDENTALCARIES.jpg";
import Gumdisease from "assets/TreamentImages/GUMDISEASE.jpg";
import ToothSensitivity from "assets/TreamentImages/TOOTH-SENSITIVITY.jpg";
import ToothLoss from "assets/TreamentImages/TOOTH-LOSS.jpg";
import Malocclusion from "assets/TreamentImages/MALOCCLUSION.jpg";
import OralCancer from "assets/TreamentImages/ORAL-CANCER.jpg";
import Preventive from "assets/TreamentImages/PREVENTIVE-CARE.jpg";
import DentalImplants from "assets/TreamentImages/DENTAL-IMPLANTS.jpg";
import DentalVeneers from "assets/TreamentImages/DENTAL-VENEERS.jpg";

// AESTHETIC TREATMENTS
import Botox from "assets/TreamentImages/BotoxandDysport.jpg";
import Dermal from "assets/TreamentImages/DermalFillers.jpg";
import Kybella from "assets/TreamentImages/Kybella.jpg";
import Sculptra from "assets/TreamentImages/SculptraAesthetic.jpg";
import IntensePulsed from "assets/TreamentImages/IntensePulsed.jpg";
import SkinRejuvenation from "assets/TreamentImages/SkinRejuvenation.jpg";
import LaserHair from "assets/TreamentImages/LaserHairRemoval.jpg";
import FacialsGlow from "assets/TreamentImages/FacialsGlow.jpg";

// PSYCHIATRY
import Depression from "assets/TreamentImages/DEPRESSION.jpg";
import Anxiety from "assets/TreamentImages/ANXIETYDISORDERS.jpg";
import Bipolar from "assets/TreamentImages/BIPOLARDISORDER.jpg";
import Schizophrenia from "assets/TreamentImages/SCHIZOPHRENIA.jpg";
import Attention from "assets/TreamentImages/HYPERACTIVITYDSORDER.jpg";
import PostTraumatic from "assets/TreamentImages/POSTTRAUMATICSTRESSDISORDE.jpg";
import Eating from "assets/TreamentImages/POSTTRAUMATICSTRESSDISORDE.jpg";

// LUNGS
import Chronic from "assets/TreamentImages/CHRONICOBSTRUCTIVE.jpg";
import Asthma from "assets/TreamentImages/ASTHMA.jpg";
import Pneumonia from "assets/TreamentImages/PNEUMONIA.jpg";
import Interstitial from "assets/TreamentImages/INTERSTITIALLUNG.jpg";
import LungsCancer from "assets/TreamentImages/LUNGCANCER.jpg";

// UROLOGY
import TURP from "assets/TreamentImages/TURP.jpg";
import Shock from "assets/TreamentImages/SHOCKWAVELITHOTRIPSY.jpg";
import KidneyBiopsy from "assets/TreamentImages/KIDNEYBIOPSY.jpg";
import Radical from "assets/TreamentImages/RADICALPROSTATECTOMY.jpg";
import Urologic from "assets/TreamentImages/UROLOGICRECONSTRUCTIVESURGERY.jpg";
import Percutaneous from "assets/TreamentImages/PERCUTANEOUSNEPHROLITHOTRIPSY.jpg";

// GASTROENTROLOGYAlzheimer's
import Gastroesophageal from "assets/TreamentImages/GASTROESOPHAGEALREFLUXDISEASE.jpg";
import Peptic from "assets/TreamentImages/PEPTICULCERDISEASE.jpg";
import Inflammatory from "assets/TreamentImages/INFLAMMATORYBOWELDISEASE.jpg";
import Irritable from "assets/TreamentImages/IRRITABLEBOWELSYNDROME.jpg";
import Gallbladder from "assets/TreamentImages/GALLBLADDERANDBILIARY.jpg";
import LiverDiseases from "assets/TreamentImages/LIVERDISEASES.jpg";
import Pancreatic from "assets/TreamentImages/PANCREATICDISORDERS.jpg";

// NEUROLOGY
import Stroke from "assets/TreamentImages/STROKE.jpg";
import Epilepsy from "assets/TreamentImages/EPILEPSY.jpg";
import Alzheimer from "assets/TreamentImages/ALZHEIMERSDISEASEANDOTHERDEMENTIAS.jpg";
import MultipleSclerosis from "assets/TreamentImages/MULTIPLESCLEROSIS.jpg";
import Parkinson from "assets/TreamentImages/PARKINSONSDISEASE.jpg";
import Headaches from "assets/TreamentImages/HEADACHESANDMIGRAINES.jpg";
import Peripheral from "assets/TreamentImages/PERIPHERALNEUROPATHY.jpg";

// Fertility
import Intrauterine from "assets/TreamentImages/Intrauterine.jpg";
import Fertilization from "assets/TreamentImages/InVitroFertilization.jpg";
import Intracytoplasmic from "assets/TreamentImages/Intracytoplasmic.jpg";
import PGT from "assets/TreamentImages/ScreeningforGender.jpg";
import PDG from "assets/TreamentImages/PDGPreimplantationGenetic.jpg";
import STEM from "assets/TreamentImages/STEMErectiledysfunction.jpg";
import STEMCELLTHERAPYTesticular from "assets/TreamentImages/STEMCELLTHERAPYTesticular-.jpg";
import STEMCELLTHERAPYOvarian from "assets/TreamentImages/STEMCELLTHERAPYinOvarianRejuvenation.jpg";

// NEPHROLOGY
import CHRONICs from "assets/TreamentImages/CHRONICKIDNEYDISEASE.jpg";
import AcuteKidney from "assets/TreamentImages/ACUTEKIDNEYINJURY.jpg";
import Glomerulonephritis from "assets/TreamentImages/GLOMERULONEPHRITIS.jpg";
import Polycystic from "assets/TreamentImages/POLYCYSTICKIDNEYDISEASE.jpg";
import KidneyStones from "assets/TreamentImages/KIDNEYSTONES.jpg";
import Urinary from "assets/TreamentImages/URINARYTRACTINFECTIONS.jpg";
import Nephrotic from "assets/TreamentImages/NEPHROTICSYNDROME.jpg";

// E.N.T
import Cholesteatoma from "assets/TreamentImages/CHOLESTEATOMA.jpg";
import Dizziness from "assets/TreamentImages/DIZZINESS.jpg";
import DYSPHAGIA from "assets/TreamentImages/DYSPHAGIADIFFICULTYSWALLOWING.jpg";
import EARINFECTION from "assets/TreamentImages/EARINFECTIONOTITISMEDIA.jpg";
import Sinus from "assets/TreamentImages/SINUSSURGERY.jpg";
import Snoring from "assets/TreamentImages/SLEEPDISORDERSURGERY.jpg";
import Corrective from "assets/TreamentImages/CORRECTIVEBREATHINGSURGERY.jpg";
import Tonsil from "assets/TreamentImages/TONSILREMOVAL.jpg";

// CARDIOVASCULAR & METABOLIC
import Coronary from "assets/TreamentImages/CORONARYHEARTDISEASECHD.jpg";
import HeartFailure from "assets/TreamentImages/HEART-FAILURE.jpg";
import Hypertension from "assets/TreamentImages/HYPERTENSION.jpg";
import Stroke2 from "assets/TreamentImages/STROKE.jpg";
import Arrhythmia from "assets/TreamentImages/ARRHYTHMIA.jpg";
import Cardiac from "assets/TreamentImages/CARDIAC-INFECTIONS.jpg";
import Angiography from "assets/TreamentImages/ANGIOGRAPHY.jpg";
import StentPlacement from "assets/TreamentImages/STENT-PLACEMENT.jpg";
import Angina from "assets/TreamentImages/ANGINA.jpg";
import Atherectomy from "assets/TreamentImages/ATHERECTOMY.jpg";
import Bypass from "assets/TreamentImages/BYPASS-SURGERY.jpg";
import Cardiomyoplasty from "assets/TreamentImages/CARDIOMYOPLASTY.jpg";
import Diabetes from "assets/TreamentImages/DIABETES.jpg";
import HighCholesterol from "assets/TreamentImages/HIGH-CHOLESTEROL.jpg";
import Obesity from "assets/TreamentImages/OBESITY.jpg";
import CardiacValve from "assets/TreamentImages/CARDIAC-VALVE-REPLACEMENT.jpg";
import Angioplasty from "assets/TreamentImages/ANGIOPLASTY.jpg";
import CoronaryArtery from "assets/TreamentImages/CORONARYARTERYBYPASGRAFTINGCABG.jpg";
import Pacemaker from "assets/TreamentImages/PACEMAKER-IMPLANTATION-SURGERY.jpg";

export const treatmentsData = [
  {
    mainTitle: "Gynecologists",
    list: [
      {
        title: "Recurrent Pregnancy Loss",
        img: PREGNANCYLOSS,
        about:
          "Recurrent pregnancy loss includes three or more consecutive spontaneous miscarriages.Miscarriages are any loss before 20 gestational weeks and can be further divided into embryonic, defined as a pregnancy that fails before 10 weeks of gestation, and fetal, defined as a pregnancy that fails at or after 10 weeks of gestation.",
      },
      {
        title: "High Risk Pregnancy",
        img: HIGHRISK,
        about:
          "A high-risk pregnancy is one in which the mother’s health or the baby’s growth and development may be jeopardized due to a new or existing health condition. Health issues may also increase the likelihood of premature birth.If you are over the age of 35 or under the age of 17, you may be carrying a high-risk pregnancy.Other common reasons for a high-risk pregnancy include:Obesity Diabetes Cardiovascular disease Twin pregnancy Pre-eclampsia High blood pressure Previous pregnancy complications",
      },
      {
        title: "Obstetrics",
        img: OBSTETRICS,
        about:
          "A high-risk pregnancy is one in which the mother’s health or the baby’s growth and development may be jeopardized due to a new or existing health condition. Health issues may also increase the likelihood of premature birth.If you are over the age of 35 or under the age of 17, you may be carrying a high-risk pregnancy.Other common reasons for a high-risk pregnancy include:ObesityDiabetes Cardiovascular disease Twin pregnancy Pre-eclampsia High blood pressurePrevious pregnancy complications",
      },
      {
        title: "Hysteroscopy",
        img: Hysteroscopy,
        about:
          "Hysteroscopy has significantly transformed the field of gynecology, providing a preferred approach for managing various gynecological conditions.The advantages of hysteroscopy over traditional surgical methods are evident in cost-effectiveness, patient convenience, diagnostic accuracy, and patient acceptance.As gynecologists have become more familiar with the benefits and techniques of operative hysteroscopy, it has emerged as the primary choice for treating intrauterine pathology.Hysteroscopy, as a minimally invasive technique, offers a relatively low-risk approach that utilizes the natural passageway of the endocervical canal to access the uterine cavity.Refinements in optical and fiberoptic light instrumentation and advancements in operative accessories have enabled high-resolution imaging and excellent visual documentation during hysteroscopy.",
      },
      {
        title: "Laparotomy",
        img: Laparotomy,
        about:
          "A laparotomy is a surgical procedure that allows access to the abdominal cavity for the purpose of examining and treating various abdominal or internal organ conditions. There are several reasons why a laparotomy may be necessary. A laparotomy may be necessary to investigate abdominal or internal organ problems or to treat specific medical conditions.  Identifying the source of abdominal pain, treating abdominal injuries, examining the extent of diseases such as endometriosis or cancer, and responding to emergencies are common reasons for a laparotomy",
      },
    ],
  },

  {
    mainTitle: "COSMETIC SURGERY",
    list: [
      {
        title: "Liposuction",
        img: Liposuction,
        about:
          "Liposuction is a surgical operation for removing unwanted body fat. It’s generally performed on the buttocks, thighs, tummy, arms, and other areas where fat tends to accumulate. The exact amount of fat to be removed is a decision that needs to be made between the patient and the doctor and takes into account both cosmetic and health-related concerns. One thing to be aware of is that liposuction is only useful for removing subcutaneous fat, which is the fat just below the skin. Deep, inner body fat that develops between organs cannot be removed by liposuction and can only be removed through diet and exercise.That said, stubborn fat deposits sometimes build up under the skin. Liposuction can give you a more athletic and toned appearance.",
      },
      {
        title: "Cosmetic Rhinoplasty",
        img: Cosmetic,
        about:
          "Cosmetic rhinoplasty is a special surgery that changes the shape and structure of your nose. This elective surgery can even help to bring balance to your facial features and make you more attractive. It is also useful in helping people who have defects in the nose, resulting in other issues.",
      },
      {
        title: "Breast Augmentation",
        img: Breast,
        about:
          "Breast Augmentation is an elective surgery that is done to change the size and shape of your breasts. Sometimes referred to as a “boob job”, this procedure is done by either transferring fat into your breasts, or by using special implants. With a fat transfer, fat is removed from one part of your body and inserted into your breasts. This will give your breasts better volume and shape, and since it is your own tissue there is no risk of rejection. With implants, special material, either silicone or saline, is surgically inserted into your breasts to achieve better shape and size. Regardless of which method is used, breast augmentation not only enhances your looks but can greatly improve your balance, as well. This is because the contours of your hips will better match those of your breasts, which will improve your overall gait.",
      },
      {
        title: "Abdominoplasty",
        img: Abdominoplasty,
        about:
          "An abdominoplasty is a medical procedure that removes fat from your abdominal wall and tightens your muscles. This results in a flatter abdomen and an improved, healthier physique. A tummy tuck involves surgery where excess fat and skin are removed from your abdomen and muscles are tightened at the same time to give you a firmer and flatter look. A full or partial abdominoplasty can be done depending on your needs. A partial abdominoplasty is usually limited to the lower part of the abdomen and may be all that you need. However, a full abdominoplasty is also not a problem for people who need it.",
      },
    ],
  },

  {
    mainTitle: "BARIATRIC SURGERY",
    list: [
      {
        title: "Sleeve Gastrectomy",
        img: Gastrectomy,
        about:
          "A sleeve gastrectomy surgery is one of several bariatric treatments that reduce the size of the stomach to help the patient lose weight. The surgery is laparoscopic. This means that your surgeon makes small incisions on the abdomen to insert the surgical instruments in order to conduct the surgery.",
      },
      {
        title: "Adjustable Gastric Band",
        img: Gastric,
        about:
          "The adjustable gastric band bariatric surgery involves wrapping a silicone band towards the upper part of the stomach. This will shape the stomach into two portions. A smaller upper portion and a bigger lower portion with the silicone band in between. With a tube attached to the band, your doctor fills the band with saline solution. This makes the band expand and construct the stomach. With a smaller pouch for food intake, the amount of food you eat will drastically decrease. Furthermore, you’ll feel hungry less as your body releases fewer hunger hormones. The entire procedure is laparoscopic and only requires a few small incisions on the abdomen",
      },
      {
        title: "BPD/DS",
        img: BPD,
        about:
          "BPD/DS in complexity surpasses other Bariatric surgeries. But it’s, for this reason, it works better than other solutions. The procedure of BPD/DS focuses on three aspects of the gastric system to combat obesity. In the first step, your doctor removes a big portion of the stomach making it look like a sleeve or a tube. Your doctor then staples the changes done to the stomach. This step ensures that you eat less. Your food intake will decrease dramatically. And because the sleeve fills up faster with a much smaller potion, your body will produce fewer hunger hormones.Next comes the re-routing of the gastric system. Your doctor will connect your stomach directly to the end of the small intestine. By skipping a large part of the intestine, you’ll absorb fewer calories and also fewer nutrients. So far the procedure is similar to gastric sleeve. But the difference between a BPD/DS and a gastric sleeve is in the third step. In a BPD/DS, both the bile and digestive juices work differently to reduce caloric absorption. To do this your doctor connects the small end of your small intestine to the duodenum. It is an extremely successful weight loss surgery. But it puts you at risk for malnutrition so make sure you run blood tests and take your supplements.",
      },
    ],
  },

  {
    mainTitle: "TRANSPLANTS",
    list: [
      {
        title: "Liver Transplant",
        img: Liver,
        about:
          "A sleeve gastrectomy surgery is one of several bariatric treatments that reduce the size of the stomach to help the patient lose weight. The surgery is laparoscopic. This means that your surgeon makes small incisions on the abdomen to insert the surgical instruments to conduct the surgery.",
      },
      {
        title: "Kidney Transplant",
        img: Kideny,
        about:
          "The adjustable gastric band bariatric surgery involves wrapping a silicone band towards the upper part of the stomach. This will shape the stomach into two portions. A smaller upper portion and a bigger lower portion with the silicone band in between. With a tube attached to the band, your doctor fills the band with saline solution. This makes the band expand and construct the stomach. With a smaller pouch for food intake, the amount of food you eat will drastically decrease. Furthermore, you’ll feel hungry less as your body releases fewer hunger hormones. The entire procedure is laparoscopic and only requires a few small incisions on the abdomen",
      },
      {
        title: "Bone Marrow Transplant",
        img: BoneMarrow,
        about:
          "The bone marrow is where all your blood cells are formed from special stem cells. Stem cells found in the bones differentiate to form both the red blood cells, erythrocytes, and white blood cells, leukocytes, of the body. The red blood cells carry the oxygen around the body in the blood while the white blood cells are needed for your immune system. In the case of blood disorders such as aplastic anemia or lymphoma, thalassemia, multiple myeloma, and leukemia, it is then possible to treat and even cure such conditions by replacing the diseased bone marrow with new donor marrow. A successful bone marrow transplant gives you a new source of stem cells from which blood cells can then be made. A person with blood cancer such as leukemia or multiple myeloma can go into remission because of a successful marrow transplant.",
      },
      {
        title: "Hair Transplant ",
        img: Hair,
        about:
          "BPD/DS in complexity surpasses other Bariatric surgeries. But it’s, for this reason, it works better than other solutions. The procedure of BPD/DS focuses on three aspects of the gastric system to combat obesity. In the first step, your doctor removes a big portion of the stomach making it look like a sleeve or a tube. Your doctor then staples the changes done to the stomach. This step ensures that you eat less. Your food intake will decrease dramatically. And because the sleeve fills up faster with a much smaller potion, your body will produce fewer hunger hormones. Next comes the re-routing of the gastric system. Your doctor will connect your stomach directly to the end of the small intestine. By skipping a large part of the intestine, you’ll absorb fewer calories and also fewer nutrients. So far the procedure is similar to gastric sleeve. However, the difference between a BPD/DS and a gastric sleeve is in the third step. In a BPD/DS, both the bile and digestive juices work differently to reduce caloric absorption. To do this your doctor connects the small end of your small intestine to the duodenum. It is an extremely successful weight loss surgery. But it puts you at risk for malnutrition so make sure you run blood tests and take your supplements.",
      },
    ],
  },

  {
    mainTitle: "OPTHALMOLOGY",
    list: [
      {
        title: "Refractive Errors",
        img: Refractive,
        about:
          "Refractive errors include conditions such as myopia (nearsightedness), hyperopia (farsightedness), astigmatism, and presbyopia. These conditions result in blurred vision due to the eye's inability to properly focus light onto the retina.",
      },
      {
        title: "Cataracts",
        img: Cataracts,
        about:
          "Cataracts occur when the natural lens of the eye becomes cloudy, leading to blurred or dimmed vision. Causes may include aging, UV exposure, eye trauma, or certain medical conditions.",
      },
      {
        title: "Glaucoma",
        img: Glaucoma,
        about:
          "Glaucoma is a group of eye conditions characterized by damage to the optic nerve, often associated with increased intraocular pressure (IOP). It can lead to vision loss and blindness if left untreated.",
      },
      {
        title: "Age-Related Macular Degeneration(AMD)",
        img: AGERELATED,
        about:
          "AMD is a progressive eye disease affecting the macula, the central part of the retina responsible for sharp, central vision. It can result in blurred or distorted vision, or a blind spot in the central visual field.",
      },
      {
        title: "Diabetic Retinopathy",
        img: Diabetic,
        about:
          "Diabetic retinopathy is a complication of diabetes that affects the blood vessels in the retina. It can lead to vision loss, including blindness, if not detected and managed early.",
      },
      {
        title: "Dry Eye Syndrome",
        img: DryEye,
        about:
          "Dry eye syndrome occurs when the eyes do not produce enough tears or the tears evaporate too quickly, leading to discomfort and irritation. Causes may include aging, hormonal changes, medications, environmental factors, or underlying medical conditions.",
      },
    ],
  },

  {
    mainTitle: "CANCER",
    list: [
      {
        title: "Hemicolectomy",
        img: Hemicolectomy,
        about:
          "Hemicolectomy involves surgery that removes a portion of your colon, either on the left or the right side, depending on where the problem lies. When this surgery is performed, the diseased part of your large intestine (colon) is removed by a trained surgeon. Once the segment of the colon is successfully taken out, the remaining ends of the colon are then joined together by staples or stitches. This surgery is needed when dealing with cancerous conditions that require the immediate removal of the affected area of your colon. However, a hemicolectomy is also sometimes necessary in cases of severe Crohn’s disease or diverticulitis.",
      },
      {
        title: "Lung Cancer Treatment",
        img: LungCancer,
        about:
          "There are two main types of lung cancer, namely, small cell and non-small cell lung carcinoma. While the latter is the more common of the two, small cell carcinoma is still faced by many people, and unfortunately is often more difficult to detect, meaning that treatment needs to begin right away. Even in the case of non-small cell carcinoma, quickly starting treatment is important. Once the stage of your lung cancer is determined, the type of treatment will be discussed with you. There are several potential treatments including surgery, chemotherapy, targeted therapy, or radiation therapy.",
      },
      {
        title: "Prostate Cancer Treatment",
        img: ProstateCancer,
        about:
          "There are many types of treatment options available to help fight prostate cancer. Your surgeon will determine the extent of your cancer and then they will discuss with you, which treatment plan is right for your situation. Radiation therapy for prostate cancer is a strategic course of action that works by killing the active cancer cells, some types of radiation use photons to accomplish this. This radical therapy destroys the DNA of the actual cancer cells. In many cases, surgery is suggested, even at an early stage of cancer. If your cancer has not yet spread, then this is likely to be a recommended treatment option. There are two types of surgery, a radical prostatectomy, and a laparoscopic prostatectomy. The choice between these two depends on the extent of the cancer and will be discussed with you by your doctor.",
      },
      {
        title: "Stomach Cancer Treatment",
        img: StomachCancer,
        about:
          "There are several treatment options for stomach cancer. The choice of treatment depends on factors such as the stage of your cancer and the consideration of side effects. Your doctor will advise you accordingly. Early-stage cancer can be treated by an endoscopic mucosal resection, where the doctor removes the tumor using an endoscope. This is a non-surgical method; in other cases, surgery may be required in the form of a gastrectomy. A partial or total gastrectomy may be needed in which either part of the stomach or the entire organ is removed, respectively.",
      },
      {
        title: "Breast Cancer Treatment",
        img: BreastCancer,
        about:
          "There are several options available for treating breast cancer. Your experienced physician will work with you to help to decide which course of action is best suited to your situation and which will give you the best chance of recovery. A series of tests will be performed to determine what you’re facing so that the right care can be given to you. The doctor will be able to stage the cancer, in other words, determine how advanced it is, which also will determine the best treatment option. Surgery is necessary for some patients. When this is needed, the surgeon will remove the cancerous tissue and, in some cases, also the nearby lymph nodes. Chemotherapy involves the use of special medicine that helps kill cancer cells. This treatment is often a combination of intravenous medicines and pills. Radiation therapy uses powerful rays, like X-rays, to kill the cancer cells in your breasts.",
      },
      {
        title: "Cervical Cancer Treatment",
        img: CervicalCancer,
        about:
          "The type of cervical cancer treatment you should get depends on many factors. Your experienced doctor will work with you to determine the stage of your cervical cancer, considering the stage of the cancer and other factors such as your general state of health. If caught early enough, surgery is often the best course of action. The cancer can be cut away if it is still very small and has not spread into surrounding tissues. In these cases, a cone biopsy is often done and is the best choice of treatment for the cancer. Other patients, where the cancer has spread further into the cervix and other tissues may require a trachelectomy that removes the cervix, upper region of the vagina, and some of the tissue near the cervix. Others may need a hysterectomy. This is a surgery in which the uterus, ovaries, fallopian tubes, and often also the cervix, nearby lymph nodes, and partial vaginal area are all removed.",
      },
    ],
  },

  {
    mainTitle: "ORTHOPEDICS",
    list: [
      {
        title: "Total Hip Replacement",
        img: TotalHip,
        about:
          "A total hip replacement B/L (bilateral) is a surgery in which both of your hips are replaced. The surgery is common as many people suffer the effects of joint degeneration with time. The hip joint is a ball-and-socket type of joint that allows for a range of motion of the legs. The ball part of the joint is the top section of the femur bone, while the socket part is on your pelvis and is the region with which the ball articulates. At each joint, the replacement is comprised of four parts, namely, a metal socket, some type of liner to facilitate movement at the joint, a ball made of ceramic or metal, and a metal rod. The rod functions for stability and it will be attached to the ball of the joint, and it is placed inside the femur bone to ensure strength and support.	",
      },
      {
        title: "Total Knee Replacement",
        img: TotalKnee,
        about:
          "A total knee replacement B/L is when both knees are replaced by surgery. The knee joint is a complicated structure made up of various bones, ligaments, and cartilage. One of the lower leg bones, the tibia, meets and articulates with the upper leg bone, the femur, at the knee. Metal is used to replace the part of the femur that articulates at the knee. A special piece of plastic with metal is also used to replace part of the tibia which joins at the knee. Sometimes, an artificial kneecap is also used. This is a plastic piece shaped for that function. The knee joint has several cruciate ligaments that attach bone to bone, and in some cases, the posterior cruciate ligament is replaced with a polyethylene post.",
      },
      {
        title: "Anterior Cruciate Ligament (ACL) Reconstruction",
        img: AnteriorCruciate,
        about:
          "The anterior cruciate ligament is a piece of tissue found on the front part of the knee joint and it connects bones. It is only one of several ligaments in the knee, but it is the most often injured during physical activity associated with playing sports. When the ACL is torn, the surgeons can reconstruct it using tissue from the patient. Usually, the patellar ligament or ligament of one of the hamstring tendons is used in the process of making a new ACL. The graft tissue is inserted through two holes, one drilled in the tibia bone and the other, into the femur bone. ",
      },
      {
        title: "Disc Replacement (Cervical/Lumbar)",
        img: DiscReplacement,
        about:
          "Disc replacement is a surgical procedure in which damaged discs in the backbone are replaced. The backbone is made up of several bones called vertebrae which enclose and protect the spinal cord. The discs are the pieces of cartilage found between individual vertebrae that allow us to move and bend our backs. When these discs become damaged or if they deteriorate, then problems arise. A damaged disc can put pressure on one or more nerves causing intractable pain. The ability to move may also be compromised by degenerated disc tissue. In disc replacement surgery, the surgeon removes the damaged disc material and replaces it with a suitable substance; any tissue pushing on the nerves is also removed, thus alleviating pain. The cervical discs are in the neck while the lumbar discs are in the lower back. Where the incision is made depends on which discs need to be replaced. ",
      },
      {
        title: "Knee Arthroscopy",
        img: KneeArthroscopy,
        about:
          "Knee arthroscopy is a surgical technique in which a small camera called an arthroscope is inserted through a tiny opening made in the knee. The procedure can be done to both diagnose and treat a variety of knee joint ailments. Problems that are commonly diagnosed and treated using knee arthroscopy include torn anterior or posterior cruciate ligaments, torn meniscus, damaged cartilage, bone fractures, and removal of cysts of the knee. An arthroscopy is not a major surgery, and it has a low risk of complications, with recovery and outcomes generally being good.",
      },
    ],
  },

  {
    mainTitle: "STEM CELL",
    list: [
      {
        title: "Stem Cell Therapy – Testicular Perfusion (Azoospermia)",
        img: Testicular,
        about:
          "Azoospermia is a condition characterized by zero sperm production and it accounts for 20-30% of the fertility-related issues in men. Advanced research on stem cells has suggested that the multi-differentiation potential of stem cells helps in hormonal regularization, regeneration of male germ cell epithelium, and production of sperms, increasing the chances of a reversal of fertility issues. Stem cells when administered along with exosomes can enhance cellular communication through increased activities and paracrine actions, which further takes care of genetic dysfunctions associated with no sperm production. We have mastered and successfully applied this technique in clinical practice, helping a major chunk of patients to revive spermatogenesis and enjoy fatherhood.",
      },
      {
        title: "Stem Cell Therapy in Ovarian Rejuvenation.",
        img: Ovarian,
        about:
          "The diminished ovarian reserve (infertility) problem can be treated with stem cell therapy using the patient's stem cells without any risks and side effects. In case of diminished ovarian reserve, one's stem cells replenish the ovarian reserve by replicating healthy ovarian cells. It can be applied to all women suffering from diminished ovarian reserve. The stem cells obtained from the patient are multiplied in the laboratory and administered to the patient via vascular access, and the cells replicate the healthy ovarian cells they touch. The success rate of stem cell therapy of diminished ovarian reserve is 67% and above.",
      },
      {
        title: "Erectile Dysfunction",
        img: Erectile,
        about:
          "Erectile Dysfunction (ED) is a condition that can be debilitating for millions of men across the world, causing significant psychological and physical distress. Regenerative medicine is an innovative field of medical science that utilizes specialized techniques to repair or replace damaged cells, tissues, and organs. Erectile Dysfunction (ED) is a condition in which a man has difficulty getting or maintaining an erection sufficient for sexual intercourse. Increasing stress, irregular lifestyles, and various other factors adversely affect our health. Among various other diseases, the health condition that is steadily rising is erectile dysfunction. The condition can be caused due to somatic factors like endocrine, urologic, neurologic, and vascular diseases and also due to psychological factors. For many people, both types of factors can cause erectile dysfunction. Stem cell therapy is a promising form of treatment for erectile dysfunction (ED). Stem cells are natural, undifferentiated cells that can be used to regenerate damaged tissues in the body. In ED, stem cells may be injected directly into the corpora cavernosa, which are the two columns of tissue that run alongside the length of the penis. This allows them to form new blood vessels and regenerate healthy, functioning tissue. Stem cells can also be used to stimulate the production of growth factors and promote the repair of damaged nerves.",
      },
      {
        title: "Osteoarthritis",
        img: Osteoarthritis,
        about:
          "Stem cell therapy – a cutting-edge approach that holds the potential to revolutionize osteoarthritis treatment by addressing the root cause rather than merely alleviating symptoms. This groundbreaking technique harnesses the body’s natural ability to heal and regenerate, offering a beacon of hope to those who have faced the daunting prospect of a life marked by chronic joint pain. Stem cell therapy is a cutting-edge medical treatment that harnesses the regenerative potential of stem cells to repair, replace, or restore damaged or diseased cells, tissues, or organs in the body. Stem cells are unique cells with the remarkable ability to develop into various specialized cell types and to replicate themselves through cell division. This property, known as self-renewal and pluripotency, enables stem cells to differentiate into specific cell types with distinct functions, such as muscle cells, nerve cells, or cartilage cells. Stem cell therapy involves the isolation, extraction, and manipulation of stem cells from various sources, including embryos, umbilical cord blood, bone marrow, adipose tissue (fat), and other adult tissues. These stem cells are then deployed to target areas of injury, disease, or degeneration, where they can integrate and differentiate into the specific cell types needed for tissue repair or regeneration.",
      },
      {
        title: "Cartilage Regeneration Stem Cell Therapy",
        img: Cartilage,
        about:
          "Cartilage Regeneration by using stem cell therapy is a remarkable breakthrough in the field of healthcare. This treatment procedure has opened new doors of hope for people suffering from osteoarthritis. Embryonic stem cells are utilized to treat chronic osteoarthritis with an effective cure.",
      },
      {
        title: "Rheumatoid Arthritis",
        img: Rheumatoid,
        about:
          "Rheumatoid arthritis leads to deformed or damaged joints, which cannot be repaired. Mesenchymal Stem Cells (MSCs) can be used not only for bone regeneration but also for anti-inflammation. Research across the world indicates that the use of MSCs in stem cell therapy can be quite rewarding as Antiaging/ Organ & Tissue Regeneration Stem cell therapy for anti-aging can reduce the signs of aging such as wrinkles, age spots, and other skin issues. The therapy works by utilizing stem cells to regenerate and repair damaged cells, which can help rebuild your skin’s structure and improve its overall appearance. Stem cells are specialized cells that can transform into any cell type within the body, allowing them to repair, replace, and regenerate damaged cells. They can help heal damaged or aging skin by increasing the production of collagen, a protein responsible for giving skin its structure and elasticity.",
      },
    ],
  },

  {
    mainTitle: "PEDIATRICS",
    list: [
      {
        title: "Respiratory Infections",
        img: Respiratory,
        about:
          "Respiratory infections such as the common cold, flu, bronchitis, pneumonia, and croup are common in children. Symptoms may include cough, congestion, fever, sore throat, difficulty breathing, and fatigue.",
      },
      {
        title: "Ear infections (Otitis Media)",
        img: Earinfections,
        about:
          "Ear infections occur when bacteria or viruses cause inflammation and fluid buildup in the middle ear. Symptoms may include ear pain, fever, irritability, difficulty sleeping, and sometimes hearing loss.",
      },
      {
        title: "Gastrointestinal Disorders",
        img: Gastrointestinal,
        about:
          "Gastrointestinal disorders in children include conditions such as gastroenteritis (stomach flu), diarrhea, constipation, gastroesophageal reflux disease (GERD), and inflammatory bowel disease (IBD). Symptoms vary depending on the specific condition but may include abdominal pain, diarrhea, vomiting, constipation, and weight loss.",
      },
      {
        title: "Skin Conditions",
        img: SkinConditions,
        about:
          "Pediatric dermatology encompasses a wide range of skin conditions such as eczema, diaper rash, acne, hives, ringworm, and warts. Symptoms may include rash, itching, redness, bumps, blisters, or discoloration of the skin.",
      },
      {
        title: "Allergic And Asthma",
        img: Allergic,
        about:
          "Allergic conditions such as allergic rhinitis, food allergies, and asthma are common in children. Symptoms may include sneezing, runny or stuffy nose, coughing, wheezing, shortness of breath, chest tightness, hives, or swelling. ",
      },
      {
        title: "Childhood Immunizations",
        img: CHILDHOOD,
        about:
          "Pediatricians administer vaccinations to prevent various infectious diseases such as measles, mumps, rubella, polio, influenza, hepatitis, diphtheria, tetanus, pertussis, and meningitis. Vaccinations protect children from serious illnesses and help prevent outbreaks in the community.",
      },
      {
        title: "Developmental And Behavioral Disorders",
        img: Developmental,
        about:
          "Pediatricians assess and manage developmental delays, autism spectrum disorder, attention-deficit/hyperactivity disorder (ADHD), learning disabilities, and behavioral issues in children.",
      },
      {
        title: "Genetic Disorders",
        img: GeneticDisorders,
        about:
          "Pediatricians diagnose and manage genetic conditions such as Down syndrome, cystic fibrosis, sickle cell disease, muscular dystrophy, and chromosomal abnormalities.",
      },
    ],
  },

  {
    mainTitle: "DENTAL",
    list: [
      {
        title: "Cavities (Dental Caries)",
        img: Cavities,
        about:
          "Cavities are caused by bacteria that produce acid, leading to tooth decay. Treatments include dental fillings to restore the affected tooth's structure and prevent further decay. For more severe cases, procedures like dental crowns or root canal therapy may be necessary.",
      },
      {
        title: "Gum disease (Periodontal Disease)",
        img: Gumdisease,
        about:
          "Gum disease is an infection of the gums and bone that support the teeth, often caused by poor oral hygiene. Treatments range from professional dental cleanings to deep cleaning procedures like scaling and root planning. In advanced stages, surgical interventions may be required.",
      },
      {
        title: "Tooth Sensitivity",
        img: ToothSensitivity,
        about:
          "Tooth sensitivity can result from exposed tooth roots, enamel erosion, or cavities. Treatments may include desensitizing toothpaste, fluoride treatments, dental bonding, or in severe cases, root canal therapy.",
      },
      {
        title: "Tooth Loss",
        img: ToothLoss,
        about:
          "Tooth loss can occur due to various reasons, including decay, gum disease, or trauma. Treatments include dental implants, bridges, or dentures to restore functionality and aesthetics.",
      },
      {
        title: "Malocclusion (Misaligned Teeth)",
        img: Malocclusion,
        about:
          "Malocclusion refers to misalignment of the teeth or jaw, leading to bite problems and potential oral health issues. Treatments range from orthodontic options like braces or clear aligners to correct alignment issues, to surgical interventions for severe cases.",
      },
      {
        title: "Oral Cancer",
        img: OralCancer,
        about:
          "Oral cancer can affect the lips, tongue, cheeks, floor of the mouth, and throat. Early detection is crucial for successful treatment. Treatments may include surgery, radiation therapy, chemotherapy, or a combination of these.",
      },
      {
        title: "Preventive Care",
        img: Preventive,
        about:
          "Regular dental check-ups, professional cleanings, and practicing good oral hygiene habits are essential for preventing dental diseases.",
      },
      {
        title: "Dental Implants",
        img: DentalImplants,
        about:
          "Dental implants are artificial tooth roots that are surgically implanted into the jawbone to support replacement teeth or bridges. They are made of biocompatible materials such as titanium, which fuse with the jawbone through a process called osseointegration.",
      },
      {
        title: "Dental Veneers",
        img: DentalVeneers,
        about:
          "Dental veneers are thin, custom-made shells designed to cover the front surface of teeth, improving their appearance. Made of tooth-colored materials like porcelain or composite resin, veneers are bonded to the front of teeth to change their color, shape, size, or length. They can effectively address various cosmetic issues such as discoloration, chips, cracks, gaps, or misalignments, providing a natural-looking and durable solution for enhancing the aesthetics of a smile. Dental veneers are a popular choice for individuals seeking to achieve a brighter, more symmetrical smile with minimal tooth alteration.",
      },
    ],
  },
  // 2222
  {
    mainTitle: "AESTHETIC TREATMENTS",

    list: [
      {
        title: "Botox and Dysport",
        img: Botox,
        about:
          "This is a simple, non-surgical procedure that can smooth and soften lines of the forehead, crow’s feet, and other areas. The procedure involves injections with tiny needles over a particular area to produce a specific result.",
      },
      {
        title: "Dermal Fillers",
        img: Dermal,
        about:
          "Dermal fillers can soften lines, and wrinkles, and improve facial hollowing or create fuller lips. This treatment provides instant gratification with an immediate result. This process involves the placement of a product into a specific treatment area with improvement upon injection. This may require one or more syringes to achieve the desired effect. Our Aesthetic Nurse can help guide you in planning that best suits your needs and provide you with options for variable results. ",
      },
      {
        title: "Kybella",
        img: Kybella,
        about:
          "Kybella is the first FDA injectable to reduce that “double chin” appearance. At your treatment session, small injections are performed under the chin that permanently reduce submental chin fat, leaving you with a noticeable reduction in fullness.",
      },
      {
        title: "Sculptra Aesthetic",
        img: Sculptra,
        about:
          "Sculptra Aesthetic is a biostimulatory product that is injected into the skin to produce collagen response. This treatment is a natural result that fills in hollowed areas without distorting facial shape.",
      },
      {
        title: "Intense Pulsed Light (IPL) and Photo facials",
        img: IntensePulsed,
        about:
          "Photo rejuvenation is the gold standard of minimally invasive skin treatments to help reverse the visible signs of aging and other skin conditions. Photo rejuvenation skin treatments work with a broad spectrum of light filtered to target irregularities and imperfections of the skin.IPL may help you produce a small amount of collagen and focuses on discolorations such as hyperpigmentation (brown spots), melasma, acne, facial capillaries, and rosacea. Photorejuvenation shrinks pore size.",
      },
      {
        title: "Skin Rejuvenation",
        img: SkinRejuvenation,
        about:
          "Skin resurfacing is a more intense treatment to help treat lines, wrinkles, melasma, or acne scarring. This treatment uses microbeams from a laser to penetrate down to the dermis of the skin. It creates columns of tissue coagulation and promotes a collagen response in those areas.",
      },
      {
        title: "Laser Hair Removal",
        img: LaserHair,
        about:
          "Laser hair removal is a process of permanently removing the hair and hair follicles in the area(s) treated. ",
      },
      {
        title: "Facials Glow",
        img: FacialsGlow,
        about:
          "Facials and Chemical Peels Including the Perfect Derma Peel We offer individualized consultations for facials and chemical peels to meet our patient’s aesthetic goals. Our Licensed Esthetician will consult and assess the patient's needs to determine the treatment option for the best results.",
      },
    ],
  },

  {
    mainTitle: "PSYCHIATRY",
    list: [
      {
        title: "Depression",
        img: Depression,
        about:
          "Depression is a mood disorder characterized by persistent feelings of sadness, hopelessness, and loss of interest or pleasure in activities. Symptoms may include low energy, changes in appetite or sleep patterns, difficulty concentrating, feelings of worthlessness or guilt, and thoughts of suicide.",
      },
      {
        title: "Anxiety Disorders",
        img: Anxiety,
        about:
          "Anxiety disorders involve excessive worry or fear that is out of proportion to the actual threat or situation. Types of anxiety disorders include generalized anxiety disorder (GAD), panic disorder, social anxiety disorder, specific phobias, and obsessive-compulsive disorder (OCD). Symptoms vary but may include excessive worry, restlessness, irritability, muscle tension, rapid heartbeat, sweating, and panic attacks.",
      },
      {
        title: "Bipolar Disorders",
        img: Bipolar,
        about:
          "Bipolar disorder is a mood disorder characterized by alternating periods of mania (elevated mood, increased energy, impulsivity) and depression. Symptoms of mania may include euphoria, excessive energy, decreased need for sleep, racing thoughts, and risky behavior.",
      },
      {
        title: "Schizophrenia",
        img: Schizophrenia,
        about:
          "Schizophrenia is a chronic and severe mental disorder characterized by disturbances in thinking, emotions, perceptions, and behavior. Symptoms may include hallucinations, delusions, disorganized thinking, disorganized behavior, and social withdrawal.",
      },
      {
        title: "Attention-Deficit/Hyperactivity Disorders (ADHD)",
        img: Attention,
        about:
          "ADHD is a neurodevelopmental disorder characterized by symptoms of inattention, hyperactivity, and impulsivity. Symptoms may include difficulty concentrating, excessive fidgeting or restlessness, impulsivity, forgetfulness, and difficulty following instructions.",
      },
      {
        title: "Post-Traumatic Stress Disorders (PTSD)",
        img: PostTraumatic,
        about:
          "PTSD is a mental health condition that can develop after experiencing or witnessing a traumatic event. Symptoms may include intrusive memories, flashbacks, nightmares, avoidance of reminders of the trauma, hypervigilance, and emotional numbness.",
      },
      {
        title: "Eating Disorders",
        img: Eating,
        about:
          "Eating disorders such as anorexia nervosa, bulimia nervosa, and binge-eating disorder involve abnormal eating behaviors and distorted body image. Symptoms vary but may include restrictive eating, binge eating, purging behaviors, excessive exercise, and preoccupation with food and weight.",
      },
    ],
  },

  {
    mainTitle: "LUNGS",
    list: [
      {
        title: "Chronic Obstructive Pulmonary Disease (COPD)",
        img: Chronic,
        about:
          "COPD is a group of progressive lung diseases, including emphysema and chronic bronchitis.shortness of breath, chronic cough, wheezing, and chest tightness are the symptoms of COPD.",
      },
      {
        title: "Asthma",
        img: Asthma,
        about:
          "Asthma is a chronic inflammatory condition of the airways, causing intermittent episodes of wheezing, breathlessness, chest tightness, and coughing.",
      },
      {
        title: "Pneumonia",
        img: Pneumonia,
        about:
          "Pneumonia is an infection that inflames air sacs in one or both lungs, causing symptoms such as cough, fever, chills, chest pain, and difficulty breathing.",
      },
      {
        title: "Lung Cancer",
        img: LungsCancer,
        about:
          "Lung cancer occurs when abnormal cells grow uncontrollably in the lungs, often forming tumors that interfere with lung function.",
      },
      {
        title: "Interstitial Lung Disease (ILDS)",
        img: Interstitial,
        about:
          "ILDs are a group of disorders that cause inflammation and scarring (fibrosis) of the lung tissue, leading to impaired lung function.",
      },
    ],
  },

  {
    mainTitle: "UROLOGY",
    list: [
      {
        title: "TURP",
        img: TURP,
        about:
          "TURP is a surgical procedure that reduces the size of an enlarged prostate hence eliminating all the symptoms that were caused by it. There are two approaches to this. The first procedure of TURP is conducted via a resectoscope where your doctor will insure this tube-like instrument through the tip of your penis. The instrument moves down the path that urine takes leading to the urethra. The retro scope gives your doctor perspective and the right vision to conduct the surgery by removing excess tissue growth around the prostate. After surgery, there will be no sutures, but you’ll need a urinal catheter to ensure that swelling after the procedure doesn’t block your urinary pathway. But this is only for 2 to 3 days until you can urinate without its help. The second approach to removing an enlarged prostate is using a laser-assisted retro scope. Your doctor inserts the tube-like instrument into the penis and emits a green light laser to evaporate the enlarged tissue of the prostate. It’s a less invasive procedure than TURP and goes by the name of laser TURP. You will still need a urine catheter as well because swelling of the prostate is a common side effect. And the catheter will prevent this from blocking your urinary pathway.",
      },
      {
        title: "Shock Wave Lithotripsy",
        img: Shock,
        about:
          "The shock wave lithotripsy treatment has two methods to it. There is the first technique that involves you being in a tub of lukewarm water. You’ll be in the tub as your practitioners locate the exact position of your kidney stone using an X-ray or ultrasound. Once the exact target is determined, you’ll stay in place as your technician aims for 1 to 2 shock waves to break the kidney & ureter stones. This method isn’t very common. In the second method, your technician will have you lie down on a cushion that the waves can penetrate. Similarly, your technician will use an x-ray or ultrasound to locate the kidney stone. Then using 1 to 2 thousand shock waves, the kidney stone breaks down. The procedure isn’t painful, and you’ll be under local anesthesia, general anesthesia, or sedative. Because lithotripsy isn’t surgical, the side effects and complications are minimal. While it’s always better to opt for non-surgical procedures, lithotripsy isn’t always effective.",
      },
      {
        title: "Kidney Biopsy",
        img: KidneyBiopsy,
        about:
          "To perform a kidney biopsy your doctor uses a thin needle to insert through your skin towards the kidney. This procedure is typically done with the help of an imaging device to guide the needle toward the kidney. Usually, an ultrasound probe or CT scan. To avoid pain, your doctor will apply local anesthesia typically with numbing cream. After marking where to make the incision, your doctor will insert the needle and make their way to the kidney with the help of the ultrasound probe.",
      },
      {
        title: "Radical Prostatectomy",
        img: Radical,
        about:
          "Radical prostatectomy is a surgical procedure that removes the prostate gland entirely in addition to neighboring lymph nodes. It’s most necessary for men who suffer from prostate cancer that is localized. The second technique for radical prostatectomy is open. This technique is a traditional surgery where your surgeon creates an incision on the lower abdomen to proceed with the surgery. Typically, the incisions are slightly bigger than in robot-assisted radical prostatectomy.",
      },
      {
        title: "Urologic Reconstructive Surgery",
        img: Urologic,
        about:
          "Urologic reconstructive surgery is a surgical field in urology that revolves around the correction of physical abnormalities. The surgery helps you restore your erectile and functional functions. The field is very extensive & and several cases fit under it. But here’s some of the common cases.",
      },
      {
        title: "Percutaneous Nephrolithotripsy",
        img: Percutaneous,
        about:
          "The procedure of percutaneous Nephrolithotripsy involves making a small incision on your back. Then inserting a Nephroscope and surgical instruments through the incisions, your doctor locates the kidney stone. Using sound waves of high frequency, your doctor breaks down the kidney stone into small pieces. Another machine then vacuums these smaller pieces. The procedure is highly effective in removing kidney stones. In rare cases, you may need another procedure ",
      },
    ],
  },

  {
    mainTitle: "GASTROENTEROLOGY",
    list: [
      {
        title: "Gastroesophageal Reflux Disease (GERD)",
        img: Gastroesophageal,
        about:
          "GERD is a chronic condition characterized by the reflux of stomach acid into the esophagus, leading to symptoms such as heartburn, regurgitation, chest pain, and difficulty swallowing.",
      },
      {
        title: "Peptic Ulcer Disease",
        img: Peptic,
        about:
          "Peptic ulcer disease involves the formation of open sores (ulcers) in the lining of the stomach, duodenum (the first part of the small intestine), or esophagus. Causes may include infection with Helicobacter pylori bacteria, long-term use of nonsteroidal anti-inflammatory drugs (NSAIDs), or excessive alcohol consumption.",
      },
      {
        title: "Inflammatory Bowel Disease (IBD)",
        img: Inflammatory,
        about:
          "IBD encompasses chronic inflammatory conditions of the digestive tract, including Crohn's disease and ulcerative colitis. Symptoms may include abdominal pain, diarrhea, rectal bleeding, weight loss, fatigue, and fever.",
      },
      {
        title: "Irritable Bowel Syndrome (IBS)",
        img: Irritable,
        about:
          "IBS is a functional gastrointestinal disorder characterized by abdominal pain or discomfort, bloating, and changes in bowel habits (diarrhea, constipation, or alternating diarrhea and constipation) without evidence of structural or biochemical abnormalities.",
      },
      {
        title: "Gallbladder And Biliary Tract Disorders",
        img: Gallbladder,
        about:
          "Gallbladder and biliary tract disorders include conditions such as gallstones, cholecystitis (inflammation of the gallbladder), and bile duct obstruction. Symptoms may include abdominal pain, nausea, vomiting, fever, jaundice (yellowing of the skin and eyes), and digestive disturbances.",
      },
      {
        title: "Liver Diseases",
        img: LiverDiseases,
        about:
          "Liver diseases encompass a wide range of conditions affecting the liver, including viral hepatitis (such as hepatitis B and C), fatty liver disease, cirrhosis, liver cancer, autoimmune liver diseases, and genetic liver disorders. Symptoms vary depending on the specific condition but may include fatigue, jaundice, abdominal pain, swelling, itching, and changes in appetite and weight.",
      },
      {
        title: "Pancreatic Disorders",
        img: Pancreatic,
        about:
          "Pancreatic disorders include conditions such as pancreatitis (inflammation of the pancreas), pancreatic cysts, pancreatic cancer, and pancreatic insufficiency. Symptoms may include abdominal pain, nausea, vomiting, diarrhea, weight loss, jaundice, and digestive disturbances.",
      },
    ],
  },

  {
    mainTitle: "NEUROLOGY",
    list: [
      {
        title: "Stroke",
        img: Stroke,
        about:
          "A stroke occurs when blood flow to the brain is disrupted, leading to brain cell damage. Ischemic strokes result from blocked blood vessels, while hemorrhagic strokes occur due to bleeding in the brain. Symptoms may include sudden weakness or numbness on one side of the body, difficulty speaking or understanding speech, severe headache, and loss of balance or coordination.",
      },
      {
        title: "Epilepsy",
        img: Epilepsy,
        about:
          "Epilepsy is a neurological disorder characterized by recurrent seizures, which are abnormal electrical disturbances in the brain. Seizure types and symptoms vary but may include convulsions, temporary confusion, staring spells, and loss of consciousness.",
      },
      {
        title: "Alzheimer's Disease And Other Dementia",
        img: Alzheimer,
        about:
          "Alzheimer's disease is a progressive neurodegenerative disorder characterized by memory loss, cognitive decline, and changes in behavior and personality. Other types of dementia include vascular dementia, Lewy body dementia, and frontotemporal dementia. Symptoms may include memory impairment, language difficulties, impaired reasoning, disorientation, and changes in mood or behavior.",
      },
      {
        title: "  Multiple Sclerosis (MS)",
        img: MultipleSclerosis,
        about:
          "MS is a chronic autoimmune disease that affects the central nervous system, leading to inflammation, demyelination (damage to the protective covering of nerves), and neurological symptoms. Symptoms vary widely and may include fatigue, weakness, numbness or tingling, vision problems, difficulty walking, and muscle stiffness or spasms.",
      },
      {
        title: " Parkinson's Disease",
        img: Parkinson,
        about:
          "Parkinson's disease is a progressive neurodegenerative disorder characterized by tremors, bradykinesia (slowness of movement), rigidity, and postural instability. Symptoms may also include freezing of gait, stooped posture, and non-motor symptoms such as depression, anxiety, and sleep disturbances.",
      },
      {
        title: "Headaches And Migraines",
        img: Headaches,
        about:
          "Headaches and migraines are common neurological conditions characterized by pain or discomfort in the head or neck region. Symptoms may include throbbing or pulsating pain, sensitivity to light or sound, nausea, and visual disturbances.",
      },
      {
        title: " Peripheral Neuropathy",
        img: Peripheral,
        about:
          "Peripheral neuropathy refers to damage or dysfunction of the peripheral nerves, leading to symptoms such as numbness, tingling, weakness, and pain in the extremities. Causes may include diabetes, infections, autoimmune diseases, toxins, and genetic factors.",
      },
    ],
  },

  {
    mainTitle: "FERTILITY",
    list: [
      {
        title: "Intrauterine Insemination (IUI) ",
        img: Intrauterine,
        about:
          "Intrauterine insemination is a procedure in which a man’s sperm are placed directly into the uterus, and the sperm used is either that of the partner of the woman or from a donor; it is a procedure that is commonly used in situations where there is a problem with the woman’s cervix or the man has a sperm motility issue or low sperm count. This is a painless treatment that is sometimes done in conjunction with medicines that stimulate the production of eggs from the ovary; the process involves the doctor inserting the sperm through the vagina, past the cervix, and into the uterus; a pregnancy test is usually done about 14 days after the intrauterine insemination procedure is done.",
      },
      {
        title: "In Vitro Fertilization (IVF)",
        img: Fertilization,
        about:
          "In Vitro Fertilization (IVF), is a very effective way for many people to achieve fertilization; the process can be done in several ways, either using the egg and sperm of the two parents or using one or more sex cells from donors; the latter may be necessary in some cases but it depends on the exact cause of infertility. During IVF, the eggs of the woman or donor are removed and then fertilized with sperm in a laboratory setting; the fertilized embryo is then placed inside the woman’s uterus, but in some cases, a surrogate may be used for the gestation. The procedure is only minimally invasive and it is not high risk; the outcomes are normally very good with a high success rate; and many people have been able to have children thanks to IVF.",
      },
      {
        title: "Intracytoplasmic Sperm Injection (ICSI)",
        img: Intracytoplasmic,
        about:
          "ICSI (Intracytoplasmic Sperm Injection) is a common technique used in the process of in vitro fertilization which takes IVF one step further. This treatment option involves a single sperm being injected directly into the egg allowing the sperm to bypass several steps in the fertilization process. Instead of conception in a petri dish, this process involves injecting a single sperm directly into an egg. In our on-site fertility laboratory, we locate and separate individual sperm from a semen sample. Once the sperm cell is selected, based on normal head shape, good motility, and overall performance, the sperm cell is then inserted into an egg to fertilize. Several timed follow-up steps occur similar to a typical IVF procedure, including embryo development.ICSI is tested worldwide, and experience shows that ICSI is successful in achieving fertilization, the establishment of a pregnancy, and the birth of a healthy baby.",
      },
      {
        title: "PGT/PGS (Preimplantation Genetic Testing/Screening) for Gender",
        img: PGT,
        about:
          "Advanced reproductive technology allows for embryos to be created through in vitro fertilization (IVF) and screened for chromosomal and genetic abnormalities. This screening allows fertility specialists to select the healthiest embryos, with the greatest potential for implantation. Preimplantation genetic screening (PGS) counts the number of chromosomes in an embryo and can rule out chromosomal number abnormalities.",
      },
      {
        title: "PDG (Preimplantation Genetic Diagnosis)  for Gender Selection",
        img: PDG,
        about:
          "PGD is a type of genetic testing done during in vitro fertilization (IVF) treatment. This test identifies specific genetic conditions, allowing the fertility specialist to choose the healthiest embryos, increasing the chance of a successful pregnancy and a healthy baby. It is a means of avoiding transmission of a specific genetic disorder without a couple having the possibility of the baby being affected and therefore having to face the decision of terminating the pregnancy.PGD can decrease the risk of miscarriage and this technology can help you achieve a healthy pregnancy. Individuals who suspect or know they carry genes for serious medical conditions may opt to screen for healthy embryos ahead of time. PGD can test for over 400 different disorders such as cystic fibrosis, sickle cell anemia, Huntington’s disease, or other hereditary conditions. Preimplantation Genetic Diagnosis (PGD) is a more enhanced process than Preimplantation Genetic Screening (PGS) since it scans for individual genes.",
      },
      {
        title: "STEM Erectile Dysfunction",
        img: STEM,
        about:
          "Erectile Dysfunction (ED) is a condition that can be debilitating for millions of men across the world, causing significant psychological and physical distress. Regenerative medicine is an innovative field of medical science that utilizes specialized techniques to repair or replace damaged cells, tissues, and organs. Erectile Dysfunction (ED) is a condition in which a man has difficulty getting or maintaining an erection sufficient for sexual intercourse. Increasing stress, irregular lifestyles, and various other factors adversely affect our health. Among various other diseases, the health condition that is steadily rising is erectile dysfunction. The condition can be caused due to somatic factors like endocrine, urologic, neurologic, and vascular diseases and also due to psychological factors. For many people, both types of factors can cause erectile dysfunction. Stem cell therapy is a promising form of treatment for erectile dysfunction (ED). Stem cells are natural, undifferentiated cells that can be used to regenerate damaged tissues in the body. In ED, stem cells may be injected directly into the corpora cavernosa, which are the two columns of tissue that run alongside the length of the penis. This allows them to form new blood vessels and regenerate healthy, functioning tissue. Stem cells can also be used to stimulate the production of growth factors and promote the repair of damaged nerves.",
      },
      {
        title: "STEM CELL THERAPY – Testicular Perfusion (Azoospermia)",
        img: STEMCELLTHERAPYTesticular,
        about:
          "Azoospermia is a condition characterized by zero sperm production and it accounts for 20-30% of the fertility-related issues in men. Advanced research on stem cells has suggested that the multi-differentiation potential of stem cells helps in hormonal regularization, regeneration of male germ cell epithelium, and production of sperms, increasing the chances of a reversal of fertility issues. Stem cells when administered along with exosomes can enhance cellular communication through increased activities and paracrine actions, which further takes care of genetic dysfunctions associated with no sperm production. We have mastered and successfully applied this technique in clinical practice, helping a major chunk of patients to revive spermatogenesis and enjoy fatherhood.",
      },
      {
        title: "STEM CELL THERAPY in Ovarian Rejuvenation",
        img: STEMCELLTHERAPYOvarian,
        about:
          "The diminished ovarian reserve (infertility) problem can be treated with stem cell therapy using the patient's stem cells without any risks and side effects. In case of diminished ovarian reserve, one's stem cells replenish the ovarian reserve by replicating healthy ovarian cells. It can be applied to all women suffering from diminished ovarian reserve. The stem cells obtained from the patient are multiplied in the laboratory and administered to the patient via vascular access, and the cells replicate the healthy ovarian cells they touch. The success rate of stem cell therapy of diminished ovarian reserve is 67% and above.",
      },
    ],
  },

  {
    mainTitle: "NEPHROLOGY",
    list: [
      {
        title: "Chronic  Kidney Disease (CKD)",
        img: CHRONICs,
        about:
          "CKD is a progressive condition characterized by the gradual loss of kidney function over time. Causes include diabetes, hypertension, glomerulonephritis, polycystic kidney disease, and other systemic conditions. Symptoms may include fatigue, swelling, changes in urine output, nausea, and difficulty concentrating.",
      },
      {
        title: "Acute Kidney Injury (AKI)",
        img: AcuteKidney,
        about:
          "AKI is a sudden and temporary loss of kidney function, often due to factors such as dehydration, infections, medication toxicity, or reduced blood flow to the kidneys. Symptoms may include decreased urine output, fluid retention, electrolyte imbalances, and confusion.",
      },
      {
        title: "Glomerulonephritis",
        img: Glomerulonephritis,
        about:
          "Glomerulonephritis is inflammation of the glomeruli, the tiny filters in the kidneys responsible for removing waste and excess fluids from the blood. Causes may include infections, autoimmune diseases, genetic disorders, and certain medications. Symptoms vary but may include blood in the urine, proteinuria, swelling, high blood pressure, and decreased urine output.",
      },
      {
        title: "Polycystic Kidney  Disease (PKD)",
        img: Polycystic,
        about:
          "PKD is an inherited disorder characterized by the growth of numerous fluid-filled cysts in the kidneys, leading to kidney enlargement and progressive loss of function. Symptoms may include flank pain, high blood pressure, urinary tract infections, blood in the urine, and kidney stones.",
      },
      {
        title: "Kidney Stones",
        img: KidneyStones,
        about:
          "Kidney stones are hard mineral deposits that form in the kidneys and can cause severe pain when they pass through the urinary tract. Causes include dehydration, dietary factors, genetics, and certain medical conditions. Symptoms may include intense pain in the back or side, nausea, vomiting, blood in the urine, and frequent urination.",
      },
      {
        title: "Urinary Tract Infections (UTIs)",
        img: Urinary,
        about:
          "UTIs are bacterial infections that affect the urinary tract, including the kidneys, bladder, ureters, and urethra. Symptoms may include frequent urination, burning sensation during urination, cloudy or foul-smelling urine, abdominal pain, and fever.",
      },
      {
        title: "Nephrotic Syndrome",
        img: Nephrotic,
        about:
          "Nephrotic syndrome is a group of symptoms that occur when the kidneys leak large amounts of protein into the urine, leading to edema (swelling), hypoalbuminemia (low blood albumin levels), hyperlipidemia (high blood lipid levels), and proteinuria. Causes may include glomerulonephritis, diabetes, lupus, and other kidney diseases.",
      },
    ],
  },

  {
    mainTitle: "E.N.T",
    list: [
      {
        title: "Cholesteatoma",
        img: Cholesteatoma,
        about:
          "Cholesteatoma is an abnormal, non-cancerous growth of skin cells in the middle ear behind the eardrum. It can cause hearing loss, chronic ear infections, ear drainage, and dizziness.",
      },
      {
        title: "Dizziness",
        img: Dizziness,
        about:
          "Dizziness is a sensation of lightheadedness, unsteadiness, or spinning. It can be caused by various factors, including inner ear disorders, vestibular migraines, medication side effects, or neurological conditions.",
      },
      {
        title: "Dysphagia (DIFFICULTY SWALLOWING)",
        img: DYSPHAGIA,
        about:
          "Sinus surgery is a procedure to improve sinus drainage and alleviate chronic sinusitis symptoms such as facial pain, congestion, and recurrent infections. It may involve endoscopic sinus surgery to remove blockages, widen the sinus openings, or repair structural abnormalities.",
      },
      {
        title: "Ear Infection (OTITIS MEDIA)",
        img: EARINFECTION,
        about:
          "Surgery for snoring and sleep disorders may involve procedures such as uvulopalatopharyngoplasty (UPPP), septoplasty, or turbinate reduction to address obstructive sleep apnea (OSA) or other sleep-related breathing disorders. The goal is to improve airflow and reduce symptoms such as snoring, gasping, and daytime fatigue.",
      },
      {
        title: "Sinus Surgery",
        img: Sinus,
        about:
          "Sinus surgery is a procedure to improve sinus drainage and alleviate chronic sinusitis symptoms such as facial pain, congestion, and recurrent infections. It may involve endoscopic sinus surgery to remove blockages, widen the sinus openings, or repair structural abnormalities.",
      },
      {
        title: "Snoring/Sleep Disorder Surgery",
        img: Snoring,
        about:
          "Surgery for snoring and sleep disorders may involve procedures such as uvulopalatopharyngoplasty (UPPP), septoplasty, or turbinate reduction to address obstructive sleep apnea (OSA) or other sleep-related breathing disorders. The goal is to improve airflow and reduce symptoms such as snoring, gasping, and daytime fatigue.",
      },
      {
        title: "Corrective Breathing Surgery",
        img: Corrective,
        about:
          "Corrective breathing surgery aims to improve nasal airflow and alleviate breathing difficulties caused by nasal valve collapse, deviated septum, or other structural abnormalities in the nose. Procedures may include septoplasty, rhinoplasty, or nasal valve repair to optimize breathing function",
      },
      {
        title: "Tonsil Removal (Tonsillectomy)",
        img: Tonsil,
        about:
          "Tonsillectomy is a surgical procedure to remove the tonsils, often performed to treat recurrent tonsillitis, obstructive sleep apnea, or breathing difficulties caused by enlarged tonsils. The procedure can reduce the frequency of throat infections and improve breathing and sleep quality",
      },
    ],
  },

  {
    mainTitle: "CARDIOVASCULAR & METABOLIC",
    list: [
      {
        title: "Coronary Heart Disease (CHD)",
        img: Coronary,
        about:
          "CHD is a condition where plaque builds up inside the coronary arteries, leading to reduced blood flow to the heart muscle. Symptoms may include chest pain (angina), shortness of breath, and heart attack.",
      },
      {
        title: "Heart Failure",
        img: HeartFailure,
        about:
          "Heart failure occurs when the heart is unable to pump enough blood to meet the body's needs. Symptoms may include shortness of breath, fatigue, swelling of the legs, and difficulty exercising.",
      },
      {
        title: "Hypertension",
        img: Hypertension,
        about:
          "Hypertension, or high blood pressure, is a condition where the force of blood against the artery walls is consistently too high. It is often asymptomatic but can lead to serious health complications if left untreated.",
      },
      {
        title: "Stroke",
        img: Stroke2,
        about:
          "A stroke occurs when blood flow to the brain is disrupted, leading to brain cell damage. Symptoms may include sudden numbness or weakness in the face, arm, or leg, confusion, trouble speaking or understanding, and severe headache.",
      },
      {
        title: "Arrhythmia",
        img: Arrhythmia,
        about:
          "Arrhythmia refers to abnormal heart rhythms, which can be too slow (bradycardia) or too fast (tachycardia). Symptoms may include palpitations, dizziness, fainting, chest pain, or shortness of breath.",
      },
      {
        title: "Cardiac Infections",
        img: Cardiac,
        about:
          "Cardiac infections, such as endocarditis or myocarditis, are infections of the heart tissue or valves. Symptoms may include fever, fatigue, chest pain, and shortness of breath ",
      },
      {
        title: "Angiography",
        img: Angiography,
        about:
          "Angiography is a medical imaging technique used to visualize the blood vessels in the body, including the coronary arteries. It helps diagnose blockages or narrowing in the arteries and guide treatment decisions.",
      },
      {
        title: "Stent Placement",
        img: StentPlacement,
        about:
          "Stent placement involves inserting a small mesh tube (stent) into a narrowed artery to keep it open and improve blood flow.",
      },
      {
        title: "Angina",
        img: Angina,
        about:
          "Angina is chest pain or discomfort that occurs when the heart muscle doesn't get enough oxygen-rich blood. It is often a symptom of underlying coronary artery disease.",
      },
      {
        title: "Atherectomy",
        img: Atherectomy,
        about:
          "Atherectomy is a procedure to remove plaque buildup from blood vessels using specialized catheters or devices",
      },
      {
        title: "Bypass Surgery",
        img: Bypass,
        about:
          "Bypass surgery (coronary artery bypass grafting) is a procedure to reroute blood flow around blocked or narrowed coronary arteries using grafts from other blood vessels.",
      },
      {
        title: "Cardiomyoplasty",
        img: Cardiomyoplasty,
        about:
          "Cardiomyoplasty is a surgical procedure where skeletal muscle is wrapped around the heart to assist its pumping action in cases of heart failure.",
      },
      {
        title: "Diabetes",
        img: Diabetes,
        about:
          "Diabetes is a chronic condition characterized by high blood sugar levels, either due to inadequate insulin production (type 1 diabetes) or insulin resistance (type 2 diabetes). It can lead to various complications, including cardiovascular disease, neuropathy, kidney disease, and vision problems.",
      },
      {
        title: "High Cholesterol",
        img: HighCholesterol,
        about:
          "High cholesterol refers to elevated levels of cholesterol in the blood, which can lead to plaque buildup in the arteries and increase the risk of heart disease and stroke.",
      },
      {
        title: "Obesity",
        img: Obesity,
        about:
          "Obesity is a medical condition characterized by excess body fat accumulation, which can increase the risk of various health problems, including heart disease, diabetes, and stroke.",
      },
      {
        title: "Cardiac Valve Replacement",
        img: CardiacValve,
        about:
          "Cardiac valve replacement is a surgical procedure in which one of the heart valves is replaced. It is common for the aortic valve to become stiff and not work properly with age such that it will need to be surgically replaced. Some people are born with somewhat deformed heart valves, and most often the mitral valve is affected. In severe cases of mitral valve deformity, patients will need valve replacement surgery. Valves are replaced by open heart surgery in which you are put under general anesthesia and put on bypass while the surgery is done. The diseased valve is replaced by the new valve and your heart is then restarted.",
      },
      {
        title: "Angioplasty",
        img: Angioplasty,
        about:
          "An angioplasty is a minimally invasive procedure that is done to unblock clogged arteries. It is an important procedure because blocked arteries can end up causing a heart attack or even a lung embolism, both of which can be fatal. In this procedure, which is often used to unblock the coronary arteries of the heart, a catheter is threaded through the blood vessels. When the blockage is reached a balloon carried by the catheter is inflated to open up the blocked artery and restore blood flow.",
      },
      {
        title: "Coronary Artery Bypass Grafting (CABG)",
        img: CoronaryArtery,
        about:
          "Blocked heart arteries can easily and quickly result in a heart attack, which if it does not kill you, will likely cause irreversible heart damage. The procedure is done under general anesthesia and entails the surgeon cutting your chest open and placing you on a bypass machine while the procedure is carried out. The surgeon selects part of a healthy blood vessel either from inside your chest or your leg and grafts this in place to bypass the blockage in the coronary artery.",
      },
      {
        title: "Pacemaker Implantation Surgery",
        img: Pacemaker,
        about:
          "Pacemaker implantation surgery is needed to correct certain types of irregular heartbeats that can be very dangerous in some cases. The procedure is not as major as others since the chest does not have to be opened up. A pacemaker is an electrical device that is surgically inserted just underneath the skin, often just beneath the collarbone. It works to stabilize the heartbeat by controlling the electrical impulses that travel to the heart and cause your heart muscles to contract. If your muscles don’t contract properly and in synchrony then you are at risk of sudden death, so the surgery is important and needs to be done as soon as possible. The surgery is lower risk than many other types of cardiac surgery and the risk of complications is very minimal.",
      },
    ],
  },
];
