import Footerr from "pages/Home/HomeNavBar/Footer";
import { useNavigate } from "react-router-dom";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import ServiceHighlightCard from "shared/components/ServiceHighlightCard";
import { DOCTOR } from "shared/utils/mainHeaderQuery";
import styles from "./styles.module.css";

const Nav_SelectionCards = ({ data }: { data?: any }) => {
  const navigate = useNavigate();

  const handleNavigate = (name: any) => {
    if (name === "Travel Agency") {
      navigate("/services/travel");
    } else if (name === "Rent A Car") {
      navigate("/services/rentacar");
    } else if (name === "Ambulance") {
      navigate("/services/ambulance");
    } else if (name === "Paramedic") {
      navigate("/services/paramedicstaff");
    } else {
      navigate("/services/doctor", {
        state: { serviceName: name == "Doctors" ? "Doctor" : name },
      });
    }
  };

  return (
    <>
      <div>
        <NavBreadCrumbs {...DOCTOR} />
      </div>
      <div className={styles.container}>
        <ServiceHighlightCard data={data} onPress={handleNavigate} />
      </div>
      <Footerr />
    </>
  );
};

export default Nav_SelectionCards;
