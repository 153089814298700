import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "./style.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import CustomSelect from "shared/components/CustomSelect";
import { IoMdArrowForward } from "react-icons/io";
import img from "assets/images/insuranceboth.png";

interface InsuranceFormProps {
  insuranceOptions: string[];
  onSelectPlan: (selectedOption: string) => void;
  title: string;
  subtitle: string;
  formtitle: string;
  id?: number;
  formType: "health" | "travel";
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  insuranceOptions,
  onSelectPlan,
  title,
  subtitle,
  formtitle,
  formType,
}) => {
  const navigate = useNavigate();

  // Define the countries array
  const countries = [
    { id: 1, title: "Pakistan" },
    { id: 2, title: "USA" },
    { id: 3, title: "UK" },
    { id: 4, title: "Bangladesh" },
    { id: 5, title: "Italy" },
    { id: 6, title: "Spain" },
    { id: 7, title: "Portugal" },
    { id: 9, title: "Australia" },
    { id: 10, title: "South Africa" },
    { id: 11, title: "Sri Lanka" },
    { id: 12, title: "Spain" },
    { id: 13, title: "UAE" },
  ];

  const validationSchema = Yup.object({
    selectedPlan: Yup.string().required("Please select a plan"),
    selectedCountry:
      formType === "travel"
        ? Yup.string().required("Please select a country")
        : Yup.string(),
    passengerType:
      formType === "travel"
        ? Yup.string().required("Please select a passenger type")
        : Yup.string(),
  });

  // Set up Formik
  const formik = useFormik({
    initialValues: {
      selectedPlan: "",
      selectedCountry: "",
      passengerType: "",
    },
    validationSchema, 
    onSubmit: (values) => {
      navigate("/services/insurance/InsuranceCards", {
        state: {
          selectedPlan: values.selectedPlan,
          selectedCountry: values.selectedCountry,
          passengerType: values.passengerType,
          formType,
        },
      });
    },
  });

  return (
    <div>
      <div
        className={classNames(
          commonstyle.flx,
          commonstyle.flxBetween,
          commonstyle.flxWrap
        )}
      >
        <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
          <div>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              {title}
            </p>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              <span
                className={classNames(
                  commonstyle.colorOrange,
                  commonstyle.fs16,
                  commonstyle.semiBold
                )}
              >
                {subtitle}
              </span>
            </p>
          </div>
          <img
            src={img}
            alt="Insurance Plan"
            className={style.customInsuranceImage}
          />
        </div>

        <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
          <div className={classNames(style.formcontainer)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs14,
                commonstyle.semiBold
              )}
            >
              {formtitle}
            </p>

            {/* Start of Formik Form */}
            <form onSubmit={formik.handleSubmit}>
              <CustomSelect
                options={insuranceOptions}
                placeholder="Please Select Best Plan"
                onSelect={(option: string) =>
                  formik.setFieldValue("selectedPlan", option)
                }
              />
              {formik.touched.selectedPlan && formik.errors.selectedPlan ? (
                <div className={classNames(commonstyle.error)}>
                  *{formik.errors.selectedPlan}
                </div>
              ) : null}

              {formType === "travel" && formik.values.selectedPlan && (
                <>
                  <CustomSelect
                    options={countries.map((country) => country.title)}
                    placeholder="Select Country for Traveling"
                    onSelect={(option: string) =>
                      formik.setFieldValue("selectedCountry", option)
                    }
                  />
                  {formik.touched.selectedCountry &&
                  formik.errors.selectedCountry ? (
                    <div className={classNames(commonstyle.error)}>
                      *{formik.errors.selectedCountry}
                    </div>
                  ) : null}

                  <CustomSelect
                    options={["Individual Travel", "Family Travel"]}
                    placeholder="Select Passenger Type"
                    onSelect={(option: string) =>
                      formik.setFieldValue("passengerType", option)
                    }
                  />
                  {formik.touched.passengerType &&
                  formik.errors.passengerType ? (
                    <div className={classNames(commonstyle.error)}>
                      *{formik.errors.passengerType}
                    </div>
                  ) : null}
                </>
              )}

              <button type="submit" className={style.selectbutton}>
                Select Plan <IoMdArrowForward />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceForm;
