import React from "react";
import CardStyless from "./CardStyless.module.css";
import CommonStyless from "shared/utils/common.module.css";
import Vector from "assets/images/Vector.png";
import classNames from "classnames";
import RatingStar from "shared/RatingStar";
import { Link } from "react-router-dom";
import { IoMdArrowRoundForward } from "react-icons/io";
import DoctorCard from "../A_New_Components/DoctorCard";

interface Props {
  data?: any;
  onPressDetail?: any;
}

const DynamicCard = (props: Props) => {
  const { data, onPressDetail } = props;
  return (
    <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
      <div className={CardStyless.cardContainer}>
        {/* {data?.map((doc: any, index: number) => (
          <div key={index} className={CardStyless.cardWrapper}>
            <div
              className={classNames(CardStyless.cardImageWrapper)}
              style={{
                backgroundColor: "gray",
              }}
            >
              <img
                src={
                  doc?.doctorImage ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                }
                alt={doc?.name}
                className={CardStyless.cardImage}
              />
            </div>
            <div className={classNames(CardStyless.cardBody)}>
              <div
                className={classNames(
                  CommonStyless.flx,
                  CommonStyless.flxBetween
                )}
              >
                <div
                  className={classNames(
                    CardStyless.cardName,
                    CommonStyless.colorBlue
                  )}
                >
                  {doc?.name}
                </div>
                <div>
                  <img
                    src={Vector}
                    alt="Vector icon"
                    className={CardStyless.vectorIcon}
                  />
                </div>
              </div>
              <div
                className={classNames(
                  CommonStyless.flx,
                  CardStyless.cardDRTitle
                )}
                style={{
                  overflow: "hidden",
                  width: "70%",
                }}
              >
                {doc?.speciality?.map((s: string, ind: number) => (
                  <div
                    key={ind}
                    className={CardStyless.cardTitle}
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {s},&nbsp;
                  </div>
                ))}
              </div>

              <div
                className={classNames(CardStyless.cardDRTitle)}
                style={{
                  overflow: "hidden",
                  width: "70%",
                }}
              >
                <div className={CardStyless.cardDetail}>
                  {doc?.qualifications}
                </div>
                <div className={CardStyless.cardExperience}>
                  {doc?.clinicExperience}
                </div>
              </div>

              <div
                className={classNames(
                  CommonStyless.flx,
                  CommonStyless.flxBetween
                )}
              >
                <RatingStar rating={2.5} />

                <div
                  className={classNames(
                    CommonStyless.flx,
                    CardStyless.viewMore
                  )}
                  onClick={() => onPressDetail(doc)}
                >
                  <Link to="#" className={CardStyless.cardViewMore}>
                    Details
                  </Link>
                  <div className={CardStyless.cardArrow}>
                    <div
                      style={{
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoMdArrowRoundForward />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))} */}

        {data?.map((doc: any, index: number) => (
          <DoctorCard
            key={index}
            item={doc}
            onClick={onPressDetail}
            // type="speciality"
          />
        ))}
      </div>
    </div>
  );
};

export default DynamicCard;
