import React from "react";
import styles from "./ModelTermsAndCondition.module.css";
import logo from "../../../assets/images/smallLogo.png";

const TermsAndConditions: React.FC = () => {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.logoContainer}>
        <img
          src={logo} // Replace with your logo path
          alt="The TMG Private Limited Logo"
          className={styles.logo}
        />
      </div>
      <h1 className={styles.heading}>Terms and Conditions</h1>
      <h2 className={styles.companyName}>THE TMG PRIVATE LIMITED</h2>
      <p className={styles.paragraph}>
        This E-Agreement ("Agreement") is a binding contract between you and The
        TMG Private Limited ("TMG"), a service company that facilitates the
        coordination of medical, travel, and other ancillary services for
        patients like Medical online OPD, Laboratory services, Pharmacy
        Services, Booking Hotel/Guest Houses, Arrange travelling and Excursions,
        Home services like Physiotherapists, Psychologists, Paramedic Staff and
        Doctor on Call services, Ambulance services and offering travel and
        health insurance through third party.
      </p>
      <h3 className={styles.subheading}>1. Scope of Services</h3>
      <p className={styles.paragraph}>
        MediTour provides the following services to facilitate your medical
        journey:
      </p>
      <ol className={styles.list}>
        <li>
          TMG aims to create a professional, non-political platform for
          promoting and facilitating unique and exclusive medical, dental,
          diagnostic, tourism, fertility treatments (IVF), aesthetic, cosmetic &
          plastic surgery, hair transplant, cardiac and metabolic wellness,
          rehabilitation services and all other medical treatments.
        </li>
        <li>Arrangements for all the services related to Medical Tourism.</li>
        <li>
          Assistance with travel arrangements, including flights,
          accommodations, and local transportation in connection with medical
          services.
        </li>
        <li>
          Support in procuring prescribed medications from third-party
          suppliers.
        </li>
        <li>Coordination of other ancillary services related to the above.</li>
      </ol>
      <h3 className={styles.subheading}>2. Acknowledgement and Disclaimer</h3>
      <p className={styles.paragraph}>
        By creating an account and using TMG platform, you agree to the
        following terms and conditions: You engaged in providing the multiple
        services or any of the services mentioned in the{" "}
        <span className={styles.highlight}>scope of services</span> provided by
        TMG to the community, with the aim to improve the quality of patient
        care and promote the well-being of patients.
      </p>
      <p className={styles.paragraph}>
        Both parties desire to establish a formal agreement outlining their
        respective roles, rights, and obligations.
      </p>
      <h3 className={styles.subheading}>
        NOW, THEREFORE, IT IS AGREED AS FOLLOWS:
      </h3>
      <ul className={styles.list}>
        <li>
          <strong>Engagement:</strong> TMG retains you as a health service
          provider. Service Provider hereby agrees to be retained by TMG under
          the terms of this Agreement.
        </li>
        <li>
          <strong>Performance Obligations:</strong> Service Provider shall apply
          their utmost knowledge and skill in caring for patients referred
          through TMG.
        </li>
        <li>
          <strong>Service Scheduling:</strong> Both parties shall agree on a
          schedule for professional services provided through TMG. Service
          Provider shall not provide direct patient contact services
          independently forwarded by TMG.
        </li>
        <li>
          <strong>Licensing Requirements:</strong> The Service Provider must be
          duly licensed to practice in the Islamic Republic of Pakistan and
          maintain all necessary documents, licenses, and controlled substances
          permits.
        </li>
        <li>
          <strong>Service Standards:</strong> During the term of this Agreement,
          the Service Provider shall render services without discrimination
          based on race, sex, religion, handicap, national origin, age, or
          ability to pay.
        </li>
        <li>
          <strong>Compensation and Billing:</strong> All fees for medical
          services provided by The TMG after deducting a 20% service fee payable
          to The TMG.
        </li>
        <li>
          <strong>Reporting and Documentation:</strong> The Service Provider
          shall prepare and submit timely reports of all examinations,
          procedures, and other services performed.
        </li>
        <li>
          <strong>Confidentiality:</strong> Both parties agree to hold
          confidential information in strict confidence.
        </li>
        <li>
          <strong>Indemnification:</strong> The Service Provider shall indemnify
          and hold The TMG harmless against all claims, losses, liabilities,
          costs, and expenses.
        </li>
        <li>
          <strong>Termination:</strong> Either party may terminate this
          Agreement if the other party fails to fulfill its obligations.
        </li>
        <li>
          <strong>Legal Compliance:</strong> All services must comply with
          applicable laws, regulations, and ordinances.
        </li>
        <li>
          <strong>Validity and Enforceability:</strong> If any provision of this
          Agreement is found to be unenforceable, the remaining provisions shall
          remain in full force and effect.
        </li>
        <li>
          <strong>Entire Agreement:</strong> This Agreement constitutes the
          entire understanding between the parties regarding its subject matter.
        </li>
        <li>
          <strong>Term and Renewal:</strong> The Agreement is effective from the
          date of acceptance and will remain in effect for an initial period of
          one year.
        </li>
        <li>
          <strong>Dispute Resolution:</strong> Any disputes shall be settled by
          an arbitration committee.
        </li>
      </ul>
      <h3 className={styles.subheading}>ACCEPTANCE</h3>
      <p className={styles.paragraph}>
        By clicking "I Agree," both parties confirm they have read, understood,
        and agree to the terms and conditions set forth in this Agreement.
      </p>

      <h3 className={styles.footer}>THE MEDITOUR PRIVATE LIMITED</h3>
      <p className={styles.contactInfo}>
        If you have any questions or concerns, please contact us at
        info@meditour.global.
      </p>
    </div>
  );
};

export default TermsAndConditions;
