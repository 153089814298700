import React, { useEffect, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import img from "assets/images/HospitalService.png";
import CardStyless from "../../../pages/Services/DoctarServices/Cards.module.css";

import classNames from "classnames";
import CommonStyless from "shared/utils/common.module.css";
import { MdOutlineYoutubeSearchedFor } from "react-icons/md";
import Vector from "assets/images/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import {
  IoLocateOutline,
  IoLocationOutline,
  IoSearchSharp,
  IoTimeOutline,
} from "react-icons/io5";
import { getAll_Hospitals } from "shared/services";
import { useSelector } from "react-redux";
import PhysiotheristsEmpty from "../PhsiotheristEmpty";
import LoadingModal from "../LoaderModal";
import RatingStar from "shared/RatingStar";
import CustomLoader from "../New_Loader/Loader";

// Define card data type
interface CardData {
  id: number;
  name: string;
  vector: string; //img
  time: string;
  location: string;
  rating: string;
}

interface MyComponentProps {
  serviceName: any;
}
const MainHospitalCard: React.FC<MyComponentProps> = ({ serviceName }) => {
  const navigate = useNavigate();
  const [visibleCards, setVisibleCards] = useState<number>(2);
  const [hospitalData, setHospitalData] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { location } = useSelector((state: any) => state.root.common);

  useEffect(() => {
    setLoading(true);
    all_Hospital();
  }, []);

  const handleShowMore = () => {
    setVisibleCards((prevCount) => prevCount + 2);
  };

  const handleViewMoreClick = (id: number) => {
    navigate(`/services/hospital/HospitalDetail/${id}`, {
      state: { serviceName },
    });
  };
  const all_Hospital = () => {
    let params = {
      page: 1,
      search: searchValue,
      lat: location?.latitude,
      long: location?.longitude,
    };
    getAll_Hospitals(params)
      .then((res: any) => {
        setHospitalData(res?.data?.hospitals);
        console.log(".res......", res?.data?.hospitals);
      })
      .catch((err: any) => {
        console.log(".err......", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    console.log("Search Input Value:", value); // Log the search input value
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      all_Hospital(); // Fetch the list when Enter is pressed
    }
  };

  return (
    <div className={classNames(CommonStyless.container, CommonStyless.mt32)}>
      <div style={{ backgroundColor: "FDFDFD" }}>
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxWrap,
            CommonStyless.flxBetween
          )}
        >
          <div className={classNames(CommonStyless.flx)}></div>
          <div className={CardStyless.searchBarContainer}>
            <IoSearchSharp className={CardStyless.searchIcon} />
            <input
              type="text"
              placeholder="Search..."
              className={CardStyless.searchInput}
              value={searchValue} // Controlled input
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          {/* <div className={CardStyless.tabContent}> */}

          <div className={CardStyless.cardContainer}>
            {hospitalData && hospitalData.length > 0 ? (
              hospitalData?.map((hosp: any, index: any) => {
                return (
                  // <></>

                  <div key={index} className={CardStyless.cardWrapper}>
                    <div className={CardStyless.cardImageWrapper}>
                      <img
                        src={hosp?.logo}
                        alt="card img"
                        className={CardStyless.cardImage}
                      />
                    </div>
                    <div
                      className={CardStyless.cardBody}
                      onClick={() => handleViewMoreClick(hosp._id)}
                    >
                      <div
                        className={classNames(
                          CommonStyless.flx,
                          CommonStyless.flxBetween
                        )}
                      >
                        <div
                          className={classNames(
                            CardStyless.cardName,
                            CommonStyless.colorBlue
                          )}
                        >
                          {hosp?.name}
                        </div>
                        <div>
                          <img
                            src={Vector}
                            alt="Vector icon"
                            className={CardStyless.vectorIcon}
                          />
                        </div>
                      </div>
                      <div className={CardStyless.cardtime}>
                        <span className={CardStyless.timeIcon}>
                          <IoTimeOutline />
                        </span>
                        <span className={CardStyless.specialityTag}>
                          {hosp?.openTime}
                        </span>
                      </div>

                      <div className={CardStyless.cardtime}>
                        <span className={CardStyless.timeIcon}>
                          <IoLocationOutline />
                        </span>
                        <span>{hosp?.location?.address}</span>
                      </div>
                      <div className={CardStyless.cardFooter}>
                        <RatingStar rating={2.5} />
                        {/* {card.rating} */}

                        <span
                          className={classNames(
                            CommonStyless.flx,
                            CardStyless.viewMore
                          )}
                          onClick={() => handleViewMoreClick(hosp._id)}
                        >
                          <Link to="#" className={CardStyless.cardViewMore}>
                            View more
                          </Link>
                          <span className={CardStyless.cardArrow}>
                            <IoMdArrowForward />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {!loading && (
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxCenter
                    )}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div className={classNames(CommonStyless.flx)}>
                      <PhysiotheristsEmpty />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          {hospitalData?.length >= 10 && <></>}
        </div>
      </div>
      {/* </div> */}

      {loading && <CustomLoader />}
    </div>
  );
};

export default MainHospitalCard;
