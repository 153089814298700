import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./index.module.css";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import Footer from "pages/Home/HomeNavBar/Footer";
import tree from "assets/images/Tree.png";
import InsuranceCardComponent from "shared/components/Insuranceuser/TravelCards/InsuranceCardCamponet";
import { postInsuranceFamily, postInsuranceFlight } from "shared/services";
import { LoadingModal } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { INSURANCE } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";

interface CardData {
  id: number;
  title: string;
  price: string;
  description: string;
  imgSrc: string;
}

const InsuranceCards = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { formType, selectedPlan, selectedCountry, passengerType } =
    location.state || {};
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (formType == "health") {
      InsuranceFamily();
    }
    if (formType == "travel") {
      postInsuranceTravel();
    }
  }, [formType]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postInsuranceTravel = () => {
    setLoading(true);
    let data = {
      page: 1,
    };
    let params = {
      planType: selectedPlan?.toLowerCase(),
      passengerTraveling: passengerType.toLowerCase(),
      country: selectedCountry,
    };
    console.log(params, "......./user/searchTravelInsurance");
    postInsuranceFlight(data, params)
      .then((res: any) => {
        console.log(
          res?.data?.insurances,
          "..............res?.data?.insurances"
        );
        setData(res?.data?.insurances);
      })
      .catch((err: any) => {
        console.log(err, "............errorTravel");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const InsuranceFamily = () => {
    setLoading(true);
    let data = {
      page: 1,
    };
    let params: any = {
      planType: selectedPlan?.toLowerCase(),
    };
    console.log(params, ".......params");
    postInsuranceFamily(data, params)
      .then((res: any) => {
        setData(res?.data?.insurances);
      })
      .catch((err: any) => {
        console.log(err, ".......error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onPressDetail = (item: any) => {
    navigate("/services/insurance/InsuranceDetails", {
      state: {
        selectedPlan,
        selectedCountry,
        passengerType,
        formType,
        item,
      },
    });
  };

  return (
    <div>
      <NavBreadCrumbs {...INSURANCE} />
      <div className={classNames(commonstyle.container)}>
        <p
          className={classNames(
            commonstyle.colorBlue,
            commonstyle.fs24,
            commonstyle.semiBold
          )}
        >
          {formType === "health"
            ? "Health Insurance Plans"
            : "Travel Insurance Plans"}
        </p>
        {data?.length > 0 ? (
          <div className={style.cardContainer}>
            {data?.map((card: any) => (
              <InsuranceCardComponent
                key={card.id}
                title={card?.packageName}
                handleDetailsClick={() => onPressDetail(card)}
                price={card?.actualPrice}
                description={card?.packageDescription}
                imgSrc={
                  card?.insuranceId?.logo ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                }
              />
            ))}
          </div>
        ) : (
          <div>{!loading && <PhysiotheristsEmpty />}</div>
        )}
      </div>
      {loading && <CustomLoader />}
      <Footer />
    </div>
  );
};

export default InsuranceCards;
