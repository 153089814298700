import React, { useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import commonStyles from "shared/utils/common.module.css";
import * as Yup from "yup";
import commonstyles from "shared/utils/common.module.css";
import style from "./health.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import MySelfImg from "assets/images/myself.png";
import FamilyImg from "assets/images/family.png";
import ParentsImg from "assets/images/parents.png";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { IoClose } from "react-icons/io5";
import Empty from "assets/images/empty2.png";
import CustomSelect from "shared/components/CustomSelect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
} from "@mui/material";
import {
  insuranceHealthMySelfPackageSchema,
  insuranceHealthParentsPackageSchema,
  insuranceMySelfPackageSchema,
} from "shared/utils";
import {
  setInsuranceHealthFamilyPackage,
  setInsuranceHealthParentsPackage,
  setInsuranceMySelfPackage,
} from "shared/redux";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import InsuranceEmpty from "shared/components/InsuranceEmpty";
const Age = ["your age Criteria", "Karachi", "Multan", "Islambad"];
const SpouseAge = ["Spouse Age Criteria", "Karachi", "Multan", "Islambad"];
const Kidage = ["Kid age", "Karachi", "Multan", "Islambad"];
const SelectHospital = [
  "Select Hospitalization Limit (PKR)",
  "Karachi",
  "Multan",
  "Islambad",
];

export default function HeathMain() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/insurance/Category");
  };
  const handleGoToMyself = () => {
    navigate("/insurance/MySelfMian");
  };
  const handleGoToMyFamily = () => {
    navigate("/insurance/MyFamilyMian");
  };
  const handleGoToMyParents = () => {
    navigate("/insurance/ParentsMian");
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonStyles.mr87}>
        <div className={classNames(commonstyles.outerContainer)}>
          <div className={commonstyles.flx}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back{" "}
              </p>
            </div>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Health
            </p>
          </div>
          <div className={classNames(commonstyles.flx, commonStyles.flxWrap)}>
            <div className={style.myselfcard} onClick={handleGoToMyself}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Myself
              </p>
              <div className={style.textcontainer}>
                <p className={classNames(commonstyles.fs16, style.textdata)}>
                  Single person insurance is a policy designed to provide
                  coverage tailored specifically for an individual, addressing
                  their unique health, life, or personal protection needs.
                </p>
              </div>

              <div className={style.imgcontainer}>
                <img src={MySelfImg} className={style.healtImgss} />
              </div>
            </div>
            <div className={style.myselfcard} onClick={handleGoToMyFamily}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Family
              </p>
              <div className={style.textcontainer}>
                <p className={classNames(commonstyles.fs16, style.textdata)}>
                  Family Health Insurance Plan offers extensive medical coverage
                  tailored to protect the health of every family member,
                  ensuring each receives quality care at every stage of life.
                </p>
              </div>

              <div className={style.imgcontainer}>
                <img src={FamilyImg} className={style.healtImgss} />
              </div>
            </div>
            <div className={style.myselfcard} onClick={handleGoToMyParents}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Parents
              </p>
              <div className={style.textcontainer}>
                <p className={classNames(commonstyles.fs16, style.textdata)}>
                  Parents Health Insurance Plan is designed to provide
                  comprehensive medical coverage for your beloved parents,
                  ensuring they receive the best possible care as they age.
                </p>
              </div>
              <div className={style.imgcontainer}>
                <img src={ParentsImg} className={style.healtImgss} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
