import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import dstyle from "./DonationPackeg.module.css";
import { CiLocationOn } from "react-icons/ci";
import { IoMdArrowForward } from "react-icons/io";
import { FcDonate } from "react-icons/fc";
import { getDonation_Package } from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";
import { IoCloseSharp } from "react-icons/io5";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { DONATION_PACKAGE } from "shared/utils/mainHeaderQuery";
const DonationPackeg = () => {
  const { state } = useLocation();
  const [data, setData] = useState<any>([]);
  const item = state.items;
  const dispatch = useDispatch();
  const [errorDonation, seterrorDonation] = useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const [donateAmount, setdonateAmount] = useState<any>("");
  const { isLoggedIn } = useSelector((state: any) => state.root.common);
  const handleShowMore = () => {
    if (isLoggedIn) {
      setShowModal(true);
    } else {
      navigate("/user/login");
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    Packages_Details();
  }, []);
  const Packages_Details = () => {
    let params = {
      packageId: item?._id,
    };
    getDonation_Package(params)
      .then((res: any) => {
        setData(res.data.package);
      })
      .catch((err: any) => {
        console.log(err, "...err");
      })
      .finally(() => {});
  };
  const navigate = useNavigate();
  const handleContinueClick = () => {
    let isValid = true;

    if (!donateAmount.trim()) {
      seterrorDonation("Please Enter Amount");
      isValid = false;
    } else if (donateAmount.trim() === "0") {
      seterrorDonation("Minimum amount is 1");
      isValid = false;
    } else {
      seterrorDonation("");
    }

    if (isValid) {
      dispatch(setObj(item));
      navigate("/services/paymentDetail", {
        state: { actualAmount: donateAmount, serviceName: "donation" },
      });
    }
  };

  return (
    <div>
      <NavBreadCrumbs {...DONATION_PACKAGE} />

      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <p className={classNames(dstyle.title)}>Package Details</p>
        <div className={dstyle.imagesContainer}>
          {item?.images.map((img: any, index: any) => (
            <img
              key={index}
              src={img}
              alt={`Image ${index + 1}`}
              className={dstyle.image}
            />
          ))}
        </div>
        <div className={classNames(dstyle.textbox, commonstyles.mt8)}>
          <div>
            <img
              className={dstyle.roundimg}
              src={item?.donationId?.logo}
              alt=""
            />
          </div>
          <div>
            <p className={classNames(dstyle.subtitle)}>
              {item?.donationId?.name}
            </p>
            <span
              className={classNames(
                commonstyles.flx,
                commonstyles.fs14,
                commonstyles.colorBlue
              )}
            >
              <span>
                <CiLocationOn />
              </span>
              <span> {`${item?.donationId?.location?.address}`}</span>
            </span>
          </div>
        </div>
        <div className={commonstyles.mt24}>
          <p className={dstyle.subtitle}>{item?.donationTitle}</p>
          <p>
            <span
              className={classNames(commonstyles.fs14, commonstyles.colorBlue)}
            >
              Target Audience:{" "}
            </span>
            <span
              className={classNames(commonstyles.fs12, commonstyles.colorBlue)}
            >
              {data?.targetAudience}
            </span>
          </p>
          <p>
            <span
              className={classNames(commonstyles.fs14, commonstyles.colorBlue)}
            >
              Amount:{" "}
            </span>
            <span
              className={classNames(commonstyles.fs12, commonstyles.colorBlue)}
            >
              {data?.requiredAmount}
            </span>
          </p>
          <p>
            <span
              className={classNames(commonstyles.fs14, commonstyles.colorBlue)}
            >
              Days:{" "}
            </span>
            <span
              className={classNames(commonstyles.fs12, commonstyles.colorBlue)}
            >
              {data?.totalDays}
            </span>
          </p>
        </div>

        <div>
          <p className={dstyle.subtitle}>Description</p>
          <p className={classNames(commonstyles.fs12, commonstyles.colorBlue)}>
            {data?.description}
          </p>
        </div>
        <div className={dstyle.showMoreContainer}>
          <button
            onClick={handleShowMore}
            className={dstyle.showMoreButton}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
            }}
          >
            Donate
            <span
              className={dstyle.icon}
              style={{
                fontSize: "16px",
              }}
            >
              <IoMdArrowForward />
            </span>
          </button>
        </div>

        {showModal && (
          <div className={classNames(dstyle.modal)}>
            <div className={classNames(dstyle.modalContent)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  width: "100%",
                  position: "relative",
                  top: "0px",
                  marginRight: "32px",
                }}
              >
                <IoCloseSharp
                  className={classNames(dstyle.modalCloseButton)}
                  onClick={handleCloseModal}
                  style={{
                    color: "#0e54a3",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  close
                </IoCloseSharp>
              </div>

              <div
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs16,
                  commonstyles.semiBold
                )}
                style={{
                  marginBottom: "10px",
                }}
              >
                <p>Please Enter Amount</p>
                <span
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  to Donate!.
                </span>
              </div>

              <FcDonate className={classNames(dstyle.donateicon)} />
              <div className={classNames(dstyle.inputarea)}>
                <input
                  placeholder="Enter Amount"
                  className={dstyle.amount}
                  value={donateAmount}
                  type="text"
                  style={{ width: "200px" }}
                  onChange={(text: any) => setdonateAmount(text.target.value)}
                />

                <span
                  className={classNames(
                    commonstyles.fs12,
                    commonstyles.colorBlue
                  )}
                >
                  PKR
                </span>
              </div>
              {errorDonation ? (
                <div className={classNames(commonstyles.error)}>
                  *{errorDonation}
                </div>
              ) : null}
              <div
                className={dstyle.showMoreContainer}
                style={{
                  margin: "10px 0",
                }}
              >
                <button
                  onClick={() => {
                    handleShowMore();
                    handleContinueClick();
                  }}
                  className={dstyle.showMoreButton}
                >
                  Continue
                  <span className={dstyle.icon}>
                    <IoMdArrowForward />
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footerr />
    </div>
  );
};

export default DonationPackeg;
