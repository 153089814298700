import { useEffect } from "react";
import Footerr from "../Footer";
import styles from "./PrivacyPolicies.module.css";
import PrivactPolicy2 from "shared/components/PrivacyPolicy/PrivactPolicy";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PRIVACY_POLICY } from "shared/utils/mainHeaderQuery";

export default function PrivactPolicys() {
  useEffect(() => {
    document.title = "MediTour Global | Privacy&Policies";
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <NavBreadCrumbs {...PRIVACY_POLICY} />
      <div className={styles.container}>
        <PrivactPolicy2 />
      </div>
      <Footerr />
    </div>
  );
}
