import React, { useEffect, useState } from "react";
import TableResult from "../../../shared/components/Tables/TableResult/index";
import styles from "./result.module.css";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "../../../shared/utils/common.module.css";
import { LoadingModal, RingLoader } from "shared/components";
import { LabGetAllResults } from "shared/services";
import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setRenderResultFlag,
  setResults,
  setResultslength,
} from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
interface Item {
  testName: string;
  testCode: number;
  price: number;
}

const Results = () => {
  const { results, renderResultFlag, resultslength } = useSelector(
    (state: any) => state.root.lab
  );
  const [itemAmount, setItemAmount] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const itemsPerPage = 10;
  const totalItems = resultslength;

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (resultslength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setRenderResultFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setRenderResultFlag(true));
    }
  };

  const fetchResults = (pageno: number) => {
    setLoading(true);
    LabGetAllResults(pageno)
      .then((res: any) => {
        console.log("Result APi....", res);

        dispatch(setResults(res?.data?.orders));
        dispatch(setResultslength(res?.data?.totalOrders));

        // setOrder(res?.data?.orders);
        const itemIds = res?.data?.orders.flatMap((order: any) =>
          order.items.map((item: any) => item.itemId)
        );
        console.log("Item IDs:", itemIds);
        setItemAmount(itemIds); // or use itemIds.flat(1) if using TypeScript
      })

      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchResults(currentPage);
    dispatch(setRenderResultFlag(false));
  }, [renderResultFlag, currentPage]);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div className={classNames(commonstyles.flxBetween)}>
            <div className={classNames(commonstyles.flx)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                All Result
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={styles.outerRefresh}>
                  <TbRefresh
                    className={styles.RefreshIcon}
                    onClick={() => fetchResults(pageno)}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {/* {loading ? (
            <CustomLoader />
          ) : ( */}
          <div className={classNames(styles.mt32)}>
            <TableResult order={results} />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Results;
