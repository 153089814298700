import * as React from "react";
import tabletest from "./tabletest.module.css";
import classNames from "classnames";
import commonstyles from "../../../utils/common.module.css";
import { PiUploadSimpleFill } from "react-icons/pi";
import { common } from "@mui/material/colors";
import styles from "../../../../pages/Laboratory/Tests/test.module.css";
import empty from "assets/images/empty2.png";
import { Navigate, useNavigate } from "react-router-dom";
import PrimaryButton from "shared/components/PrimaryButton";
import LabEmpty from "shared/components/LabEmpty";

interface Props {
  tests: any;
  setShowModal: any;
}
function Tabletest(props: Partial<Props>) {
  const navigate = useNavigate();
  const { tests, setShowModal } = props;
  console.log("🚀 ~ tests:", tests);
  const handleGoToTestDetail = (id: string) => {
    navigate(`/testDetail/${id}`);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  return (
    <div className={tabletest.outerContainer}>
      <div className={tabletest.payment}>
        {tests.length > 0 && (
          <div className={tabletest.headerOuter}>
            <p className={tabletest.headerclass}>Test Code</p>
            <p className={tabletest.headerclass}>Test Name</p>
            <p className={tabletest.headerclass}>Test Category</p>
            <p className={tabletest.headerclass}>MediTour Price</p>
            <p className={tabletest.headerclass}>Duration</p>
            <p className={tabletest.headerclass}>Price</p>
          </div>
        )}
        <div className={tabletest.tableData}>
          {tests.length > 0 ? (
            <table
              style={{
                margin: "0px",
              }}
            >
              <tbody className={tabletest.wapper}>
                {tests.map((val: any, key: any) => {
                  console.log(val, '......val')
                  return (
                    <tr
                      className={tabletest.tableRow}
                      key={key}
                      onClick={() => handleGoToTestDetail(val?._id)}
                    >
                      <td className={tabletest.w20}>{val?.testCode}</td>
                      <td className={tabletest.w20}>{val?.testNameId?.name}</td>
                      <td className={tabletest.w20}>{val?.testNameId?.categoryName}</td>
                      <td className={tabletest.w20}>{val?.priceForMeditour}</td>
                      <td className={tabletest.w20}>{val?.duration}</td>
                      <td className={tabletest.w20}>{val?.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <>
              <LabEmpty />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tabletest;
