import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import style from "./Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import DoctorCards from "./DoctorCards";
import { MainHospitalCard } from "shared/components";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { HOSPITALS_AND_DOCTORS } from "shared/utils/mainHeaderQuery";

const DoctorServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams(); // Use params instead of state for service identification

  const serviceName =
    location?.state?.type === "speciality"
      ? location?.state?.item?.subCategory
      : location.state?.serviceName ||
        (id === "hospital" ? "Hospital" : "Doctors");

  let checkSpeciality = location?.state?.type === "speciality" ? true : false;
  useEffect(() => {
    if (!location.state?.serviceName && !id) {
      console.error("Service name or id is missing");
      navigate("/services/doctor");
    }
  }, [location.state, id, navigate]);

  return (
    <div>
      <NavBreadCrumbs {...HOSPITALS_AND_DOCTORS(serviceName)} />
      {serviceName === "Hospital" ? (
        <div>
          <MainHospitalCard serviceName={serviceName} />
        </div>
      ) : (
        <DoctorCards serviceName={serviceName} speciality={checkSpeciality} />
      )}
      <Footerr />
    </div>
  );
};

export default DoctorServices;
