import React, { useEffect, useState } from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyle from "shared/utils/common.module.css";
import styles from "./ambulancedashboard.module.css";
import AmblanceLineChart from "shared/components/AmblanceCharts/AmblanceLineChart";
import AmblanceAreaBarChart from "shared/components/AmblanceCharts/AmblanceBarChart";
import AmblanceBarChart2 from "shared/components/AmblanceCharts/AmblaceBarchart2";
import { Avatar } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AmbulanceDASHBOARD,
  AmbulanceRecentBooking,
} from "shared/services/Ambulance";
import { LoadingModal, RingLoader } from "shared/components";
import { TbRefresh } from "react-icons/tb";
import {
  setAmbulanceDashboardDetails,
  setAmbulanceDashboardrenderFlag,
  setAmbulanceMonthArray,
  setAmbulanceonRouteGraphData,
  setAmbulancerecentBooking,
  setAmbulancerequestGraphData,
} from "shared/redux";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import CustomLoader from "shared/components/New_Loader/Loader";
const data = [
  { name: "0", value1: 0 },
  { name: "Jan", value1: 10 },
  { name: "Feb", value1: 2 },
  { name: "Mar", value1: 5 },
  { name: "Apr", value1: 22 },
  { name: "May", value1: 7 },
  { name: "Jun", value1: 2 },
  { name: "Jul", value1: 12 },
  { name: "Aug", value1: 22 },
  { name: "Sep", value1: 7 },
  { name: "Oct", value1: 2 },
  { name: "Nov", value1: 12 },
  { name: "Dec", value1: 10 },
  // Add more data as needed
];

const data2 = [
  { name: "Label 1", value: 0 },
  { name: "Label 2", value: 0 },
  { name: "Label 3", value: 0 },
  { name: "Label 4", value: 0 },
  { name: "Label 5", value: 0 },
  { name: "Label 6", value: 10 },
  { name: "Label 7", value: 10 },
  // { name: "Label 8", value: 10 },
];

const data3 = [
  { name: "Label 1", value: 12 },
  { name: "Label 2", value: 19 },
  { name: "Label 3", value: 3 },
  { name: "Label 4", value: 5 },
  { name: "Label 5", value: 2 },
  { name: "Label 6", value: 12 },
  { name: "Label 7", value: 5 },
  // { name: "Label 8", value: 20 },
];
interface RecentRequest {
  userId: {
    userImage: string;
    name: string;
  };
  pickUp: {
    address: string;
  };
  createdAt: string;
  _id: string;
  date: string;
  time: string;
  name: string;
  address: string;
}

export default function AmbulanceDashBoard() {
  const { ambulance } = useSelector((state: any) => state.root.ambulance);

  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recentRequest, setRecentRequest] = useState<RecentRequest[]>([]);
  const [bids, setBids] = useState(0);
  const [requests, setRequests] = useState(0);
  const [servedPatients, setServedPatients] = useState(0);

  const fetchDashboard = () => {
    setLoading(true);
    AmbulanceDASHBOARD()
      .then((res: any) => {
        setRecentRequest(res?.data?.recentRequests);
        setBids(res?.data?.totalBids);
        setRequests(res?.data?.totalRequests);
        setServedPatients(res?.data?.servedPatients[0]?.distinctUserIds || 0);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRotate = (): void => {
    setRotation(rotation - rotationIncrement);
    fetchDashboard();
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <>
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div className={classNames(commonstyle.col12, commonstyle.colorBlue)}>
        <SearchBar />

        <div className={commonstyle.mr87}>
          <div className={styles.flx}>
            <div className={styles.nameCard}>
              <div className={styles.refreshdiv}>
                <p
                  className={classNames(commonstyle.fs32, commonstyle.semiBold)}
                >
                  Hello {ambulance?.name}
                </p>
                {loading ? (
                  <div style={{ marginLeft: "16px" }}>
                    <RingLoader color={"#0D47A1"} size={30} />
                  </div>
                ) : (
                  <TbRefresh
                    className={styles.refresh}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={handleRotate}
                  />
                )}
              </div>

              <p
                className={classNames(
                  commonstyle.fs16,
                  commonstyle.semiBold,
                  styles.mt8
                )}
              >
                Welcome Back!
              </p>
            </div>{" "}
            <div className={styles.bidCard}>
              <p className={classNames(commonstyle.fs14, commonstyle.semiBold)}>
                Total Request
              </p>
              <div className={commonstyle.flx}>
                <p
                  className={classNames(
                    commonstyle.fs32,
                    commonstyle.semiBold,
                    styles.mt8
                  )}
                >
                  {requests}
                </p>

                {requests > 0 ? (
                  <div className={styles.iconsOuterGreen}>
                    <FaArrowUp className={classNames(styles.arrowUp)} />
                  </div>
                ) : (
                  <div className={styles.iconsOuterOrange}>
                    <FaArrowDown className={classNames(styles.arrowdown)} />
                  </div>
                )}
              </div>
            </div>{" "}
            <div className={styles.bidCard}>
              <p className={classNames(commonstyle.fs14, commonstyle.semiBold)}>
                Sent Bids
              </p>
              <div className={commonstyle.flx}>
                <p
                  className={classNames(
                    commonstyle.fs32,
                    commonstyle.semiBold,
                    styles.mt8
                  )}
                >
                  {bids}
                </p>
                {bids > 0 ? (
                  <div className={styles.iconsOuterGreen}>
                    <FaArrowUp className={classNames(styles.arrowUp)} />
                  </div>
                ) : (
                  <div className={styles.iconsOuterOrange}>
                    <FaArrowDown className={classNames(styles.arrowdown)} />
                  </div>
                )}
              </div>
            </div>{" "}
            <div className={styles.bidCard}>
              <p className={classNames(commonstyle.fs14, commonstyle.semiBold)}>
                Served Patients
              </p>
              <div className={commonstyle.flx}>
                <p
                  className={classNames(
                    commonstyle.fs32,
                    commonstyle.semiBold,
                    styles.mt8
                  )}
                >
                  {servedPatients}
                </p>
                {servedPatients > 0 ? (
                  <div className={styles.iconsOuterGreen}>
                    <FaArrowUp className={classNames(styles.arrowUp)} />
                  </div>
                ) : (
                  <div className={styles.iconsOuterOrange}>
                    <FaArrowDown className={classNames(styles.arrowdown)} />
                  </div>
                )}
              </div>
            </div>{" "}
          </div>
          <p
            className={classNames(
              commonstyle.fs32,
              commonstyle.semiBold,
              styles.mt24
            )}
          >
            Recent Request
          </p>
          <div className={styles.AbmbulanceDashBoard}>
            <table style={{ width: "100%" }}>
              <thead style={{ backgroundColor: "transparent" }}>
                <th>Image</th> <th>Date</th> <th>Time</th> <th>Name</th>{" "}
                <th>Pick up Location</th>
              </thead>
              <tbody>
                {recentRequest.map((request, index) => (
                  <tr
                    key={request?._id}
                    style={{ borderBottom: "1px solid #C7C7C7" }}
                  >
                    <td>
                      <Avatar
                        src={request?.userId?.userImage}
                        sx={{ display: "flex", margin: "auto" }}
                      />
                    </td>
                    <td>
                      {" "}
                      {request?.createdAt
                        ? new Date(request.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            }
                          )
                        : "__"}
                    </td>
                    <td>
                      {" "}
                      {request &&
                        request.createdAt &&
                        new Date(request.createdAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                    </td>
                    <td>{request?.userId?.name}</td>
                    <td>{request?.pickUp?.address}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}
