import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"; // Import useParams
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import dstyle from "./EduStyle.module.css";
import style from "./Help.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import { IoMdHeartEmpty } from "react-icons/io";
import { AddRemovedFev, getPackages } from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { set_User } from "shared/redux";
import toast from "react-hot-toast";
import MainHeader from "shared/components/MainScreen/Index";
import { LoadingModal } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { DONATION_EDU } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";

const DonationEdu = () => {
  const { state } = useLocation();
  let item = state?.items;
  const [data, setData] = useState<any>([]);
  const [visibleCards, setVisibleCards] = useState(3);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);

  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3);
  };
  const navigate = useNavigate();
  const handleCardClick = (items: any) => {
    // setActiveCardId(id);
    navigate(`/services/donation/DonationPackeg`, { state: { items } });
  };
  useEffect(() => {
    PackagesCompany();
  }, []);

  const PackagesCompany = () => {
    setLoading(true);
    let params = {
      criteriaName: item?.criteriaName,
      page: 2,
    };
    console.log(params, ".parmas");
    getPackages(params)
      .then((res: any) => {
        setData(res?.data?.packages);
      })
      .catch((err: any) => {
        console.log(err, "..........error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlefavourite = (id: any) => {
    const params = {
      type: "donation",
      itemId: id,
    };
    console.log(params, "......params");
    AddRemovedFev(params)
      .then((res: any) => {
        dispatch(set_User(res?.data?.user));
        console.log(res?.data?.user, "......res?.data?.user");
        toast?.success(res?.data?.message);
      })
      .catch((err: any) => {
        console.log(err, ".....err");
      })
      .finally(() => {});
  };

  return (
    <div>
      <NavBreadCrumbs {...DONATION_EDU} />

      {/* Cards Section */}
      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <p className={classNames(dstyle.cardTitle)}>{item?.criteriaName}</p>
        <div className={dstyle.cardContainer}>
          {loading ? (
            <CustomLoader />
          ) : data.length === 0 ? (
            <div
              className={classNames(commonstyles.flx, commonstyles.flxCenter)}
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              <div className={classNames(commonstyles.flx)}>
                <PhysiotheristsEmpty />
              </div>
            </div>
          ) : (
            <>
              {data.slice(0, visibleCards).map((item: any) => {
                const isFavorite = user?.favourites?.some(
                  (fav: any) =>
                    fav.itemId === item._id && fav.favModel === "donation"
                );
                return (
                  <div
                    className={dstyle.card}
                    onClick={() => handleCardClick(item)}
                    key={item._id} // Add a unique key for each card
                  >
                    <div className={dstyle.cardTextOverlay}>
                      <p className={dstyle.cardOverlayText}>
                        {item.donationTitle}
                      </p>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handlefavourite(item?._id);
                        }}
                      >
                        {isFavorite ? <FaHeart color="red" /> : <FaRegHeart />}
                      </div>
                    </div>
                    <div className={dstyle.cardOverlay}>
                      <img
                        src={
                          item?.images?.[0] ||
                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                        }
                        alt="Card"
                        className={dstyle.cardImg}
                      />
                    </div>
                    <div className={dstyle.cardInnerBody}>
                      <div className={dstyle.cardLogoTextContainer}>
                        <img
                          src={
                            item?.donationId?.logo ||
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                          }
                          alt="Logo"
                          className={dstyle.cardLogo}
                        />
                        <div className={dstyle.textarea}>
                          <p className={dstyle.cardTitle}>
                            {item?.donationId?.name}
                          </p>
                          <p className={dstyle.cardSubtitle}>
                            {item?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>

        {data.length > visibleCards && (
          <div className={dstyle.showMoreContainer}>
            <button onClick={handleShowMore} className={dstyle.showMoreButton}>
              Show More
              <span className={dstyle.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        )}
        <div className={style.marginTopDonation}></div>
      </div>

      {/* Footer */}
      <Footerr />
    </div>
  );
};

export default DonationEdu;
