import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";

import Footerr from "../Footer";

import styles from "./PaymentNavbar.module.css";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal, TestCard } from "shared/components";
import Checkout from "shared/services/stripe/checkout";
import { setPaidAmount, setPaymentParams } from "shared/redux";
import axios from "axios";
import { createBlinq } from "shared/services";
import moment from "moment";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PAYMENT_NAVBAR } from "shared/utils/mainHeaderQuery";
import SearchBar from "shared/components/Searchbar";
import CustomLoader from "shared/components/New_Loader/Loader";
const PaymentNavbar = React.memo((props) => {
  const { state, location }: any = useLocation();
  const { systemType, exchangeRate } = useSelector(
    (state: any) => state.root.common
  );
  const dispatch = useDispatch();
  const { obj, hotelDetail, user } = useSelector(
    (state: any) => state?.root?.common
  );
  let arryObj = state?.serviceName === "pharmacy" ? obj : [obj];
  const [paymentType, setPaymentType] = useState("Full Payment");
  const [selection, setSelection] = useState("Pakistan");
  const [convertedRemainingAmount, setConvertedRemainingAmount] =
    useState<any>(0);

  const [stripeOpen, setStripeOpen] = useState(false);
  // const [convertedAmount, setConvertedAmount] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [calculateAmount, setCalculateAmount] = useState<any>();

  let processingFee: number = selection == "Pakistan" ? 0 : 0.2;
  let amountwithFee = Number(state?.actualAmount) + processingFee;
  let thirtyPercent: number = Number(state?.actualAmount) * 0.3;
  let thirtyPercent_WithFee: number = thirtyPercent + processingFee;
  let convertedAmount = calculateAmount * exchangeRate;
  let amount = convertedAmount + processingFee;
  let totalAmount = state?.actualAmount * exchangeRate;
  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}\/${month}\/${day}`;
  };

  const newDate = new Date();
  const formattedDate = formatDate(newDate);

  const mapSelectedItems = () => {
    if (state?.serviceName == "Lab") {
      return obj?.map((item: any) => ({
        itemId: item?._id,
        quantity: 1,
      }));
    }
  };

  const formattedData = mapSelectedItems();

  useEffect(() => {
    handleAmount();
  }, [paymentType, selection]);

  useEffect(() => {
    const query = new URLSearchParams(location?.search);
    const paymentStatus = query.get("status");
    console.log("Payment was successful!", paymentStatus);

    if (paymentStatus === "success") {
      console.log("Payment was successful!");
    } else {
      console.log("Payment failed or was cancelled.");
    }
  }, [location]);

  const arivalDate = `${hotelDetail?.fromDate}  ${hotelDetail?.fromDate}`;
  console.log(state?.serviceName, "......amount");
  const onPressBtn = () => {
    if (selection == "Pakistan") {
      const transactionReferenceNumber = Math.floor(
        Math.random() * 100000
      ).toString();
      const invoiceNo = `MED-${transactionReferenceNumber}`;
      dispatch(
        setPaymentParams({
          paymentId: invoiceNo,
        })
      );
      postRequest_Blink(invoiceNo);
    } else {
      dispatch(
        setPaymentParams({
          actualAmount: totalAmount?.toFixed(2),
          paymentType: paymentType,
          processingFee: processingFee,
          appointmentType: state?.appointmentType || "",
          docId: state?.doctorId || "",
          item: formattedData || "",
          labId: state?.labId || "",
          ambulanceId: state?.ambulanceId || "",
          speciality: state?.speciality || "",
        })
      );
      setStripeOpen(true);
    }
  };
  const postRequest_Blink = async (invoiceNo: any) => {
    setLoading(true);
    let body = {
      InvoiceNumber: invoiceNo,
      InvoiceAmount: calculateAmount,
      IssueDate: formattedDate,
      CustomerName: user?.name,
    };
    console.log("blinq body......", body);
    createBlinq(body)
      .then((res: any) => {
        const mapUrl: any = res?.data?.data?.ResponseDetail[0]?.ClickToPayUrl;
        if (mapUrl) {
          window.location.href = mapUrl;
        }
      })
      .catch((err: any) => {
        console.log("errr,,", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAmount = () => {
    const baseAmount =
      paymentType === "Full Payment" ? amountwithFee : thirtyPercent_WithFee;
    setCalculateAmount(baseAmount);

    if (paymentType === "Partial Payment") {
      const remaining = amountwithFee - thirtyPercent_WithFee;
      setConvertedRemainingAmount(
        selection === "International" && exchangeRate
          ? (remaining * exchangeRate).toFixed(2)
          : remaining
      );
    }
  };

  return (
    <div className={classNames(commonstyles.col12)}>
      {systemType === "user" ? (
        <NavBreadCrumbs {...PAYMENT_NAVBAR} />
      ) : (
        <SearchBar />
      )}

      {stripeOpen ? (
        <Checkout
          serviceName={state?.serviceName}
          convertedAmount={amount?.toFixed(2)}
          paidAmount={convertedAmount?.toFixed(2)}
          remainingAmount={convertedRemainingAmount}
        />
      ) : (
        <>
          {systemType === "user" &&
            arryObj.map((profile: any, index: any) => {
              console.log(profile, "......profile");
              return state?.serviceName == "Lab" ||
                state?.serviceName == "prescription" ? (
                <TestCard item={profile} serviceName={state?.serviceName} />
              ) : (
                <div key={index} className={styles.profileCard}>
                  <img
                    src={
                      profile?.doctorImage ||
                      profile?.item?.images?.[0] ||
                      profile?.images?.[0] ||
                      profile?.item?.packageLogo ||
                      profile?.ambulanceId?.logo ||
                      profile?.item?.propertyphoto?.[0] ||
                      profile?.item?.agencyId?.logo
                    }
                    alt={profile?.name}
                    className={styles.profileImage}
                  />
                  <div className={styles.profileInfo}>
                    <h3>
                      {profile?.name ||
                        profile?.item?.packageName ||
                        profile?.testNameId?.name ||
                        profile?.ambulanceName ||
                        profile?.productName ||
                        profile?.donationTitle ||
                        profile?.vehicleName ||
                        profile?.item?.agencyId?.name ||
                        profile?.item?.propertyName}
                    </h3>
                    <p>
                      {profile?.qualifications
                        ? profile.qualifications
                        : profile?.item?.from && profile?.item?.to
                        ? `${profile.item.from} - ${profile.item.to}`
                        : profile?.brand
                        ? profile.brand
                        : profile?.description
                        ? profile?.description
                        : profile?.travelers
                        ? `${profile?.travelers?.length} Traveler`
                        : state?.serviceName == "Hotel" && arivalDate}
                    </p>
                    <p>
                      {profile?.speciality
                        ? profile.speciality.join(", ")
                        : profile?.item?.departDate
                        ? `Depart Date: ${moment(
                            profile.item?.departDate
                          ).format("MM/DD/YYYY")}`
                        : profile?.quantity
                        ? `Quantity: ${profile.quantity}`
                        : profile?.totalDays && `Days: ${profile?.totalDays}`}
                    </p>
                    <p>
                      {profile?.clinicExperience ||
                        (profile?.item?.returnDate &&
                          `Return Date: ${moment(
                            profile?.item?.returnDate
                          ).format("MM/DD/YYYY")}`)}
                    </p>
                  </div>
                </div>
              );
            })}

          <div className={styles.paymentContainer}>
            <h2 className={styles.paymentHadingNavbar}>Payment</h2>
            <div className={styles.optionGroup}>
              <label>
                <input
                  type="radio"
                  name="selection"
                  value="Pakistan"
                  checked={selection === "Pakistan"}
                  onChange={() => setSelection("Pakistan")}
                />
                Pakistan
              </label>
              {systemType === "user" && (
                <label>
                  <input
                    type="radio"
                    name="selection"
                    value="International"
                    checked={selection === "International"}
                    onChange={() => setSelection("International")}
                  />
                  International
                </label>
              )}
            </div>

            <h2 className={styles.paymentHadingNavbar}>Payment Type</h2>
            <div className={styles.optionGroup}>
              <label>
                <input
                  type="radio"
                  name="paymentType"
                  value="Full Payment"
                  checked={paymentType === "Full Payment"}
                  onChange={() => setPaymentType("Full Payment")}
                />
                Full Payment
              </label>
              {state?.serviceName == "Lab" ||
              state?.serviceName == "prescription" ||
              state?.serviceName == "Ambulance" ||
              state?.serviceName == "insurance" ||
              state?.serviceName == "donation" ||
              state?.serviceName == "pharmacy" ||
              state?.serviceName == "flights" ||
              systemType !== "user" ? null : (
                <label>
                  <input
                    type="radio"
                    name="paymentType"
                    value="Partial Payment"
                    checked={paymentType === "Partial Payment"}
                    onChange={() => setPaymentType("Partial Payment")}
                  />
                  Partial Payment
                </label>
              )}
            </div>

            <div className={styles.amountDetails}>
              {selection === "International" && (
                <div>
                  <span className={styles.processPaymentAmountMargin}>
                    Process Amount
                  </span>
                  <span>
                    {selection == "International" ? "$" : "PKR"} {processingFee}
                  </span>
                </div>
              )}
              {paymentType === "Partial Payment" && (
                <div>
                  <span className={styles.processPaymentAmountMargin}>
                    Remaining Amount
                  </span>
                  <span>
                    {selection == "International" ? "$" : "PKR"}{" "}
                    {convertedRemainingAmount}
                  </span>
                </div>
              )}
              <div>
                <span className={styles.processPaymentAmountMargin}>
                  Total Amount
                </span>
                <span>
                  {systemType === "user"
                    ? selection == "International"
                      ? `${"$" + " " + convertedAmount?.toFixed(2)}`
                      : `${"PKR" + " " + calculateAmount}`
                    : `${"$" + " " + convertedAmount?.toFixed(2)}`}
                </span>
              </div>

              {selection == "International" && (
                <div>
                  <span className={styles.processPaymentAmountMargin}>
                    Payable Amount
                  </span>
                  <span>
                    {selection == "International" &&
                      `${"$" + " " + amount?.toFixed(2)}`}
                  </span>
                </div>
              )}
            </div>

            <button onClick={onPressBtn} className={styles.paymentButton}>
              PAYMENT
            </button>
          </div>
        </>
      )}

      {loading && <CustomLoader />}

      {systemType === "user" && <Footerr />}
    </div>
  );
});

export default PaymentNavbar;
