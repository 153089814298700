import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../../../assets/logo.png';
import styles from './BookigHotelDetail.module.css';
import DownloadImagelogo from '../../../../../assets/images/DownloadImagelogo.png';
import LocationIcon from '../../../../../assets/images/LocationIcon12.png';
import PaymentMyAppointMent from "../PaymentMyAppointMent";
const BookingRentACarFullPaymentDetail = React.memo((props) => {
          const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const bookingDetails = {
    image: Avatar,
    customer: {
      name: "Umer Jhangir Khan",
      cnic: "01234-5678912-3",
      phone: "01234567819",
      destination: "Architect society Lahore"
    },
    rental: {
      pickUpLocation: "Architect society Lahore",
      dropOffLocation: "Architect society Lahore",
      pickupDate: "13 April 2024 09:00 AM"
    }
  };
  const [paymentDetails, setPaymentDetails] = useState({
   
      totalAmount: "12,504",
      partialAmount: "3,504",
      pendingAmount: "8,000",
      pendingAmountDate: "07/12/2024"
    
  });




return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Booking
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            My Booking
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Detail
          </p>
        </div>
      </div>

       <div className={styles.bookingHotelDetailContainer}>
        <div className={styles.hotelDetail}>
            <div className={styles.imageContainerRental}>
    <img src={bookingDetails.image} alt="Rent A Car" className={styles.bookingImageRental} />
</div>
              <div className={styles.dashedLineDetail}></div>


         <div className={styles.customerRentalDetails}>
          <div className={styles.rentalHeading}>
    <h2>Customer Details</h2>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelCustomerName}>Name:</span>
        <span className={styles.valueCustomerName}>{bookingDetails.customer.name}</span>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelCustomerCnic}>CNIC:</span>
        <span className={styles.valueCustomerCnic}>{bookingDetails.customer.cnic}</span>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelCustomerPhone}>Phone No.:</span>
        <span className={styles.valueCustomerPhone}>{bookingDetails.customer.phone}</span>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelCustomerDestination}>Destination:</span>
        <span className={styles.valueCustomerDestination}>{bookingDetails.customer.destination}</span>
    </div>
    <div className={styles.rentalHeading}>
    <h2>Rental Details</h2>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelRentalPickupLocation}>Pick-up Location:</span>
        <span className={styles.valueRentalPickupLocation}>{bookingDetails.rental.pickUpLocation}</span>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelRentalDropoffLocation}>Drop-off Location:</span>
        <span className={styles.valueRentalDropoffLocation}>{bookingDetails.rental.dropOffLocation}</span>
    </div>
    <div className={styles.detailItem}>
        <span className={styles.labelRentalPickupTime}>Pickup Date & Time:</span>
        <span className={styles.valueRentalPickupTime}>{bookingDetails.rental.pickupDate}</span>
    </div>
</div>

    </div>
   <div className={styles.paymentDetail}>
    <h3 className={styles.paymentHeader}>Payment</h3>
    <div className={styles.totalAmount}>
    <span className={styles.labelTotal}>Total Amount:</span>
    <span className={styles.amountValue}>{paymentDetails.totalAmount}</span>
</div>

    <div className={styles.totalAmount}>
    <span className={styles.labelTotal}>Partial Amount:</span>
    <span className={styles.amountValue}>{paymentDetails.partialAmount}</span>
</div>
{/* <div className={styles.pendingAmount}>
    <span className={styles.label}>Pending Amount:</span>
    <span className={styles.amountValue}>{paymentDetails.pendingAmount}</span>
</div>
<div className={styles.pendingAmountDate}>
    <span className={styles.label}>Pending Amount Date:</span>
    <span className={styles.dateValue}>{paymentDetails.pendingAmountDate}</span>
</div> */}

    {/* <div className={styles.paymentButtonContainer}>
        <button className={styles.paymentButton} onClick={() => setModalOpen(true)}>Payment
            <img src={DownloadImagelogo} alt="Logo" className={styles.downloadButtonLogo} />
        </button>
        <PaymentMyAppointMent isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div> */}
</div>

</div>

     




         <Footerr />
    </div>
  );
});

export default BookingRentACarFullPaymentDetail;