import classNames from "classnames";
import { useSelector } from "react-redux";
import { IoMdHeartEmpty } from "react-icons/io";
import { LoadingModal } from "shared/components";
import { IoMdArrowForward } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonStyless from "shared/utils/common.module.css";
import { MdOutlineYoutubeSearchedFor } from "react-icons/md";
import CardStyless from "../DoctarServices/Cards.module.css";
import {
  IoLocationOutline,
  IoSearchSharp,
  IoTimeOutline,
} from "react-icons/io5";
import { getUser_Laboratory } from "shared/services/UserService";
import Vector from "assets/images/Vector.png";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import RatingStar from "shared/RatingStar";
import CustomLoader from "shared/components/New_Loader/Loader";

const LaboratoryCards = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState<string>("ALL");
  const { location } = useSelector((state: any) => state?.root?.common);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getList();
  }, [activeTab]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      getList(); // Fetch the list when Enter is pressed
    }
  };

  const handleViewMoreClick = (id: any) => {
    navigate(`/services/laboratory/LabortoryDetail`, {
      state: { serviceName: "Lab", id: id },
    });
  };
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  const getList = () => {
    let params = {
      page: 1,
      search: searchValue,
      lat: activeTab == 'Nearby' ? location?.latitude : '',
      long: activeTab == 'Nearby' ? location?.longitude : '',
      filter: activeTab?.toLowerCase()
    };
    getUser_Laboratory(params)
      .then((res: any) => {
        setData(res.data.labs);
      })
      .catch((err: any) => {
        console.log("err...", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classNames(CommonStyless.container, CommonStyless.mt32)}>
      <div style={{ backgroundColor: "FDFDFD" }}>



        <div
          className={classNames(
            CardStyless.tabContainer,
            CommonStyless.flx
          )}
        >
          {[
            "ALL",
            "Nearby",

          ]
            ?.filter(Boolean)
            .map((tab: any) => (
              <button
                key={tab}
                className={classNames(
                  CardStyless.tab,
                  activeTab === tab && CardStyless.activeTab
                )}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </button>
            ))}
        </div>
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxWrap,
            CommonStyless.flxBetween
          )}
        >
          <div className={classNames(CommonStyless.flx)}></div>
          <div className={CardStyless.searchBarContainer}>
            <IoSearchSharp className={CardStyless.searchIcon} />
            <input
              type="text"
              placeholder="Search..."
              className={CardStyless.searchInput}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          <div className={CardStyless.cardContainer}>
            {data && data.length > 0 ? (
              data.map((card: any, index: any) => (
                <div key={index} className={CardStyless.cardWrapper}>
                  <div className={CardStyless.cardImageWrapper}>
                    <img
                      src={card?.logo}
                      alt="card img"
                      className={CardStyless.cardImage}
                    />
                  </div>
                  <div
                    className={CardStyless.cardBody}
                    onClick={() => handleViewMoreClick(card?._id)}
                  >
                    <div
                      className={classNames(
                        CommonStyless.flx,
                        CommonStyless.flxBetween
                      )}
                    >
                      <div
                        className={classNames(
                          CardStyless.cardName,
                          CommonStyless.colorBlue
                        )}
                      >
                        {card?.name}
                      </div>
                      <div>
                        <img
                          src={Vector}
                          alt="Vector icon"
                          className={CardStyless.vectorIcon}
                        />
                      </div>
                    </div>
                    <div className={CardStyless.cardtime}>
                      <span className={CardStyless.timeIcon}>
                        <IoTimeOutline />
                      </span>
                      <span>{card?.openTime}</span>
                    </div>

                    <div className={CardStyless.cardtime}>
                      <span className={CardStyless.timeIcon}>
                        <IoLocationOutline />
                      </span>
                      <span>{card?.location?.address}</span>
                    </div>
                    <div className={CardStyless.cardFooter}>
                      <RatingStar rating={3.5} />
                      {/* {card?.rating} */}

                      <span
                        className={classNames(
                          CommonStyless.flx,
                          CardStyless.viewMore
                        )}
                        onClick={() => handleViewMoreClick(card?._id)}
                      >
                        <Link to="#" className={CardStyless.cardViewMore}>
                          Details
                        </Link>
                        <span className={CardStyless.cardArrow}>
                          <IoMdArrowForward />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                {!loading && (
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxCenter
                    )}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <div className={classNames(CommonStyless.flx)}>
                      <PhysiotheristsEmpty />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default LaboratoryCards;
