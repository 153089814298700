import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./medicines.module.css";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SearchBar from "shared/components/Searchbar/index";
import commonStyles from "shared/utils/common.module.css";
import commonstyle from "shared/utils/common.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import style from "./medicines.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import {
  PharmacyMedicineDELETE,
  PharmacyMedicineDetails,
  PharmacyMedicineEdit,
  pharmacyAddMedicine,
} from "shared/services";
import { MdDeleteOutline } from "react-icons/md";
import {
  ArrowBack,
  CustomInput,
  CustomModal,
  CustomStepper,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";
import { Typography } from "@mui/material";
import CustomSelect from "shared/components/CustomSelect";
import {
  pharmAddMedicineMedicineInfo,
  pharmAddMedicineMedicinePrice,
} from "shared/utils";
import ImgPicker from "shared/components/Img-picker";
import { useDispatch, useSelector } from "react-redux";
import { setpharmacyMedicine } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
const steps = [
  {
    id: "1",
    lable: " MedicinesInfo",
  },
  {
    id: "2",
    lable: "Medicinesprice",
  },
];

const Medicinestypes = [
  "Tablets ",
  "Capsules",
  "syrup",
  "Drops",
  "Injections",
  "Inhalers",
];
interface Props {
  setShowAddModal: any;
}
const MedicineConfirmDelete = (props: Partial<Props>) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { medicine } = useSelector((state: any) => state.root.pharmacy);
  const dispatch = useDispatch();

  const deleteMedicine = () => {
    let med_clone = JSON.parse(JSON.stringify(medicine));

    setLoading(true);
    const medicineId = id || "";
    PharmacyMedicineDELETE(medicineId)
      .then((res: any) => {
        if (res?.status === 200) {
          med_clone?.map((item: any, ind: any) => {
            if (id == item?._id) {
              med_clone.splice(ind, 1);
            }
          });
          dispatch(setpharmacyMedicine(med_clone));
          navigate("/pharmacy/medicine");
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const { setShowAddModal } = props;
  return (
    <div className={styles.modelwidth}>
      <div className={styles.end}>
        <IoClose
          className={styles.close}
          onClick={() => setShowAddModal(false)}
        />
      </div>
      <p
        className={classNames(
          commonstyle.fs16,
          commonstyle.semiBold,
          styles.mt16,
          commonstyle.colorBlue,
          styles.textjustifys
        )}
      >
        Are you sure you want to delete this Medicine?
      </p>
      <div className={classNames(commonstyle.flxBetween, styles.mt32)}>
        <button
          onClick={() => setShowAddModal(false)}
          className={styles.cancel}
        >
          Cancel
        </button>
        <button className={styles.btnDelete} onClick={deleteMedicine}>
          Delete
        </button>
      </div>
    </div>
  );
};

interface EditProps {
  setShowEditModel: any;
  medicinedetails: MediDetails | null;
  MedicineDetail?: any;
  setMedicineDetails?: any;
}

const MedicineEdit = (props: Partial<EditProps>) => {
  const {
    setShowEditModel,
    medicinedetails,
    MedicineDetail,
    setMedicineDetails,
  } = props;
  const [screenName, setScreenName] = useState("MedicineInfo");
  const [Medicines, setMedicines] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);
  const [addData, setAddData] = useState({});
  console.log("MEDI>>>>>>>>>>>>>>>>>>>>>>>>>>>.", medicinedetails);
  const handleClickNext = () => {
    if (screenName === "MedicineInfo") {
      setScreenName("Medicineprice");
    }

    if (selectedStep < 3) {
      setSelectedStep(selectedStep + 1);
    }
  };

  const handleClicKPrev = () => {
    if (screenName === "Medicineprice") {
      setScreenName("MedicineInfo");
    }

    if (selectedStep > 0) {
      setSelectedStep(selectedStep - 1);
    }
  };

  const handleCloseModal = () => {
    setShowEditModel(false);
  };

  return (
    <Typography
      id="modal-modal-description"
      sx={{ textAlign: "center", color: "#001F57" }}
    >
      <div className={commonstyle.flx}>
        <div className={commonstyle.flx} style={{ width: "97%" }}>
          <ArrowBack onClick={handleClicKPrev} />
          <p className={classNames(commonstyle.semiBold, commonstyle.fs16)}>
            Previous
          </p>
        </div>
        <div className={styles.end}>
          <button className={styles.close} onClick={handleCloseModal}>
            &#10006;
          </button>
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <CustomStepper steps={steps} selectedStep={selectedStep} />
      </div>
      <div>
        {screenName == "MedicineInfo" && (
          <MedicineInfo
            handleClickNext={handleClickNext}
            setAddData={setAddData}
            medicinedetails={medicinedetails}
          />
        )}
        {screenName == "Medicineprice" && (
          <Medicineprice
            MedicineDetail={MedicineDetail}
            handleClickNext={handleClickNext}
            addData={addData}
            setAddData={setAddData}
            setShowEditModel={setShowEditModel}
            medicinedetails={medicinedetails}
            setMedicineDetails={setMedicineDetails}
          />
        )}
      </div>
      <div
        style={{ width: "210px", marginTop: "56px" }}
        className={styles.start}
      >
        {/* <button
          onClick={handleClickNext}
          style={{ fontSize: "16px", fontWeight: "600" }}
          className={styles.btnx}
        >
          Next
        </button> */}
      </div>
    </Typography>
  );
};

interface Props {
  handleClickNext: any;
  setAddData: any;
  addData: any;
  setShowEditModel: any;
  medicinedetails?: MediDetails | null;
}
const MedicineInfo = (props: Partial<Props>) => {
  const { handleClickNext, setAddData, setShowEditModel, medicinedetails } =
    props;
  const formik = useFormik({
    initialValues: {
      pharmMedicineGeneric: medicinedetails?.generic || "",
      pharmMedicineImage: medicinedetails?.medicineImage || "",
      pharmMedicineBrand: medicinedetails?.medicineBrand || "",
      pharmMedicineType: medicinedetails?.medicineType || "",
      pharmMedicineStrength: medicinedetails?.potency || "",
      pharmMedicinePackSize: medicinedetails?.packSize || "",
    },
    validationSchema: Yup.object(pharmAddMedicineMedicineInfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    console.log("Submit");
    const currentData = formik.values;
    handleClickNext();
    setAddData({
      generic: currentData.pharmMedicineGeneric,
      medicineBrand: currentData.pharmMedicineBrand,
      medicineType: currentData.pharmMedicineType,
      potency: currentData.pharmMedicineStrength,
      packSize: currentData.pharmMedicinePackSize,
      medicineImage: currentData.pharmMedicineImage,
    });
  };

  const ImageURLHandler = (url: any) => {
    formik.setFieldValue("pharmMedicineImage", url);
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("pharmMedicineType", selectedOption);
  };
  return (
    <div style={{ width: "528px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ marginRight: "12px" }} className={commonstyle.col6}>
            <CustomInput
              placeholder="Generic"
              id="pharmMedicineGeneric"
              name="pharmMedicineGeneric"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmMedicineGeneric}
            />

            {formik.touched.pharmMedicineGeneric &&
            formik.errors.pharmMedicineGeneric ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmMedicineGeneric}
              </div>
            ) : null}
          </div>
          <div style={{ marginLeft: "12px" }} className={commonstyle.col6}>
            <ImgPicker
              placeholder={medicinedetails?.medicineImage || "Medicine Image"}
              setData={ImageURLHandler}
            />
            {/* {formik.touched.pharmMedicineImage &&
          formik.errors.pharmMedicineImage ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.pharmMedicineImage}
            </div>
          ) : null} */}
          </div>
        </div>
        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ marginRight: "12px" }} className={commonstyle.col6}>
            <CustomInput
              placeholder="Medicine Brand"
              id="pharmMedicineBrand"
              name="pharmMedicineBrand"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmMedicineBrand}
            />

            {formik.touched.pharmMedicineBrand &&
            formik.errors.pharmMedicineBrand ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmMedicineBrand}
              </div>
            ) : null}
          </div>
          <div style={{ marginLeft: "12px" }} className={commonstyle.col6}>
            <CustomSelect
              options={Medicinestypes}
              placeholder={
                medicinedetails?.medicineType
                  ? medicinedetails.medicineType
                  : "Type"
              }
              onSelect={handleSelect}
              value={formik.values.pharmMedicineType}
            />

            {formik.touched.pharmMedicineType &&
            formik.errors.pharmMedicineType ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmMedicineType}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
          <div style={{ marginRight: "12px" }} className={commonstyle.col6}>
            <CustomInput
              placeholder="Strength"
              id="pharmMedicineStrength"
              name="pharmMedicineStrength"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmMedicineStrength}
            />

            {formik.touched.pharmMedicineStrength &&
            formik.errors.pharmMedicineStrength ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmMedicineStrength}
              </div>
            ) : null}
          </div>
          <div style={{ marginLeft: "12px" }} className={commonstyle.col6}>
            <CustomInput
              placeholder="Pack Size"
              id="pharmMedicinePackSize"
              name="pharmMedicinePackSize"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.pharmMedicinePackSize}
            />

            {formik.touched.pharmMedicinePackSize &&
            formik.errors.pharmMedicinePackSize ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.pharmMedicinePackSize}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ width: "110px", marginTop: "56px" }}>
          <PrimaryButton
            children={"Next Step"}
            type="submit"
            colorType={"blue"}
          />
        </div>
      </form>
    </div>
  );
};

interface Props {
  handleClickNext: any;
  addData: any;
  setShowEditModel: any;
  Medicines: any;
  setMedicines: any;
  medicinedetails?: MediDetails | null;
  MedicineDetail?: any;
  setMedicineDetails?: any;
}
const Medicineprice = (props: Partial<Props>) => {
  const {
    handleClickNext,
    addData,
    Medicines,
    setShowEditModel,
    setMedicines,
    medicinedetails,
    MedicineDetail,
    setMedicineDetails,
  } = props;
  const ID = medicinedetails?._id || "";
  const [loading, setLoading] = useState(false);
  const { medicine } = useSelector((state: any) => state.root.pharmacy);
  const dispatch = useDispatch();

  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      actualPrice: medicinedetails?.actualPrice || "",
      priceForMediTour: medicinedetails?.priceMeditour || "",
    },
    validationSchema: Yup.object(pharmAddMedicineMedicinePrice),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleSubmit = async () => {
    console.log("Submit..............");
    let med_clone = JSON.parse(JSON.stringify(medicine));

    const curr_data = formik.values;
    handleClickNext();
    setLoading(true);

    let params = {
      ...addData,
      actualPrice: curr_data.actualPrice,
      priceMeditour: curr_data.priceForMediTour,
    };

    PharmacyMedicineEdit(ID || "", params)
      .then((res: any) => {
        // MedicineDetail();
        // handleClickNext();
        if (res.status === 200) {
          console.log("response.///////////////////////....", res.data);
          setMedicineDetails(res?.data?.medicine);
          med_clone?.map((item: any, ind: any) => {
            if (id == item?._id) {
              med_clone[ind] = res?.data?.medicine;
            }
          });
          dispatch(setpharmacyMedicine(med_clone));
        }
        setShowEditModel(false);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ width: "528px" }}>
          <div style={{ marginTop: "24px" }} className={commonstyle.flx}>
            <div style={{ marginRight: "12px" }} className={commonstyle.col6}>
              <CustomInput
                placeholder="Actual Price"
                id="actualPrice"
                name="actualPrice"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.actualPrice}
              />

              {formik.touched.actualPrice && formik.errors.actualPrice ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.actualPrice}
                </div>
              ) : null}
            </div>
            <div style={{ marginLeft: "12px" }} className={commonstyle.col6}>
              <CustomInput
                placeholder="Price For MediTour"
                id="priceForMediTour"
                name="priceForMediTour"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.priceForMediTour}
              />

              {formik.touched.priceForMediTour &&
              formik.errors.priceForMediTour ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.priceForMediTour}
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ width: "110px", marginTop: "56px" }}>
            <PrimaryButton
              children={loading ? "loading..." : "Save"}
              disabled={loading ? true : false}
              type="submit"
              colorType={"green"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

interface MediDetails {
  medCode: string;
  generic: string;
  medicineImage: string;
  potency: string;
  medicineBrand: string;
  medicineType: string;
  packSize: string;
  actualPrice: number;
  priceMeditour: number;
  _id: string;
}

export default function MedicinesDetail(props: Partial<Props>) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const handleBackToMedicinesTable = () => {
    navigate("/pharmacy/medicine");
  };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const handleEditModel = () => {
    setShowEditModel(true);
  };

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [medicinedetails, setMedicineDetails] = useState<MediDetails | null>(
    null
  );

  const MedicineDetail = () => {
    setLoading(true);
    if (id === undefined) {
      console.error("ID is undefined");
      setLoading(false);
      return;
    }
    if (id) {
      // console.log("ID value:", id);

      PharmacyMedicineDetails(id)
        .then((res: any) => {
          console.log("API Response:", res);
          setMedicineDetails(res?.data.medicine);
        })
        .catch((err: any) => {
          console.log("API Error:", err?.response?.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  useEffect(() => {
    MedicineDetail();
  }, []);

  const navigate = useNavigate();
  const handleGoToBack = () => {
    navigate("/ambulance/ambulances");
  };
  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyle.col12)}>
          <SearchBar />
          <div className={classNames(commonstyle.mr87, commonstyle.colorBlue)}>
            <div className={styles.outerContainer}>
              <div className={commonstyle.flx}>
                <IoMdArrowRoundBack
                  className={style.back}
                  onClick={handleBackToMedicinesTable}
                />
                <p
                  className={classNames(commonstyle.fs20, commonstyle.semiBold)}
                >
                  Back
                </p>
                <div className={classNames(commonstyle.flx, style.end)}>
                  <div className={styles.buttoons}>
                    <BiSolidEdit
                      className={styles.reset}
                      onClick={handleEditModel}
                    />
                  </div>
                  <div className={styles.buttoons}>
                    <MdDeleteOutline
                      className={styles.reset}
                      onClick={handleOpenModal}
                    />
                  </div>
                </div>
                <CustomModal
                  showModal={showAddModal}
                  children={
                    <MedicineConfirmDelete setShowAddModal={setShowAddModal} />
                  }
                />
                <CustomModal
                  showModal={showEditModel}
                  children={
                    <MedicineEdit
                      setShowEditModel={setShowEditModel}
                      medicinedetails={medicinedetails}
                      MedicineDetail={MedicineDetail}
                      setMedicineDetails={setMedicineDetails}
                    />
                  }
                />
              </div>
              <div className={classNames(style.mt32)}>
                <p
                  className={classNames(commonstyle.fs24, commonstyle.semiBold)}
                >
                  Medicine Detail
                </p>
              </div>
              <div
                className={classNames(
                  style.mt32,
                  commonstyle.flxBetween,
                  commonstyle.fs20
                )}
              >
                <div className={classNames(commonstyle.col5)}>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Medicine code:
                    </p>
                    <p>{medicinedetails?.medCode}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Generic:
                    </p>
                    <p>{medicinedetails?.generic}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Strength:
                    </p>
                    <p>{medicinedetails?.potency}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Medicine Brand:
                    </p>
                    <p>{medicinedetails?.medicineBrand}</p>
                  </div>
                </div>
                <div className={classNames(commonstyle.col5)}>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Medicine Type:
                    </p>
                    <p>{medicinedetails?.medicineType}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Size:
                    </p>
                    <p>{medicinedetails?.packSize}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Actual Price:
                    </p>
                    <p>{medicinedetails?.actualPrice}</p>
                  </div>
                  <div className={classNames(style.mt8, commonstyle.flx)}>
                    <p
                      className={classNames(
                        commonstyle.semiBold,
                        commonstyle.col5
                      )}
                    >
                      Price for Meditour:
                    </p>
                    <p>{medicinedetails?.priceMeditour}</p>
                  </div>
                </div>
              </div>
              {/* <div className={style.mt32}>
                <p
                  className={classNames(commonstyle.fs24, commonstyle.semiBold)}
                >
                  Medicine Detail
                </p>
                <p className={classNames(commonstyle.fs16, style.mt16)}>
                  Aconite extracts have been used traditionally for analgesic,
                  anti-inflammatory, and antirheumatic purposes; however, use is
                  not recommended for any indication because aconite is highly
                  toxic.
                </p>
                <p className={classNames(commonstyle.fs16, style.mt32)}>
                  conitum napellus (aconite) is needed when a fever develops
                  suddenly, especially if it occurs after the patient has been
                  exposed to cold weather, especially a cold dry wind. Aconitum
                  napellus is also indicated when a fever develops after a
                  frightening or shocking experience. The fever is worst in the
                  evening and at night. It may be preceded by a chill. The
                  patient is usually restless, anxious, and fearful but can be
                  sad and tearful. He is overly sensitive to pain. The face is
                  red and congested, and the patient may perspire. There is a
                  marked thirst. If given at the beginning of an illness,
                  Aconitum napellus can often stop an illness from progressing.
                  This medicine be used to treat the initial stages of croup,
                  urinary tract infection, scarlatiniform viral exanthems,
                  otitis media, and influenza, among other conditions.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
