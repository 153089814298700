import React, { useState } from "react";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./index.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { BiSolidPlaneAlt } from "react-icons/bi";
import Fly from "assets/images/image 949.png";
import logo from "assets/images/flys.png";
import ContinueButton from "shared/components/ContinueButton";
import { useNavigate } from "react-router-dom";
import ToursBooking from "./Tour/Index";
import NavigationBar from "../NavigateBar";

const flightsData = [
  {
    flightName: "Ahan Travel & Tours",
    imgSrc: Fly,
    departureCity: "Lahore",
    imglogo: logo,
    departureTime: "16:30",
    arrivalCity: "Dubai",
    arrivalTime: "18:30",
    travelTime: "16:00",
    date: "20 Dec, Wed",
  },
];
const TravelBooking = () => {
  const [activeTab, setActiveTab] = useState("Flight");

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
    console.log(`Selected tab: ${tab}`);
  };

  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/services/bookingtravel/TravelDetail");
    console.log("Button clicked!");
  };

  return (
    <div>
      <MainHeader
        mainHeading="Travel Agency"
        breadcrumb={["Home", "My Booking"]}
      />
      <div className={classNames()}>
        <div>
          <NavigationBar />
        </div>

        <div style={{ margin: "20px 0" }}>
          <DoubleButton
            tab1Label="Flight"
            tab2Label="Tours"
            onTabChange={handleTabChange}
            shift={activeTab}
          />
        </div>

        <div>
          {activeTab === "Flight" && (
            <div
              className={classNames(
                commonstyle.flx,
                commonstyle.flxCenter,
                commonstyle.flxCol
              )}
            >
              <>
                {flightsData.map((flight, index) => (
                  <>
                    <div className={classNames(style.heaingcontainer)}>
                      <p className={classNames(style.title)}>Flight Type</p>
                      <p className={classNames(style.detail)}>One-Way</p>

                      <div
                        className={classNames(
                          commonstyle.flx,
                          commonstyle.flxBetween
                        )}
                      >
                        <div>
                          <p className={classNames(style.title)}>From </p>
                          <p className={classNames(style.detail)}>Lahore</p>
                        </div>
                        <div>
                          <p className={classNames(style.title)}>To </p>
                          <p className={classNames(style.detail)}>Lahore</p>
                        </div>
                        <div>
                          <p className={classNames(style.title)}>Departure </p>
                          <p className={classNames(style.detail)}>
                            17, Feb 2024
                          </p>
                        </div>
                        <div>
                          <p className={classNames(style.title)}>Traveler </p>
                          <p className={classNames(style.detail)}>
                            4 traveler{" "}
                          </p>
                        </div>
                        <div>
                          <p className={classNames(style.title)}>Class </p>
                          <p className={classNames(style.detail)}>Economy </p>
                        </div>
                      </div>
                    </div>{" "}
                    <div
                      key={index}
                      className={classNames(style.flightcontainer)}
                      // style={{
                      //   width: "33.13%",
                      // }}
                    >
                      <div className={classNames(style.imgconatiner)}>
                        <img
                          src={flight.imgSrc}
                          alt=""
                          className={style.flyimg}
                        />
                        <p
                          className={classNames(
                            commonstyle.fs16,
                            commonstyle.colorBlue,
                            commonstyle.semiBold
                          )}
                        >
                          {flight.flightName}
                        </p>
                      </div>
                      <div
                        className={classNames(
                          commonstyle.flx,
                          commonstyle.flxCenter
                        )}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            position: "relative",
                            color: "#0e54a3",
                          }}
                        >
                          {/* Left City */}
                          <div
                            style={{ marginRight: "10px", fontSize: "18px" }}
                          >
                            <p>{flight.departureCity}</p>
                            {/* <p>{flight.departureTime}</p> */}
                          </div>

                          {/* Plane Icon and Time */}
                          <div
                            style={{
                              position: "relative",
                              flexGrow: 1,
                              height: "1px",
                              borderTop: "2px dotted #0e54a3",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: " 0  10%",
                            }}
                          >
                            <BiSolidPlaneAlt
                              style={{
                                position: "absolute",
                                top: "-33px",
                                fontSize: "24px",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "15px",
                                fontSize: "14px",
                                color: "#0e54a3",
                              }}
                            >
                              {flight.travelTime}
                            </div>
                          </div>

                          {/* Right City */}
                          <div style={{ marginLeft: "10px", fontSize: "18px" }}>
                            <p>{flight.arrivalCity}</p>
                            {/* <p>{flight.arrivalTime}</p> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className={classNames(
                            commonstyle.flx,
                            commonstyle.flxBetween
                          )}
                          style={{
                            margin: "10px  0",
                          }}
                        >
                          <div>
                            <img
                              src={flight.imglogo}
                              alt=""
                              className={style.flyimg}
                            />
                          </div>
                          <div>
                            <p
                              className={classNames(
                                commonstyle.colorBlue,
                                commonstyle.fs16,
                                commonstyle.semiBold,
                                commonstyle.flx,
                                commonstyle.flxEnd
                              )}
                            >
                              {flight.arrivalCity}
                            </p>
                          </div>
                          <div>
                            <p
                              className={classNames(
                                commonstyle.colorBlue,
                                commonstyle.fs16,
                                commonstyle.semiBold,
                                commonstyle.flx,
                                commonstyle.flxEnd
                              )}
                            >
                              {flight.arrivalCity}
                            </p>
                          </div>
                        </div>

                        <div>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#006838",
                              fontWeight: "400",
                            }}
                          >
                            E-Ticket will be upload in a few minutes
                          </p>
                        </div>

                        <div>
                          <ContinueButton
                            buttonText="View Details"
                            onClick={handleContinue}
                            backgroundColor="#0d47a1"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            </div>
          )}
          {activeTab === "Tours" && (
            <div>
              <ToursBooking />
            </div>
          )}
        </div>
      </div>

      <Footerr />
    </div>
  );
};

export default TravelBooking;
