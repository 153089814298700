import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import style from "./myFamily.module.css";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
} from "shared/components";
import { IoClose } from "react-icons/io5";
import User from "assets/images/Userr2.jpeg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";

import {
  setInsuranceHealthFamilyPackage,
  setInsuranceMySelfPackage,
} from "shared/redux";
import {
  insuranceFamilyPackageSchema,
  insuranceMySelfPackageSchema,
} from "shared/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { insuranceGETFAMILYINSURANCES } from "shared/services/Insurance";
import CustomLoader from "shared/components/New_Loader/Loader";

export default function MyFamilyMain() {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [showFamilyModal, setShowFamilyModal] = useState(false);

  const navigate = useNavigate(); // Declare once

  const handleGoBack = () => {
    navigate("/insurance/Health");
  };
  // const handleGoToMyselfDetail = () => {
  //   navigate("/insurance/MyFamily/Detail");
  // };
  const type = "family";
  const handleGoToMyselfDetail = (item: any) => {
    navigate("/insurance/MySelf/Detail", { state: { item, type } });
  };

  const handleCardFamily = () => {
    // Set the state to true to show the modal
    setShowFamilyModal(true);
  };

  const fetchFamilyInsurances = () => {
    setLoading(true);
    insuranceGETFAMILYINSURANCES()
      .then((res: any) => {
        console.log("response........", res);
        setdata(res?.data?.insurances);
      })
      .catch((err: any) => {
        console.log("error........", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFamilyInsurances();
  }, []);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyles.col12)}>
          <SearchBar />
          <div className={commonstyles.mr87}>
            <div className={classNames(commonstyles.outerContainer)}>
              <div className={commonstyles.flx}>
                <div className={commonstyles.flx}>
                  <IoArrowBack className={style.back} onClick={handleGoBack} />
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold,
                      commonstyles.colorBlue,
                      style.mr56
                    )}
                  >
                    Back{" "}
                  </p>
                </div>
                <p
                  className={classNames(
                    commonstyles.fs20,
                    commonstyles.semiBold,
                    commonstyles.colorBlue
                  )}
                >
                  Health / Family
                </p>
                <div className={style.outerRefresh} onClick={handleCardFamily}>
                  <BiSolidMessageSquareAdd className={style.RefreshIcon} />
                </div>

                <CustomModal
                  showModal={showFamilyModal}
                  children={<Family setShowFamilyModal={setShowFamilyModal} />}
                />
              </div>
              <div className={style.flxWrap}>
                {data.map((item: any) => (
                  <div
                    className={style.myselfIncCard}
                    onClick={() => handleGoToMyselfDetail(item)}
                  >
                    <Avatar src={item?.packageLogo} className={style.profile} />
                    <p
                      className={classNames(
                        commonstyles.fs20,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.packageName}
                    </p>
                    <p className={classNames(commonstyles.fs15, style.mt16)}>
                      Hospitalization Limit
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt8,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.hospitalizationLimit?.startLimit} -{" "}
                      {item?.hospitalizationLimit?.endLimit}
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt16,
                        commonstyles.semiBold
                      )}
                    >
                      Claim Payout Ratio
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs15,
                        style.mt8,
                        commonstyles.semiBold
                      )}
                    >
                      {item?.claimPayoutRatio}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Family = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoFamilyflow = () => {
    navigate("/insurance/Family");
  };
  const { setShowFamilyModal, setTests, Tests } = props;

  const handleCloseModal = () => {
    setShowFamilyModal(false);
  };

  const formik = useFormik({
    initialValues: {
      insuranceAgestart: "",
      insuranceAgeend: "",
      spouseAgeStart: "",
      spouseAgeEnd: "",
      kidsAgeStart: "",
      kidsAgeEnd: "",
      hospitalizationStartLimit: "",
      hospitalizationEndLimit: "",
    },
    validationSchema: Yup.object(insuranceFamilyPackageSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    console.log("Submit");
    dispatch(setInsuranceHealthFamilyPackage({ ...formik.values }));
    navigate("/insurance/Family");
  };

  return (
    <div style={{ width: "400px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div className={style.header}>
          <p
            className={classNames(
              commonstyles.fs20,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Family Package
          </p>
          <IoClose className={style.close} onClick={handleCloseModal} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: "#00276D", transform: "rotate(0deg)" }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Your age Criteria
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <div
                    className={classNames(commonstyles.col6, commonstyles.mr32)}
                  >
                    <CustomInput
                      placeholder="Age start"
                      id="insuranceAgestart"
                      name="insuranceAgestart"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.insuranceAgestart}
                    />
                    {formik.touched.insuranceAgestart &&
                    formik.errors.insuranceAgestart ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.insuranceAgestart}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <CustomInput
                      placeholder="Age end "
                      id="insuranceAgeend"
                      name="insuranceAgeend"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.insuranceAgeend}
                    />
                    {formik.touched.insuranceAgeend &&
                    formik.errors.insuranceAgeend ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.insuranceAgeend}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ color: "#00276D", border: "none" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Spouse Age Criteria
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <div
                    className={classNames(commonstyles.col6, commonstyles.mr32)}
                  >
                    <CustomInput
                      placeholder="Age start"
                      id="spouseAgeStart"
                      name="spouseAgeStart"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.spouseAgeStart}
                    />
                    {formik.touched.spouseAgeStart &&
                    formik.errors.spouseAgeStart ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.spouseAgeStart}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <CustomInput
                      placeholder="Age end "
                      id="spouseAgeEnd"
                      name="spouseAgeEnd"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.spouseAgeEnd}
                    />
                    {formik.touched.spouseAgeEnd &&
                    formik.errors.spouseAgeEnd ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.spouseAgeEnd}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Kid age
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <div
                    className={classNames(commonstyles.col6, commonstyles.mr32)}
                  >
                    <CustomInput
                      placeholder="Age start"
                      id="kidsAgeStart"
                      name="kidsAgeStart"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.kidsAgeStart}
                    />
                    {formik.touched.kidsAgeStart &&
                    formik.errors.kidsAgeStart ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.kidsAgeStart}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <CustomInput
                      placeholder="Age end "
                      id="kidsAgeEnd"
                      name="kidsAgeEnd"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.kidsAgeEnd}
                    />
                    {formik.touched.kidsAgeEnd && formik.errors.kidsAgeEnd ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.kidsAgeEnd}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.modelFeilds}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#00276D" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.colorBlue,
                      commonstyles.lightItalic
                    )}
                  >
                    Select Hospitalization Limit (PKR)
                  </p>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classNames(commonstyles.flx)}>
                  <div
                    className={classNames(commonstyles.col6, commonstyles.mr32)}
                  >
                    <CustomInput
                      placeholder="Start Limit"
                      id="hospitalizationStartLimit"
                      name="hospitalizationStartLimit"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.hospitalizationStartLimit}
                    />
                    {formik.touched.hospitalizationStartLimit &&
                    formik.errors.hospitalizationStartLimit ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.hospitalizationStartLimit}
                      </div>
                    ) : null}
                  </div>
                  <div className={classNames(commonstyles.col6)}>
                    <CustomInput
                      placeholder="End Limit "
                      id="hospitalizationEndLimit"
                      name="hospitalizationEndLimit"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.hospitalizationEndLimit}
                    />
                    {formik.touched.hospitalizationEndLimit &&
                    formik.errors.hospitalizationEndLimit ? (
                      <div className={classNames(commonstyles.error)}>
                        *{formik.errors.hospitalizationEndLimit}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{ margin: "24px" }}>
          <PrimaryButton
            children={"Save & Continue"}
            colorType={"greenOutline"}
            type="submit"
            // onClick={handleGoFamilyflow}
          />
        </div>
      </form>
    </div>
  );
};
