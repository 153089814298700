import React, { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "routes";
import logo from "assets/images/smallLogo.png";
import { interceptorConfig } from "shared/utils";
import {
  setFcmToken,
  setHomeServiceSelectedRoute,
  setMedicalSelectedRoute,
  setTravelSelectedRoute,
  setmainSelectedRoute,
  store,
} from "shared/redux";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
// import Toast from "shared/components/Toastify/toastify";
import toast, { Toaster } from "react-hot-toast";
import Checkout from "shared/services/stripe/checkout";

// Access environment variable
// const APP_VAPID_KEY = process.env.APP_VAPID_KEY;

function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [logOutFlag, setLogOutFlag] = useState(false);

  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={logo}
            style={{ height: "32px", width: "32px", marginRight: "16px" }}
          />
        </div>
        <div>
          <p style={{ fontWeight: "700" }}>
            <b>{notification?.title}</b>
          </p>
          <p>{notification?.body}</p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        // console.log("payload", payload);
        resolve(payload);
      });
    });
  onMessageListener()
    .then((payload: any) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  //interceptor
  useEffect(() => {
    interceptorConfig(setLogOutFlag);
    // configureNotification();
  }, []);

  useEffect(() => {
    // window.addEventListener('beforeunload', alertUser)
    window.addEventListener("unload", handleTabClosing);
    return () => {
      // window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  useEffect(() => {
    // console.log("VAPID KEY IS", APP_VAPID_KEY);
    // Request permission for notifications and get the token
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const token = await getToken(messaging, {
            vapidKey:
              "BIF9ZdobPcwXfIIPSOgr_GCvI60_kchw3CHog3uXNj-RYDlNJFUnEVgW_iip-A6QxyysS8192cJFNT8IpnWoons",
          });
          if (token) {
            store.dispatch(setFcmToken(token));
          }
        } catch (error) {
          console.error("Error retrieving token:", error);
        }
      } else if (permission === "denied") {
        alert("You denied permission for notifications");
      }
    };

    requestPermission();

    // Cleanup function
    return () => {
      // Any cleanup code if needed
    };
  }, []); // Empty dependency array means this effect runs only once on component mount

  const handleTabClosing = () => {
    store.dispatch(setmainSelectedRoute("/"));
    store.dispatch(setMedicalSelectedRoute("doctor/login"));
    store.dispatch(setHomeServiceSelectedRoute("ambulanceservices/login"));
    store.dispatch(setTravelSelectedRoute("hotel/login"));
  };

  return (
    <>
      {/* <Checkout /> */}
      <AppRoutes logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
      <Toaster />
    </>
  );
}

export default App;
