import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { creatPaymentIntent } from "../UserService";
import classNames from "classnames";
import styles from "./stripeStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal } from "shared/components";
import ThankyouModel from "shared/components/ThankyouModel";
import { function_API } from "./function";
import toast from "react-hot-toast";
import CustomLoader from "shared/components/New_Loader/Loader";

interface Props {
  serviceName?: any;
  convertedAmount?: any;
  paidAmount?: any;
  remainingAmount?: any;
}

export const CheckoutForm = (props: Props) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { serviceName, convertedAmount, paidAmount, remainingAmount } = props;
  const {
    user,
    paymentParams,
    hospitalId,
    location,
    fcmToken,
    obj,
    hotelDetail,
    userAge,
    systemType,
  } = useSelector((state: any) => state?.root?.common);
  const { treatmentId } = useSelector((state: any) => state?.root?.doctor);
  const dispatch = useDispatch();

  const confirmStripePayment = async (clientSecret: any, event: any) => {
    event.preventDefault();
    const { error }: any = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url:
          systemType === "user"
            ? `https://meditour.global/ourServices`
            : `https://meditour.global/${systemType}/dashboard`,
      },
      paymentMethodType: "Card",
    });
    if (error) {
      setErrorMessage(error?.message);
    } else {
    }
  };
  const modalFunction = (secret: any, event: any) => {
    setModalVisible(true);
    setTimeout(() => {
      setModalVisible(false);
      confirmStripePayment(secret, event);
      toast.success("Successful");
    }, 2000);
  };

  let arryObj = obj;
  const backendUrl = "";
  console.log(user, ".........convertedAmount * 100,");
  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setErrorMessage(submitError.message);
      return;
    }
    let params = {
      email: user?.email,
      amount: convertedAmount * 100,
    };
    console.log(params, ".........params");
    creatPaymentIntent(params)
      .then((res: any) => {
        function_API({
          serviceName: serviceName,
          paymentId: res?.data?.paymentIntent || "",
          paymentParams: paymentParams,
          paidAmount: paidAmount || "",
          confirmStripePayment: modalFunction,
          user: user,
          arryObj: arryObj || "",
          dispatch: dispatch,
          setModalVisible: setModalVisible,
          setLoading: setLoading,
          hospitalId: hospitalId || "",
          remainingAmount: remainingAmount,
          secret: res?.data?.paymentIntent,
          event: event,
          location: location,
          hotelDetails: hotelDetail,
          userAge: userAge,
          treatmentId: treatmentId,
          systemType: systemType,
        });
      })
      .catch((err: any) => {
        console.log("🚀 ~ handleSubmit ~ err:", err?.response?.data);
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles.mainCard)}>
      <form onSubmit={handleSubmit} className="px-4">
        <PaymentElement />
        <button
          type="submit"
          disabled={!stripe || !elements}
          className={classNames(styles.payButton)}
        >
          Pay
        </button>
        {errorMessage && <div>{errorMessage}</div>}
      </form>

      {loading && <CustomLoader />}

      {modalVisible && (
        <ThankyouModel
          mainMessageLine1="Your Payment has been successful"
          mainMessageLine2="Thank you"
        />
      )}
    </div>
  );
};
