import React, { useState } from "react";
import classNames from "classnames";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import bstyle from "./Booking.module.css";
import pstyle from "../../../shared/components/DonationServices/pay.module.css";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setObj, setPaymentParams } from "shared/redux";
import moment from "moment";
import Checkout from "shared/services/stripe/checkout";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { RENT_A_CAR_DETAIL } from "shared/utils/mainHeaderQuery";
import Footer from "shared/components/Footer";
import Footerr from "pages/Home/HomeNavBar/Footer";

const RentaCarBooking = () => {
  const { user } = useSelector((state: any) => state.root.common);
  const [cnicError, setCnicError] = useState("");
  const [cnic, setCnic] = useState("");
  const { state } = useLocation();
  const {
    newData,
    calculate_amountPerDay,
    items,
    type,
    pickupLocation,
    dropoffLocation,
    pickDate,
    dropDate,
  } = state || {};
  const [open, setOpen] = useState<any>(false);
  const navigate = useNavigate();
  const [cnc, setCNC] = useState<any>("");
  const dispatch = useDispatch();
  const handlepay = async () => {
    if (items?.isPaidFull == false) {
      if (items?.gatewayName == "blinq") {
        // dispatch(setAmount(pendingPayment));
        // navigate('BlinqPayment', {
        //   type: 'Remaining_RentCar',
        //   bookingID: data?._id,
        //   pendingPayment: pendingPayment,
        // });
      } else {
        setTimeout(() => {
          dispatch(
            setPaymentParams({
              bookingID: items?._id,
              paidByUserAmount: items?.remainingAmount,
              processingFee: items?.processingFee,
            })
          );
          setOpen(true);
        }, 2000);
      }
    } else {
      if (!cnic) {
        setCnicError("CNIC is required.");
      } else {
        setCnicError("");

        let requestData: any = {
          vehicleName: items?.vehicleName,
          vehicleId: items?._id,
          doctorImage: items?.vehicleImages[0],
          rentACarId: items?.rentACarId,
          vehicleModel: items?.vehicleModel,
          totalAmount: calculate_amountPerDay,
          pickupLocation:
            type === "BookingCar"
              ? pickupLocation
              : newData?.pickupLocation?.label,
          dropoffLocation:
            type === "BookingCar"
              ? dropoffLocation
              : newData?.dropoffLocation?.label,
          pickupDateTime:
            type === "BookingCar"
              ? pickDate
              : new Date(
                  `${newData?.pickDate}T${newData?.picKupTime}`
                ).toLocaleString(),
          dropoffDateTime:
            type === "BookingCar"
              ? dropDate
              : new Date(
                  `${newData?.dropDate}T${newData?.DropOffTime}`
                ).toLocaleString(),
          cnic: cnic,
          phone: newData?.phoneNo,
          customerName: newData?.name,
          age: newData?.age,
          withDriver: newData?.withDriver,
        };
        console.log(requestData, ".......requestData");
        await dispatch(setObj(requestData));

        navigate("/services/paymentDetail", {
          state: {
            serviceName: "rentacar",
            actualAmount: calculate_amountPerDay,
          },
        });
      }
    }
  };

  let pendingPayment =
    items?.gatewayName === "blinq"
      ? items?.remainingAmount.toFixed(2)
      : items?.remainingAmount;
  let totalAmount_withFee = items?.processingFee + pendingPayment;
  let total =
    items?.gatewayName === "blinq" ? pendingPayment : totalAmount_withFee;
  const paymentDetails = [
    {
      label: "Total Amount",
      amount:
        items?.gatewayName === "blinq"
          ? `PKR ${items?.totalAmount}`
          : `$ ${items?.totalAmount}`,
      color: "rgba(0, 104, 56, 1)",
    },
    {
      label: "Partial Amount",
      amount:
        items?.gatewayName === "blinq"
          ? `PKR ${items?.paidByUserAmount}`
          : `$ ${items?.paidByUserAmount}`,
      color: "rgba(0, 104, 56, 1)",
    },
    {
      label: "Remaining Amount",
      amount:
        items?.gatewayName === "blinq"
          ? `PKR ${items?.remainingAmount}`
          : `$ ${items?.remainingAmount}`,
      color: "rgba(234, 2, 52, 1)",
      marginTop: "16px",
    },
    {
      ...(items?.gatewayName === "stripe" && {
        label: "Processing Fee",
        amount:
          items?.gatewayName === "blinq"
            ? `PKR ${items?.processingFee}`
            : `$ ${items?.processingFee}`,
        color: "rgba(234, 2, 52, 1)",
        dottedLine: true,
      }),
    },
    {
      ...(items?.gatewayName === "stripe" && {
        label: "Total Amount",
        amount:
          items?.gatewayName === "blinq"
            ? `PKR ${total?.toFixed(2)}`
            : `$ ${total?.toFixed(2)}`,
        color: "rgba(234, 2, 52, 1)",
      }),
    },
    {
      label: "Due Date",
      amount: moment(items?.arrivalDate?.from).format("MM/DD/YYYY"),
      color: "rgba(234, 2, 52, 1)",
    },
  ];

  const handleCnicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 5 && value.length <= 12) {
      value = `${value.slice(0, 5)}-${value.slice(5)}`;
    } else if (value.length > 12) {
      value = `${value.slice(0, 5)}-${value.slice(5, 12)}-${value.slice(12)}`;
    }
    setCnic(value);
  };
  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return "Date of birth not provided";
    const [day, month, year] = dateOfBirthString.split("/").map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
  const inputData = [
    { label: "Name", type: "text", placeholder: user?.name, readOnly: true },
    {
      label: "Age",
      type: "text",
      placeholder: calculateAge(user?.dateOfBirth),
      readOnly: true,
    },
    {
      label: "Phone number",
      type: "text",
      placeholder: user?.phone,
      readOnly: true,
    },
    // {
    //   label: "Identity Number",
    //   type: "text",
    //   placeholder: "Please Enter Identity Number",
    //   readOnly: false,
    //   value:cnc,
    // },
  ];
  console.log(cnc, ".......value");
  const rentalData = [
    {
      label: "Pickup Location",
      type: "text",
      placeholder:
        type === "BookingCar" ? pickupLocation : newData?.pickupLocation?.label,
      readOnly: true,
    },
    {
      label: "Pickup Date & Time",
      type: "text",
      placeholder:
        type === "BookingCar"
          ? pickDate
          : `${newData?.pickDate}-${newData?.picKupTime}`,
      readOnly: true,
    },
    {
      label: "Drop-off Location ",
      type: "text",
      placeholder:
        type === "BookingCar"
          ? dropoffLocation
          : newData?.dropoffLocation?.label,
      readOnly: true,
    },
    {
      label: "Drop-off Date & Time",
      type: "text",
      placeholder:
        type === "BookingCar"
          ? dropDate
          : `${newData?.dropDate}-${newData?.DropOffTime}`,
      readOnly: true,
    },
  ];
  // const handleViewMoreClick  => {
  //   navigate (`/services/rentacar/DonationPay/`);
  // };
  return (
    <div>
      <NavBreadCrumbs {...RENT_A_CAR_DETAIL} />
      {open ? (
        <Checkout serviceName={"Remaining_RentCar"} convertedAmount={total} />
      ) : (
        <div className={classNames(commonstyles.container, commonstyles.mb32)}>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxBetween,
              commonstyles.flxWrap
            )}
          >
            <div
              className={classNames(commonstyles.col4, commonstyles.colsm12)}
            >
              <div>
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Customer Details
                </p>
                {inputData.map((input: any, index: any) => (
                  <div key={index}>
                    {input.label && (
                      <label className={bstyle.label}>{input.label}</label>
                    )}
                    <input
                      className={bstyle.input}
                      type={input.type}
                      placeholder={input.placeholder}
                      style={input.style || {}}
                      value={
                        input.label === "Identity Number" ? cnc : input?.value
                      }
                      onChange={(text: any) =>
                        input.label === "Identity Number"
                          ? setCNC(text.target.value)
                          : null
                      }
                    />
                  </div>
                ))}
                {type == "BookingCar" ? (
                  <>
                    <RemainPaymentSection
                      paymentDetails={paymentDetails}
                      paymentStyles={bstyle}
                    />
                  </>
                ) : (
                  <div>
                    <label className={bstyle.label}>CNIC</label>
                    <input
                      className={bstyle.input}
                      type="text"
                      value={cnic}
                      placeholder="33303-1234567-1"
                      onChange={handleCnicChange}
                      maxLength={15}
                    />
                    {cnicError && <p style={{ color: "red" }}>{cnicError}</p>}
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(commonstyles.col4, commonstyles.colsm12)}
            >
              <div>
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Rental Details
                </p>
                {rentalData.map((rental, index) => (
                  <div key={index}>
                    {rental.label && (
                      <label className={bstyle.label}>{rental.label}</label>
                    )}
                    <input
                      className={bstyle.input}
                      type={rental.type}
                      placeholder={rental.placeholder}
                      readOnly={rental.readOnly}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {type === "BookingCar" ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                className={classNames(
                  commonstyles.flx,
                  commonstyles.flxBetween
                )}
              >
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                  style={{
                    margin: "0 20px",
                  }}
                >
                  Total price
                </p>
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  {calculate_amountPerDay}
                </p>
              </div>
            </div>
          )}

          <div className={pstyle.showMoreContainer}>
            <button
              className={pstyle.showMoreButton}
              onClick={handlepay}
              disabled={items?.isPaidFull == true ? true : false}
            >
              {items?.isPaidFull == false
                ? "PAYMENT"
                : items?.isPaidFull == true
                ? "Payment Completed"
                : "Confirm"}
              <span className={pstyle.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        </div>
      )}
      <div style={{ marginTop: "40px" }}></div>
      <Footerr />
    </div>
  );
};
const RemainPaymentSection = ({
  paymentDetails,
  paymentStyles,
}: {
  paymentDetails?: any;
  paymentStyles?: any;
}) => {
  return (
    <div>
      <div className={paymentStyles["dashed-line"]} />
      <p className={paymentStyles["payment-title"]}>Payment</p>
      {paymentDetails?.map((item?: any, index?: any) => (
        <React.Fragment key={index}>
          <div
            className={paymentStyles["row-payment-styles"]}
            style={{ marginTop: index === 0 ? "8px" : item?.marginTop }}
          >
            <p
              style={{ color: item?.color }}
              className={paymentStyles["payment-label"]}
            >
              {item?.label}
            </p>
            <p
              style={{ color: item?.color }}
              className={paymentStyles["payment-amount"]}
            >
              {item?.amount}
            </p>
          </div>
          {item?.dottedLine && <div className={paymentStyles["dotted-line"]} />}
        </React.Fragment>
      ))}
    </div>
  );
};
export default RentaCarBooking;
