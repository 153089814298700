import React, { useState } from "react";

import { Doctor_Login } from "shared/components";
import Logo from "assets/images/Hospital 1.png";
import { useSelector } from "react-redux";

const Hospital_Login = () => {
  const { systemType } = useSelector((state: any) => state.root.common);
  console.log("syes....ss....", systemType);
  const sourceToShow = Logo;

  return (
    <>
      <Doctor_Login
        dashboard_url={`/${systemType}/dashboard`}
        type={systemType}
        image_source={sourceToShow}
      />
    </>
  );
};

export default Hospital_Login;
