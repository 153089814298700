import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import { pharmaceutical_Menu, pharmacyMenu } from "shared/utils";
import { Pharmaceutical_Dashboard } from "pages";
import PaymentNavbarRoutes from "routes/Services_Routes/PaymentNavbar_Routes/PaymentNavbarRoute";
const Pharmaceutical_MainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/pharmaceutical/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={pharmaceutical_Menu} />
      </div>
      <Routes>
        <Route
          path="pharmaceutical/dashboard"
          element={<Pharmaceutical_Dashboard />}
        />
        <Route
          path={`/pharmaceutical/paymentDetail`}
          element={<PaymentNavbarRoutes />}
        />
      </Routes>
    </div>
  );
};

export default Pharmaceutical_MainRoutes;
