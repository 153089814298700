import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import { insuranceGetAllIndividualINSURANCE } from "shared/services/Insurance";
import style from "./Myself.module.css";
import { CustomInput, DeleteModal } from "shared/components";
import { IoArrowBack } from "react-icons/io5";
import File from "assets/images/filePicker.png";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { Avatar } from "@mui/material";
import { FaTrash } from "react-icons/fa";
import {
  INSURANCE_FAMILY_Delete,
  INSURANCE_FAMILY_TRAVELER_DELETE,
  INSURANCE_SINGLE_Delete,
} from "shared/services";
export default function MyselfDetail() {
  const navigate = useNavigate();
  const [insuranceData, setInsuranceData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { state } = useLocation();
  const [isModalVisible, setModalVisible] = useState(false);
  const item = state?.item;
  const type = state?.type;
  console.log(type, "....itemSelf");

  const onDel = () => {
    setLoading(true);
    let deletePromise;
    if (type == "family") {
      console.log(item?._id, ".....(item?._id)");
      deletePromise = INSURANCE_FAMILY_Delete(item?._id);
    } else if (type == "parent") {
      deletePromise = INSURANCE_FAMILY_TRAVELER_DELETE(item?._id);
    } else {
      deletePromise = INSURANCE_SINGLE_Delete(item?._id);
    }
    deletePromise
      .then((res: any) => {
        // console.log(res?.data, '..........resizeMode:');
        setModalVisible(false);
        if (type == "family") {
          navigate("/insurance/MyFamilyMian");
        } else if (type == "parent") {
          navigate("/insurance/ParentsMian");
        } else {
          navigate("/insurance/MySelfMian");
        }
      })
      .catch((err: any) => {
        console.log("error hos...", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchIndividualInsurances = () => {
    setLoading(true);
    insuranceGetAllIndividualINSURANCE()
      .then((res: any) => {
        console.log("response........", res);
        setInsuranceData(res?.data?.insurances[0]);
      })
      .catch((err: any) => {
        console.log("error........", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchIndividualInsurances();
  }, []);

  const handleGoBack = () => {
    if (type == "family") {
      navigate("/insurance/MyFamilyMian");
    } else if (type == "parent") {
      navigate("/insurance/ParentsMian");
    } else {
      navigate("/insurance/MySelfMian");
    }
  };

  if (loading) {
    return <div></div>;
  }

  // if (!insuranceData) {
  //   return <div>No data available</div>;
  // }
  const handleDownload = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available.");
      return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "insurance_file.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleDownloading = (fileUrl: string) => {
    if (!fileUrl) {
      console.error("File URL is not available.");
      return;
    }

    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = "insurance_file.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const onCanelModal = () => {
    setModalVisible(false);
  };
  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flxBetween}>
            <div className={commonstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoBack} />
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.semiBold,
                  commonstyles.colorBlue,
                  style.mr56
                )}
              >
                Back
              </p>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Health / Myself /
                {`${
                  item?.hospitalizationLimit?.startLimit ?? "Not Available"
                }-${item?.hospitalizationLimit?.endLimit ?? "Not Available"}`}
              </p>
              <button
                onClick={() => setModalVisible(true)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  marginLeft: "30px",
                }}
              >
                <FaTrash style={{ color: "red", fontSize: "16px" }} />
              </button>
            </div>
          </div>
          <div className={style.mt24}>
            <Avatar
              sx={{ height: "80px", width: "80px" }}
              src={item?.packageLogo}
            />
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              {item?.heading}
            </p>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt16,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Age criteria"
                value={`Start Age: ${
                  item?.ageCriteria
                    ? item?.ageCriteria?.startAge
                    : item?.spouseAgeCriteria?.startAge
                }, End Age: ${
                  item?.ageCriteria
                    ? item?.ageCriteria?.endAge
                    : item?.spouseAgeCriteria?.endAge
                }`}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Select Hospitalization Limit (PKR)"
                value={`Start Limit: ${
                  item?.hospitalizationLimit?.startLimit ?? "Not Available"
                }, End Limit: ${
                  item?.hospitalizationLimit?.endLimit ?? "Not Available"
                }`}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Hospitalization Per person"
                value={`Start Limit: ${
                  item?.hospitalizationLimit?.startLimit || "Not Available"
                }, End Limit: ${
                  item?.hospitalizationLimit?.endLimit || "Not Available"
                }`}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Daily Room & Board Limit"
                value={item?.dailyRoomAndBoardLimit}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput placeholder="ICU / CCU" value={item?.icuCcuLimits} />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Claim Payout Ratio"
                value={item?.claimPayoutRatio}
              />
            </div>
          </div>
          <div className={style.mt24}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Hospital
            </p>
            <div className={style.flexWrap}>
              {item?.hospitals?.map((hospital: any) => (
                <div className={style.cardss} key={hospital}>
                  <Avatar
                    sx={{ height: "80px", width: "80px" }}
                    src={hospital?.logo}
                  />
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    {hospital?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={style.mt24}>
            <p
              className={classNames(
                commonstyles.fs20,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Laboratory
            </p>
            <div className={style.flexWrap}>
              {item?.laboratories.map((laboratory: any) => (
                <div className={style.cardss} key={laboratory}>
                  <Avatar
                    sx={{ height: "80px", width: "80px" }}
                    src={laboratory?.logo}
                  />
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    {laboratory?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <p
            className={classNames(
              commonstyles.fs20,
              style.mt24,
              commonstyles.medium
            )}
          >
            Medical Benefits
          </p>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt16,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput placeholder="ICU / CCU" value={item?.icuCcuLimits} />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Additional Limits for Accidental Emergencies"
                value={item?.accidentalEmergencyLimits}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Ambulance Service Coverage"
                value={item?.ambulanceCoverage}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Coverage of Specialized Investigations"
                value={item?.specializedInvestigationCoverage}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col10,
              commonstyles.flxBetween,
              style.mt8,
              style.gap40
            )}
          >
            <div className={commonstyles.col12}>
              <CustomInput
                placeholder="Waiting Period"
                value={item?.waitingPeriod}
              />
            </div>
            <div className={commonstyles.col12}>
              <CustomInput placeholder="Maternity" value={item?.maternity} />
            </div>
          </div>
          <div className={style.mt24}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              Policy Documents
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: "#000",
                borderRadius: "8px",
                padding: "4px",
                // Set cursor to pointer
              }}
              onClick={() => handleDownload(item?.policyDocument)}
            >
              <img
                src={File}
                alt="Document"
                style={{
                  width: "20%",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Police Docoment.pdf
              </p>
            </div>
          </div>
          <div className={style.mt24}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              Claim Process
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 0",
                borderWidth: "1px",
                borderStyle: "dashed",
                borderColor: "#000",
                borderRadius: "8px",
                padding: "4px",
                // Set cursor to pointer
              }}
              onClick={() => handleDownloading(item?.claimProcess)}
            >
              <img
                src={File}
                alt="Document"
                style={{
                  width: "20%",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Claim Process.pdf
              </p>
            </div>
          </div>
          <div className={commonstyles.mt56}>
            <p className={classNames(commonstyles.fs20, commonstyles.medium)}>
              More Features
            </p>
            <div className={classNames(commonstyles.col4, style.mt24)}>
              <CustomInput
                placeholder="Waiting Period"
                value={item?.waitingPeriod}
              />
            </div>
            <div className={classNames(commonstyles.col12, style.mt8)}>
              <CustomInput
                placeholder="Waiting Period"
                value={item?.waitingPeriod}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        modalVisible={isModalVisible}
        handleCancel={onCanelModal}
        handleDelete={onDel}
        loading={loading}
        title="package?"
      />
    </div>
  );
}
