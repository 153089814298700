import classNames from "classnames";
import React, { useState, useEffect, ChangeEvent } from "react";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import CustomLoader from "shared/components/New_Loader/Loader";
import styles from "./ParamedicDashboard.module.css";
import commomstyles from "../../../../../shared/utils/common.module.css";
import { TbRefresh } from "react-icons/tb";
import { getallParamedicRequest, labStatusChange, paramedicStatus } from "shared/services";
import LabEmpty from "shared/components/LabEmpty";
function ParamedicDashboard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([])
  useEffect(() => {
    requestFetch()
  }, [])
  const requestFetch = () => {
    setLoading(true)
    let data = {
      status: 'accepted'
    }
    getallParamedicRequest(data).then((res?: any) => {
      console.log(res?.data)
      setData(res?.data?.paramedicRequests)
    }).catch((err: any) => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const uploadStatus = (id: any,) => {
    paramedicStatus(id,)
      .then((res: any) => {
        console.log("pas....", res);
        requestFetch()
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        setTimeout(() => {
          requestFetch()
        }, 1000);
      })

      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(commonstyles.col12)}>
          <SearchBar />
          <div className={commomstyles.mr87}>
            <div className={styles.outerContainer}>
              <div className={classNames(styles.flxBetween, commomstyles.mb32)}>
                <div className={classNames(commomstyles.flx)}>
                  <p
                    className={classNames(
                      commomstyles.fs24,
                      commomstyles.semiBold,
                      commonstyles.colorBlue
                    )}
                  >
                    All Request
                  </p>
                </div>

              </div>

              {loading ? (
                <CustomLoader />
              ) : (
                <div className={commomstyles.mb32}>
                  <>
                    <div className={styles.outerContainer}>
                      <div className={styles.payment}>
                        {data.length > 0 && (
                          <div className={styles.headerOuter}>
                            <p className={styles.headerclass}>name</p>
                            <p className={styles.headerclass}>Email</p>
                            <p className={styles.headerclass}>Contact</p>
                            <p className={styles.headerclass}>Preferred</p>
                            <p className={styles.headerclass}>Preferred Date</p>
                            <p className={styles.headerclass}>Preferred Time</p>
                            <p className={styles.headerclass}>Schedule</p>
                            <p className={styles.headerclass}>Status</p>
                          </div>
                        )}
                        <div className={styles.tableData}>
                          {data.length > 0 ? (
                            <table
                              style={{
                                margin: "0px",
                              }}
                            >
                              <tbody className={styles.wapper}>
                                {data.map((val: any, key: any) => {
                                  return (
                                    <>
                                      <tr
                                        className={styles.tableRow}
                                        style={{ marginBottom: "24px" }}
                                      >
                                        <td
                                          className={styles.w20}
                                        >
                                          {val.name}
                                        </td>
                                        <td
                                          className={styles.w20}
                                        >
                                          {val.email}
                                        </td>
                                        <td
                                          className={styles.w20}
                                        >
                                          {val.contact}
                                        </td>
                                        <td
                                          className={styles.w20}
                                        >
                                          {val.gender}
                                        </td>
                                        <td
                                          className={styles.w20}
                                        >
                                          {new Date(val?.preferredDate).toLocaleDateString()}
                                        </td>
                                        <td
                                          className={styles.w20}
                                        >
                                          {val?.preferredTime}
                                        </td>
                                        <td
                                          className={styles.w20}
                                        >
                                          {val?.schedule}
                                        </td>
                                        <td className={styles.w20}>
                                          {
                                            <CustomSelectOrder
                                              setSelectedValue={() =>
                                                uploadStatus(val._id,)
                                              }
                                              initialValue={'Pending'}
                                            />
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <div>
                              <LabEmpty />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
interface Props {
  setSelectedValue: (value: string) => void;
  initialValue: string;

}

function CustomSelectOrder({
  setSelectedValue,
  initialValue,
}: Props) {
  const [selectedOption, setSelectedOption] = useState("Pending"); // Set default to "Pending"

  useEffect(() => {
    setSelectedOption(initialValue || "Pending"); // If initialValue is not provided, fallback to "Pending"
  }, [initialValue]);

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    setSelectedValue(newValue);
  };

  const getSelectClassName = () => {
    return selectedOption === "completed" ? styles.completeSelect : "";
  };

  return (
    <div className={styles.CustomSelectOrder}>
      <select
        style={{ fontWeight: "700", width: "80%" }}
        className={getSelectClassName()}
        value={selectedOption}
        onChange={handleSelectChange}
      >
        {selectedOption === "Pending" ? (
          <option value="Pending" disabled>
            Pending
          </option>
        ) : null}
        <option value="completed">Complete</option>
      </select>
    </div>
  );
}

export default ParamedicDashboard;