import React from "react";
import { IoMdArrowForward } from "react-icons/io";
import style from "./index.module.css";

interface ContinueButtonProps {
  buttonText?: string;
  onClick?: () => void;
  customClass?: string;
  backgroundColor?: string;
  textColor?: string;
}

const ContinueButton: React.FC<ContinueButtonProps> = ({
  buttonText = "Apply",
  onClick,
  customClass = "",
  backgroundColor = "#0e54a3",
  textColor,
}) => {
  return (
    <div className={style.showMoreContainer}>
      <button
        onClick={onClick}
        className={`${style.showMoreButton} ${customClass}`}
        style={{
          backgroundColor,
          // color: textColor || "inherit", // Commented out line
        }}
      >
        {buttonText}
        <span className={style.icon}>
          <IoMdArrowForward />
        </span>
      </button>
    </div>
  );
};

export default ContinueButton;
