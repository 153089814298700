import PREGNANCYLOSS from "assets/TreamentImages/RECURRENTPREGNANCYLOSS.jpg";

export const ABOUTUS = {
  heading: "About Us",
  data: ["Home", "About Us"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const TREATMENTS = {
  heading: "Medical Treatments",
  data: ["Home", "Medical Treatments"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FMedical-Treatments%404x.jpg?alt=media&token=c1481fbe-3478-4c18-a2cf-478f108f85fc",
};

export const treatmentDetails = (state: any) => ({
  backgroundImage: PREGNANCYLOSS,
  heading: state?.item?.subCategory || state?.mainTitle,
  data: [
    "Home",
    "Medical Treatments",
    state?.mainTitle,
    state?.item?.subCategory,
  ],
});

export const SERVICES = {
  heading: "OUR Servcies",
  data: ["Home", "Our Servcies"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const PATEINT_GUIDE = {
  heading: "Patient Guide",
  data: ["Home", "Patient Guide"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FPatient-guide%404x.jpg?alt=media&token=d4fea183-f1e4-4f50-b18c-63cbf6704f01",
};

export const CONTACT_US = {
  heading: "Contact Us",
  data: ["Home", "Contact Us"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FContact-us%404x.jpg?alt=media&token=9371a495-b531-48a9-aa7a-e276557b8dbf",
};

export const DOCTOR = {
  heading: "What Do you need?",
  data: ["Home", "Services"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FContact-us%404x.jpg?alt=media&token=9371a495-b531-48a9-aa7a-e276557b8dbf",
};

export const HOSPITALS_AND_DOCTORS = (serviceName: any) => ({
  heading: serviceName,
  data: ["Home", "Services", serviceName],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
});

export const DOCTOR_DETAILS = (serviceName?: any) => ({
  heading: serviceName || "Doctors",
  data: ["Home", "Services", serviceName || "Doctor", "Details"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
});

export const PARAMEDIC_STAFF = {
  heading: "Paramedic Staff",
  data: ["Home", "Services", "Paramedic Staff"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const HOSPITAL_DETAILS = {
  heading: "Hospital",
  data: ["Home", "Services", "Hospital", "Details"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const LABORATORY = {
  heading: "Laboratory",
  data: ["Home", "Services", "Laboratory"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const LABORATORY_DETAILS = {
  heading: "Laboratory",
  data: ["Home", "Services", "Laboratory", "Product"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const PAYMENT_NAVBAR = {
  heading: "Payment",
  data: ["Home", "Payment"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const MY_APPOINTMENT_PRESCRIPTION = {
  heading: "My Appointment",
  data: ["Home", "My Appointments", "Prescription"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const INSURANCE_NAVBAR = {
  heading: "My Booking",
  data: ["Home", "My Booking"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const INSURANCE_DETAIL = {
  heading: "Insurance",
  data: ["Home", "Services", "Insurance", "Detail"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const TRAVEL_AGENCY_BOOKING = {
  heading: "Travel Agency",
  data: ["Home", "Travel Agency", "Tour"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const PHARMACY = {
  heading: "Pharmacy",
  data: ["Home", "Services", "Pharmacy"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const PHARMACY_CART = {
  heading: "Pharmacy",
  data: ["Home", "Services", "Pharmacy"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const MY_APPOINTMENTS = {
  heading: "My Appointments",
  data: ["Home", "My Appointments"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const MY_APPOINTMENT_DETAILS = (stripeOpen: any) => ({
  heading: "My Appointments",
  data: ["Home", "My Appointments", "Details", stripeOpen && "Payment"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
});

export const DOCTOR_APPOITMENT = (serviceName: any) => ({
  heading: "Doctor",
  data: ["Home", serviceName, "Appointment"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
});

export const HOME_SERVICES = {
  heading: "Home Services",
  data: [" Home", " Home Services"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const MY_BOOKING = {
  heading: " My Booking",
  data: [" Home", " My Booking"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const TRAVEL_BOOKING = {
  heading: " Travel Agencies",
  data: ["Home", "Services", "Travel Agencies", "Tour", "Details"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const BOOKING_HOTEL_DETAIL = {
  heading: " My Booking",
  data: [" Home", " My Booking", " Detail"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FAbout-us%404x.jpg?alt=media&token=c5258340-bb7b-408e-90a2-f7c4ed801cc3",
};

export const RENT_A_CAR = {
  heading: "Rent a Car",
  data: ["Home", "Services", "Reant a Car"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const RENT_A_CAR_DETAIL = {
  heading: "Rent a Car",
  data: ["Home", "Services", "Rent a Car", "Detail"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const TRAVEL_AGENCY = {
  heading: "Travel Agencies",
  data: ["Home", "Services", "Travel Agencies"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const TOUR_DETAIL = {
  heading: "Travel Agencies",
  data: ["Home", "Services", "Travel Agencies", "Tour", "Details"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const TRAVEL_DETAIL = {
  heading: "Request",
  data: ["Home", "Request"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const MY_REQUEST = {
  heading: "Request",
  data: ["Home", "Request"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const MY_ORDER_HISTORY = {
  heading: "Order Hsitory",
  data: ["Home", "Order History"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const PROFILE = {
  heading: "My Profile",
  data: ["Home", "My Profile"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const FAVOURITES = {
  heading: "My Favorite",
  data: ["Home", "My Favorite"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const DONATION_PACKAGE = {
  heading: "Donation",
  data: ["Home", "Services", "Donation"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const PRIVACY_POLICY = {
  heading: "Privacy Policy",
  data: ["Home", "Privacy Policy"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FPrivacy-Policy%404x.jpg?alt=media&token=1788321f-c2ab-4979-973b-b872b3dc1c37",
};

export const HOTEL = {
  heading: "Hotel",
  data: ["Home", "Services", "Hotel"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const HOTEL_DETAILS = {
  heading: "Hotel",
  data: ["Home", "Services", "Hotel", "Details"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const DONATION = {
  heading: "Donation",
  data: ["Home", "Services", "Donation"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const DONATION_HELP_CARD_DETAILS = {
  heading: "Donation",
  data: ["Home", "Services", "Donation"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const DONATION_EDU = {
  heading: " Donation",
  data: ["Home", "Services", "Donation"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};

export const INSURANCE = {
  heading: "Insurance",
  data: ["Homes", "Services", "Insurance"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};
export const Request = {
  heading: "Request",
  data: ["Home", "Request"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};
export const Travelinformation = {
  heading: "Request",
  data: ["Home", "Request", "Details"],
  backgroundImage:
    "https://firebasestorage.googleapis.com/v0/b/meditourglobal-ea15d.appspot.com/o/assets%2FOur-Services%404x.jpg?alt=media&token=9be64732-6e29-441e-ae44-e7b49484c32b",
};
