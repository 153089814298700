import React, { useEffect } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import HospitalCards from "../HospitalServices/HospitalCards";
import RentaCards from "./RentaCards";
import Footerr from "pages/Home/HomeNavBar/Footer";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { RENT_A_CAR } from "shared/utils/mainHeaderQuery";

const RentaCarService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavBreadCrumbs {...RENT_A_CAR} />

      <RentaCards />
      <Footerr />
    </div>
  );
};

export default RentaCarService;
