import style from "./style.module.css";
import commonstyle from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import classNames from "classnames";
import CommonStyless from "shared/utils/common.module.css";
import { RiCalendarCheckFill, RiGraduationCapFill } from "react-icons/ri";
import { IoIosVideocam, IoMdArrowForward } from "react-icons/io";
import Cards from "./Cards";
import { FaBriefcase, FaHospital, FaHouseChimneyWindow } from "react-icons/fa6";
import { FaClinicMedical } from "react-icons/fa";
import Details from "./Details";
import { SlBadge } from "react-icons/sl";
import ReviewComponent from "shared/components/DonationServices/ReviewComponent";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { DOCTOR_DETAILS } from "shared/utils/mainHeaderQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAvailability_Doctors, getSignle_Doctor } from "shared/services";
import { setObj } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";

const DoctorDetails = () => {
  const { state }: any = useLocation();
  let serviceName = state?.serviceName;
  let doc = state?.speciality ? state?.doc?.doctorId : state?.doc;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [docAvail, setDocAvail] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useSelector((state: any) => state.root.common);
  const video_Availability = docAvail && docAvail[0]?.videoAvailability;
  const clinic_Availability = docAvail && docAvail[0]?.clinicAvailability;
  const inHouse_Availablity = docAvail && docAvail[0]?.inHouseAvailability;
  const hospital_Availablity = docAvail && docAvail[0]?.hospitalAvailability;
  useEffect(() => {
    setLoading(true);
    signle_Doctor();
    doctor_Availability();
  }, []);

  const signle_Doctor = () => {
    const params = {
      doctorId: doc?._id,
      type: serviceName == "Hospital" ? "hospital" : "doctor",
      doctorType: serviceName?.toLowerCase(),
      hospitalId: state?.hospitalId || "",
      patientId: user?._id || "",
    };

    getSignle_Doctor(params)
      .then((res: any) => {
        setData(res?.data);
      })
      .catch((err: any) => {
        console.log("res......", err?.response?.data);
      });
  };

  const doctor_Availability = () => {
    const params = {
      doctorId: doc?._id,
      type: serviceName == "Hospital" ? "hospital" : "doctor",
      doctorType: serviceName?.toLowerCase(),
      hospitalId: state?.hospitalId || "",
    };
    getAvailability_Doctors(params)
      .then((res: any) => {
        setDocAvail(res?.data?.availability);
      })
      .catch((err: any) => {
        console.log("err......", err?.response);
      })
      .finally(() => setLoading(false));
  };

  const handleViewMoreClick = async () => {
    if (isLoggedIn) {
      if (serviceName == "Hospital") {
        await dispatch(setObj(data?.doctor));
        navigate("/services/paymentDetail", {
          state: {
            serviceName,
            actualAmount: hospital_Availablity[0]?.price?.actualPrice,
            appointmentType: "hospital",
            doctorId: doc?._id,
          },
        });
      } else {
        navigate(`/services/doctor/DoctorAppoinmentPay/${doc?._id}`, {
          state: {
            serviceName,
            opdType: state?.opdType,
            speciality: state?.speciality,
            paidOpdAmount: video_Availability?.price?.actualPrice,
            data,
          },
        });
      }
    } else {
      navigate("/user/login");
    }
  };

  return (
    <div>
      <NavBreadCrumbs {...DOCTOR_DETAILS()} />
      <div className={classNames(style.container)}>
        <div className={classNames(style.banner)}>
          <div className={classNames(style.maincontainer)}>
            <div className={classNames(style.col1)}>
              <DoctorProfile_Card doc={doc} data={data} />
            </div>

            <div className={style.col2}>
              <div>
                <p className={style.heading}>
                  {data?.doctor?.qualifications || doc?.qualifications}
                </p>
                <div className={classNames(commonstyle.mt16)}>
                  <p className={style.title}>
                    {data?.doctor?.clinicExperience || doc?.clinicExperience}
                  </p>
                </div>
                <p className={classNames(style.detail, commonstyle.mt32)}>
                  With more than 15 years of experience learning human
                  psychology and behavior, Dr. Jones is an expert in managing
                  mood disorders and anxiety disorders
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div className={style.showMoreContainer}>
                  <button
                    className={style.showMoreButton}
                    onClick={handleViewMoreClick}
                  >
                    Appointment
                    <span className={style.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.detailarea}>
          <div className={style.areaconatiner}>
            <div className={style.detailcol1}>
              <div className={style.flx}>
                <p className={style.icons}>
                  <RiCalendarCheckFill />
                </p>
                <p className={style.deatilheading}>Appointment Schedules</p>
              </div>

              {state?.speciality && (
                <div className={classNames(CommonStyless.mt24)}>
                  <div
                    className={classNames(
                      CommonStyless.mb32,
                      CommonStyless.mt16
                    )}
                  >
                    <div className={classNames(style.tcard)}>
                      <p
                        className={classNames(
                          CommonStyless.colorBlue,
                          CommonStyless.fs18,
                          CommonStyless.semiBold
                        )}
                      >
                        Package Include :
                      </p>
                      <p
                        className={classNames(
                          CommonStyless.colorBlue,
                          CommonStyless.fs16
                        )}
                      >
                        Procedure
                      </p>
                      <p
                        className={classNames(
                          CommonStyless.colorBlue,
                          CommonStyless.fs16
                        )}
                      >
                        Medicines
                      </p>
                      <div
                        className={classNames(
                          CommonStyless.flx,
                          CommonStyless.flxBetween
                        )}
                      >
                        <p
                          className={classNames(
                            CommonStyless.colorBlue,
                            CommonStyless.fs18,
                            CommonStyless.semiBold
                          )}
                        >
                          Toatl Cost:
                        </p>
                        <p
                          className={classNames(
                            CommonStyless.colorBlue,
                            CommonStyless.fs16
                          )}
                        >
                          {state?.doc?.totalAmount}
                          <span> / -</span>
                        </p>
                      </div>

                      {/* <div>
                    <ContinueButton
                      buttonText="Request Treatment"
                      // onClick={handleContinue}
                      backgroundColor="#0d47a1"
                    />
                  </div> */}
                    </div>
                  </div>

                  <p
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiBold
                    )}
                  >
                    Availability
                  </p>
                </div>
              )}

              {state?.opdType == "Free OPD" || state?.opdType == "Paid OPD" ? (
                video_Availability?.price?.actualPrice &&
                video_Availability && (
                  <Avail_Card
                    subTitle="Video"
                    source={<IoIosVideocam />}
                    data={video_Availability}
                  />
                )
              ) : (
                <>
                  {clinic_Availability?.availability?.length > 0 && clinic_Availability?.price?.actualPrice &&
                    clinic_Availability && (
                      <Avail_Card
                        subTitle="Clinic"
                        source={<FaClinicMedical />}
                        data={clinic_Availability}
                      />
                    )}
                  {video_Availability?.availability?.length > 0 && video_Availability?.price?.actualPrice &&
                    video_Availability &&
                    state?.type !== "speciality" && (
                      <Avail_Card
                        subTitle="Video"
                        source={<IoIosVideocam />}
                        data={video_Availability}
                      />
                    )}

                  {inHouse_Availablity?.availability?.length > 0 && inHouse_Availablity?.price?.actualPrice &&
                    inHouse_Availablity &&
                    state?.type !== "speciality" && (
                      <Avail_Card
                        subTitle="In House"
                        source={<FaHouseChimneyWindow />}
                        data={inHouse_Availablity}
                      />
                    )}

                  {hospital_Availablity?.length && hospital_Availablity &&
                    hospital_Availablity?.map((item: any) => (
                      <Avail_Card
                        subTitle={item?.hospitalId?.name}
                        source={<FaHospital />}
                        data={item}
                      />
                    ))}
                </>
              )}
            </div>
            <div className={style.detailcol2}>
              <div className={style.flx}>
                <p className={style.icons}>
                  <RiGraduationCapFill />
                </p>
                <p className={style.deatilheading}>Degrees</p>
              </div>

              <Details
                title={[
                  "University of California, San Francisco",
                  "University of California, San Francisco",
                  "University of California, San Francisco",
                ]}
                detail={[
                  "Medical degree",
                  "Bachelor of Science",
                  "Bachelor of Science",
                ]}
              />

              <div className={classNames(commonstyle.mt76)}>
                <div className={style.flx}>
                  <p className={style.icons}>
                    <FaBriefcase />
                  </p>
                  <p className={style.deatilheading}>Experiences</p>
                </div>
                <Details
                  title={[
                    "University of California, San Francisco",
                    "University of California, San Francisco",
                    "University of California, San Francisco",
                  ]}
                  detail={[
                    "Medical degree",
                    "Bachelor of Science",
                    "Bachelor of Science",
                  ]}
                />
              </div>

              <div className={classNames(commonstyle.mt72)}>
                <div className={style.flx}>
                  <p className={style.icons}>
                    <SlBadge />
                  </p>
                  <p className={style.deatilheading}>Awards/Achievements</p>
                </div>
                <Details
                  title={[
                    "University of California, San Francisco",
                    "University of California, San Francisco",
                    "University of California, San Francisco",
                  ]}
                />
              </div>
            </div>
          </div>
          <div className={classNames(commonstyle.conatiner)}>
            <ReviewComponent />
          </div>
        </div>
        {loading && <CustomLoader />}
        <Footerr />
      </div>
    </div>
  );
};

export default DoctorDetails;

const DoctorProfile_Card = ({ doc, data }: any) => {
  return (
    <div className={classNames(style.profilcard)}>
      <div className={classNames(style.profileimgcontainer)}>
        <img
          src={
            data?.doctor?.doctorImage ||
            doc?.doctorImage ||
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
          }
          alt={data?.doctor?.doctorImage}
          className={classNames(style.profileImage)}
        />
      </div>
      <div className={classNames(style.textarea)}>
        <p> {data?.name || doc?.name}</p>
      </div>
    </div>
  );
};

const Avail_Card = ({ data, subTitle, source, priceAvail }: any) => {
  return (
    <div className={classNames(commonstyle.mt24)}>
      <div className={style.flx}>
        <p className={style.dicons}>{source}</p>
        <div className={classNames(commonstyle.flx, commonstyle.flxBetween)} style={{
          width: '74%'
        }}>

          <p className={style.deatiltitle}>{subTitle || "undefined"}</p>
          <p className={style.deatiltitle}> Fee: {data?.price?.actualPrice || 0}/-</p>
        </div>
      </div>
      <Cards days={data} />
    </div>
  );
};
