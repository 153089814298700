import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./InsuranceBB.module.css";
import Download from "assets/images/downloadicon.png";
import upload from "assets/images/upload.png";
import { PrimaryButton } from "shared/components";
import {
  insuranceAcceptRequest,
  insuranceSingleREQUEST,
} from "shared/services/Insurance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImgPicker from "shared/components/Img-picker";
import { setInsuranceRequestFlag } from "shared/redux";
import { useDispatch } from "react-redux";
import Downloader from "shared/components/Downloader";
interface RequestDetails {
  name: string;
  email: string;
  mrNo: string;
  phone: string;
  location: string;
  updatedAt: string;
  _id: string;
}
interface Location {
  address: string;
}
interface InsuranceId {
  packageName: string;
  accidentalEmergencyLimits: string;
  icuCcuLimits: string;
  ambulanceCoverage: string;
  packageDescription: string;
  waitingPeriod: string;
  description: string;
  heading: string;
  perYear: string;
}
export default function RequestDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [error, setError] = useState("");
  const item = state?.item;
  console.log(error, '......error')
  const [requestdetail, setRequestdetail] = useState<RequestDetails | null>(
    null
  );
  const [location, setLocation] = useState<Location | null>(null);
  const [cnicFile, setCnicFile] = useState();
  const [data, setData] = useState<any>([]);
  const [insuranceId, setInsuranceId] = useState<InsuranceId | null>(null);
  const [url, setURL] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchRequestDetails = () => {

    setLoading(true);
    if (id === undefined) {
      setLoading(false);
      return;
    }
    if (id) {
      insuranceSingleREQUEST(id)
        .then((res: any) => {
          console.log("RESPONSE.......................", res?.data?.request);
          setRequestdetail(res?.data?.request?.userId);
          setLocation(res?.data?.request?.location);
          setInsuranceId(res?.data?.request?.insuranceId);
          setCnicFile(res?.data?.request?.cnicFile);
          setData(res?.data?.request);
        })
        .catch((err: any) => {
          console.log("error", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("id is undefined");
    }
  };

  const handleimageURL = (url: any) => {
    setURL(url);
    setError('')
  };
  const RequestAccepted = () => {
    setLoading(true);
    if (id === undefined && url === undefined) {
      console.error("ID is undefined");

      setLoading(false);
      return;
    }
    if (id && url) {
      let params = {
        requestId: id,
        insuranceFile: url,
      };
      console.log(id, '.............  {data?.insuranceId?.description}')
      insuranceAcceptRequest(params)
        .then((res: any) => {
          console.log(
            "insuranceAcceptRequest RESPONSE.......................",
            res
          );
          navigate("/insurance/request");
          dispatch(setInsuranceRequestFlag(true));
        })
        .catch((err: any) => {
          console.log("error", err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('Please Add Insurance File')
    }
  };

  useEffect(() => {
    fetchRequestDetails();
  }, [url]);

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.mr87)}>
        <div className={classNames(commonstyles.outerContainer)}>
          <div className={classNames(commonstyles.flx)}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              User
            </p>
          </div>
          <div className={style.DetailCard}>
            <p className={classNames(commonstyles.fs16, commonstyles.medium)}>
              {requestdetail?.name}
            </p>
            <div className={classNames(commonstyles.flxBetween)}>
              <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt16
                )}
              >
                <p className={commonstyles.colorBlue}>Email </p>
                <p>{requestdetail?.email}</p>
              </div>{" "}
              <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt16
                )}
              >
                <p className={commonstyles.colorBlue}>Phone No. </p>
                <p>{requestdetail?.phone}</p>
              </div>{" "}
              <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt16,
                  commonstyles.col2
                )}
              >
                {" "}
                <p className={commonstyles.colorBlue}>Location </p>
                <p>{location?.address}</p>
              </div>
            </div>{" "}
            <div className={classNames(commonstyles.flxBetween)}>
              <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt16
                )}
              >
                <p className={commonstyles.colorBlue}>MR NO</p>
                <p>{requestdetail?.mrNo}</p>
              </div>{" "}
              <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt16,
                  commonstyles.col2
                )}
              >
                {" "}
                <p className={commonstyles.colorBlue}>CNIC File.</p>
                <div className={style.filecard}>
                  <p>File name.png</p>

                  <Downloader link={cnicFile} />
                </div>
              </div>
            </div>
          </div>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue,
              style.mt24
            )}
          >
            Package Detail
          </p>
          <div
            className={classNames(style.DetailCard, commonstyles.flxBetween)}
          >
            <div className={classNames(commonstyles.col4)}>
              <div
                className={classNames(commonstyles.fs12, commonstyles.medium)}
              >
                <p className={commonstyles.colorBlue}>Package Name</p>
                <p>{insuranceId?.packageName}</p>
              </div>
              <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt24
                )}
              >
                <p className={commonstyles.colorBlue}>Medical Benefits</p>
                <p>
                  ICCU Limits-{insuranceId?.icuCcuLimits} Accidental Emergency
                  Limits-
                  {insuranceId?.accidentalEmergencyLimits} Ambulance Coverage-
                  {insuranceId?.ambulanceCoverage} Waiting Period-
                  {insuranceId?.waitingPeriod}{" "}
                </p>
              </div>
              {/* <div
                className={classNames(
                  commonstyles.fs12,
                  commonstyles.medium,
                  style.mt24
                )}
              >
                <p className={commonstyles.colorBlue}>About</p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div> */}
            </div>

            <div className={classNames(commonstyles.col5)}>
              <div
                className={classNames(commonstyles.fs12, commonstyles.medium)}
              >
                <p className={commonstyles.colorBlue}>Package Description</p>
                <p>{insuranceId?.packageDescription}</p>
              </div>
              <div
                className={classNames(
                  commonstyles.fs12,
                  style.mt24,
                  commonstyles.medium
                )}
              >
                <p className={commonstyles.colorBlue}>Policy Documents</p>
                <p>Policy Document name </p>
              </div>
              <div
                className={classNames(
                  commonstyles.fs12,
                  style.mt24,
                  commonstyles.medium
                )}
              >
                <p className={commonstyles.colorBlue}> More Features</p>
                <p>
                  Heading- {insuranceId?.heading} <br /> Description-{" "}
                  {insuranceId?.description}
                </p>
              </div>
              <div
                className={classNames(
                  commonstyles.fs12,
                  style.mt24,
                  commonstyles.medium
                )}
              >
                <p className={commonstyles.colorBlue}>Address</p>
                <p>{location?.address}</p>
              </div>
              <div
                className={classNames(
                  commonstyles.fs12,
                  style.mt24,
                  commonstyles.medium
                )}
              >
                <p className={commonstyles.colorBlue}>Per Year</p>
                <p> {insuranceId?.perYear}/-</p>
              </div>
            </div>
          </div>
          <div>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue,
                style.mt24
              )}
            >
              Insured Person File
            </p>
            <p
              className={classNames(commonstyles.fs12, commonstyles.semiBold)}
              style={{ color: "#7d7d7d" }}
            >
              To proceed, please upload tickets of all Passenger.
            </p>
          </div>
          <div className={style.filecard2}>
            {/* <img src={upload} className={style.download} /> */}
            <ImgPicker setData={handleimageURL} />
            {/* <p
              className={classNames(
                commonstyles.fs12,
                commonstyles.semiBold,
                commonstyles.colorBlue,
                style.mt8
              )}
            >
              <span>Take photo or </span>
              <span className={commonstyles.colorOrange}>choose file </span>
              <span> to upload</span>
            </p>
            <p
              className={classNames(
                commonstyles.fs8,
                commonstyles.colorBlue,
                style.mt8
              )}
            >
              Select JPEG, Png, or Pdf up to 20MB.
            </p> */}
          </div>
          {error && (
            <div style={{ color: 'red' }}>*{error}</div>
          )}
          <div style={{ width: "204px", margin: "56px 0 24px 0" }}>
            <PrimaryButton
              children={"Share"}
              colorType={"Insurance"}
              onClick={RequestAccepted}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
