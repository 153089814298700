import React, { useState } from "react";
import styles from "./NavigateBar.module.css";
import { useNavigate, useLocation } from "react-router-dom";

const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(() => {
    // Set active based on the current location path initially
    if (location.pathname.includes("bookingRentACar")) return "Rent a car";
    if (location.pathname.includes("bookingTravelAgency"))
      return "Travel Agency";
    if (location.pathname.includes("bookingInsurance")) return "Insurance";
    if (location.pathname.includes("bookingAmbulance")) return "Ambulance";
    return "Hotels";
  });

  const handleSetActive = (
    category:
      | "Hotels"
      | "Rent a car"
      | "Travel Agency"
      | "Insurance"
      | "Ambulance"
  ) => {
    setActive(category);
    switch (category) {
      case "Hotels":
        navigate("/services/booking");
        break;
      case "Rent a car":
        navigate("/services/bookingRentACar");
        break;

      case "Insurance":
        navigate("/services/bookingInsurance");
        break;
      case "Ambulance":
        navigate("/services/BookingAmbulance");
        break;
      case "Travel Agency":
        navigate("/services/bookingtravel");
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.navContainer}>
      <button
        className={`${styles.navButton} ${
          active === "Hotels" ? styles.active : ""
        }`}
        onClick={() => handleSetActive("Hotels")}
      >
        Hotels
      </button>
      <button
        className={`${styles.navButton} ${
          active === "Rent a car" ? styles.active : ""
        }`}
        onClick={() => handleSetActive("Rent a car")}
      >
        Rent a car
      </button>
      <button
        className={`${styles.navButton} ${
          active === "Travel Agency" ? styles.active : ""
        }`}
        onClick={() => handleSetActive("Travel Agency")}
      >
        Travel Agency
      </button>
      <button
        className={`${styles.navButton} ${
          active === "Insurance" ? styles.active : ""
        }`}
        onClick={() => handleSetActive("Insurance")}
      >
        Insurance
      </button>
      <button
        className={`${styles.navButton} ${
          active === "Ambulance" ? styles.active : ""
        }`}
        onClick={() => handleSetActive("Ambulance")}
      >
        Ambulance
      </button>
    </div>
  );
};

export default NavigationBar;
