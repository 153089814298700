import React, { useEffect, useRef, useState } from "react";
import style from "./TravelFlight.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import Datepicker from "shared/components/DatePicker";
import CustomDropdown from "shared/components/DropDown";
import LocationInput from "shared/components/LocationInput";
import { IoMdArrowForward } from "react-icons/io";
import { MdOutlineArrowDropDown, MdOutlineClose } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";
import ThankyouModel from "shared/components/ThankyouModel";
import { Console } from "console";
import { red } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import { flightsRequest } from "shared/services";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

interface CartItem {
  name: string;
  quantity: number;
}

const TravelFlight = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const { isLoggedIn } = useSelector((state: any) => state.root.common);
  const [multiCityData, setMultiCityData] = useState([
    { from: "", to: "", departure: "" },
  ]);
  console.log(multiCityData, "............................");
  const [cartData, setCartData] = useState<any>([
    { name: "Adult", quantity: 0 },
    { name: "Children", quantity: 0 },
    { name: "Infant", quantity: 0 },
  ]);
  const [locationDataFrom, setLocationFrom] = useState<any>("");
  const [locationDataTo, setLocationTo] = useState<any>("");
  const [showCard, setShowCard] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [tripType, setTripType] = useState<string>("oneWay");
  const [departureDate, setDepartureDate] = useState<any>("");
  const [returnDate, setreturnDate] = useState<any>("");
  const [errors, setErrors] = useState({
    locationFrom: "",
    locationTo: "",
    departureDate: "",
    returnDate: "",
    multiCity: "",
    passengers: "",
    travler: "",
    tripType: "",
    totalPassengers: "",
  });

  const handleTripTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTripType(event.target.value);
  };
  const handleAptDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setDepartureDate(formattedDate);
  };
  const handleReturnDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setreturnDate(formattedDate);
  };
  const dropdownOptions = [
    { value: "option1", label: "Economy" },
    { value: "option2", label: "Premium Economy" },
    { value: "option3", label: "Business" },
    { value: "option4", label: "First Class" },
  ];
  const dropdowntype = [
    { value: "option1", label: "One Way" },
    { value: "option1", label: "Multi City" },
    { value: "option1", label: "Round" },
  ];
  const handleDropdownChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const handleIncrement = (index: number) => {
    const updatedCart = [...cartData];
    updatedCart[index].quantity += 1;
    setCartData(updatedCart);
  };

  const handleDecrement = (index: number) => {
    const updatedCart = [...cartData];
    if (updatedCart[index].quantity > 0) {
      updatedCart[index].quantity -= 1;
      setCartData(updatedCart);
    }
  };

  const toggleDropdown = () => {
    setShowCard((prev) => !prev);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowCard(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAddMoreCities = () => {
    setMultiCityData([...multiCityData, { from: "", to: "", departure: "" }]);
  };

  const handleCityInputChange = (
    index: number,
    field: "from" | "to" | "departure",
    value: string
  ) => {
    const updatedMultiCityData = [...multiCityData];
    updatedMultiCityData[index][field] = value;
    setMultiCityData(updatedMultiCityData);
  };
  const handleDeleteCity = (index: any) => {
    const updatedCities = multiCityData.filter((_, i) => i !== index);
    setMultiCityData(updatedCities);
  };
  console.log(selectedValue, "............selectedValue");
  const requestFlights = () => {
    let data: any = {
      flights: [],
      flightClass: selectedValue,
      adult: cartData.find((item: CartItem) => item.name === "Adult")?.quantity,
      children: cartData.find((item: CartItem) => item.name === "Children")
        ?.quantity,
      infant: cartData.find((item: CartItem) => item.name === "Infant")
        ?.quantity,
      requestType: tripType,
    };

    if (tripType === "multiCity") {
      data.flights = multiCityData.map((city: any) => ({
        from: city.from.label,
        to: city.to.label,
        departure: city.departure,
      }));
    } else {
      data.flights = [
        {
          from: locationDataFrom?.label,
          to: locationDataTo?.label,
          departure: departureDate,
        },
      ];

      if (tripType === "round") {
        data.returnFlight = returnDate;
      }
    }

    console.log(data, ".....data");

    flightsRequest(data)
      .then((res: any) => {
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          navigate(-1);
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err?.response?.data, "..................errr flights");
      })
      .finally(() => {});
  };
  const totalPassengers = cartData.reduce(
    (acc: number, item: CartItem) => acc + item.quantity,
    0
  );
  const handleButtonClick = () => {
    if (isLoggedIn) {
      // Reset errors
      setErrors({
        locationFrom: "",
        locationTo: "",
        departureDate: "",
        returnDate: "",
        multiCity: "",
        passengers: "",
        travler: "",
        tripType: "",
        totalPassengers: "",
      });

      // Validation for OneWay and Round Trip

      if (!tripType) {
        setErrors((prev) => ({
          ...prev,
          tripType: "Please select a Trip.",
        }));
        return;
      }

      if (
        (tripType === "oneWay" || tripType === "round") &&
        (!locationDataFrom ||
          !locationDataTo ||
          !departureDate ||
          !selectedValue)
      ) {
        if (!locationDataFrom)
          setErrors((prev) => ({
            ...prev,
            locationFrom: "From location is required.",
          }));
        if (!locationDataTo)
          setErrors((prev) => ({
            ...prev,
            locationTo: "To location is required.",
          }));
        if (!departureDate)
          setErrors((prev) => ({
            ...prev,
            departureDate: "Departure date is required.",
          }));
        if (!selectedValue)
          setErrors((prev) => ({
            ...prev,
            passengers: "Flight class is required.",
          }));

        if (totalPassengers === 0) {
          setErrors((prev) => ({
            ...prev,
            travler: "Please select at least one Traveler.",
          }));
          return; // Stop the function execution
        }
      }

      // Validation for MultiCity Trip
      if (tripType === "multiCity") {
        let isMultiCityDataValid = true;
        multiCityData.forEach((city) => {
          if (!city.from || !city.to || !city.departure) {
            isMultiCityDataValid = false;
          }
        });

        if (!isMultiCityDataValid || !selectedValue) {
          setErrors((prev) => ({
            ...prev,
            multiCity: "Please fill all required fields for multi-city.",
          }));
          return; // Stop the function execution
        }
      }

      // Validation for cartData (at least one selection in Adult, Children, or Infant)

      requestFlights();
    } else {
      navigate("/user/login");
    }
  };

  return (
    <div className={classNames(style.maincontainer)}>
      <div className={classNames(commonstyle.flx, commonstyle.mb32)}>
        <div
          ref={dropdownRef}
          className={classNames(commonstyle.col3, commonstyle.colsm12)}
          style={{ position: "relative" }}
        >
          <div className={style.dropdownContainer}>
            <button
              onClick={toggleDropdown}
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                color: "#1e5da3",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span style={{ fontSize: "16px" }}>Travelers</span>
              <p
                style={{
                  fontSize: "27px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showCard ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </p>
            </button>

            {showCard && (
              <div className={classNames(style.card)}>
                {cartData.map((item: any, index: any) => (
                  <div key={index} className={classNames()}>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold
                          )}
                          style={{ margin: "0 10px" }}
                        >
                          {item.name}
                        </p>
                      </div>
                      <div className={classNames(commonstyle.flx)}>
                        <div className={classNames(style.viewQuantitybox)}>
                          <button
                            className={style.decrementButton}
                            onClick={() => handleDecrement(index)}
                          >
                            -
                          </button>
                          <span className={style.quantity}>
                            {item.quantity}
                          </span>
                          <button
                            className={style.incrementButton}
                            onClick={() => handleIncrement(index)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {errors.travler && (
            <p className={style.errorMessage}>{errors.travler}</p>
          )}
        </div>
        <div className={classNames(commonstyle.col3, commonstyle.colsm12)}>
          <div className={style.select_Container}>
            <select
              className={style.select_custom}
              defaultValue={tripType}
              value={tripType}
              onChange={handleTripTypeChange}
            >
              <option value="Select Tour Type" disabled hidden>
                Select Tour Type
              </option>
              <option value="oneWay">OneWay</option>
              <option value="round">Round</option>
              <option value="multiCity">MultiCity</option>
            </select>
            {errors.tripType && (
              <p className={style.errorMessage}>{errors.tripType}</p>
            )}
          </div>
        </div>
      </div>

      {(tripType === "oneWay" || tripType === "round") && (
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div
            className={classNames(style.inputContainer, commonstyle.col2)}
            style={{
              margin: "1px 5px",
              marginTop: "6px",
              // zIndex: 50,
            }}
          >
            <LocationInput placeholder="From" setData={setLocationFrom} />
            {errors.locationFrom && (
              <p className={style.errorMessage}>{errors.locationFrom}</p>
            )}
          </div>
          <div
            className={classNames(style.inputContainer)}
            style={{
              margin: "1px 5px",
              marginTop: "6px",
              // zIndex: 40,
            }}
          >
            <LocationInput placeholder="To" setData={setLocationTo} />
            {errors.locationTo && (
              <p className={style.errorMessage}>{errors.locationTo}</p>
            )}
          </div>
          <div
            className={classNames(style.inputContainer)}
            style={{
              margin: "1px 5px",
            }}
          >
            <Datepicker placeholder="Departure Date" setData={handleAptDate} />
            {errors.departureDate && (
              <p className={style.errorMessage}>{errors.departureDate}</p>
            )}
          </div>

          {tripType === "round" && (
            <div
              className={classNames(style.inputContainer)}
              style={{
                margin: "1px 5px",
              }}
            >
              <Datepicker
                placeholder="Return Date"
                setData={handleReturnDate}
              />
              {errors.returnDate && (
                <p className={style.errorMessage}>{errors.returnDate}</p>
              )}
            </div>
          )}
          <div className={classNames(style.inputContainer)}>
            <CustomDropdown
              id="my-dropdown"
              name="dropdownField"
              value={selectedValue}
              onChange={handleDropdownChange}
              options={dropdownOptions.map((option) => ({
                value: option.label,
                label: option.label,
              }))}
            />
            {errors.passengers && (
              <p className={style.errorMessage}>{errors.passengers}</p>
            )}
          </div>
        </div>
      )}

      {tripType === "multiCity" && (
        <>
          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxBetween,
              commonstyle.flxWrap
            )}
          >
            <div
              className={classNames(style.inputContainer, commonstyle.col2)}
              style={{
                marginTop: "14px",
                // zIndex: 50,
              }}
            >
              <LocationInput
                placeholder="From"
                setData={(data) => handleCityInputChange(0, "from", data)}
              />
            </div>
            <div
              className={classNames(style.inputContainer)}
              style={{
                marginTop: "14px",
                // zIndex: 40,
              }}
            >
              <LocationInput
                placeholder="To"
                setData={(data) => handleCityInputChange(0, "to", data)}
              />
            </div>
            <div
              className={classNames(style.inputContainer)}
              style={{
                margin: "10px 0",
              }}
            >
              <Datepicker
                placeholder="Departure Date"
                onChange={(date) =>
                  handleCityInputChange(
                    0,
                    "departure",
                    date ? date.format("YYYY-MM-DD") : ""
                  )
                }
              />
            </div>
            <div
              className={classNames(style.inputContainer)}
              style={{
                margin: "10px 0",

                marginTop: "14px",
              }}
            >
              <CustomDropdown
                id="my-dropdown"
                name="dropdownField"
                value={selectedValue}
                onChange={handleDropdownChange}
                options={dropdownOptions.map((option) => ({
                  value: option.label,
                  label: option.label,
                }))}
              />
            </div>
          </div>

          {multiCityData.slice(1).map((city, index) => (
            <div
              key={index + 1}
              className={classNames(
                commonstyle.flx,
                commonstyle.flxBetween,
                commonstyle.flxWrap
              )}
            >
              <div
                className={classNames(style.inputContainer, commonstyle.col2)}
                style={
                  {
                    // zIndex: 50,
                  }
                }
              >
                <LocationInput
                  placeholder="From"
                  setData={(data) =>
                    handleCityInputChange(index + 1, "from", data)
                  }
                />
              </div>
              <div
                className={classNames(style.inputContainer)}
                style={
                  {
                    // zIndex: 40,
                  }
                }
              >
                <LocationInput
                  placeholder="To"
                  setData={(data) =>
                    handleCityInputChange(index + 1, "to", data)
                  }
                />
              </div>
              <div
                className={classNames(style.inputContainer)}
                style={{
                  marginTop: "1px",
                }}
              >
                <Datepicker
                  placeholder="Departure Date"
                  onChange={(date) =>
                    handleCityInputChange(
                      index + 1,
                      "departure",
                      date ? date.format("YYYY-MM-DD") : ""
                    )
                  }
                />
              </div>
              <div
                className={classNames(style.inputContainer)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "15px 0",
                }}
              >
                <MdOutlineClose
                  onClick={() => handleDeleteCity(index + 1)}
                  style={{
                    cursor: "pointer",
                    color: "red",
                    fontSize: "27px",
                  }}
                />
              </div>
            </div>
          ))}
          {errors.multiCity && (
            <p className={style.errorMessage}>{errors.multiCity}</p>
          )}
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              handleAddMoreCities();
            }}
          >
            Add More
          </Link>
        </>
      )}

      <div>
        <div className={style.showMoreContainer}>
          <button className={style.showMoreButton} onClick={handleButtonClick}>
            Request
            <span className={style.icon}>
              <IoMdArrowForward />
            </span>
          </button>
        </div>

        {isModalVisible && (
          <ThankyouModel
            mainMessageLine1="Your request has been"
            mainMessageLine2="successfully shared"
            subMessage="We will notify you in a few minutes."
            footerMessage="Thank you."
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default TravelFlight;
