import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../../../../assets/logo.png";
import styles from "./orderHistory.module.css";
import moment from "moment";
const MyOrderDetail = React.memo((props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log("......state", state?.item?.amount);

  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);

  let dataMap = state?.item?.items || state?.item?.medicineIds;

  const orderDetails = {
    id: "102345",
    logoUrl: Avatar,
    companyName: "Chughtai Lab",
    status: "Pending",
    orderTime: "12/12/2024 11:48AM",
    items: [
      {
        name: "CBC - cbc102",
        description:
          "Complete Blood Examination CBC Complete Blood Examination CBC Blood completed...",
        price: 800,
      },
      {
        name: "Allium Cepa - ac09",
        description:
          "Complete Blood Examination CBC Complete Blood Examination CBC Blood completed...",
        price: 1600,
      },
      {
        name: "CBC - cbc102",
        description:
          "Complete Blood Examination CBC Complete Blood Examination CBC Blood completed...",
        price: 800,
      },
    ],
    totalAmount: 1000,
  };

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Order History
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Order History
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Detail
          </p>
        </div>
      </div>

      <div className={styles.orderDetailContainer}>
        <div className={styles.orderCardDetail}>
          {state?.item?.vendorId?.logo && (
            <div className={styles.logoHeaderDetail}>
              <img
                src={state?.item?.vendorId?.logo}
                alt={state?.item?.vendorId?.name}
                className={styles.logoDetail}
              />
            </div>
          )}

          <div className={styles.orderInfoDetail}>
            <h1>{state?.item?.vendorId?.name}</h1>
            <div className={styles.orderIdTime}>
              <span className={styles.orderIdTimeColor}>
                Order ID:{" "}
                {state?.item?.vendorId?.vendorId || state?.item?.requestId}
              </span>
              <div className={styles.orderDetailsRight}>
                <span>
                  {moment(state?.item?.createdAt).format("DD-MM-YYYY, h:mm a")}
                </span>
                <span
                  className={
                    state?.item?.status === "pending"
                      ? styles.statusPending
                      : styles.statusInProgress
                  }
                >
                  {state?.item?.status}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.itemListDetail}>
            <h2 className={styles.itemListDetailHeaderName}>Selected Items</h2>
            {dataMap?.map((item: any, index: any) => (
              <div key={index} className={styles.itemDetail}>
                <div className={styles.itemHeader}>
                  <div>
                    <h3 className={styles.itemListDetailItemHeaderName}>
                      {`${
                        item.itemId?.testNameId?.name || item?.id?.generic
                      } - ${item.itemId?.testCode || item?.id?.medCode}`}
                    </h3>
                    {item?.id?.brand && (
                      <h3 className={styles.itemListDetailItemHeaderName}>
                        {`Brand - ${item?.id?.brand} `}
                      </h3>
                    )}
                  </div>

                  <span className={styles.price}>
                    Rs.{" "}
                    {item.itemId?.priceForMeditour ||
                      item?.id?.tpPrice * item?.quantity}
                  </span>
                </div>
                <p className={styles.description}>
                  {item?.itemId?.testDescription}
                </p>
              </div>
            ))}
            <div className={styles.totalAmountDetail}>
              <h2 className={styles.totalLabel}>Total Amount</h2>
              <p className={styles.totalPrice}>
                RS: {state?.item?.totalAmount || state?.item?.amount}/-
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footerr />
    </div>
  );
});

export default MyOrderDetail;
