import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBar from "shared/components/Searchbar";
import { fetchSingleBooking } from "shared/services";
import commonstyles from "shared/utils/common.module.css";
import styles from "./Booking.module.css";
import moment from "moment";
import { TravelerInformation } from "shared/components";
import toast from "react-hot-toast";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomLoader from "shared/components/New_Loader/Loader";
const BookingDetailsFlight = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const { state } = useLocation();
    const type = state?.activeTab;
    const item = state?.item;
    const formattedDate = moment(data?.requestId?.createdAt).format('MM-DD-YYYY');
    const formatted = moment(data?.createdAt).format('MM-DD-YYYY');
    const formattedTime = moment(data?.requestId?.createdAt).format('hh:mm A');
    const formattedDateTime = moment(data?.tourId?.returnDate).format(
        'MM-DD-YYYY, hh:mm A',
    );
    const formattedDateTime2 = moment(data?.tourId?.departDate).format(
        'MM-DD-YYYY, hh:mm A',
    );
    useEffect(() => {
        fetchSingle()
    }, [])
    const fetchSingle = () => {
        setLoading(true);
        const params = {
            requestType: type.toLowerCase(),
            bookingId: item?._id,
        };
        fetchSingleBooking(params)
            .then((res: any) => {
                setData(res?.data?.booking);
            })
            .catch((err: any) => {
                console.log(err, "........error");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleDownload = (file: string | Blob | null) => {
        if (file) {
            const link = document.createElement("a");
            // If the file is a Blob, create an object URL
            const url = typeof file === "string" ? file : URL.createObjectURL(file);

            link.href = url;
            link.download = "policy-document.jpg"; // Specify your desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL if using a Blob
            if (typeof file !== "string") {
                URL.revokeObjectURL(url);
            }
        } else {
            alert("No policy document available.");
        }
    };

    const handleDownloading = (file: string | Blob | null) => {
        if (file) {
            const link = document.createElement("a");
            // If the file is a Blob, create an object URL
            const url = typeof file === "string" ? file : URL.createObjectURL(file);

            link.href = url;
            link.download = "policy-document.jpg"; // Specify your desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL if using a Blob
            if (typeof file !== "string") {
                URL.revokeObjectURL(url);
            }
        } else {
            alert("No policy document available.");
        }
    };
    const downloadAndSaveImage = (file: string | Blob | null) => {
        if (file) {
            const link = document.createElement("a");
            // If the file is a Blob, create an object URL
            const url = typeof file === "string" ? file : URL.createObjectURL(file);

            link.href = url;
            link.download = "policy-document.jpg"; // Specify your desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL if using a Blob
            if (typeof file !== "string") {
                URL.revokeObjectURL(url);
            }
        } else {
            alert("No policy document available.");
        }
    };
    return (
        <div className={classNames(commonstyles.col12)}>
            <SearchBar />
            {type === 'Flight' ? (
                <>
                    <div className={styles.TopView}>
                        <div className={styles.card}>
                            <p className={styles.text} style={{ marginBottom: '4px', color: '#0E54A3' }}>
                                {formattedDate} <span>{formattedTime}</span>
                            </p>

                            <Section
                                title1="Name"
                                title2="Flight Type"
                                value1={data?.userId?.name}
                                value2={data?.requestId?.requestType}
                            />

                            {data?.requestId?.flights?.map((user?: any, index?: any) => (
                                <Section
                                    key={index}
                                    title1="From"
                                    title2="To"
                                    value1={user?.from}
                                    value2={user?.to}
                                    show
                                    title3="Departure"
                                    value3={moment(user?.departure).format('DD/MM/YYYY')}
                                />
                            ))}

                            <Section
                                title2="Class"
                                value2={data?.requestId?.flightClass}
                                title1="Traveler"
                                value1={`adult ${data?.requestId?.adult}, children ${data?.requestId?.children}, infant ${data?.requestId?.infant}`}
                            />

                            {data?.requestId?.requestType === 'round' && (
                                <div>
                                    <p className={styles.text} >Return</p>
                                    <p className={styles.text} >
                                        {moment(data?.requestId?.returnFlight).format('DD/MM/YYYY')}
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className={styles.travelerInfo}>
                            <p className={styles.header}>Traveler Information</p>
                            {data?.travellers?.map((traveler: any, index: any) => (
                                <TravelerInformation
                                    traveler={traveler}
                                    index={index}
                                    onPressVise={() => handleDownload(traveler.visaFile)}
                                    onPressPass={() =>
                                        handleDownloading(traveler.passportFile)
                                    }
                                />
                            ))}
                        </div>

                        {data?.eTicket && (
                            <div className={styles.eTicketContainer}>
                                <p className={styles.header}>E-Ticket</p>
                                <p className={styles.subHeader}>To proceed, please upload tickets of all Passenger.</p>
                                <div className={styles.buttonContainer}>
                                    <button className={styles.touchable} onClick={() => downloadAndSaveImage(data?.eTicket)}>
                                        <span>E-Ticket</span>
                                        <img src="LabDownload.png" alt="Download" className={styles.DownloadImage} />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div style={{ paddingBottom: '30px' }}>
                    <div className={styles.justifyStyle}>
                        <Carousel
                            showThumbs={false}
                            showStatus={false}
                            infiniteLoop
                            autoPlay
                            interval={2000}
                        // selectedItem={activeSlide}
                        // onChange={handleSnap}
                        >
                            {data?.tourId?.images?.map((item: any, index: any) => (
                                <div key={index} className={styles.ImageContainer}>
                                    <img src={item} alt={`slide-${index}`} className={styles.ImageStyle} />
                                </div>
                            ))}
                        </Carousel>
                        {/* <Pagination
                            dotsLength={3}
                            activeDotIndex={activeSlide}
                            containerStyle={styles.paginationStyles}
                            inactiveDotStyle={styles.bgStyles}
                            dotStyle={styles.dotStyles}
                            inactiveDotOpacity={0.4}
                            inactiveDotScale={0.6}
                        /> */}
                    </div>
                    <div style={{ margin: '8px 24px', gap: '4px' }}>
                        <InfoRow label="Mr Id:" value={data?.bookingId} />
                        <InfoRow label="Date:" value={formatted} />
                        <Detail
                            styles={styles}
                            MainTitle="Booking Allotment"
                            title1="Booking ID:" value1={data?.bookingId}
                            title2="Name:" value2={data?.name}
                            title3="Age:" value3={data?.age}
                            title4="Email:" value4={data?.email}
                            title5="Address:" value5={data?.agencyId?.location?.city}
                            title6="Limited Seats:" value6={data?.tourId?.limitedSeats}
                            title7="Price:" value7={data?.actualPrice}
                        />
                        <Detail
                            styles={styles}
                            MainTitle="Tour Details"
                            title1="Tour Name:" value1={data?.packageName}
                            title2="Meal:" value2={data?.name}
                            title3="Days:" value3={data?.tourId?.packageDuration}
                            title4="Depart Date & Time:" value4={moment(data?.tourId?.departDate).format("MM-DD-YYYY h:mm A")}
                            title5="Return Date & Time:" value5={formattedDateTime}
                            title6="Price Per Head:" value6={data?.tourId?.pricePerHead}
                            title7="Price Per Couple:" value7={data?.tourId?.pricePerCouple}
                        />
                        <h3 style={{ marginTop: '16px', color: '#0D47A1' }}>Plan</h3>
                        <p style={{ color: '#0D47A1' }}>{data?.tourId?.dayByDayPlans}</p>
                        <h3 style={{ marginTop: '16px', color: '#0D47A1' }}>Rules</h3>
                        <p style={{ color: '#0D47A1' }}>{data?.tourId?.recentTourPolicy}</p>
                        <h3 style={{ marginTop: '16px', color: '#FD5650' }}>Payment</h3>
                        <p style={{ color: '#FD5650' }}>
                            {data?.isPaidFull ? 'User has made full payment' : 'User has made a partial payment'}
                        </p>
                    </div>
                </div>
            )}
            {loading && <CustomLoader />}
        </div>
    );
};

const InfoRow = ({ label, value }: { label?: any, value?: any }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{ fontSize: '14px', color: '#0D47A1' }}><strong>{label}</strong></p>
        <p style={{ fontSize: '14px', color: '#0D47A1' }}>{value}</p>
    </div>
);
const Section = ({
    value1,
    value2,
    title1,
    title2,
    title3,
    value3,
    show,
}: {
    value1?: any,
    value2?: any,
    title1?: any,
    title2?: any,
    title3?: any,
    value3?: any,
    show?: any,
}) => {
    return (
        <div>
            <div className={`${styles.row} ${styles.marginBottom}`}>
                <div>
                    <span className={`${styles.text} ${styles.blueText}`}>
                        {title1}
                    </span>
                    <span className={`${styles.text} ${styles.greyText}`}>
                        {value1}
                    </span>
                </div>
                <div>
                    <span className={`${styles.text} ${styles.blueText}`}>
                        {title2}
                    </span>
                    <span className={`${styles.text} ${styles.greyText}`}>
                        {value2}
                    </span>
                </div>
                {show && (
                    <div>
                        <span className={`${styles.text} ${styles.blueText}`}>
                            {title3}
                        </span>
                        <span className={`${styles.text} ${styles.greyText}`}>
                            {value3}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};


const Detail = (props: any) => {
    const {
        MainTitle,
        title1,
        value1,
        title2,
        value2,
        title3,
        value3,
        title4,
        value4,
        title5,
        value5,
        title6,
        value6,
        title7,
        value7,
        styles,
    } = props;
    return (
        <div>
            <h3 style={{ color: '#0D47A1', marginTop: '8px' }}>{MainTitle}</h3>
            <div style={{ gap: '8px', marginTop: '8px' }}>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title1}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400' }}>{value1}</p>
                </div>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title2}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400' }}>{value2}</p>
                </div>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title3}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400' }}>{value3}</p>
                </div>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title4}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400', fontSize: '12px' }}>{value4}</p>
                </div>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title5}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400', fontSize: '12px' }}>{value5}</p>
                </div>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title6}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400' }}>{value6}</p>
                </div>
                <div className={styles.rowVertical}>
                    <p style={{ color: '#0D47A1', fontWeight: '500' }}>{title7}</p>
                    <p style={{ color: '#0D47A1', fontWeight: '400' }}>{value7}</p>
                </div>
            </div>
        </div>
    );
};



export default BookingDetailsFlight;
