import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import style from "../DoctarServices/Doctor.module.css";
import { IoIosArrowForward } from "react-icons/io";
import img from "assets/images/RentaCarcard.png";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import dstyle from "./dstyle.module.css";
import CardStyless from "../DoctarServices/Cards.module.css";
import classNames from "classnames";
import CommonStyless from "shared/utils/common.module.css";
import ReviewComponent from "shared/components/DonationServices/ReviewComponent";
import Footerr from "pages/Home/HomeNavBar/Footer";
import {
  getAll_CarsDetails,
  GetAllRentACarReview_Rating,
} from "shared/services";
import { IoLocationOutline } from "react-icons/io5";
import { LoadingModal } from "shared/components";
import { useSelector } from "react-redux";
import RatingStar from "shared/RatingStar";
import MainHeader from "shared/components/MainScreen/Index";
import CustomLoader from "shared/components/New_Loader/Loader";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { RENT_A_CAR_DETAIL } from "shared/utils/mainHeaderQuery";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

const RentaCarDetail = () => {
  const navigate = useNavigate();
  const { item } = useParams();
  const [showAll, setShowAll] = useState(false);
  const [showRentalCar, setshowRentalCar] = useState(false);
  const [ratings, setRatings] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [cars, setCars] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [allCars, setAllCars] = useState<any>([]);
  const [totalRating, setTotalRating] = useState<any>([]);
  const carsToShow = showAll ? cars : cars.slice(0, 4);
  const allCarRent = showRentalCar ? allCars : allCars.slice(0, 4);
  const { rentacarItem } = useSelector((state: any) => state.root.common);
  console.log(rentacarItem, "...");
  // let item = rentacarItem?.item;
  const handleViewMoreClick = (items: any) => {
    navigate(`/services/rentacar/RentaCarmoreDetail/`, { state: { items } });
  };
  useEffect(() => {
    if (item) {
      getTopRentalCars_Details(item);
      getReviewRent(item);
    }
  }, [item]);
  const getTopRentalCars_Details = (itemId: any) => {
    setLoading(true);
    let params = {
      id: itemId,
    };
    getAll_CarsDetails(params)
      .then((res: any) => {
        console.log(res?.data?.rentACar, ".........[res?.data?.rentACar]");
        setData(res?.data?.rentACar);
        setTotalRating(res?.data);
        setAllCars(res?.data?.topRentalVehicles);
        setCars(res?.data?.allVehicles);
      })
      .catch((err: any) => {
        console.log("Error vehical:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getReviewRent = (itemId: any) => {
    let params = {
      vendorId: itemId,
    };
    GetAllRentACarReview_Rating(params)
      .then((res: any) => {
        setRatings(res?.data?.reviewsWithTimeAgo);
      })
      .catch((err: any) => {
        console.log(err?.response?.data?.message, "reviews catch");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) {
    return <CustomLoader />; // Show loader while loading
  }
  return (
    <div>
      <NavBreadCrumbs {...RENT_A_CAR_DETAIL} />
      <div className={classNames(CommonStyless.container)}>
        <div>
          <div
            className={classNames(
              CommonStyless.flx,
              CommonStyless.flxBetween,
              CommonStyless.col12
            )}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div className={CardStyless.cardWrapper}>
                <div className={CardStyless.cardImageWrapper}>
                  <img
                    src={data.logo}
                    alt="card img"
                    className={CardStyless.cardImage}
                  />
                </div>
                <div className={CardStyless.cardBody}>
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxBetween
                    )}
                  >
                    <div
                      className={classNames(
                        CardStyless.cardName,
                        CommonStyless.colorBlue
                      )}
                    >
                      {data?.name}
                    </div>
                  </div>
                  <div className={CardStyless.cardtime}>
                    <div>
                      <IoLocationOutline />
                    </div>
                    <span>{data?.location?.address}</span>
                  </div>
                  <div className={CardStyless.cardFooter}>
                    <RatingStar rating={data?.averageRating} />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
              }}
            ></div>
          </div>
        </div>
        <div>
          <p className={dstyle.intro}>{data?.description}</p>
        </div>

        <div className={classNames(CommonStyless.mt16)}>
          <div
            className={classNames(CommonStyless.flx, CommonStyless.flxBetween)}
          >
            <p className={classNames(dstyle.title, CommonStyless.semiBold)}>
              Top Rental Cars
            </p>
            {allCars?.length > 5 && (
              <p
                className={classNames(
                  CommonStyless.fs16,
                  CommonStyless.colorBlue,
                  CommonStyless.semiBold
                )}
                onClick={() => setshowRentalCar(!showRentalCar)}
              >
                {showRentalCar ? "View Less" : "View All"}
              </p>
            )}
          </div>
          {allCarRent && allCarRent?.length > 0 ? (
            <div className={classNames(dstyle.cardContainer)}>
              {allCarRent.map((car: any, index: any) => (
                <div
                  key={index}
                  // className={dstyle.cardWrapper}
                  onClick={() => handleViewMoreClick(car)}
                >
                  <div className={classNames(dstyle.card)}>
                    <img
                      src={
                        car?.vehicleImages[0] ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                      }
                      alt={car.vehicleImages}
                      className={dstyle.carImage}
                    />
                    {car?.requestSent === true && (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          top: 0,
                          zIndex: 100,
                          backgroundColor: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px",
                            margin: "10px",
                            backgroundColor:
                              car?.requestSent === true ? "#00B69B" : "#FDCB2E",
                            opacity: 0.8,
                            width: "100px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: "10px",
                          }}
                        >
                          <p
                            style={{
                              color:
                                car?.requestSent === true ? "#fff" : "#393A44",
                              fontSize: "10px",
                              fontWeight: 600,
                            }}
                          >
                            {car?.requestSent === true ? "Booked" : "Requested"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={dstyle.textContainer}>
                    <p>{`${car?.vehicleName}-${car?.vehicleYear}`}</p>
                    <p> {`${car?.actualPricePerDay} per day`}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <PhysiotheristsEmpty />
            </div>
          )}
          <div
            className={classNames(CommonStyless.flx, CommonStyless.flxBetween)}
          >
            <p className={classNames(dstyle.title, CommonStyless.semiBold)}>
              All Cars
            </p>
            {cars?.length > 0 && (
              <p
                className={classNames(
                  CommonStyless.fs16,
                  CommonStyless.colorBlue,
                  CommonStyless.semiBold
                )}
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "View Less" : "View All"}
              </p>
            )}
          </div>
          <div className={classNames(dstyle.cardContainer)}>
            {carsToShow && carsToShow.length > 0 ? (
              carsToShow.map((car: any, index: any) => (
                <div
                  key={index}
                  // className={dstyle.cardWrapper}
                  onClick={() => handleViewMoreClick(car)}
                >
                  <div className={classNames(dstyle.card)}>
                    <img
                      src={
                        car?.vehicleImages[0] ||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                      }
                      alt={car.model}
                      className={dstyle.carImage}
                    />
                    {car?.requestSent === true && (
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          position: "absolute",
                          top: 0,
                          zIndex: 100,
                          backgroundColor: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px",
                            margin: "10px",
                            backgroundColor:
                              car?.requestSent === true ? "#00B69B" : "#FDCB2E",
                            opacity: 0.8,
                            width: "100px",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            borderRadius: "10px",
                          }}
                        >
                          <p
                            style={{
                              color:
                                car?.requestSent === true ? "#fff" : "#393A44",
                              fontSize: "10px",
                              fontWeight: 600,
                            }}
                          >
                            {car?.requestSent === true ? "Booked" : "Requested"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={dstyle.textContainer}>
                    <p>{`${car?.vehicleName}-${car?.vehicleYear}`}</p>
                    <p> {`${car?.actualPricePerDay} per day`}</p>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
              ></div>
            )}
          </div>
          <div className={classNames(CommonStyless.mt32)}>
            {ratings && carsToShow.length > 0 ? (
              <ReviewComponent ratings={ratings} />
            ) : (
              <div>
                <PhysiotheristsEmpty />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
};

export default RentaCarDetail;
