import React from "react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import style from "./Help.module.css";

import commonstyles from "shared/utils/common.module.css";
import MainHeader from "shared/components/MainScreen/Index";
import Footerr from "pages/Home/HomeNavBar/Footer";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { DONATION_HELP_CARD_DETAILS } from "shared/utils/mainHeaderQuery";

const DonationCardDetail = () => {
  const location = useLocation();
  const item = location.state?.item;
  return (
    <>
      <NavBreadCrumbs {...DONATION_HELP_CARD_DETAILS} />
      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            {item?.company?.name}
          </p>
          <div className={classNames(commonstyles.flx, commonstyles.flxCenter)}>
            <div className={style.mainimgconatiner}>
              <img src={item.company?.logo} alt="" className={style.mainimg} />
            </div>
          </div>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Address
          </p>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              commonstyles.colorGray
            )}
          >
            {item?.company?.location?.address}
          </p>
          <div className={style.marginTopDonation}></div>
        </>
      </div>

      <Footerr />
    </>
  );
};

export default DonationCardDetail;
