import React, { useState, ChangeEvent, useEffect } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./booking.module.css";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import { fetchBook } from "shared/services";
import { AdminTable } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
import { useNavigate } from "react-router-dom";

function Booking() {
  const [activeTab, setActiveTab] = useState("Flight");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchBooking = () => {
    setLoading(true);
    let params = {
      requestType: activeTab?.toLowerCase(),
      page: 1,
    };
    fetchBook(params)
      .then((res: any) => {
        const newData = res?.data?.requests;
        setData(res?.data?.requests);
      })
      .catch((err: any) => {
        console.log(err, ".....");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchBooking();
  }, [activeTab]);

  const titles = [
    { id: 1, title: "Mr Id" },
    { id: 2, title: "User Name" },
    { id: 3, title: activeTab == "Flight" ? "Booking Id" : "Package Name" },
    { id: 4, title: activeTab == "Flight" ? "Flight type" : "Booking" },
    activeTab == "Tour" && { id: 5, title: "No. of Seats" },
  ];
  const handleGoToDetail = (item: any) => {
    navigate("/travelAgency/Booking/BookingDetailsFlight", {
      state: { item, activeTab },
    });
  };
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div style={{ height: "20px" }} />
      <DoubleButton
        tab1Label="Flight"
        tab2Label="Tour"
        shift={activeTab}
        onTabChange={handleTabChange}
      />
      <AdminTable
        titles={titles}
        data={data}
        booking
        activeTab={activeTab}
        handleGoToDetail={handleGoToDetail}
        headerWidth={"16.66%"}
        itemWidth={"13.66%"}
      />
      {loading && <CustomLoader />}
    </div>
  );
}

export default Booking;
