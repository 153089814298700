import React, { useEffect, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import hstyle from "./Hotel.module.css";
import trust from "assets/images/trust 1.png";
import sliderimg1 from "assets/images/hotelslider1.png";
import sliderimg2 from "assets/images/hotelslider2.png";
import sliderimg3 from "assets/images/hotelslider3.png";

const Hotelfirstpage = () => {
  const images = [sliderimg1, sliderimg2, sliderimg3];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classNames(commonstyles.container)}>
      <div
        className={classNames(
          commonstyles.flx,
          commonstyles.flxBetween,
          commonstyles.flxWrap,
          commonstyles.mb32
        )}
      >
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap,
            commonstyles.mt16
          )}
        >
          <div className={classNames(commonstyles.col5, commonstyles.colsm12)}>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs36,
                commonstyles.semiBold
              )}
            >
              Providing the best insurance in Health & Travel
            </p>
          </div>
          <div className={classNames(commonstyles.col5, commonstyles.colsm12)}>
            <div className={classNames(hstyle.barcontainer, commonstyles.flx)}>
              <div>
                <img src={trust} alt="Trust" className={hstyle.trustimg} />
              </div>
              <div>
                <p className={classNames(hstyle.customParagraphStyle)}>
                  More than 15 insurance companies that partner with us
                </p>
              </div>
            </div>

            <div className={classNames(hstyle.barcontainer, commonstyles.flx)}>
              <div>
                <img src={trust} alt="Trust" className={hstyle.trustimg} />
              </div>
              <div>
                <p className={classNames(hstyle.customParagraphStyle)}>
                  Trusted by 10,000+ valued customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={hstyle.carousel}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={classNames(hstyle.image, {
              [hstyle.active]: index === currentIndex,
            })}
            style={{
              display: index === currentIndex ? "block" : "none",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Hotelfirstpage;
