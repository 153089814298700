import React, { useState, useEffect } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import style from "./donationcriteria.module.css";
import commonstyles from "shared/utils/common.module.css";
import {
  CustomInput,
  CustomModal,
  LoadingModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import ImagePickerNew from "shared/components/FilePickeInsurance/ImagePickerNew";
import CustomSelect from "shared/components/CustomSelect";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import {
  donationAddCriteria,
  donationGETALLCriteria,
} from "shared/services/Donation";
import { donationAddCriteriaSchema } from "shared/utils";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { setCriterion, setCriterionRenderFlag } from "shared/redux";
import CustomLoader from "shared/components/New_Loader/Loader";
const DonationCriteria = ["Food", "Education"];

function DonationCruteria() {
  const { criterion, criterionRenderFlag } = useSelector(
    (state: any) => state.root.donation
  );
  const navigate = useNavigate();

  const handleGoToCriteriaDetail = (_id: string) => {
    navigate(`/donation/criteria/Detail/${_id}`);
  };
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const dispatch = useDispatch();

  const handleModelOpen = () => {
    setShowAddModal(true);
  };

  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    GetAllCriteria();
  };

  const GetAllCriteria = () => {
    setLoading(true);
    donationGETALLCriteria()
      .then((res: any) => {
        console.log("GetAllCriteria", res);
        dispatch(setCriterion(res.data.criterion));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (criterionRenderFlag) {
      setLoading(true);
      GetAllCriteria();
      dispatch(setCriterionRenderFlag(false));
    }
  }, []);
  useEffect(() => {
    GetAllCriteria();
  }, []);
  return (
    <>
      {/* {loading ? (
        <CustomLoader />
      ) : ( */}
      <div className={classNames(commonstyles.col12)}>
        <SearchBar />

        <div className={commonstyles.mr87}>
          <div className={commonstyles.outerContainer}>
            <div className={classNames(commonstyles.flx)}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Your criteria
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={style.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={handleRotate}
                  />
                </div>
              )}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px 5px" }}>
              {criterion.map((criterion: any) => (
                <>
                  <div
                    className={style.criteriaCard}
                    style={{
                      background: `url(${criterion.image}) no-repeat center center`,
                      backgroundSize: "cover",
                    }}
                    onClick={() => handleGoToCriteriaDetail(criterion._id)}
                  >
                    <div className={style.cardtext}>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                      >
                        {criterion.criteriaName}
                      </p>
                    </div>
                  </div>
                </>
              ))}

              <div className={style.ADDcriteriaCard} onClick={handleModelOpen}>
                <IoMdAddCircleOutline className={style.addIconCriteria} />
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.bold,
                    style.mt16,
                    style.textcenter
                  )}
                >
                  Add Criteria
                </p>
              </div>
            </div>

            <CustomModal
              showModal={showAddModal}
              children={
                <Criteria
                  setShowAddModal={setShowAddModal}
                  GetAllCriteria={GetAllCriteria}
                />
              }
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}
export default DonationCruteria;

const Criteria = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const navigate = useNavigate();
  const handleGoToNextscreen = () => {
    navigate("/donation/Categories");
  };
  const dispatch = useDispatch();

  const { setShowCriteriaModal, setShowAddModal, GetAllCriteria } = props;
  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const formik = useFormik({
    initialValues: {
      criteriaName: "",
      description: "",
      image: "",
    },
    validationSchema: Yup.object(donationAddCriteriaSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });
  const handleImageUrl = (url: any) => {
    setImage(false);
    formik.setFieldValue("image", url);
  };
  const handleSelect = (selectedOption: string) => {
    console.log("Selected Option:", selectedOption);
    formik.setFieldValue("criteriaName", selectedOption);
  };
  const handleSubmit = async () => {
    setLoading(true);
    const currentData = formik.values;
    let params = {
      criteriaName: currentData.criteriaName,
      description: currentData.description,
      image: currentData.image,
    };
    donationAddCriteria(params)
      .then((res: any) => {
        console.log("Done", res);
        setShowAddModal(false);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
        GetAllCriteria();
      });
  };

  return (
    <div style={{ width: "400px" }}>
      <form onSubmit={formik.handleSubmit}>
        <div className={commonstyles.flx}>
          <div className={classNames(style.modelHeader, style.mb32)}>
            <p style={{ textAlign: "center" }}>Add Criteria</p>
            <IoClose className={style.close} onClick={handleCloseModal} />
          </div>
        </div>
        <div className={classNames(commonStyles.mb16)}>
          <CustomInput
            placeholder="Criteria Name"
            id="criteriaName"
            name="criteriaName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.criteriaName}
          />

          {formik.touched.criteriaName && formik.errors.criteriaName ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.criteriaName}
            </div>
          ) : null}
        </div>
        <div>
          <div
            className={classNames(commonstyles.col12, style.ImgPicker)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ImagePickerNew setData={handleImageUrl} />
            {formik.touched.image && formik.errors.image ? (
              <div
                className={classNames(commonStyles.error)}
                style={{ marginTop: "8px" }}
              >
                *{formik.errors.image}
              </div>
            ) : null}
          </div>

          <div className={classNames(commonstyles.col12, style.description)}>
            <textarea
              placeholder="Description"
              style={{ resize: "none" }}
              onChange={formik.handleChange}
              id="description"
              name="description"
              value={formik.values.description}
            ></textarea>
            {formik.touched.description && formik.errors.description ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.description}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <PrimaryButton
            children={"Add"}
            type="submit"
            colorType={"green"}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
};
