import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CommonStyles from "shared/utils/common.module.css";
import LoginStyles from "./Uselogin.module.css";
import { CustomInput, PrimaryButton, RingLoader } from "shared/components";
import PasswordInput from "shared/components/PasswordInput";
import { Checkbox } from "@mui/material";
import Logo from "assets/images/loginLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { user_login } from "shared/services";
import {
  set_User,
  setIsLoggedIn,
  setSystemType,
  setToken,
  setUser,
  setUserAge,
} from "shared/redux";

interface FormValues {
  email: string;
  password: string;
}

const UserLogin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const { fcmToken } = useSelector((state: any) => state.root.common);
  const [isMobile, setIsMobile] = useState(false);
  const { cart, isLoggedIn, user } = useSelector(
    (state: any) => state.root.common
  );
  console.log("🚀 ~ UserLogin ~ isLoggedIn:", isLoggedIn);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const formik: any = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: (values: any) => {
      handleLogin(values);
    },
  });
  // console.log(state?.type, "type......");

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.handleSubmit();
    }
  };
  useEffect(() => {
    // Clear error when email or password changes
    setError("");
  }, [formik.values.email, formik.values.password]);

  const handleAge = async (daetOfBirth: any) => {
    const dobString = daetOfBirth;
    const dob = new Date(
      dobString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
    );
    const now = new Date();
    const diffMilliseconds = now.getTime() - dob.getTime();
    const calculatedAge = Math.floor(
      diffMilliseconds / (1000 * 60 * 60 * 24 * 365)
    );
    const ageConvert_InString = calculatedAge?.toLocaleString();
    await dispatch(setUserAge(ageConvert_InString));
  };

  const handleLogin = (values: any) => {
    setLoading(true);

    const params = {
      email: values.email,
      password: values.password,
      fcmToken,
    };

    user_login(params)
      .then((res: any) => {
        if (res?.status === 200 && res?.data?.auth) {
          dispatch(set_User(res.data.user));
          dispatch(setToken(res.data.token));
          dispatch(setSystemType("user"));
          dispatch(setIsLoggedIn(true));
          handleAge(res.data.user.dateOfBirth);
          formik.resetForm();

          setTimeout(() => {
            navigate(state?.type === "forgot" ? "/" : "/");
          }, 800);
        } else {
          dispatch(setIsLoggedIn(false));

          setError("Incorrect email or password. Please try again.");
          window.location.reload();
        }
      })
      .catch((err: any) => {
        dispatch(setIsLoggedIn(false));

        setError(
          err?.response?.data?.message || "An unexpected error occurred."
        );
        window.location.reload();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleForgetPassword = () => {
    navigate("/user/Forget_Password");
  };

  const handleRegisterForm = () => {
    navigate("/user/Email_Verify");
  };

  return (
    <>
      <div
        className={classNames(
          LoginStyles.paretns,
          CommonStyles.flx,
          CommonStyles.flxWrap
        )}
      >
        {!isMobile && (
          <>
            <div
              className={classNames(
                CommonStyles.col6,
                CommonStyles.colmd12,
                CommonStyles.colsm12,
                LoginStyles.centerContent
              )}
            >
              <div>
                <form
                  className={LoginStyles.loginFormContainer}
                  style={{ marginTop: "10%", marginBottom: "10%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <p
                    className={classNames(
                      CommonStyles.fs40,
                      CommonStyles.semiBold,
                      CommonStyles.colorBlue
                    )}
                  >
                    Login
                  </p>
                  <p
                    className={classNames(
                      CommonStyles.fs16,
                      CommonStyles.semiBold,
                      CommonStyles.colorBlue
                    )}
                  >
                    Login to access MediTour
                  </p>
                  {error && (
                    <div className={classNames(CommonStyles.error)}>
                      *{error}
                    </div>
                  )}
                  <div className={CommonStyles.mt24}>
                    <CustomInput
                      placeholder="Please enter Email"
                      id="email"
                      name="email"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className={classNames(CommonStyles.error)}>
                        *{formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className={CommonStyles.mt16}>
                    <PasswordInput
                      placeholder="Enter Your Password"
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      onKeyPress={handleKeyPress}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className={classNames(CommonStyles.error)}>
                        *{formik.errors.password}
                      </div>
                    ) : null}

                    <div
                      className={classNames(
                        LoginStyles.flxBetween,
                        CommonStyles.mb40,
                        LoginStyles.mt8
                      )}
                    >
                      <div className={classNames(CommonStyles.flx)}>
                        <Checkbox className={CommonStyles.checkBoxx} />
                        <p
                          className={classNames(
                            CommonStyles.colorBlue,
                            CommonStyles.fs14
                          )}
                        >
                          Remember me
                        </p>
                      </div>
                      <a
                        className={classNames(
                          CommonStyles.colorBlue,
                          CommonStyles.fs14,
                          CommonStyles.flxEnd,
                          LoginStyles.cursor
                        )}
                        onClick={handleForgetPassword}
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        Forgot Password
                      </a>
                    </div>

                    <div className={classNames(CommonStyles.mb16)}>
                      <PrimaryButton
                        type="submit"
                        disabled={loading}
                        children={
                          loading ? (
                            <RingLoader size={35} color={"#fff"} />
                          ) : (
                            "Log In"
                          )
                        }
                        colorType={"blue"}
                      />
                    </div>
                    <div>
                      <p
                        className={classNames(
                          CommonStyles.regular,
                          CommonStyles.fs14,
                          CommonStyles.textCenter
                        )}
                      >
                        New to this platform?{" "}
                        <a
                          className={classNames(
                            CommonStyles.colorBlue,
                            LoginStyles.cursor
                          )}
                          onClick={handleRegisterForm}
                        >
                          Signup
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={classNames(
                LoginStyles.imgBackgrond,
                CommonStyles.col6,
                CommonStyles.colmd12,
                CommonStyles.colsm12
              )}
            >
              <div className={classNames(LoginStyles.centerContent)}>
                <img src={Logo} alt="Logo" className={LoginStyles.logoImage} />
              </div>
            </div>
          </>
        )}
      </div>

      {/* for mobile and tab */}
      {isMobile && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <form
              className={LoginStyles.loginFormContainer}
              style={{ marginTop: "20%", marginBottom: "20%" }}
              onSubmit={formik.handleSubmit}
            >
              <div
                className={classNames(
                  CommonStyles.flx,
                  CommonStyles.flxBetween
                )}
              >
                <p
                  className={classNames(
                    CommonStyles.fs40,
                    CommonStyles.semiBold,
                    CommonStyles.colorBlue
                  )}
                >
                  Login
                </p>

                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    display: "flex",
                    // justifyContent: "flex-end",
                    width: "80px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  CommonStyles.fs16,
                  CommonStyles.semiBold,
                  CommonStyles.colorBlue
                )}
              >
                Login to access MediTour
              </p>
              {error && (
                <div className={classNames(CommonStyles.error)}>*{error}</div>
              )}
              <div className={CommonStyles.mt24}>
                <CustomInput
                  placeholder="Please enter Email"
                  id="email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className={classNames(CommonStyles.error)}>
                    *{formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className={CommonStyles.mt16}>
                <PasswordInput
                  placeholder="Enter Your Password"
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onKeyPress={handleKeyPress}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className={classNames(CommonStyles.error)}>
                    *{formik.errors.password}
                  </div>
                ) : null}

                <div
                  className={classNames(
                    LoginStyles.flxBetween,
                    CommonStyles.mb40,
                    LoginStyles.mt8
                  )}
                >
                  <div className={classNames(CommonStyles.flx)}>
                    <Checkbox className={CommonStyles.checkBoxx} />
                    <p
                      className={classNames(
                        CommonStyles.colorBlue,
                        CommonStyles.fs14
                      )}
                    >
                      Remember me
                    </p>
                  </div>
                  <a
                    className={classNames(
                      CommonStyles.colorBlue,
                      CommonStyles.fs14,
                      CommonStyles.flxEnd,
                      LoginStyles.cursor
                    )}
                    onClick={handleForgetPassword}
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Forgot Password
                  </a>
                </div>

                <div className={classNames(CommonStyles.mb16)}>
                  <PrimaryButton
                    type="submit"
                    disabled={loading}
                    children={
                      loading ? (
                        <RingLoader size={35} color={"#fff"} />
                      ) : (
                        "Log In"
                      )
                    }
                    colorType={"blue"}
                  />
                </div>
                <div>
                  <p
                    className={classNames(
                      CommonStyles.regular,
                      CommonStyles.fs14,
                      CommonStyles.textCenter
                    )}
                  >
                    New to this platform?{" "}
                    <a
                      className={classNames(
                        CommonStyles.colorBlue,
                        LoginStyles.cursor
                      )}
                      onClick={handleRegisterForm}
                    >
                      Signup
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default UserLogin;
