import React, { useState, useEffect } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import style from "./Donationpackages.module.css";
import commonstyles from "shared/utils/common.module.css";
import styles from "../../Laboratory/Tests/test.module.css"; ///
import commonstyle from "shared/utils/common.module.css"; ////
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineSync } from "react-icons/ai";
import { useParams } from "react-router-dom";
import Donation1 from "assets/images/Donation1.png";
import Donation2 from "assets/images/Donation2.png";
import Donation3 from "assets/images/Donation3.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Navigate, useNavigate } from "react-router-dom";
import {
  donationPackageDelete,
  donationPackageDetail,
} from "shared/services/Donation";
import { CustomModal, LoadingModal, RingLoader } from "shared/components";
import { IoClose } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import CustomLoader from "shared/components/New_Loader/Loader";

interface Props {
  setShowAddModal: any;
}
const PackageConfirmDelete = (props: Partial<Props>) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setShowAddModal } = props;
  const { id } = useParams();
  const deletePackage = () => {
    setLoading(true);
    const donationID = id || "";
    donationPackageDelete(donationID)
      .then((res: any) => {
        if (res?.status === 200) {
          navigate("/donation/packages");
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {/* <div className={styles.modelwidth}>
      <div className={styles.end}>
        <IoClose
          className={styles.close}
          onClick={() => setShowAddModal(false)}
        />
      </div>
      <p
        className={classNames(
          commonstyle.fs16,
          commonstyle.semiBold,
          styles.mt16,
          commonstyle.colorBlue,
          styles.textjustifys
        )}
      >
        Are you sure you want to delete this Package?
      </p>
      <div className={classNames(commonstyle.flxBetween, styles.mt32)}>
        <button
          onClick={() => setShowAddModal(false)}
          className={styles.cancel}
        >
          Cancel
        </button>
        <button className={styles.btnDelete} onClick={deletePackage}>
          Delete
        </button>
      </div>
    </div> */}

      <div className={classNames(styles.modalBackdrop)}>
        <div className={classNames(styles.modalContainer)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IoClose
              className={styles.closeicon}
              // onClick={handleCancel}

              onClick={() => setShowAddModal(false)}
            />
          </div>
          <div className={classNames(commonstyle.flx, commonstyle.flxCol)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              Are you sure ?
            </p>
            <p
              className={classNames(
                commonstyle.colorGray,
                commonstyle.fs16,
                commonstyle.semiBold
              )}
            >
              You want to delete this package?
            </p>
            <div
              className={classNames(commonstyle.flx, commonstyle.flxBetween)}
            >
              <button
                className={styles.cancelbtn}
                onClick={() => setShowAddModal(false)}
              >
                No Cancel
              </button>
              <button className={styles.dltbtn} onClick={deletePackage}>
                {loading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  "Yes Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface DonationDetails {
  description: string;
  donationType: string;
  requiredAmount: number;
  targetAudience: string;
  totalDays: number;
  donationTitle: string;
  images: string[];
}
export default function DonationDetail() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<DonationDetails | null>(null);
  const { id } = useParams();
  console.log(id);
  const navigate = useNavigate();
  const handleBackToCategories = () => {
    navigate("/donation/packages");
  };

  const handleOpenModal = () => {
    setShowAddModal(true);
  };
  const GetDonationDetail = (_id: string) => {
    setLoading(true);
    donationPackageDetail(_id)
      .then((res: any) => {
        console.log("RESPONSE", res);
        setDetails(res.data.package);
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      GetDonationDetail(id);
    }
  }, [id]);

  return (
    <div className={classNames(commonstyles.col12, commonstyles.colorBlue)}>
      <SearchBar />
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={commonstyles.mr87}>
          <div className={commonstyles.outerContainer}>
            <div
              className={classNames(commonstyles.flx)}
              style={{
                cursor: "pointer",
              }}
            >
              <IoArrowBack
                className={style.back}
                onClick={handleBackToCategories}
              />
              <p
                className={classNames(commonstyles.fs20, commonstyles.semiBold)}
              >
                Back
              </p>
            </div>
            <div className={classNames(commonstyles.flx, style.mt32)}>
              <p
                className={classNames(commonstyles.fs24, commonstyles.semiBold)}
              >
                {details?.donationTitle}
              </p>
              <div style={{ marginLeft: "auto" }} className={commonstyles.flx}>
                {/* <div className={style.reset}>
                  <AiOutlineSync className={style.bttm} />
                </div> */}

                <div className={classNames(commonstyles.flx, style.end)}>
                  <MdDeleteOutline
                    className={style.Delete}
                    onClick={handleOpenModal}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(commonstyles.col5, style.mt32)}>
              {details?.images && details?.images[0] && (
                <div>
                  <img src={details.images[0]} className={style.DDonation1} />
                </div>
              )}
              {details?.images &&
                (details?.images[1] || details?.images[2]) && (
                  <div className={commonstyles.flxBetween}>
                    {details.images[1] && (
                      <img
                        src={details.images[1]}
                        className={style.DDonation2}
                      />
                    )}
                    {details.images[2] && (
                      <img
                        src={details.images[2]}
                        className={style.DDonation2}
                      />
                    )}
                  </div>
                )}
              <div className={classNames(commonstyles.flx, style.mt32)}>
                <div className={style.mr50}>
                  <p className={commonstyles.fs14}>Target Audience:</p>
                  <p className={classNames(commonstyles.fs14, style.mt8)}>
                    Total Amount:
                  </p>
                  <p className={classNames(commonstyles.fs14, style.mt8)}>
                    No. Of Days
                  </p>
                </div>
                <div>
                  <p className={commonstyles.fs14}>
                    {details?.targetAudience}{" "}
                  </p>
                  <p className={classNames(commonstyles.fs14, style.mt8)}>
                    {details?.requiredAmount}
                  </p>
                  <p className={classNames(commonstyles.fs14, style.mt8)}>
                    {details?.totalDays}
                  </p>
                </div>
              </div>
              <div className={classNames(style.mt16)}>
                <p className={style.quotions}>{details?.description}</p>
              </div>
            </div>

            {showAddModal && (
              <div>
                <PackageConfirmDelete setShowAddModal={setShowAddModal} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
