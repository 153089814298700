import React from "react";
import { Routes, Route } from "react-router-dom";
import InsuranceBooking from "pages/Home/HomeNavBar/NavBarr/BookingMenu/InsuranceBooking";
import InsuranceBookingDetail from "pages/Home/HomeNavBar/NavBarr/BookingMenu/InsuranceBookingDetail";

const InsuranceBookingDetailRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<InsuranceBookingDetail />} />
    </Routes>
  );
};

export default InsuranceBookingDetailRoute;
