import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import style from "../RentAcarOrder/RentAcarOrder.module.css";
import commomstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import RentacarOrdertable from "shared/components/RentacarTables/RentacarOrder";
import { rentcarCompletedHistory } from "shared/services/RentaCar";
import { LoadingModal, RingLoader } from "shared/components";
import NewPagination from "shared/components/NewPagination/NewPagination";
import CustomLoader from "shared/components/New_Loader/Loader";

const RentAcarHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { rentcarorders, rentcarorderLength } = useSelector(
    (state: any) => state.root.rentcar
  );

  const [completedRequests, setCompletedRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const itemsPerPage = 10;
  const currentPath = location.pathname;

  // Determine the text to show based on the path
  const textToShow = currentPath.includes("rentacar/Order")
    ? "All Order"
    : "History";

  useEffect(() => {
    // Fetch rentcar data when the path matches
    if (currentPath === "/rentacar/history") {
      fetchRentcarData(1);
    } else {
      navigate("/rentacar/history");
    }
  }, [currentPath, navigate]);

  const fetchRentcarData = (pageNumber: number) => {
    setLoading(true);
    rentcarCompletedHistory(pageNumber)
      .then((res: any) => {
        setCompletedRequests(res?.data?.completedRequestsList);
        setTotalItems(res?.data?.totalCustomersCount);
      })
      .catch((err: any) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => setLoading(false));
  };

  const handleRefresh = () => {
    fetchRentcarData(1);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      fetchRentcarData(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchRentcarData(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={classNames(commomstyles.col12)}>
      <SearchBar />
      <div className={commomstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={classNames(style.flxBetween, commomstyles.mb32)}>
            <div className={classNames(commomstyles.flx)}>
              <p
                className={classNames(
                  commomstyles.fs24,
                  commomstyles.semiBold,
                  commomstyles.colorBlue
                )}
              >
                {textToShow}
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={style.RefreshIcon}
                    onClick={handleRefresh}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>

          {/* {loading ? (
            <CustomLoader />
          ) : ( */}
          <div className={commomstyles.mb32}>
            <RentacarOrdertable
              orders={completedRequests}
              onStatusChange={() => fetchRentcarData(currentPage)}
              type={"history"}
            />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default RentAcarHistory;
