import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { CustomInput } from "shared/components";
import { resetPassword } from "shared/services";
import { user_ResetPassword } from "shared/utils";
import { PrimaryButton } from "shared/components";
import { initialValues, onSumbit } from "./resetProps";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import classNames from "classnames";
import LoginStyles from "./Uselogin.module.css";
import Logo from "assets/images/UserForget.png";
import CommonStyles from "shared/utils/common.module.css";
import PasswordInput from "shared/components/PasswordInput";
import CustomLoader from "shared/components/New_Loader/Loader";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setCanResend(true); // Enable resend code after 60 seconds
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(countdown);
  }, [canResend]);

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object(user_ResetPassword),
    onSubmit: (values: any) => onSumbit(setLoading, values, state, navigate),
  });

  const resendCode = () => {
    let params = {
      email: state?.email,
    };
    setLoading(true);
    resetPassword(params)
      .then((res: any) => {
        toast.success(res?.data?.message);
        setTimer(60);
        setCanResend(false);
      })
      .catch((err: any) => {
        toast?.error(err?.response.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className={classNames(
        LoginStyles.paretns,
        CommonStyles.flx,
        CommonStyles.flxWrap
      )}
    >
      <div
        className={classNames(
          LoginStyles.imgBackgrond,
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12
        )}
      >
        <div className={classNames(LoginStyles.centerContent)}>
          <img src={Logo} alt="Logo" className={LoginStyles.logoImage} />
        </div>
      </div>

      <div
        className={classNames(
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12,
          LoginStyles.centerContent
        )}
      >
        <form
          className={LoginStyles.loginFormContainer}
          onSubmit={formik?.handleSubmit}
        >
          <p
            className={classNames(
              CommonStyles.fs28,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Forgot
          </p>
          <p
            className={classNames(
              CommonStyles.fs16,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Your new password must be different from previous used password
          </p>

          <div className={CommonStyles.mt14}>
            <PasswordInput
              placeholder="Enter Your Desire Password"
              id="newPassword"
              type="text"
              value={formik?.values?.newPassword}
              onChange={formik?.handleChange}
            />
            {formik?.touched?.newPassword && formik?.errors?.newPassword && (
              <div className={classNames(CommonStyles.error)}>
                *{formik?.errors?.newPassword}
              </div>
            )}
          </div>

          <div className={CommonStyles.mt14}>
            <PasswordInput
              placeholder="Confirm your  Password"
              id="confirmPassword"
              type="text"
              value={formik?.values?.confirmPassword}
              onChange={formik?.handleChange("confirmPassword")}
            />
            {formik?.touched?.confirmPassword &&
              formik?.errors?.confirmPassword && (
                <div className={classNames(CommonStyles.error)}>
                  *{formik?.errors?.confirmPassword}
                </div>
              )}
          </div>

          <div className={CommonStyles.mt14}>
            <CustomInput
              placeholder="Verification Code"
              id="verificationCode"
              type="text"
              value={formik?.values?.verificationCode}
              onChange={formik?.handleChange("verificationCode")}
            />
            {formik?.touched?.verificationCode &&
              formik?.errors?.verificationCode && (
                <div className={classNames(CommonStyles.error)}>
                  *{formik?.errors?.verificationCode}
                </div>
              )}
          </div>

          <div className={classNames(CommonStyles.mt16)}>
            {canResend ? (
              <p onClick={resendCode} className={LoginStyles.resendCode}>
                Resend Code
              </p>
            ) : (
              <p style={{ color: "#a0a0a0" }}>Resend Code in {timer} seconds</p>
            )}
          </div>

          <div className={classNames(CommonStyles.mt24)}>
            <PrimaryButton type="submit" children={"Next"} colorType={"blue"} />
          </div>

          <div
            className={classNames(
              CommonStyles.mt24,
              CommonStyles.flx,
              CommonStyles.flxBetween
            )}
          >
            <p className={classNames(CommonStyles.regular, CommonStyles.fs14)}>
              Already signed up?
            </p>
            <a
              className={classNames(
                CommonStyles.colorBlue,
                CommonStyles.fs16,
                CommonStyles.Bold,
                LoginStyles.cursor
              )}
              onClick={() => navigate("/user/login")}
            >
              Login
            </a>
          </div>
        </form>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default ResetPassword;
