import { FormikProps } from "formik";
import React from "react";
import styles from "./SocialInfo.module.css";

interface Props {
  formik?: any;
}

const SocialInfo = (props: Props) => {
  const { formik } = props;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.socialInfoContainer}>
        <div className={styles.rowSocial}>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              type="text"
              id="facebook"
              value={formik?.values?.facebook}
              onChange={formik?.handleChange("facebook")}
              placeholder="Facebook (Optional)"
            />
          </div>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              type="text"
              id="instagram"
              value={formik?.values?.instagram}
              onChange={formik?.handleChange("instagram")}
              placeholder="Instagram (Optional)"
            />
          </div>
        </div>
        <div className={styles.rowSocial}>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              type="text"
              id="linkedin"
              value={formik?.values?.linkedIn}
              onChange={formik?.handleChange("linkedIn")}
              placeholder="LinkedIn (Optional)"
            />
          </div>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              type="text"
              id="youtube"
              value={formik?.values?.youtube}
              onChange={formik?.handleChange("youtube")}
              placeholder="YouTube (Optional)"
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.continueButton} type="submit">
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default SocialInfo;
