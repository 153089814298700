import React, { useEffect, useState } from "react";
import style from "../DoctarServices/Doctor.module.css";
import { useLocation, useParams } from "react-router-dom";
import Vector from "assets/images/Vector.png";
import CardStyless from "../DoctarServices/Cards.module.css";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import CommonStyless from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import classNames from "classnames";
import Footerr from "pages/Home/HomeNavBar/Footer";
import GoogleMapComponent from "shared/components/GoogleMap/GoogleMap";
import detailStyle from "./HospitalDetail.module.css";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { LoadingModal } from "shared/components";
import {
  AddRemovedFev,
  getHospital_Departments,
  getHospital_Doctors,
  getSingle_UserHospital,
} from "shared/services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setHospitalId, set_User } from "shared/redux";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import toast from "react-hot-toast";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import ContinueButton from "shared/components/ContinueButton";
import RatingStar from "shared/RatingStar";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { HOSPITAL_DETAILS } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";

const DoctorDetail: React.FC = () => {
  const { state } = useLocation();
  let serviceName = state.serviceName;
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [hospitalDoc, setHospitalDoc] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [indicator, setIndicator] = useState(false);
  const [cardType, setCardType] = useState("departments");
  const [visibleCards, setVisibleCards] = useState(12);
  const [doctorCards, setDoctorCards] = useState(4);

  const [hospitalDepartment, setHospitalDepartments] = useState<any>();

  const { user } = useSelector((state: any) => state.root.common);

  const isFavorite = user?.favourites?.some(
    (fav: any) => fav.itemId === id && fav.favModel === "hospitals"
  );

  console.log(".....isFavorite", isFavorite);
  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 12);
  };
  const handleDoctor = () => {
    setDoctorCards((prevVisibleCards) => prevVisibleCards + 12);
  };
  let params = {
    hospitalId: id,
  };

  useEffect(() => {
    getHospitalDetails();
  }, []);

  const getHospitalDetails = async () => {
    setLoading(true);
    try {
      await dispatch(setHospitalId(id));
      await getSingleHospital();
      await getHospitalDepartments();
      await getHospitalDoctors();
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const getSingleHospital = async () => {
    try {
      const res = await getSingle_UserHospital(params);
      setData(res?.data);
      getHospitalDepartments();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getHospitalDepartments = async () => {
    try {
      const res = await getHospital_Departments(params);
      setHospitalDepartments(res?.data?.departments);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const getHospitalDoctors = async () => {
    try {
      const res = await getHospital_Doctors(params);
      setHospitalDoc(res?.data?.doctors);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleDepartemntClick = (doc: any) => {
    console.log("...dep check", doc);
    navigate(`/services/hospital/HospitalDetail/${id}/HospitalDepartments`, {
      state: { serviceName: "departmentDoc", doc },
    });
  };

  const handleViewMoreClick = (doc: any) => {
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName, doc, hospitalId: data?.hospital?._id },
    });
  };

  const handlefavourite = () => {
    setIndicator(true);
    const params = {
      type:
        serviceName === "Hospital" ? "hospitals" : serviceName?.toLowerCase(),
      itemId: id,
    };
    console.log(params, "......params");
    AddRemovedFev(params)
      .then((res: any) => {
        dispatch(set_User(res?.data?.user));
        toast?.success(res?.data?.message);
      })
      .catch((err: any) => {
        console.log(err, ".....err");
      })
      .finally(() => {
        setIndicator(false);
      });
  };

  if (id === undefined) {
    return <div>Doctor ID is missing</div>;
  }

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <>
      <div>
        <NavBreadCrumbs {...HOSPITAL_DETAILS} />
      </div>

      <div
        className={classNames(
          detailStyle.mainconatiner,
          CommonStyless.container
        )}
      >
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxBetween,
            detailStyle.conatiner
          )}
        >
          <div>
            <div className={CardStyless.cardWrapper}>
              <div className={CardStyless.cardImageWrapper}>
                <img
                  src={data?.hospital?.logo}
                  alt="card img"
                  className={CardStyless.cardImage}
                />
              </div>
              <div className={CardStyless.cardBody}>
                <div
                  className={classNames(
                    CommonStyless.flx,
                    CommonStyless.flxBetween
                  )}
                >
                  <div
                    className={classNames(
                      CardStyless.cardName,
                      CommonStyless.colorBlue
                    )}
                  >
                    {data?.hospital?.name}
                  </div>
                  <div onClick={handlefavourite}>
                    {isFavorite ? <FaHeart color="red" /> : <FaRegHeart />}
                  </div>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <IoTimeOutline />
                  </span>
                  <span>{data?.hospital?.openTime}</span>
                </div>

                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <IoLocationOutline />
                  </span>
                  <span>{data?.hospital?.location?.address}</span>
                </div>
                <div className={CardStyless.cardFooter}>
                  {/* <RatingStar rating={5} /> */}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            padding: "2px",
            borderRadius: "16px",
            border: "0.5px solid #13A89E",
            boxShadow: "0px 5px 8px 0px #B2B2B229",
          }}
        >
          <GoogleMapComponent
            location={{
              lat: data?.hospital?.location?.lat,
              lng: data?.hospital?.location?.lng,
            }}
          />
        </div>
        <div className={classNames(CommonStyless.mt24)}>
          <div>
            <p
              className={classNames(
                CommonStyless.colorBlue,
                CommonStyless.fs24,
                CommonStyless.semiBold
              )}
            >
              Departments
            </p>
            {hospitalDepartment?.length == 0 ? <PhysiotheristsEmpty /> : null}

            <div className={detailStyle.departmentCardsContainer}>
              {hospitalDepartment
                ?.slice(0, visibleCards)
                .map((dep: any, index: any) => (
                  <div
                    key={index}
                    className={detailStyle.departmentCardWrapper}
                  >
                    <div
                      className={detailStyle.departmentCards}
                      onClick={() => handleDepartemntClick(dep)}
                    >
                      <div className={detailStyle.departmentCardsImage}>
                        <img
                          src={dep?.dapartmentLogo}
                          alt={dep?.departmentName}
                          className={detailStyle.departmentCardsImage}
                        />
                      </div>
                    </div>
                    <p className={detailStyle.departmentCardsText}>
                      {dep?.departmentName}
                    </p>
                  </div>
                ))}
            </div>
            {/* {visibleCards < departmentData.length && ( */}
            {hospitalDepartment?.length > visibleCards && (
              <ContinueButton
                buttonText="View Details"
                onClick={handleShowMore}
              />
            )}
          </div>
        </div>

        <div className={classNames(CommonStyless.mt24)}>
          <p
            className={classNames(
              CommonStyless.colorBlue,
              CommonStyless.fs24,
              CommonStyless.semiBold
            )}
          >
            Hospital’s Doctors
          </p>
          {hospitalDoc?.length === 0 || hospitalDepartment?.length === 0 ? (
            <PhysiotheristsEmpty />
          ) : (
            <>
              <div className={CardStyless.cardContainer}>
                {hospitalDoc
                  ?.slice(0, doctorCards)
                  .map((hospDoc: any, index: any) => (
                    <div key={index} className={CardStyless.cardWrapper}>
                      <div className={CardStyless.cardImageWrapper}>
                        <img
                          src={
                            hospDoc?.doctorImage ||
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                          }
                          alt={hospDoc?.name}
                          className={CardStyless.cardImage}
                        />
                      </div>
                      <div
                        className={CardStyless.cardBody}
                        onClick={() => handleViewMoreClick(hospDoc)}
                      >
                        <div
                          className={classNames(
                            CommonStyless.flx,
                            CommonStyless.flxBetween
                          )}
                        >
                          <div
                            className={classNames(
                              CardStyless.cardName,
                              CommonStyless.colorBlue
                            )}
                          >
                            {hospDoc?.name}
                          </div>
                          <div>
                            <img
                              src={Vector}
                              alt="Vector icon"
                              className={CardStyless.vectorIcon}
                            />
                          </div>
                        </div>
                        <div className={CardStyless.cardTitle}>
                          {hospDoc?.speciality?.join(", ")}
                        </div>
                        <div className={CardStyless.cardDetail}>
                          {hospDoc?.qualifications}
                        </div>
                        <div className={CardStyless.cardExperience}>
                          {hospDoc?.clinicExperience}
                        </div>
                        <div className={CardStyless.cardFooter}>
                          <RatingStar rating={4.5} />

                          <span
                            className={classNames(
                              CommonStyless.flx,
                              CardStyless.viewMore
                            )}
                            onClick={() => handleViewMoreClick(hospDoc)}
                          >
                            <Link to="#" className={CardStyless.cardViewMore}>
                              View more
                            </Link>
                            <span className={CardStyless.cardArrow}>
                              <IoMdArrowForward />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                {hospitalDoc?.length > doctorCards && (
                  <ContinueButton
                    buttonText="View Details"
                    onClick={handleDoctor}
                    backgroundColor="#0d47a1"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <Footerr />
    </>
  );
};

export default DoctorDetail;

{
  /* <div className={classNames(CommonStyless.mt32, CommonStyless.mb32)}>
          <p
            className={classNames(
              CommonStyless.colorBlue,
              CommonStyless.fs24,
              CommonStyless.semiBold
            )}
          >
            Similar Hospitals
          </p>
          <div className={CardStyless.cardContainer}>
            {cardData.slice(0, visibleCards).map((card) => (
              <div key={card.id} className={CardStyless.cardWrapper}>
                <div className={CardStyless.cardImageWrapper}>
                  <img
                    src={img}
                    alt="card img"
                    className={CardStyless.cardImage}
                  />
                </div>
                <div className={CardStyless.cardBody}>
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxBetween
                    )}
                  >
                    <div
                      className={classNames(
                        CardStyless.cardName,
                        CommonStyless.colorBlue
                      )}
                    >
                      {card.name}
                    </div>
                    <div>
                      <img
                        src={card.vector}
                        alt="Vector icon"
                        className={CardStyless.vectorIcon}
                      />
                    </div>
                  </div>
                  <div className={CardStyless.cardtime}>
                    <span className={CardStyless.timeIcon}>
                      <IoTimeOutline />
                    </span>
                    <span>{card.time}</span>
                  </div>

                  <div className={CardStyless.cardtime}>
                    <span className={CardStyless.timeIcon}>
                      <IoLocationOutline />
                    </span>
                    <span>{card.location}</span>
                  </div>
                  <div className={CardStyless.cardFooter}>
                    <span className={CardStyless.cardRating}>*****</span>
                    <span className={CardStyless.cardRatingValue}>
                      {card.rating}
                    </span>
                    <span
                      className={classNames(
                        CommonStyless.flx,
                        CardStyless.viewMore
                      )}
                      // onClick={() => handleViewMoreClick(card.id)}
                    >
                      <Link to="#" className={CardStyless.cardViewMore}>
                        View more
                      </Link>
                      <span className={CardStyless.cardArrow}>
                        <IoMdArrowForward />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */
}
