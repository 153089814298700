import React from "react";
import AdminNavBar from "../../../Components/AdminNavBar";
import commonStyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Styles from "./Doctors.module.css";
import { TbRefresh } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { CiSearch } from "react-icons/ci";
import { BiFilterAlt } from "react-icons/bi";
import { RiSearchLine } from "react-icons/ri";
import { Checkbox } from "@mui/material";
import SearchFilter from "pages/AdminPanel/Components/SearchFilter";
import moment from "moment";
const data = [
  {
    labId: "4 Feb 2019 05:30 PM",
    labName: "kP02365",
    phoneNumber: "Salim Qureshi",
    labEmail: "Jinnah Hospital LHR",
    labAddress: "25632",
  },
  {
    labId: "4 Feb 2019 05:30 PM",
    labName: "KL02365",
    phoneNumber: "Zubair Ahmed",
    labEmail: "Clinic",
    labAddress: "56489",
  },
];

export default function DoctorsVender() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const handleGoToDetail = (index: any) => {
    navigate("/admin/Users/VendorDetail", {
      state: { data: state[index], type: "Doctors" },
    });
  };
  return (
    <div className={classNames(commonStyles.col12)}>
      <div className={Styles.Navouter}>
        <AdminNavBar labelText="Users" />
      </div>
      <div className={classNames(Styles.mainOuter)}>
        <div className={classNames(Styles.flxBetween)}>
          <div className={classNames(commonStyles.flx, commonStyles.colsm12)}>
            <p
              className={classNames(
                commonStyles.fs22,
                Styles.primarycolor,
                commonStyles.semiBold
              )}
            >
              Doctors
            </p>{" "}
            <TbRefresh className={Styles.refresh} />
            <div className={commonStyles.flx} style={{ marginLeft: "60px" }}>
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#0D47A1",
                  },
                }}
              />
              <p className={classNames(commonStyles.fs16, Styles.colorgray)}>
                Consultant
              </p>
            </div>{" "}
            <div className={commonStyles.flx}>
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#0D47A1",
                  },
                }}
              />
              <p className={classNames(commonStyles.fs16, Styles.colorgray)}>
                General
              </p>
            </div>
            <SearchFilter vender={false} />
          </div>
          <div className={classNames(commonStyles.flx)}>
            <NewPagination />
          </div>
        </div>
        <div>
          <div className={Styles.payment}>
            <div className={classNames(Styles.headerOuter, commonStyles.bold)}>
              <p className={Styles.headerclass}>Dr ID</p>
              <p className={Styles.headerclass}> NAME</p>
              <p className={Styles.headerclass}>PHONE NUMBER</p>
              <p className={Styles.headerclass}>CLINIC NAME</p>
              <p className={Styles.headerclass}>CLINIC ADDRESS</p>
            </div>
            <div className={Styles.tableData}>
              <table
                style={{
                  margin: "0px",
                  borderCollapse: "separate",
                  borderSpacing: "0 4px",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <tbody className={Styles.wapper}>
                  {state?.map((val: any, rowIndex: any) => {
                    const Date = moment(val?.createdAt).format(
                      "MM-DD-YYYY,  h:mm a"
                    );

                    return (
                      <tr
                        className={Styles.tableRow}
                        key={rowIndex}
                        onClick={() => handleGoToDetail(rowIndex)}
                      >
                        <td className={Styles.w20}>{val?.vendorId}</td>
                        <td className={Styles.w20}>{val?.name}</td>
                        <td className={Styles.w20}>{val?.phoneNumber}</td>
                        <td
                          className={Styles.w20}
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "90px",
                          }}
                        >
                          {val?.clinicName}
                        </td>
                        <td
                          className={Styles.w20}
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "90px",
                          }}
                        >
                          {val?.location?.address}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
