import React, { useState } from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./TravelStyle.module.css";
import { MdDeleteOutline } from "react-icons/md";
import img from "assets/images/Edhilogo.png";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/emirets.png";
import { BiSolidPlaneAlt } from "react-icons/bi";
import moment from "moment";
import { allBidRequest } from "shared/services";
import { RingLoader } from "shared/components";

const FlightContent = ({ item }: { item?: any }) => {
  const navigate = useNavigate();
  const handledetail = (travel: any, totalTravelers: any) => {
    navigate("/services/myRequest/TravellDetail", {
      state: {
        data: travel,
        totalTravelers: totalTravelers,
      },
    });
  };

  const inBooking =
    item.requestId?.adult + item?.requestId?.children + item?.requestId?.infant;
  console.log(inBooking, "......inBooking");
  const checkItme = item?.requestId?.flights;
  console.log(item?.requestId?.returnFlight, '........item?.returnFlight')
  return (
    <>
      <div className={classNames(style.mainconatiner)}>
        <div
          className={classNames(commonstyle.flx, commonstyle.flxBetween)}
          style={{ width: "100%" }}
        >
          <div>
            <p className={classNames(style.pickdrop)} style={{ color: "#fff" }}>
              Flight Type
            </p>
            <p
              className={classNames(style.pickupdetail)}
              style={{ color: "#fff" }}
            >
              {item?.requestId?.requestType}
            </p>
          </div>
        </div>
        {checkItme?.map((user: any) => (
          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxBetween

              //   commonstyle.flxWrapsty
            )}
          >
            <div className={classNames(style.colwidth)}>
              <DataAtom title="From" description={user?.from} />
            </div>
            <div className={classNames(style.colwidth)}>
              <DataAtom title="To" description={user?.to} />
            </div>
            <div className={classNames(style.colwidth)}>
              <DataAtom
                title="Departure"
                description={moment(user?.departure).format("MM-DD-YYYY")}
              />
            </div>
          </div>
        ))}
        <div>
          <p className={classNames(style.pickdrop)} style={{ color: "#fff" }}>
            Class
          </p>
          <p
            className={classNames(style.pickupdetail)}
            style={{ color: "#fff" }}
          >
            {item?.flightClass || item?.requestId?.flightClass}
          </p>
          <div>
            <p className={classNames(style.pickdrop)} style={{ color: "#fff" }}>
              Traveler
            </p>
            <p
              className={classNames(style.pickupdetail)}
              style={{ color: "#fff" }}
            >
              {inBooking} Traveler
            </p>
          </div>

          {item?.requestId?.returnFlight && <div>
            <p className={classNames(style.pickdrop)} style={{ color: "#fff" }}>
              Return
            </p>
            <p
              className={classNames(style.pickupdetail)}
              style={{ color: "#fff" }}
            >
              {moment(item?.requestId?.returnFlight).format("MM-DD-YYYY")}
            </p>
          </div>}
        </div>
      </div>
    </>
  );
};

export default FlightContent;
const DataAtom = (props: any) => {
  return (
    <div>
      <p className={classNames(style.pickdrop)} style={{ color: "#fff" }}>
        {props.title}
      </p>
      <p className={classNames(style.pickupdetail)} style={{ color: "#fff" }}>
        {props.description}
      </p>
    </div>
  );
};
