import React from "react";
import { Routes, Route } from "react-router-dom";
import PaymentNavbar from "pages/Home/HomeNavBar/NavBarr/PaymentNavbar";

const PaymentNavbarRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PaymentNavbar />} />
    </Routes>
  );
};

export default PaymentNavbarRoutes;
