import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PdfDownload = ({
  item,
  logoBase64,
  data,
  testData,
  prescription,
  fileName,
}: {
  item: any;
  logoBase64: any;
  data: any;
  testData: any;
  prescription: any;
  fileName: any;
}) => {
  const generatePDF = () => {
    const input: any = document.getElementById("pdf-content");
    html2canvas(input, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 1.0);
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(fileName);
      })
      .finally(() => {
        input.style.display = "none"; // Hide after capturing
      });
  };

  return (
    <div>
      <div id="pdf-content" style={{ display: "none" }}>
        <html lang="en">
          <head>
            <style>
              {`
                p {
                  margin: 0;
                  padding: 5px;
                  line-height: 1;
                }
                .container {
                  padding: 24px;
                }
                .row {
                  display: flex;
                  justify-content: space-between;
                }
                .bold {
                  font-weight: bold;
                }
                .semibold {
                  font-weight: 600;
                }
                .regular {
                  font-weight: 400;
                }
                .border-bottom {
                  border-bottom: 1px solid #000;
                }
                .border-top {
                  border-top: 1px solid #000;
                }
                .logo {
                  width: 100px;
                  height: auto;
                }
                .footer-text {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  text-align: center;
                  color: #000;
                  font-size: 18px;
                }
              `}
            </style>
          </head>
          <body>
            <div className="container">
              <div
                className="row border-bottom"
                style={{ paddingBottom: "30px" }}
              >
                <div>
                  <p
                    className="bold"
                    style={{ fontSize: "26px", color: "#000" }}
                  >
                    {item?.doctorId?.name}
                  </p>
                  <p style={{ color: "#000", fontSize: "18px" }}>
                    {item?.doctorId?.qualifications}
                  </p>
                  <p style={{ color: "#000", fontSize: "18px" }}>
                    PM&DC Reg: {item?.doctorId?.pmdcNumber}
                  </p>
                </div>
                <img src={logoBase64} className="logo" alt="Logo" />
              </div>
              <div className="row">
                <p
                  style={{ color: "#000", fontSize: "20px", marginTop: "16px" }}
                  className="semibold"
                >
                  Patient Name:{" "}
                  <span
                    style={{ fontSize: "18px", color: "#000" }}
                    className="regular"
                  >
                    {item?.patientId?.name}
                  </span>
                </p>
                <p
                  style={{ color: "#000", fontSize: "20px", marginTop: "16px" }}
                  className="semibold"
                >
                  M.R No:{" "}
                  <span
                    style={{ fontSize: "18px", color: "#000" }}
                    className="regular"
                  >
                    {item?.patientId?.mrNo}
                  </span>
                </p>
              </div>
              <div className="row" style={{ alignItems: "center" }}>
                <p
                  style={{ color: "#000", fontSize: "20px" }}
                  className="semibold"
                >
                  Date:{" "}
                  <span
                    style={{ fontSize: "18px", color: "#000" }}
                    className="regular"
                  >
                    {new Date(
                      item?.appointmentDateAndTime
                    ).toLocaleDateString()}
                  </span>
                </p>
                <p
                  style={{ color: "#000", fontSize: "20px" }}
                  className="semibold"
                >
                  Age:{" "}
                  <span
                    style={{ fontSize: "18px", color: "#000" }}
                    className="regular"
                  >
                    {/* {`${calculateAge(item?.patientId?.dateOfBirth)} */}
                    10 years old
                  </span>
                </p>
              </div>
              <div className="row">
                <p
                  style={{ color: "#000", fontSize: "20px" }}
                  className="semibold"
                >
                  Cell:{" "}
                  <span
                    style={{ fontSize: "18px", color: "#000" }}
                    className="regular"
                  >
                    {item?.patientId?.phone}
                  </span>
                </p>
              </div>
              <p
                style={{ color: "#000", fontSize: "20px" }}
                className="semibold"
              >
                Address:{" "}
                <span
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    marginRight: "34px",
                  }}
                  className="regular"
                >
                  {`${item?.patientId?.addresses?.[0]?.address}-${item?.patientId?.addresses?.[0]?.city}`}
                </span>
              </p>
              <p style={{ fontSize: "18px", color: "#000" }}>
                Weight (Kg):{" "}
                {`${data?.weight}, BP: ${data?.bloodPressure?.systolicPressure}/${data?.bloodPressure?.diastolicPressure} mmHg`}
              </p>
              <div
                className="row border-top border-bottom"
                style={{ marginTop: "16px" }}
              >
                <p
                  className="bold"
                  style={{ fontSize: "22px", color: "#1A3D7C" }}
                >
                  Symptoms
                </p>
                <p
                  className="bold"
                  style={{ width: "45%", fontSize: "22px", color: "#1A3D7C" }}
                >
                  Clinical Findings
                </p>
              </div>
              <div className="row">
                <p style={{ fontSize: "18px", color: "#000", width: "45%" }}>
                  {data?.symptoms}
                </p>
                <p
                  style={{ fontSize: "18px", color: "#000", width: "45%" }}
                  className="text-left"
                >
                  {data?.description}
                </p>
              </div>
              <div
                className="border-bottom border-top"
                style={{ padding: "16px 0" }}
              >
                <p
                  style={{ color: "#1A3D7C", fontSize: "22px" }}
                  className="semibold"
                >
                  Laboratory Test
                </p>
                {testData.map((test: any, index: any) => (
                  <p key={index} style={{ color: "#000", fontSize: "18px" }}>
                    * {test?.testName}
                  </p>
                ))}
              </div>
              <div
                className="row border-bottom"
                style={{ alignItems: "center" }}
              >
                <p
                  className="bold"
                  style={{ width: "34%", color: "#1A3D7C", fontSize: "22px" }}
                >
                  Medicine Name
                </p>
                <p
                  className="bold"
                  style={{
                    width: "33%",
                    textAlign: "center",
                    padding: "0 10px",
                    color: "#1A3D7C",
                    fontSize: "22px",
                  }}
                >
                  Dosage
                </p>
                <p
                  className="bold"
                  style={{
                    width: "33%",
                    textAlign: "center",
                    padding: "0 10px",
                    color: "#1A3D7C",
                    fontSize: "22px",
                  }}
                >
                  Duration
                </p>
              </div>
              {prescription?.map((med: any, index: any) => (
                <div
                  key={index}
                  className="row border-bottom"
                  style={{
                    alignItems: "center",
                    marginTop: "10px",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ width: "34%", color: "#000", fontSize: "18px" }}>
                    {index + 1}) {med.medicineName}
                  </p>
                  <p
                    style={{
                      width: "33%",
                      textAlign: "center",
                      padding: "0 10px",
                      color: "#000",
                      fontSize: "18px",
                    }}
                  >
                    {med.dosage}
                  </p>
                  <p
                    style={{
                      width: "33%",
                      textAlign: "center",
                      padding: "0 10px",
                      color: "#000",
                      fontSize: "18px",
                    }}
                  >
                    {med?.days}
                  </p>
                </div>
              ))}
              <p className="footer-text">
                For any guidance please contact helpline 111 111 MTG
              </p>
            </div>
          </body>
        </html>
      </div>
      <button onClick={generatePDF}>Download PDF</button>
    </div>
  );
};

export default PdfDownload;
