import React, { useRef } from "react";
import PropTypes from "prop-types";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { SlLocationPin } from "react-icons/sl";

const LocationInput = ({
  placeholder = "Enter Your Location",
  type,
  setData,
  defaultValue, // Add defaultValue prop
}) => {
  const autocompleteRef = useRef();

  const handleSelect = async (value) => {
    const {
      label,
      value: { place_id },
    } = value;

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A`
      );
      const data = await response.json();

      if (data?.results?.[0]?.geometry?.location) {
        const { lat, lng } = data.results[0].geometry.location;

        // Extract city from address components, checking for multiple types
        const addressComponents = data.results[0].address_components;
        let cityComponent = addressComponents.find((component) =>
          component.types.includes("locality")
        );

        // Fallback to 'administrative_area_level_3' if 'locality' is not found
        if (!cityComponent) {
          cityComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_3")
          );
        }

        const city = cityComponent ? cityComponent.long_name : "";

        setData({ label, lat, lng, city });
      } else {
        console.error("Location data not available");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const handleIconClick = () => {
    if (autocompleteRef.current) {
      autocompleteRef.current.focus();
    }
  };

  return (
    <div
      style={
        type == "box"
          ? {
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              paddingRight: "10px",
              paddingLeft: "10px",
              width: "100%",
            }
          : {
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #6F6F72",
            }
      }
    >
      <div style={{ width: "100%" }}>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A"
          selectProps={{
            ref: autocompleteRef,
            placeholder,
            value: defaultValue,
            styles: {
              placeholder: (provided) => ({
                ...provided,
                color: type == "box" ? "#999" : "#00276d",
                fontStyle: type == "box" ? "normal" : "italic",
                fontSize: "14px",
                fontFamily: '"Poppins", sans-serif',
              }),
              input: (provided) => ({
                ...provided,
                color: "#00276d",
              }),
              option: (provided) => ({
                ...provided,
                color: "#00276d",
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "#00276d",
                fontStyle: "italic",
                fontSize: "14px",
                fontFamily: '"Poppins", sans-serif',
              }),
              control: (provided) => ({
                ...provided,
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
                borderBottom: "none",
                alignItems: "end",
                fontFamily: '"Poppins", sans-serif',
                backgroundColor: "transparent",
                borderRadius: "0px",
                borderColor: "rgba(0, 0, 0, 0.5)",
                marginTop: "8px",
                boxShadow: "0px",
                padding: "0px",
                minHeight: "32px",
                borderColor: "#6F6F72",
                "&:hover": {
                  borderBottom: "none",
                },
                "&:focus": {
                  borderBottom: "none",
                },
              }),

              indicatorsContainer: (provided) => ({
                ...provided,
                display: "none",
              }),
              valueContainer: (provided) => ({
                ...provided,
                padding: "0px",
              }),
              container: (provided) => ({
                ...provided,
                "&:active": {
                  border: "none",
                },
                "&:hover": {
                  border: "none",
                },
              }),
            },
            onChange: handleSelect,
          }}
        />
      </div>
      <SlLocationPin
        style={{
          fontSize: type == "box" ? "20px" : "28px",
          color: type == "box" ? "#999" : "#00276d",
        }}
        onClick={handleIconClick}
      />
    </div>
  );
};

LocationInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  setData: PropTypes.func.isRequired,
  defaultValue: PropTypes.any, // Add prop type for defaultValue
};

export default LocationInput;
