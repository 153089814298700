import Footerr from "pages/Home/HomeNavBar/Footer";
import PharmacyCards from "shared/PharmacyServices/PhamaycyCards";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PHARMACY } from "shared/utils/mainHeaderQuery";
const PharmacyService = () => {
  return (
    <div>
      <NavBreadCrumbs {...PHARMACY} />
      <PharmacyCards />
      <Footerr />
    </div>
  );
};

export default PharmacyService;
