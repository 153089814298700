import React, { useEffect, useState } from "react";
import classNames from "classnames";
import MainTravelStyle from "../../../mainTravel.module.css";
import { CustomInput, PrimaryButton } from "shared/components";
import labMainStyles from "../../../../Laboratory/laboratoryMain.module.css";
import commonStyles from "shared/utils/common.module.css";
import ImgPicker from "shared/components/Img-picker";
import { setRentCarUserFormData } from "shared/redux/reducers/rentacar/rentcarReducer";
import { useDispatch, useSelector } from "react-redux";
import { rentACarInfoSchema } from "shared/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import Datepicker from "shared/components/DatePicker";
import LocationInput from "shared/components/LocationInput";
import dayjs from "dayjs";

interface Props {
  handleClickNext: any;
}
export default function RentACarBasicInfo(props: Partial<Props>) {
  const { handleClickNext } = props;
  const dispatch = useDispatch();
  const { rentcarUserFormData } = useSelector(
    (state: any) => state.root.rentcar
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      logo: "",
      licenseNumber: "",
      licenseExpiry: "",
      licenseImage: "",
      emergencyNumber: "",
      ownerFirstName: "",
      ownerLastName: "",
      cnicNumber: "",
      cnicExpiry: "",
      cnicImage: "",
      address: "",
      lat: "",
      lng: "",
      city: "",
    },
    validationSchema: Yup.object(rentACarInfoSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  useEffect(() => {
    formik.setValues(rentcarUserFormData);
  }, []);

  const handleLogoUrl = (url: any) => {
    formik.setFieldValue("logo", url);
  };
  const handleLicenseUrl = (url: any) => {
    formik.setFieldValue("licenseImage", url);
  };
  const handleCnicUrl = (url: any) => {
    formik.setFieldValue("cnicImage", url);
  };

  const handleSubmit = () => {
    dispatch(setRentCarUserFormData(formik.values));
    handleClickNext();
  };
  // const handleCnicExpiry = (date: any) => {
  //   formik.setFieldValue("cnicExpiry", date);
  // };

  const handleLicenseExpiry = (date: any) => {
    formik.setFieldValue("licenseExpiry", date);
  };

  const handleSetAddressData = (value: any) => {
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
          <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col8,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Company Name"
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />

                {formik.touched.name && formik.errors.name ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.name}
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker placeholder="Company Logo" setData={handleLogoUrl} />
                {formik.touched.logo && formik.errors.logo ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.logo}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Company License number "
                  id="licenseNumber"
                  name="licenseNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.licenseNumber}
                />
                {formik.touched.licenseNumber && formik.errors.licenseNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.licenseNumber}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <Datepicker
                  placeholder="License Expiry"
                  setData={handleLicenseExpiry}
                  value={dayjs(formik.values.licenseExpiry)}
                />

                {formik.touched.licenseExpiry && formik.errors.licenseExpiry ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.licenseExpiry}
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker
                  placeholder="License Image "
                  setData={handleLicenseUrl}
                />
                {formik.touched.licenseImage && formik.errors.licenseImage ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.licenseImage}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Owner First Name"
                  id="ownerFirstName"
                  name="ownerFirstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ownerFirstName}
                />
                {formik.touched.ownerFirstName &&
                formik.errors.ownerFirstName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.ownerFirstName}
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <CustomInput
                  placeholder="Owner Last Name"
                  id="ownerLastName"
                  name="ownerLastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ownerLastName}
                />
                {formik.touched.ownerLastName && formik.errors.ownerLastName ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.ownerLastName}
                  </div>
                ) : null}
              </div>
            </div>

            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="Company Emergency No. "
                  id="emergencyNumber"
                  name="emergencyNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.emergencyNumber}
                />

                {formik.touched.emergencyNumber &&
                formik.errors.emergencyNumber ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.emergencyNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={classNames(commonStyles.mb16, MainTravelStyle.flx)}>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput
                  placeholder="CNIC/Passport number (Optional)"
                  id="cnicNumber"
                  name="cnicNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.cnicNumber}
                />

                {/* {formik.touched.cnicNumber && formik.errors.cnicNumber ? ( */}
                  <div className={classNames(commonStyles.error)}>
                    {/* *{formik.errors.cnicNumber} */}
                  </div>
                {/* ) : null} */}
              </div>
            </div>
            {/* <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <CustomInput placeholder="CNIC/Passport number" />
              </div>
            </div> */}
            {/* <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                {" "}
                <Datepicker placeholder="CNIC/Passport Expiry" />
              </div>
            </div> */}

            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <Datepicker
                  placeholder="CNIC/Passport Expiry (Optional)"
                  //  setData={handleCnicExpiry}
                  // value={dayjs(formik.values.cnicExpiry)}
                />

                {/* {formik.touched.cnicExpiry && formik.errors.cnicExpiry ? ( */}
                  <div className={classNames(commonStyles.error)}>
                    {/* *{formik.errors.cnicExpiry} */}
                  </div>
                {/* ) : null} */}
              </div>
            </div>
            <div
              className={classNames(
                commonStyles.col4,
                commonStyles.mtsm28,
                commonStyles.colsm12
              )}
            >
              <div className={commonStyles.mr32}>
                <ImgPicker
                  placeholder="CNIC/Passport Image (optional)"
                  setData={handleCnicUrl}
                />
                {/* {formik.touched.cnicImage && formik.errors.cnicImage ? ( */}
                  <div className={classNames(commonStyles.error)}>
                    {/* *{formik.errors.cnicImage} */}
                  </div>
                {/* ) : null} */}
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "16px" }}
            className={classNames(commonStyles.mb16)}
          >
            <div className={classNames(commonStyles.col8, commonStyles.mtsm28)}>
              {" "}
              <div className={commonStyles.mr32}>
                <LocationInput
                  placeholder="Company Address"
                  setData={handleSetAddressData}
               
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.address}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(labMainStyles.buttonWidth)}>
          <PrimaryButton children={"Next"} type="submit" colorType={"Travel"} />
        </div>
      </form>
    </div>
  );
}
