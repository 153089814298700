import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./AddFlight.module.css";
import flightPlane from "../../../../assets/images/Illustration.png";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { addBidFlight } from "shared/services/TravelAgency";
import FlightForm from "shared/components/FlightForm";
import CustomLoader from "shared/components/New_Loader/Loader";
import ThankyouModel from "shared/components/ThankyouModel";
const initialValues: any = {
  companyName: "",
  departureDate: "",
  departureTime: "",
  arrivalDate: "",
  arrivalTime: "",
  amenities: [],
  from: "",
  to: "",
  flightNo: "",
  noOfHandbag: "",
  baggageWeight: "",
};

const validationSchema = Yup.object({
  companyName: Yup.string().required("CompanyName is required"),
  companyLogo: Yup.string().required("companyLogo is required"),
  from: Yup.string().required("From is required"),
  to: Yup.string().required("to is required"),
  departureDate: Yup.string().required("departureDate is required"),
  departureTime: Yup.string().required("departureTime is required"),
  arrivalDate: Yup.string().required("ArrivalDate is required"),
  arrivalTime: Yup.string().required("ArrivalTime is required"),
  flightNo: Yup.string().required("FlightNo is required"),
  noOfHandbag: Yup.string().required("noOfHandbag is required"),
  baggageWeight: Yup.string().required("Baggage is required"),
  amenities: Yup.array().min(1, "Select at least one amenities"),
});
interface FormValues {
  departureTime: string;
  arrivalTime: string;
}
const AddFlight: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [flightTypeSelected, setFlightTypeSelected] = useState<string>("");

  const [formVisible, setFormVisible] = useState(false);
  const [returnForm, setReturnForm] = useState(false);
  const [flights, setFlights] = useState<FormValues[]>([]);
  const [flightsReturn, setFlightsReturn] = useState<FormValues[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [returnIndex, setReturnIndex] = useState<number | null>(null);
  const [isReturn, SetIsReturn] = useState<string>("");
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Visible, setVisible] = useState(false);
  const [returnEditMode, setReturnEditMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const val = state?.data;
  const formatTime = (time: string): string => {
    return new Date(time).toISOString();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      const formattedValues = {
        ...values,
        departureTime: formatTime(values.departureTime),
        arrivalTime: formatTime(values.arrivalTime),
      };

      if (editMode) {
        const updatedFlights: any = flights.map((flight: any, index: any) =>
          index === currentIndex ? formattedValues : flight
        );
        setFlights(updatedFlights);
        setEditMode(false);
      } else {
        setFlights([...flights, formattedValues]);
      }
      setFormVisible(false);
      resetForm();
    },
  });
  const form = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      const formattedValues = {
        ...values,
        departureTime: formatTime(values.departureTime),
        arrivalTime: formatTime(values.arrivalTime),
      };

      if (returnEditMode) {
        const updatedFlights = flightsReturn.map((flight: any, index: any) =>
          index === returnIndex ? formattedValues : flight
        );
        setFlightsReturn(updatedFlights);
        setReturnEditMode(false);
      } else {
        setFlightsReturn([...flightsReturn, formattedValues]);
      }
      setReturnForm(false);
      resetForm();
    },
  });
  const handleSave = () => {
    if (!formik?.isValid && formik?.submitCount > 0) {
      let values: any = Object.values(formik?.errors)[0];
      toast.success(values);
    } else {
      formik.handleSubmit();
    }
  };

  const handleFlightTypeSelection = (type: string) => {
    setFlightTypeSelected(type);
    setFormVisible(true);
  };

  const handleFlightType = (type: string) => {
    SetIsReturn(type);
    setReturnForm(true);
  };

  const handleEditFlight = (index: number) => {
    const flightToEdit = flights[index];
    formik.setValues(flightToEdit);
    // console.log(flightToEdit, '.........flightToEdit');
    setCurrentIndex(index);
    setEditMode(true);
    setFormVisible(true);
  };
  const handleDeleteFlight = () => {
    const updatedFlights = flights.filter(
      (_: any, i: any) => i !== currentIndex
    );
    setFlights(updatedFlights);
    // console.log(updatedFlights, '.......updatedFlights');
    setModalVisible(false);
    if (updatedFlights.length === 0) {
      setFlightTypeSelected("");
      formik.resetForm();
    }
  };
  const handleDeleteReturn = () => {
    const updatedFlights = flightsReturn.filter(
      (_: any, i: any) => i !== returnIndex
    );
    setFlightsReturn(updatedFlights);
    setModalVisible(false);
    if (updatedFlights.length === 0) {
      SetIsReturn("");
    }
  };
  const polForm = useFormik({
    initialValues: {
      cancelationDuration: "",
      cancelationDeduction: "",
      TicketPrice: "",
    },
    validationSchema: Yup.object({
      cancelationDuration: Yup.string().required(
        "cancelation Duration is required"
      ),
      cancelationDeduction: Yup.string().required(
        "cancelation Deduction is required"
      ),
      TicketPrice: Yup.string().required("Ticket Price is required"),
    }),
    onSubmit: (values) => {
      addBid(values);
    },
  });
  const addBid = (values: any) => {
    setLoading(true);
    let params: any = {
      requestId: val?._id,
      // flightType: flightTypeSelected.toLowerCase(),
      flightDetails: [...flights],
      flightPolicies: {
        cancelationDuration: values?.cancelationDuration,
        cancelationDeduction: values?.cancelationDeduction,
      },
      ticketPrice: parseInt(values?.TicketPrice, 10),
    };
    if (val?.requestType === "round") {
      params = {
        ...params,
        returnFlights: [...flightsReturn],
      };
    } else {
      params = {
        ...params,
      };
    }
    console.log(params, ".........params");
    addBidFlight(params)
      .then(() => {
        // showToast('Success', 'Flight Bid successfully', true);
        setVisible(true);
        toast.success("Success");
        setTimeout(() => {
          setVisible(false);
          navigate('/travelAgency/ticketRequests');
        }, 3000);
      })
      .catch((err) => {
        // showToast(err, 'error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const addForm = () => {
    formik.resetForm();
    setFormVisible(true);
  };
  const addFormReturn = () => {
    form.resetForm();
    setReturnForm(true);
  };
  const onPress = () => {
    if (!polForm.isValid && polForm.submitCount > 0) {
      const firstError = Object.values(polForm.errors)[0];
      toast.error(firstError);
    } else if (val?.requestType === "round" && flightsReturn.length === 0) {
      toast.error('Please add a return flight before sharing the bid');
    } else {
      polForm.handleSubmit();
    }
  };
  const handleEditReturn = (index: number) => {
    const flightToEdit = flightsReturn[index];
    form.setValues(flightToEdit);
    setReturnIndex(index);
    setReturnEditMode(true);
    setReturnForm(true);
  };
  const handleSaveReturn = () => {
    if (!form.isValid && form.submitCount > 0) {
      const value: any = Object.values(form.errors)[0];
      toast.error(value);
    } else {
      form.handleSubmit();
    }
  };
  return (
    <div className={styles.containerFlight}>
      {!flightTypeSelected && (
        <>
          <img src={flightPlane} alt="Flight" className={styles.image} />
          <button
            className={styles.addButton}
            onClick={() => setFormVisible(true)}
          >
            Add Flight
          </button>
          {formVisible && (
            <div className={styles.buttonContainer}>
              <button
                className={`${styles.optionButton}`}
                onClick={() => handleFlightTypeSelection("Direct")}
              >
                Direct
              </button>
              <button
                className={`${styles.optionButton}`}
                onClick={() => handleFlightTypeSelection("Stay")}
              >
                Stay
              </button>
            </div>
          )}
        </>
      )}
      {flightTypeSelected && (
        <FlightForm
          flightType={flightTypeSelected}
          formik={formik}
          handleSubmit={handleSave}
          flights={flights}
          handleEditFlight={handleEditFlight}
          formVisible={formVisible}
          flightsReturn={flightsReturn}
          addForm={addForm}
          handleDeleteFlight={handleDeleteFlight}
          setCurrentIndex={setCurrentIndex}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          setLoading={setLoading}
        />
      )}
      {flights.length > 0 &&
        val?.requestType === "round" &&
        !isReturn &&
        !formVisible &&
        !editMode && (
          <>
            <label className={styles.returnFlight}>Return Flight:</label>
            <div style={{ display: "flex", alignSelf: "center" }}>
              <img src={flightPlane} alt="Flight" className={styles.image} />
            </div>
            <button
              className={styles.addButton}
              onClick={() => setReturnForm(true)}
            >
              Add Return Flight
            </button>

            {returnForm && (
              <div className={styles.buttonContainer}>
                <button
                  className={`${styles.optionButton}`}
                  onClick={() => handleFlightType("Direct")}
                >
                  Direct
                </button>
                <button
                  className={`${styles.optionButton}`}
                  onClick={() => handleFlightType("Stay")}
                >
                  Stay
                </button>
              </div>
            )}
          </>
        )}
      {isReturn && (
        <FlightForm
          flightType={isReturn}
          formik={form}
          handleSubmit={handleSaveReturn}
          flights={flightsReturn}
          handleEditFlight={handleEditReturn}
          formVisible={returnForm}
          addForm={addFormReturn}
          handleDeleteFlight={handleDeleteReturn}
          showText
          modalVisible={modalVisible}
          setCurrentIndex={setReturnIndex}
          setModalVisible={setModalVisible}
          setLoading={setLoading}
          returnEditMode={returnEditMode}
        />
      )}
      {flights.length > 0 && !editMode && !formVisible && !returnForm && (
        <>
          <label className={styles.returnFlight}>Flight Policies:</label>
          <div className={styles.cancellationInputContainer}>
            <input
              type="text"
              placeholder="Cancellation Duration *"
              value={polForm.values.cancelationDuration}
              onChange={(e: any) =>
                polForm.setFieldValue("cancelationDuration", e.target.value)
              }
              className={styles.cancellationInput}
            />
            <input
              type="text"
              placeholder="Cancellation Percentage *"
              value={polForm.values.cancelationDeduction}
              onChange={(e: any) =>
                polForm.setFieldValue("cancelationDeduction", e.target.value)
              }
              className={styles.cancellationInput}
            />
            <input
              type="text"
              placeholder="Total Price *"
              value={polForm.values.TicketPrice}
              onChange={(e: any) =>
                polForm.setFieldValue("TicketPrice", e.target.value)
              }
              className={styles.cancellationInput}
            />
            <div className={styles.buttonContainer} onClick={onPress}>
              <button className={styles.addBidButton}>Share Bid</button>
            </div>
          </div>
        </>
      )}
      {Visible && (
        <ThankyouModel
          mainMessageLine1="Your Bid has been successful"
          mainMessageLine2="Thank you"
        />
      )}
      {loading && <CustomLoader />}
    </div>
  );
};

export default AddFlight;
