import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";

const AdminTable = (props: any) => {
  const { titles, data, handleGoToDetail, headerWidth, itemWidth, booking, activeTab } =
    props;
  console.log(data, '......data')
  return (
    <div className={styles.payment}>
      <div className={classNames(styles.headerOuter, commonStyles.bold)}>
        {titles?.map((val: any, index: any) => (
          <p
            key={index}
            className={styles.headerclass}
            style={{ width: headerWidth }}
          >
            {val?.title}
          </p>
        ))}
      </div>

      <div className={styles.tableData}>
        <table
          style={{
            margin: "0px",
            borderCollapse: "separate",
            borderSpacing: "0 4px",
          }}
        >
          <tbody className={styles.wapper}>
            {data?.map((rowData: any, rowIndex: any) => {
              console.log(rowData, '.........rowData')
              return (
                <tr
                  key={rowIndex}
                  className={styles.row}
                  onClick={() =>
                    booking ? handleGoToDetail(rowData) : handleGoToDetail(rowIndex)

                  }
                >
                  {booking ? (
                    <>
                      <td className={styles.w20} style={{ width: itemWidth }}>
                        {rowData?.userId?.mrNo || "N/A"}
                      </td>
                      <td className={styles.w20} style={{ width: itemWidth }}>
                        {rowData?.userId?.name || "N/A"}
                      </td>
                      {activeTab == "Flight" ? (
                        <td className={styles.w20} style={{ width: itemWidth }}>
                          {rowData?.bookingId || "N/A"}
                        </td>
                      ) : (
                        <td className={styles.w20} style={{ width: itemWidth }}>
                          {rowData?.tourId?.packageName || "N/A"}
                        </td>
                      )}
                      {activeTab == "Flight" ? (
                        <td className={styles.w20} style={{ width: itemWidth }}>
                          {rowData?.bidRequestId?.flightType || "N/A"}
                        </td>
                      ) : (
                        <td className={styles.w20} style={{ width: itemWidth }}>
                          {rowData?.isPaidFull === false ? 'Reserved' : 'Booked'}
                        </td>
                      )}
                      {activeTab == "Tour" && (
                        <td className={styles.w20} style={{ width: itemWidth }}>
                          {rowData?.tourId?.bookedSeats || "N/A"}
                        </td>
                      )}
                    </>
                  ) : // Render content if `booking` is false and `rowData` is an array
                    Array.isArray(rowData) ? (
                      rowData.map((cellData: any, cellIndex: any) => (
                        <td
                          key={cellIndex}
                          className={styles.w20}
                          style={{ width: itemWidth }}
                        >
                          {cellData}
                        </td>
                      ))
                    ) : (
                      <td className={styles.w20} style={{ width: itemWidth }}>
                        {rowData || "N/A"}
                      </td>
                    )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminTable;

// Orders
// <p
//                         className={classNames(Styles.statusComp, {
//                           [Styles.statusProcessingText]:
//                             val.status === "inProcess",
//                           [Styles.statusPendingText]: val.status === "pending",
//                         })}
//                       >
//                         {val.status}
//                       </p>
