import React, { useState } from "react";
import styles from "./SocialInfo.module.css";

interface Props {
  formik?: any;
  password?: any;
  setPassword?: any;
  confirmPassword?: any;
  setConfirmPassword?: any;
}

const Password = (props: Props) => {
  const { formik, password, confirmPassword, setPassword, setConfirmPassword } =
    props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.socialInfoContainer}>
        <div className={styles.rowSocial}>
          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password *"
            />
            <div
              className={styles.showIcon}
              onClick={togglePasswordVisibility}
            ></div>
          </div>

          <div className={styles.inputGroupSocial}>
            <input
              style={{ color: "#000" }}
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password *"
            />
            <div
              className={styles.showIcon}
              onClick={toggleConfirmPasswordVisibility}
            ></div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.continueButton} type="submit">
            Continue
          </button>
        </div>
      </div>
    </form>
  );
};

export default Password;
