import React, { useEffect, useRef, useState } from "react";
import styles from "./MyProfileNavbar.module.css";
import Footerr from "../Footer";
import MainHeader from "shared/components/MainScreen/Index";
import { FaChevronRight } from "react-icons/fa";
import { useFormik } from "formik";
import Styles from "./MyProfileNavbar.module.css";
import DatePicker from "react-datepicker";
import calendarIcon from "../../../../assets/images/Calender.png";
import { useNavigate } from "react-router-dom";
import { add_File, blockUser, updateProfile } from "shared/services";
import { useDispatch, useSelector } from "react-redux";
import { LoadingModal } from "shared/components";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import toast from "react-hot-toast";
import { setIsLoggedIn, set_User } from "shared/redux";
import { FiArrowRight } from "react-icons/fi";
import image1 from "../../../../assets/images/ProfileDownload.png";
import ProfileModal from "./ProfileModel";
import ProfileDataBlock from "shared/components/ProfileDataBlock";
import { bankInfo, socialInfo, userInfo } from "shared/utils";
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import LocationInput from "shared/components/LocationInput";
import { finalValue, initialValue } from "./UpdateProfileQuery";
import PhoneNumberInputNew from "pages/Home/Userlogin/SignUplogin/PhoneNumberInputNew";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PROFILE } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
import PasswordInput from "shared/components/PasswordInput";

const MyProfileNavbar: React.FC = () => {
  const { user } = useSelector((state: any) => state?.root?.common);
  const [profileImage, setProfileImage] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<
    "basic" | "social" | "bank" | "changePassword"
  >("basic");

  const [url, setUrl] = useState<any>("");
  const datePickerRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSaveClick = (values: any) => {
    if (formType === "changePassword" && (!values.currentPassword || !values.newPassword || !values.confirmPassword)) {
      toast?.error('Please fill all fields');
      return;
    }
    if (values?.newPassword !== values?.confirmPassword) {
      toast?.error("Passwords do not match");
      return;
    }
    const { countryCode, ...otherValues } = values;
    const hasOtherValue = Object.values(otherValues).some((value) => value);
    if (!hasOtherValue) {
      toast.error("Please enter at least one field");
      return;
    }
    setLoading(true);
    let params: any = finalValue(values, url);
    updateProfile(params)
      .then((res: any) => {
        setModalOpen(false);
        toast?.success(res?.data?.message);
        dispatch(set_User(res?.data?.user));
      })
      .catch((err: any) => {
        toast?.error(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const formik: any = useFormik({
    initialValues: initialValue(user),

    onSubmit: (values) => {
      handleSaveClick(values);
    },
  });

  useEffect(() => {
    finalValue();
  }, [isModalOpen]);

  const deleteUser = () => {
    setLoading(true);
    let data = {
      vendorType: "Users",
      vendorId: user?._id,
      blocked: true,
    };
    blockUser(data)
      .then(async (res: any) => {
        console.log(res?.data);
        await dispatch(set_User(null));
        await dispatch(setIsLoggedIn(false));
        navigate("/");
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (e: any) => {
    setLoading(true);
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setProfileImage(reader.result as string);

        try {
          const formData = new FormData();
          if (file) {
            formData.append("file", file);
          }
          const response = await add_File(formData);
          setUrl(response?.data?.fileUrl);
          let params = {
            userImage: response?.data?.fileUrl,
          };
          console.log("🚀 ~ params ~ file:", params);

          updateProfile(params)
            .then((res: any) => {
              toast?.success("Profile Updated Successfully");
              dispatch(
                set_User({
                  ...user,
                  userImage: response?.data?.fileUrl,
                })
              );
            })
            .catch((err: any) => {
              console.log("🚀 ~ reader.onloadend= ~ err:", err);
            });
          setLoading(false);
        } catch (error) {
          console.error("Upload error:", error);
        } finally {
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLocationChange = async (newLocation: any) => {
    const labelParts: any = newLocation?.label.split(", ");
    const country = labelParts[labelParts.length - 1];
    formik?.setFieldValue("address", newLocation?.label);
    formik?.setFieldValue("city", newLocation?.city);
    formik?.setFieldValue("country", country);
    const address = newLocation?.label;
    const apiKey = "AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A"; // Replace with your actual API key
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        formik?.setFieldValue("lat", location.lat);
        formik?.setFieldValue("long", location.lng);
      } else {
        console.error("Geocoding error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocoding data: ", error);
    }
  };

  useEffect(() => {
    if (isModalOpen) {

      formik.resetForm({ values: initialValue(user) });
    }
  }, [isModalOpen, user,]);


  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.handleSubmit();
    }
  };
  return (
    <div>
      <NavBreadCrumbs {...PROFILE} />

      <div className={styles.profilePage}>
        {/* Centered Profile Image */}
        <div className={styles.profilePage__imageWrapper}>
          <img
            src={
              user?.userImage ||
              profileImage ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
            }
            alt={user?.name}
            className={styles.profilePage__image}
          />
          <div className={styles.profilePage__smallImageWrapper}>
            <img
              src={image1}
              alt="Edit"
              className={styles.profilePage__editIcon}
              onClick={() => document.getElementById("fileInput")?.click()}
            />
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </div>

        <ProfileDataBlock
          data={userInfo(user)}
          heading={"Basic Info"}
          setModalOpen={setModalOpen}
          setFormType={() => setFormType("basic")}
        />

        <ProfileDataBlock
          data={socialInfo(user)}
          heading={"Social Media"}
          setModalOpen={setModalOpen}
          setFormType={() => setFormType("social")}
        />

        <ProfileDataBlock
          data={bankInfo(user)}
          heading={"Bank Details"}
          setModalOpen={setModalOpen}
          setFormType={() => setFormType("bank")}
        />

        <div className={styles.profilePage__socialMediaContainer}>
          <div className={styles.profilePage__socialMediaHeader}>
            <h2 className={styles.profilePage__socialMediaHeading}>
              Change Password
            </h2>
            <button
              className={styles.profilePage__editButton}
              onClick={() => {
                setModalOpen(true);
                setFormType("changePassword");
              }}
            >
              <FiArrowRight size={20} />
            </button>
          </div>
        </div>
      </div>

      <ProfileModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <form
          className={Styles.basicInfoContainer}
          onSubmit={formik.handleSubmit}
        >
          {/* Conditional Rendering Based on Form Type */}
          {formType === "basic" && (
            <>
              <h2 className={Styles.sectionTitle}>Basic Info</h2>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"fullName"}
                  formik={formik}
                  placeHolder={"Full Name *"}
                />
                <FormikField
                  id={"fatherName"}
                  formik={formik}
                  placeHolder={"fatherName *"}
                />
              </div>

              <div className={Styles.rowBasic}>
                <div className={Styles.inputGroupBasic}>
                  <select
                    style={{ color: "#000" }}
                    id="gender"
                    className={Styles.wideSelect}
                    value={formik.values.gender}
                    onChange={formik.handleChange("gender")}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" disabled>
                      Select Gender *
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="confidential">Confidential</option>
                  </select>
                </div>

                <div className={Styles.inputGroupBasic}>
                  <DatePicker
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    showMonthDropdown
                    scrollableMonthYearDropdown
                    id="dob"
                    selected={formik?.values?.dob}
                    onChange={(date) => formik.setFieldValue("dob", date)}
                    onBlur={formik.handleBlur}
                    placeholderText="Date of Birth *"
                    dateFormat="dd/MM/yyyy"
                    className={Styles.dateInput}
                    customInput={
                      <input
                        style={{ color: "#000" }}
                        placeholder="Date of Birth "
                      />
                    }
                    ref={datePickerRef}
                  />
                  <img
                    src={calendarIcon}
                    alt="Calendar"
                    className={Styles.calendarIcon}
                    onClick={() => {
                      if (datePickerRef.current) {
                        datePickerRef.current.setOpen(true);
                      }
                    }}
                  />
                </div>
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  editable={true}
                  id={"email"}
                  formik={formik}
                  placeHolder={"Email"}
                />

                <div className={Styles.inputGroupBasic}>
                  <div className={Styles.phoneInputBasic}>
                    <PhoneNumberInputNew
                      value={formik.values.phoneNumber}
                      setValue={(newValue: string) => {
                        formik.setFieldValue("phoneNumber", newValue);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  id={"passport"}
                  formik={formik}
                  placeHolder={"NIC/Passport *"}
                />
                <FormikField
                  id={"bloodGroup"}
                  formik={formik}
                  placeHolder={"Blood Group *"}
                />
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  id={"qualification"}
                  formik={formik}
                  placeHolder={"Qualification *"}
                />

                <FormikField
                  id={"childrenNumber"}
                  formik={formik}
                  placeHolder={"Number of Children"}
                />
              </div>
              <LocationInput
                placeholder={"Address *"}
                type={"box"}
                setData={handleLocationChange}
                defaultValue={{
                  label:
                    formik?.values?.address || user?.address?.address || "",
                  value: { place_id: null },
                }} // Or similar structure
              />
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"city"}
                  formik={formik}
                  placeHolder={"City *"}
                />
                <FormikField
                  id={"country"}
                  formik={formik}
                  placeHolder={"Country *"}
                />
              </div>
            </>
          )}

          {formType === "social" && (
            <>
              <h2 className={Styles.sectionTitle}>Social Details</h2>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"facebook"}
                  formik={formik}
                  placeHolder={"Facebook Profile URL (Optional)"}
                />

                <FormikField
                  id={"instagram"}
                  formik={formik}
                  placeHolder={"Instagram Profile URL (Optional)"}
                />
              </div>

              <div className={Styles.rowBasic}>
                <FormikField
                  id={"linkedin"}
                  formik={formik}
                  placeHolder={"LinkedIn Profile URL (Optional)"}
                />

                <FormikField
                  id={"youtube"}
                  formik={formik}
                  placeHolder={"Youtube Profile URL (Optional)"}
                />
              </div>
            </>
          )}

          {formType === "bank" && (
            <>
              <h2 className={Styles.sectionTitle}>Bank Details</h2>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"bankName"}
                  formik={formik}
                  placeHolder={"Bank Name (Optional)"}
                />
                <FormikField
                  id={"accountNumber"}
                  formik={formik}
                  placeHolder={"IBAN / ACC Number (Optional)"}
                />
              </div>
              <div className={Styles.rowBasic}>
                <FormikField
                  id={"accountTitle"}
                  formik={formik}
                  placeHolder={"Account Title (Optional)"}
                />
                <FormikField
                  id={"ntn"}
                  formik={formik}
                  placeHolder={"NTN (Optional)"}
                />
              </div>
            </>
          )}
          {formType === "changePassword" && (
            <>
              <h2 className={Styles.sectionTitle}>Update Password</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >

                <FormikField
                  id={"currentPassword"}
                  formik={formik}
                  placeHolder={"Current Password *"}
                  type="password"
                  onKeyPress={handleKeyPress}

                />

                <FormikField
                  id={"newPassword"}
                  formik={formik}
                  placeHolder={"New Password *"}
                  type="password"
                />
                <FormikField
                  id={"confirmPassword"}
                  formik={formik}
                  placeHolder={"Confirm Password *"}
                  type="password"
                />
              </div>
            </>
          )}

          <button type="submit" className={Styles.submitButton}>
            Save <FaChevronRight style={{ marginLeft: "8px" }} />
          </button>
        </form>
      </ProfileModal>
      {loading && <CustomLoader />}
      <Footerr />
    </div>
  );
};

export default MyProfileNavbar;

const FormikField = ({
  id,
  placeHolder,
  formik,
  editable,
  type = "text",
  onKeyPress,
}: {
  id: any;
  placeHolder: any;
  formik: any;
  editable?: any;
  type?: string;
  onKeyPress?: any;
}) => {
  let value = formik?.values;
  let touched = formik?.touched;
  let error = formik.errors;
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={Styles.inputGroupBasic}>
      <input
        disabled={editable}
        style={{ color: "#000" }}
        type={type}
        id={id}
        onKeyDown={onKeyPress}
        value={value[id]}
        onChange={formik?.handleChange(id)}
        // onBlur={formik.handleBlur}
        placeholder={placeHolder}


      />
      {/* <InputAdornment position="end">
        <IconButton onClick={handleTogglePassword} edge="end">
          {showPassword ? (
            <BsEyeFill className={styles.eyeIcon} />
          ) : (
            <BsEyeSlashFill className={styles.eyeIcon} />
          )}
        </IconButton>
      </InputAdornment> */}
      {/* {touched[id] && error[id] && (
        <div className={Styles.error}>{error[id]}</div>
      )} */}
    </div>
  );
};
