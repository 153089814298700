import React, { useEffect, useMemo, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./Detail.module.css";
import { IoMdArrowForward } from "react-icons/io";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import moment from "moment";
import Checkout from "shared/services/stripe/checkout";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentParams } from "shared/redux";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { TOUR_DETAIL } from "shared/utils/mainHeaderQuery";

const TravelDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState<any>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isLoggedIn } = useSelector((state: any) => state?.root?.common);
  let item = state.item;
  let type = state.type;
  const withBooking = item?.tourId;
  console.log(type, ".........withBooking");

  const dispatch = useDispatch();
  console.log(item?.processingFee, "..............");
  const handleBooking = () => {
    if (isLoggedIn) {
      if (type == "TourBooking" && item?.isPaidFull == false) {
        if (item?.gatewayName == "blinq") {
          // await dispatch(setAmount(pendingPayment));
          // navigate('BlinqPayment', {
          //   type: 'Remaining_TourBooking',
          //   bookingID: item?._id,
          // });
        } else {
          setTimeout(() => {
            dispatch(
              setPaymentParams({
                bookingID: item?._id,
                paidByUserAmount: total,
                processingFee: item?.processingFee,
              })
            );
            setOpen(true);
          }, 2000);
        }
      } else if (item?.remainingSeats === 0) {
        alert("We're Sorry. No seats available");
      } else {
        navigate("/services/travel/TravelBooking", { state: { item: item } });
      }
    } else {
      navigate("/user/login");
    }
  };
  let images = type == "TourBooking" ? withBooking?.images : item?.images;
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === (images?.length || 0) - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images]);
  const formattedDateTime = useMemo(
    () => moment(item?.returnDate).format("DD MMM YYYY"),
    [item?.returnDate]
  );
  const format = useMemo(
    () =>
      moment(item?.departDate || withBooking?.departDate).format("DD MMM YYYY"),
    [item?.departDate, withBooking?.departDate]
  );
  const travelDetails = [
    {
      heading: "From",
      detail: item?.from,
    },
    {
      heading: "To",
      detail: item?.to,
    },
    {
      heading: "Departure",
      detail: [format, " ", item?.departTime || withBooking?.departTime],
    },
    {
      heading: "Return",
      detail: [
        formattedDateTime,
        " ",
        item?.destinationTime || withBooking?.destinationTime,
      ],
    },
    {
      heading: "Duration",
      detail: item?.packageDuration || withBooking?.packageDuration,
    },
    {
      heading: "Breakfast",
      detail: item?.breakfastQuantity || withBooking?.breakfastQuantity,
    },
    {
      heading: "Lunch",
      detail: item?.lunchQuantity || withBooking?.lunchQuantity,
    },
    {
      heading: "Dinner",
      detail: item?.dinnerQuantity || withBooking?.dinnerQuantity,
    },
  ];
  const travelmeals = [
    {
      heading: "Day by day",
      detail: item?.dayByDayPlans,
    },
  ];
  let MinusProcessingFee = item?.paidByUserAmount - item?.processingFee;
  let calculatePayment = item?.actualPrice - MinusProcessingFee;
  let pendingPayment =
    item?.gatewayName === "blinq"
      ? calculatePayment.toFixed(2)
      : calculatePayment;

  let totalAmount_withFee = item?.processingFee + pendingPayment;

  let total =
    item?.gatewayName === "blinq" ? pendingPayment : totalAmount_withFee;
  const paymentDetails = [
    {
      label: "Total Amount",
      amount: item?.actualPrice,
      color: "rgba(0, 104, 56, 1)",
    },
    {
      label: "Partial Amount",
      amount: MinusProcessingFee,
      color: "rgba(0, 104, 56, 1)",
    },

    {
      label: "Remaining Amount ",
      amount: pendingPayment,
      color: "rgba(234, 2, 52, 1)",
    },
    {
      ...(item?.gatewayName === "stripe" && {
        label: "Processing Fee",
        amount: item?.processingFee,
        color: "rgba(234, 2, 52, 1)",
        dottedLine: true,
      }),
    },
    {
      ...(item?.gatewayName === "stripe" && {
        label: "Total Amount",
        amount: total,
        color: "rgba(234, 2, 52, 1)",
      }),
    },
    {
      label: "Due Date",
      amount: moment(item?.tourId?.departDate).format("MM/DD/YYYY"),
      color: "rgba(234, 2, 52, 1)",
    },
  ];

  return (
    <div>
      <NavBreadCrumbs {...TOUR_DETAIL} />

      {open ? (
        <Checkout
          serviceName={"TourRemaing"}
          convertedAmount={pendingPayment}
        />
      ) : (
        <div
          className={classNames(commonstyle.container)}
          style={{ marginBottom: "32px" }}
        >
          <p
            className={classNames(
              commonstyle.colorBlue,
              commonstyle.fs24,
              commonstyle.semiBold
            )}
          >
            {item?.packageName || withBooking?.packageName}
          </p>
          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxBetween,
              commonstyle.flxWrap
            )}
          >
            <div className={classNames(commonstyle.col6, commonstyle.colsm12)}>
              <div
                className={classNames(
                  commonstyle.flx,
                  commonstyle.flxBetween,
                  commonstyle.flxWrap
                )}
              >
                <p
                  className={classNames(
                    commonstyle.colorGreen,
                    commonstyle.fs16,
                    commonstyle.semiBold
                  )}
                >
                  {`${item?.limitedSeats || withBooking?.limitedSeats} Seats -${
                    item?.className || withBooking?.className
                  }`}
                </p>
                <p
                  className={classNames(
                    commonstyle.colorGreen,
                    commonstyle.fs16,
                    commonstyle.semiBold
                  )}
                >
                  Remain {`${item?.remainingSeats}`}
                </p>
              </div>
              <div>
                <div className={classNames(style.imgcontainer)}>
                  <img
                    src={
                      images?.[currentIndex] ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                    }
                    alt="Slider"
                    className={classNames(style.mainimg)}
                  />
                  <div className={style.dotsContainer}>
                    {images?.map((_: any, index: number) => (
                      <span
                        key={index}
                        className={classNames(style.dot, {
                          [style.activeDot]: index === currentIndex,
                        })}
                      ></span>
                    ))}
                  </div>
                </div>
              </div>
              <div className={classNames()} style={{ marginTop: "10px" }}>
                <p
                  className={classNames(
                    commonstyle.fs18,
                    commonstyle.colorBlue,
                    commonstyle.semiBold
                  )}
                >
                  Travel Summary
                </p>
              </div>
            </div>
            <div className={classNames(commonstyle.col4, commonstyle.colsm12)}>
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.fs24,
                  commonstyle.semiBold
                )}
              >
                Price
              </p>
              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorGreen,
                      commonstyle.fs16,
                      commonstyle.semiBold
                    )}
                  >
                    Per Couple -{" "}
                    {item?.pricePerCouple || withBooking?.pricePerCouple}/-
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorGreen,
                      commonstyle.fs16,
                      commonstyle.semiBold
                    )}
                  >
                    Per Head - {item?.pricePerHead || withBooking?.pricePerHead}
                    /-
                  </p>
                </div>
                <button
                  className={
                    item?.isPaidFull == true
                      ? style.paymentcomplted
                      : style.showMoreButton
                  }
                  onClick={handleBooking}
                  disabled={item?.isPaidFull == true ? true : false}
                >
                  {item?.isPaidFull == false
                    ? "PAYMENT"
                    : item?.isPaidFull == true
                    ? "Payment Completed"
                    : "Tour Details"}

                  {item?.isPaidFull == true ? null : (
                    <span className={style.icon}>
                      <IoMdArrowForward />
                    </span>
                  )}
                </button>

                {/* if not ..... */}

                {/* <div
                className="  "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#13A89E",
                    padding: "10px 15px",
                    color: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  {item?.isPaidFull == false
                    ? "PAYMENT"
                    : item?.isPaidFull == true
                    ? "Payment Completed"
                    : "Tour Details"}
                </div>
              </div> */}
              </div>
            </div>
          </div>

          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxBetween,
              commonstyle.flxWrap
            )}
          >
            {travelDetails.map((item, index) => (
              <div key={index}>
                <p
                  className={classNames(
                    commonstyle.colorBlue,
                    commonstyle.fs16,
                    commonstyle.semiBold
                  )}
                >
                  {item.heading}
                </p>
                <p
                  className={classNames(
                    commonstyle.colorBlue,
                    commonstyle.fs16
                  )}
                >
                  {item.detail}
                </p>
              </div>
            ))}
          </div>

          <div className={classNames(commonstyle.col4, commonstyle.colsm12)}>
            <p
              className={classNames(
                commonstyle.fs16,
                commonstyle.colorBlue,
                commonstyle.semiBold
              )}
            >
              Plans
            </p>
            <div
              className={classNames(
                commonstyle.flx,
                commonstyle.flxBetween,
                commonstyle.flxWrap
              )}
            >
              {travelmeals.map((item, index) => (
                <div key={index}>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs16,
                      commonstyle.semiBold
                    )}
                  >
                    {item.heading}
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs16
                    )}
                  >
                    {item.detail}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={classNames()} style={{ marginTop: "16px" }}>
            <p
              className={classNames(
                commonstyle.fs16,
                commonstyle.colorBlue,
                commonstyle.semiBold
              )}
            >
              Policies
            </p>

            <p className={classNames(commonstyle.fs16, commonstyle.colorBlue)}>
              {item?.recentTourPolicy || withBooking?.recentTourPolicy}
            </p>
          </div>
          {type == "TourBooking" && item?.isPaidFull == false && (
            <RemainPaymentSection
              paymentDetails={paymentDetails}
              paymentStyles={style}
            />
          )}
        </div>
      )}
      <div style={{ marginTop: "50px" }}></div>
      <Footerr />
    </div>
  );
};
const RemainPaymentSection = ({
  paymentDetails,
  paymentStyles,
}: {
  paymentDetails?: any;
  paymentStyles?: any;
}) => {
  return (
    <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
      <div className={paymentStyles["dashed-line"]} />
      <p className={paymentStyles["payment-title"]}>Payment</p>
      {paymentDetails?.map((item?: any, index?: any) => (
        <React.Fragment key={index}>
          <div
            className={paymentStyles["row-payment-styles"]}
            style={{ marginTop: index === 0 ? "8px" : item?.marginTop }}
          >
            <p
              style={{ color: item?.color }}
              className={paymentStyles["payment-label"]}
            >
              {item?.label}
            </p>
            <p
              style={{ color: item?.color }}
              className={paymentStyles["payment-amount"]}
            >
              {item?.amount}
            </p>
          </div>
          {item?.dottedLine && <div className={paymentStyles["dotted-line"]} />}
        </React.Fragment>
      ))}
    </div>
  );
};
export default TravelDetail;
