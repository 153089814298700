import classNames from "classnames";
import cardStyle from "./highlightCard.module.css";

const ServiceHighlightCard = ({
  data,
  onPress,
}: {
  onPress?: any;
  data?: any;
}) => {
  const emptyData = [
    {
      title: "Title ",
      content: "content.........",
      color: "#FF8A8A",
      icon: "◻️",
    },
  ];
  const Data = data ? data : emptyData;

  return (
    <>
      <div className={cardStyle.cardContainer}>
        {Data.map((card: any, index: any) => (
          <div
            onClick={() => onPress(card?.title)}
            className={classNames(cardStyle.card)}
            style={{
              border: `2px solid ${card?.color}`,
            }}
          >
            <div className={cardStyle.icon}>{card.icon}</div>
            <h3>{card.title}</h3>
            <p>{card.content}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceHighlightCard;
