import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import style from "./PhyAvailability.module.css";
import commonStyles from "shared/utils/common.module.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CustomInput,
  CustomModal,
  DeleteModal,
  RingLoader,
} from "shared/components";
import { PrimaryButton } from "shared/components";
import commomstyles from "shared/utils/common.module.css";
import { Typography } from "@mui/material";
import classNames from "classnames";
import CustomSelect from "shared/components/CustomSelect";
import { useSelector } from "react-redux";
import SearchBar from "shared/components/Searchbar";
import { doctor_Availability } from "shared/utils";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import { TbRefresh } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import {
  DoctorAddAvailabilityPrice,
  DoctorAddOnsiteAvailability,
  DoctorGetAvailibility,
} from "shared/services/DoctorService";

interface Onsite {
  setShowAddModal: any;
}
interface Price {
  actualPrice?: number;
  meditourPrice?: number;
}
const DoctorAvailabilityDetail = (props: Partial<Onsite>) => {
  const { state } = useLocation();
  const [availability_Data, set_AvailabilityData] = useState<any>({});
  const navigate = useNavigate();
  const [priceLoading, setPriceLoading] = useState(false);

  const [selectedPeriodId, setSelectedPeriodId] = useState("");
  const [selectedDeleteDay, setSelectedDeleteDay] = useState<number>(0);
  const [isMode, setIsMode] = useState("");
  const [frontdata, setFrontdata] = useState([]);
  const [price, setPrice] = useState<any>({ actualPrice: 0 });
  const [showAddModal, setShowAddModal] = useState(false);
  const [delModal, setDelmodal] = useState(false);
  const [selectedDayData, setSelectedDayData] = useState<{
    dayOfWeek: number;
    periods: Array<{ startTime: string; endTime: string; _id: string }>;
  } | null>(null);
  const { systemType } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);

  const handleDaySelect = (dayData: any) => {
    setSelectedDayData(dayData);
  };

  const FetchAvailibility = () => {
    setLoading(true);
    DoctorGetAvailibility()
      .then((res: any) => {
        console.log("resss........", res?.data);
        let hosAvailToShow = {};
        let hosPriceToShow = {};
        if (state.availabilityType == "hospital") {
          let avail = res?.data?.availability[0]?.hospitalAvailability;
          avail?.map((item: any, index: any) => {
            if (state?.hospitalId == item?.hospitalId) {
              hosAvailToShow = item;
              hosPriceToShow = item?.price;
            }
          });
        }
        let availToSet =
          state.availabilityType == "clinic"
            ? res?.data?.availability[0]?.clinicAvailability
            : state.availabilityType == "in-house"
            ? res?.data?.availability[0]?.inHouseAvailability
            : state.availabilityType == "video"
            ? res?.data?.availability[0]?.videoAvailability
            : state.availabilityType == "hospital"
            ? hosAvailToShow
            : {};

        let priceToset =
          state.availabilityType == "clinic"
            ? res?.data?.availability[0]?.clinicAvailability?.price
            : state.availabilityType == "in-house"
            ? res?.data?.availability[0]?.inHouseAvailability?.price
            : state.availabilityType == "video"
            ? res?.data?.availability[0]?.videoAvailability?.price
            : state.availabilityType == "hospital"
            ? hosPriceToShow
            : {};

        if (priceToset) {
          setPrice(priceToset);
        }
        set_AvailabilityData(availToSet);
      })
      .catch((err: any) => {
        console.error("Error in API call:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    FetchAvailibility();
  }, []);

  const handleGoback = () => {
    navigate(`/${systemType}/availability`);
  };

  const onDeleteSlot = () => {
    let availability_clone = JSON.parse(JSON.stringify(availability_Data));

    let Index = availability_clone?.availability?.findIndex(
      (item: any) => item.dayOfWeek === selectedDayData?.dayOfWeek
    );

    if (Index > -1 && Index < availability_clone?.availability?.length) {
      if (
        availability_clone?.availability[Index]?.evening &&
        availability_clone?.availability[Index]?.morning
      ) {
        delete availability_clone?.availability[Index][selectedPeriodId];
      } else {
        availability_clone?.availability.splice(Index, 1);
      }
    }

    let params = {
      type: state.availabilityType,
      ...(state.hospitalId && {
        hospitalId: state.hospitalId,
      }),
      availability: availability_clone?.availability,
    };

    setLoading(true);

    handleSendTOServer(params);
  };

  const handleSendTOServer = (params: any) => {
    DoctorAddOnsiteAvailability(params)
      .then((res: any) => {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", res);
        setShowAddModal(false);
        setDelmodal(false);
        FetchAvailibility();
      })
      .catch((err: any) => {
        console.log("error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdatePrice = () => {
    let hospitalId = state?.hospitalId;
    setPriceLoading(true);
    let params = {
      type: state.availabilityType,
      ...(hospitalId && {
        hospitalId,
      }),
      price: price.actualPrice,
    };

    DoctorAddAvailabilityPrice(params)
      .then((res: any) => {
        console.log("resss.....", res?.data);
      })
      .catch((err: any) => {
        console.log("err.....", err?.response?.data);
      })
      .finally(() => setPriceLoading(false));
  };

  return (
    <>
      <div className={classNames(commomstyles.col12)}>
        <SearchBar />
        <div className={commomstyles.mr87}>
          <div className={style.outerContainer}>
            <div className={commomstyles.flx}>
              <IoArrowBack className={style.back} onClick={handleGoback} />
              <p
                className={classNames(
                  commomstyles.fs24,
                  commomstyles.semiBold,
                  commonStyles.colorBlue
                )}
              >
                Availablitiy {state.availabilityTitle}
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={24} />
                </div>
              ) : (
                <TbRefresh
                  className={style.Refresh}
                  onClick={FetchAvailibility}
                />
              )}

              <BiSolidMessageSquareAdd
                className={style.addbtn}
                onClick={() => {
                  setShowAddModal(true);
                  setIsMode("add");
                }}
              />
            </div>
            {/* Delete Modal */}
            {/* <CustomModal showModal={delModal}>
              <div style={{ width: "300px" }}>
                <p
                  className={classNames(
                    commomstyles.fs14,
                    commomstyles.semiBold,
                    commonStyles.colorBlue
                  )}
                >
                  Are you sure to Delete current Availibility
                </p>
                <div className={commonStyles.flxBetween}>
                  <div className={style.btnwidth}>
                    <PrimaryButton
                      disabled={loading}
                      children={"Cancel"}
                      colorType={"blue"}
                      onClick={() => setDelmodal(false)}
                    />{" "}
                  </div>
                  <div className={style.btnwidth}>
                    <PrimaryButton
                      disabled={loading}
                      children={
                        loading ? (
                          <RingLoader size={35} color={"#fff"} />
                        ) : (
                          "Delete"
                        )
                      }
                      colorType={"Red"}
                      onClick={() => {
                        onDeleteSlot();
                      }}
                    />
                  </div>
                </div>
              </div>
            </CustomModal> */}

            <DeleteModal
              title="availability?"
              modalVisible={delModal}
              handleCancel={() => setDelmodal(false)}
              handleDelete={onDeleteSlot}
              loading={loading}
            />

            {/* Delete Modal */}

            <div style={{ marginTop: "60px" }}>
              <div className={style.flx} style={{ alignItems: "center" }}>
                <p className={style.heading} style={{ marginTop: "16px" }}>
                  Fee:
                </p>

                {/* <div className={style.actual}>
                  <p>{availability_Data?.price?.actualPrice} PKR</p>
                </div> */}

                <div style={{ marginLeft: "64px", width: 200 }}>
                  <CustomInput
                    placeholder=""
                    id="actualPrice"
                    name="actualPrice"
                    type="text"
                    value={price?.actualPrice && price?.actualPrice}
                    onChange={(e: any) =>
                      setPrice({
                        actualPrice: e.target.value,
                      })
                    }
                    // value={formik.values.actualPrice}
                  />
                </div>
                <div style={{ marginLeft: "64px", width: 200 }}>
                  <PrimaryButton
                    disabled={priceLoading}
                    children={
                      priceLoading ? (
                        <RingLoader size={35} color={"#fff"} />
                      ) : (
                        "Save"
                      )
                    }
                    onClick={handleUpdatePrice}
                    // type="submit"
                    colorType={"MedicalService"}
                  />
                </div>
              </div>
              <p className={style.heading} style={{ marginTop: 40 }}>
                Slots:
              </p>
              {availability_Data?.availability?.length > 0 ? (
                availability_Data?.availability?.map(
                  (dayData: any, index: any) => (
                    <>
                      <Accordion
                        key={index}
                        className={style.dropdown}
                        onClick={() => handleDaySelect(dayData)}
                        style={{ margin: " 0 0 8px 110px" }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "#00276d" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ fontWeight: "600" }}>
                            <p className={classNames(commomstyles.fs16)}>
                              {getDayName(dayData.dayOfWeek)}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {dayData?.morning && (
                            <Typography>
                              {/* {dayData.periods?.map((period: any) => ( */}
                              <div
                                key={dayData?.morning?._id}
                                style={{ marginBottom: "16px" }}
                                className={commomstyles.flx}
                              >
                                <div className={style.borderbttom}>
                                  <p>{`From: ${dayData?.morning?.startTime}`}</p>
                                </div>
                                <div className={style.borderbttom}>
                                  <p>{`To: ${dayData?.morning?.endTime}`}</p>
                                </div>
                                <div className={classNames(commomstyles.flx)}>
                                  {/* <div
                                    className={classNames(style.editbox)}
                                    onClick={() => {
                                      setShowAddModal(true);
                                      setIsMode("edit");
                                    }}
                                  >
                                    <FaEdit className={style.Faedit} />
                                    <p>Edit</p>
                                  </div> */}
                                  <div
                                    className={style.deletebtn}
                                    onClick={() => {
                                      setSelectedPeriodId("morning");
                                      setSelectedDeleteDay(dayData.dayOfWeek);
                                      setDelmodal(true);
                                    }}

                                    // onClick={() => {
                                    //   onDeleteSlot(
                                    //     dayData.dayOfWeek,
                                    //     period._id
                                    //   );
                                    // }}
                                  >
                                    <MdDelete />
                                  </div>
                                </div>
                              </div>
                              {/* ))} */}
                              {/* <div className={style.flx}>
                             <div className={style.actual}>
                               <p>{availability_Data?.price?.actualPrice} PKR</p>
                             </div>
                             <div className={style.actual}>
                               <p>{price?.meditourPrice} PKR</p>
                             </div>
                           </div> */}
                            </Typography>
                          )}

                          {dayData?.evening && (
                            <Typography>
                              {/* {dayData.periods?.map((period: any) => ( */}
                              <div
                                key={dayData?.evening?._id}
                                style={{ marginBottom: "16px" }}
                                className={commomstyles.flx}
                              >
                                <div className={style.borderbttom}>
                                  <p>{`From: ${dayData?.evening?.startTime}`}</p>
                                </div>
                                <div className={style.borderbttom}>
                                  <p>{`To: ${dayData?.evening?.endTime}`}</p>
                                </div>
                                <div className={classNames(commomstyles.flx)}>
                                  {/* <div
                                    className={classNames(style.editbox)}
                                    onClick={() => {
                                      setShowAddModal(true);
                                      setIsMode("edit");
                                    }}
                                  >
                                    <FaEdit className={style.Faedit} />
                                    <p>Edit</p>
                                  </div> */}
                                  <div
                                    className={style.deletebtn}
                                    onClick={() => {
                                      setSelectedPeriodId("evening");
                                      setSelectedDeleteDay(dayData.dayOfWeek);
                                      setDelmodal(true);
                                    }}

                                    // onClick={() => {
                                    //   onDeleteSlot(
                                    //     dayData.dayOfWeek,
                                    //     period._id
                                    //   );
                                    // }}
                                  >
                                    <MdDelete />
                                  </div>
                                </div>
                              </div>
                              {/* ))} */}
                              {/* <div className={style.flx}>
                               <div className={style.actual}>
                                 <p>{availability_Data?.price?.actualPrice} PKR</p>
                               </div>
                               <div className={style.actual}>
                                 <p>{price?.meditourPrice} PKR</p>
                               </div>
                             </div> */}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )
                )
              ) : (
                <div>{!loading && <PhysiotheristsEmpty />}</div>
              )}
            </div>
          </div>{" "}
        </div>
        <CustomModal
          showModal={showAddModal}
          children={
            <AddAvailibilityModal
              mode={isMode}
              availabilityType={state.availabilityType}
              setShowAddModal={setShowAddModal}
              selectedDayData={selectedDayData}
              frontdata={frontdata}
              loading={loading}
              setLoading={setLoading}
              handleSendTOServer={handleSendTOServer}
              FetchAvailibility={FetchAvailibility}
              availability_Data={availability_Data}
              hospitalId={state?.hospitalId}
            />
          }
        />
      </div>
    </>
  );
};
export default DoctorAvailabilityDetail;

const getDayName = (dayOfWeek: number) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[dayOfWeek];
};

const AddAvailibilityModal = (props: any) => {
  // const [loading, setLoading] = useState(false);
  const {
    setShowAddModal,
    mode,
    selectedDayData,
    availabilityType,
    frontdata,
    FetchAvailibility,
    availability_Data,
    loading,
    setLoading,
    handleSendTOServer,
    hospitalId,
  } = props;

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  return (
    <Typography
      id="modal-modal-description"
      sx={{ textAlign: "center", color: "#001F57" }}
    >
      <div className={commomstyles.flx}>
        <div style={{ marginLeft: "auto" }}>
          <button className={style.close} onClick={handleCloseModal}>
            &#10006;
          </button>
        </div>
      </div>

      <div>
        <Availability
          availabilityType={availabilityType}
          frontdata={frontdata}
          FetchAvailibility={FetchAvailibility}
          selectedDayData={selectedDayData}
          mode={mode}
          setShowAddModal={setShowAddModal}
          availability_Data={availability_Data}
          loading={loading}
          setLoading={setLoading}
          handleSendTOServer={handleSendTOServer}
          hospitalId={hospitalId}
        />
      </div>
    </Typography>
  );
};

interface AvailabilityProps {
  setAddData: any;
  handleClickNext: any;
  setShowAddModal: any;
  FetchAvailibility: any;
  addData: any;
  mode?: any;
  availabilityType?: any;
  availability_Data?: any;
  frontdata?: any;
  selectedDayData?: any;
  loading?: boolean;
  setLoading?: any;
  handleSendTOServer?: any;
  hospitalId?: any;
}

const Availability = (props: Partial<AvailabilityProps>) => {
  const Day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [error, setError] = React.useState("");
  const {
    mode,
    availabilityType,
    availability_Data,
    loading,
    setLoading,
    handleSendTOServer,
    hospitalId,
  } = props;
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
  const formik = useFormik({
    initialValues: {
      onsiteDay: selectedDay || "",
      morningfrom: "",
      morningto: "",
      eveningfrom: "",
      eveningto: "",
      actualPrice: "",
    },
    validationSchema: Yup.object(doctor_Availability),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleMorningFrom = (newTime: any) => {
    setError("");
    formik.setFieldValue("morningfrom", newTime);
  };
  const handleMorningTo = (newTime: any) => {
    setError("");
    formik.setFieldValue("morningto", newTime);
  };
  const handleEveningFrom = (newTime: any) => {
    setError("");
    const selectedTime = newTime ? newTime.toString() : "00:00";
    formik.setFieldValue("eveningfrom", selectedTime);
  };
  const handleEveningTo = (newTime: any) => {
    setError("");
    const selectedTime = newTime ? newTime.toString() : "00:00";
    formik.setFieldValue("eveningto", selectedTime);
  };

  const handleSelect = (selectedOption: string) => {
    const dayToSelect = selectedOption;
    formik.setFieldValue("onsiteDay", dayToSelect);
  };
  const validateData = () => {
    const currentdata = formik.values;
    if (
      !currentdata?.morningfrom &&
      !currentdata?.morningto &&
      !currentdata?.eveningfrom &&
      !currentdata?.eveningto
    ) {
      setError("Add atleast one shift.");
      return false;
    } else if (
      (currentdata?.morningfrom && !currentdata?.morningto) ||
      (currentdata?.eveningfrom && !currentdata?.eveningto)
    ) {
      setError("Select a complete shift.");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (validateData()) {
      let currentdata = formik.values;

      let availability: any = [];
      let day = Day.findIndex((day) => day === currentdata.onsiteDay);
      let Index: any = -1;
      let availability_clone: any = {};

      if (availability_Data) {
        availability_clone = JSON.parse(JSON.stringify(availability_Data));
        availability_clone?.availability?.map(async (item: any, index: any) => {
          if (item.dayOfWeek == day) {
            Index = index;
          }
        });
      }

      let morning = null;
      let evening = null;

      if (currentdata.morningfrom && currentdata.morningto) {
        morning = {
          startTime: currentdata.morningfrom,
          endTime: currentdata.morningto,
        };
      }
      if (currentdata.eveningfrom && currentdata.eveningto) {
        evening = {
          startTime: currentdata.eveningfrom,
          endTime: currentdata.eveningto,
        };
      }

      if (Index !== -1) {
        let clone = JSON.parse(
          JSON.stringify(availability_clone?.availability)
        );

        if (morning) {
          clone[Index].morning = morning;
        }

        if (evening) {
          clone[Index].evening = evening;
        }

        availability = clone;
      } else {
        if (availability_clone?.availability) {
          availability = [...availability_clone?.availability];
        }
        availability = [
          {
            dayOfWeek: day,
            ...(morning && {
              morning,
            }),
            ...(evening && {
              evening,
            }),
          },
          ...availability,
        ];
      }

      let params = {
        type: availabilityType,
        ...(hospitalId && {
          hospitalId,
        }),
        availability,
      };

      setLoading(true);
      handleSendTOServer(params);
    } else {
      setError("Please select a time.");
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {error && <div className={classNames(style.error)}>*{error}</div>}
        <div style={{ width: "504px", marginTop: "24px" }}>
          <CustomSelect
            options={Day}
            placeholder="Day"
            onSelect={handleSelect || selectedDay}
            value={formik.values.onsiteDay}
            isEditing={mode === "edit"}
          />

          {formik.touched.onsiteDay && formik.errors.onsiteDay ? (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.onsiteDay}
            </div>
          ) : null}
        </div>

        <div className={commomstyles.flx} style={{ marginTop: "16px" }}>
          <div style={{ width: "210px", marginRight: "82px" }}>
            <CustomTimePicker
              placeholder={"Morning Shift Start"}
              setData={handleMorningFrom}
              onTimeChange={handleMorningFrom}
              // defaultValue={
              //   formik.values.morningfrom
              // }
              value={formik.values.morningfrom}
            />

            {formik.touched.morningfrom && formik.errors.morningfrom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.morningfrom}
              </div>
            ) : null}
          </div>
          <div style={{ width: "210px" }}>
            <CustomTimePicker
              placeholder={"Morning Shift End"}
              setData={handleMorningTo}
              onTimeChange={handleMorningTo}
              // defaultValue={
              //   formik.values.morningto || selectedDayData?.periods[0]?.endTime
              // }
              value={formik.values.morningfrom}
            />

            {formik.touched.morningto && formik.errors.morningto ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.morningto}
              </div>
            ) : null}
          </div>
        </div>

        <div className={commomstyles.flx} style={{ marginTop: "16px" }}>
          <div style={{ width: "210px", marginRight: "82px" }}>
            <CustomTimePicker
              placeholder={"Evening Shift Start"}
              setData={handleEveningFrom}
              onTimeChange={handleEveningFrom}
              value={formik.values.eveningfrom}
            />

            {formik.touched.eveningfrom && formik.errors.eveningfrom ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.eveningfrom}
              </div>
            ) : null}
          </div>
          <div style={{ width: "210px" }}>
            <CustomTimePicker
              placeholder={"Evening Shift End"}
              setData={handleEveningTo}
              onTimeChange={handleEveningTo}
              // defaultValue={
              //   formik.values.eveningto ||
              //   selectedDayData?.periods[1]?.endTime ||
              //   "00:00"
              // }
              value={formik.values.eveningto}
            />

            {formik.touched.eveningto && formik.errors.eveningto ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.eveningto}
              </div>
            ) : null}
          </div>
        </div>

        <div style={{ width: "210px", margin: "56px 0 0 0" }}>
          <PrimaryButton
            children={
              loading ? (
                <RingLoader size={35} color={"#fff"} />
              ) : mode === "edit" ? (
                "Update Availability"
              ) : (
                "Add Availability"
              )
            }
            type="submit"
            colorType={"MedicalService"}
          />
        </div>
      </form>
    </div>
  );
};
