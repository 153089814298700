import React, { useState } from "react";
import styles from "./Donors.module.css";
import empty from "assets/images/empty2.png";
import commonstyle from "../../../utils/common.module.css";
import classNames from "classnames";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingModal from "shared/components/LoaderModal";
import CustomLoader from "shared/components/New_Loader/Loader";

interface Props {
  donors: any;
  setShowModal: any;
}

function DonorsTable(props: Partial<Props>) {
  const [loading, setLoading] = useState(false);
  const { donors, setShowModal } = props;
  const navigate = useNavigate();

  const handleGoToDetail = (_id: string) => {
    // navigate(`/donorDetail/${_id}`);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className={classNames(styles.App)}>
          {donors.length > 0 ? (
            <div className={styles.payment}>
              <div className={styles.headerOuter}>
                <p className={styles.headerclass}>Donor Id</p>
                <p className={styles.headerclass}>Donor Name</p>
                <p className={styles.headerclass}>Donation Purpose</p>
                <p className={styles.headerclass}>Donation Amount</p>
              </div>
              <div className={styles.tableData}>
                <table
                  style={{
                    margin: "0px",
                    // borderCollapse: "separate",
                    // borderSpacing: "0 10px",
                  }}
                >
                  <tbody className={styles.wrapper}>
                    {donors.map((val: any, key: any) => (
                      <tr
                        className={styles.tableRow}
                        key={key}
                        onClick={() => handleGoToDetail(val._id)}
                      >
                        <td className={styles.w20}>{val?.donationId}</td>
                        <td className={styles.w20}>{val?.donorName}</td>
                        <td className={styles.w20}>{val?.donationPurpose}</td>
                        <td className={styles.w20}>{val?.paidByUserAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className={classNames(styles.center)}>
              <img src={empty} className={classNames(styles.empty3)} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default DonorsTable;
