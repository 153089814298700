import React, { useState } from "react";
import styles from "./FlightForm.module.css";
import LogoComponent from "../LogoComponent";
import TimeSelctionComponent from "../TimeSelctionComponent";
import toast from "react-hot-toast";
import edit from "assets/images/edit2.png";
import del from "assets/images/del.png";
import clock from "assets/images/clock.png";
import flightPlane from "assets/images/Illustration.png";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import DeleteModal from "../DeleteModal";
import moment from "moment";
const FlightForm = ({
    flightType,
    formik,
    returnEditMode,
    handleSubmit,
    setLoading,
    flights,
    setCurrentIndex,
    flightsReturn,
    handleEditFlight,
    formVisible,
    handleDeleteFlight,
    addForm,
    setModalVisible,
    modalVisible,
    showText,
    loading,
}: {
    flightType?: any;
    formik?: any;
    returnEditMode?: any;
    handleSubmit?: any;
    setLoading?: any;
    loading?: any;
    flights?: any;
    setCurrentIndex?: any;
    handleEditFlight?: any;
    formVisible?: any;
    handleDeleteFlight?: any;
    addForm?: any;
    setModalVisible?: any;
    modalVisible?: any;
    showText?: any;
    flightsReturn?: any;
}) => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [fileName, setFileName] = useState<string>("");
    const toggleExpanded = (index: any) => {
        setExpandedIndex((prevIndex: any) => (prevIndex === index ? null : index));
    };

    const handleAmenityChange = (amenity: any, checked: any) => {
        if (checked) {
            formik.setFieldValue("amenities", [...formik.values.amenities, amenity]);
        } else {
            formik.setFieldValue(
                "amenities",
                formik.values.amenities.filter((item: any) => item !== amenity)
            );
        }
    };
    const confirmDeleteFlight = (index: any) => {
        setCurrentIndex(index);
        if (flightsReturn?.length > 0) {

            toast.error("Please delete the return flight before deleting the flight.");
            setModalVisible(false);
        } else {
            setModalVisible(true);
        }
    };
    return (
        <>
            {formVisible && (
                <>
                    <h2 className={styles.heading}>{flightType} Flight</h2>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Company Name:</label>
                            <input
                                type="text"
                                value={formik?.values?.companyName}
                                onChange={(e) =>
                                    formik.setFieldValue("companyName", e.target.value)
                                }
                                className={styles.input}
                                placeholder="Enter Company Name *"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Company logo:</label>
                            <LogoComponent
                                setLoading={setLoading}
                                fileName={fileName}
                                setFileName={setFileName}
                                url={formik?.values?.companyLogo}
                                setUrl={(value: any) =>
                                    formik.setFieldValue("companyLogo", value)
                                }
                            />
                        </div>
                    </div>

                    <div className={styles.inputContainer}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>From:</label>
                            <input
                                type="text"
                                value={formik?.values?.from}
                                onChange={(e) => formik.setFieldValue("from", e.target.value)}
                                className={styles.input}
                                placeholder="From *"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label className={styles.label}>To:</label>
                            <input
                                type="text"
                                value={formik?.values?.to}
                                onChange={(e) => formik.setFieldValue("to", e.target.value)}
                                className={styles.input}
                                placeholder="To *"
                            />
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Departure Date:</label>
                            <input
                                type="date"
                                className={styles.input}
                                value={formik?.values?.departureDate}
                                onChange={(e) =>
                                    formik.setFieldValue("departureDate", e.target.value)
                                }
                                required
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Departure Time</label>
                            <TimeSelctionComponent
                                selectedTime={formik.values.departureTime}
                                setTime={(time: any) =>
                                    formik.setFieldValue("departureTime", time)
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Arrival Date:</label>
                            <input
                                type="date"
                                className={styles.input}
                                value={formik?.values?.arrivalDate}
                                onChange={(e) =>
                                    formik.setFieldValue("arrivalDate", e.target.value)
                                }
                                required
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Arrival Time:</label>
                            <TimeSelctionComponent
                                selectedTime={formik?.values?.arrivalTime}
                                setTime={(time: any) =>
                                    formik.setFieldValue("arrivalTime", time)
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Flight No:</label>
                            <input
                                type="text"
                                value={formik?.values?.flightNo}
                                onChange={(e) =>
                                    formik.setFieldValue("flightNo", e.target.value)
                                }
                                className={styles.input}
                                placeholder="Enter Flight Number"
                            />
                        </div>
                    </div>
                    <h3 className={styles.amenitiesHeading}>Flight Amenities</h3>
                    <div className={styles.amenitiesContainer}>
                        <div className={styles.leftGroup}>
                            {[
                                "Reading Material",
                                "Wi-Fi",
                                "In-Flight Entertainment",
                                "Beverage",
                                "Comfort Items",
                                "Basic Toiletries",
                                "Light Meal",
                            ].map((amenity, index) => (
                                <div className={styles.radioGroup} key={index}>
                                    <input
                                        type="checkbox"
                                        id={amenity}
                                        name="flightAmenities"
                                        checked={formik.values.amenities.includes(amenity)}
                                        className={styles.radioInput}
                                        value={formik.values.amenities.includes(amenity)}
                                        onChange={(checked: any) =>
                                            handleAmenityChange(amenity, checked)
                                        }
                                    />
                                    <label
                                        htmlFor={amenity}
                                        className={
                                            formik.values.amenities.includes(amenity)
                                                ? styles.orangeLabel
                                                : styles.blueLabel
                                        }
                                    >
                                        {amenity}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.inputContainer}>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>No of Bags:</label>
                            <input
                                type="number"
                                value={formik?.values?.noOfHandbag}
                                onChange={(e) =>
                                    formik.setFieldValue("noOfHandbag", e.target.value)
                                }
                                className={styles.input}
                                placeholder="Number of Bags *"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label className={styles.label}>Baggage Weight:</label>
                            <input
                                type="text"
                                value={formik?.values?.baggageWeight}
                                onChange={(e) =>
                                    formik.setFieldValue("baggageWeight", e.target.value)
                                }
                                className={styles.input}
                                placeholder="Baggage Weight (kg) *"
                            />
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button className={styles.saveButton} onClick={handleSubmit}>
                            {formik.editIndex !== null ? "Save" : "Update"}
                        </button>
                    </div>
                </>
            )}
            {!formVisible && !returnEditMode && (
                <>
                    <div>
                        {showText && (
                            <p
                                style={{
                                    fontSize: "18px",
                                    fontFamily: "bold",
                                    color: "#0D47A1",
                                    marginTop: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                Return Flight
                            </p>
                        )}
                        <p
                            style={{
                                fontSize: "16px",
                                fontFamily: "SFmedium",
                                color: "#0D47A1",
                                paddingBottom: "8px",
                                fontWeight: "bold",
                            }}
                        >
                            {flightType}
                        </p>
                    </div>
                    {flights.map((flight: any, index: any) => (
                        <div className={styles.flightSummary}>
                            <div className={styles.header}>
                                <h2 className={styles.flightHeading}>
                                    {`Flight ${index + 1}`}
                                </h2>
                                <div
                                    style={{ display: "flex", gap: "50px", alignItems: "center" }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "20px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={edit}
                                            className={styles.editButton}
                                            alt="Upload"
                                            onClick={() => handleEditFlight(index)}
                                        />
                                        <img
                                            src={del}
                                            className={styles.deleteIcon}
                                            alt="Delete"
                                            onClick={() => confirmDeleteFlight(index)}
                                        />
                                    </div>
                                    <button
                                        onClick={() => toggleExpanded(index)}
                                        className={styles.detailsButton}
                                    >
                                        {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                    </button>
                                </div>
                            </div>

                            {expandedIndex === index && (
                                <div className={styles.detailsContainer}>
                                    <>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Company Name:
                                            </span>
                                            <span className={styles.detailData}>
                                                {flight?.companyName}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Company logo:
                                            </span>
                                            <span className={styles.detailData}>{fileName}</span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>Flight No:</span>
                                            <span className={styles.detailData}>
                                                {flight?.flightNo}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>From:</span>
                                            <span className={styles.detailData}>{flight?.from}</span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>To:</span>
                                            <span className={styles.detailData}> {flight?.to}</span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Departure Date:
                                            </span>
                                            <span className={styles.detailData}>
                                                {flight?.departureDate}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Departure Time:
                                            </span>
                                            <span className={styles.detailData}>
                                                {moment(flight?.departureTime).format("hh:mm A")}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Arrival Date:
                                            </span>
                                            <span className={styles.detailData}>
                                                {flight?.arrivalDate}

                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Arrival Time:
                                            </span>
                                            <span className={styles.detailData}>
                                                {moment(flight?.arrivalTime).format("hh:mm A")}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                No of HandBag:
                                            </span>
                                            <span className={styles.detailData}>
                                                {flight?.noOfHandbag}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Baggage Weight:
                                            </span>
                                            <span className={styles.detailData}>
                                                {flight?.baggageWeight}
                                            </span>
                                        </div>
                                        <div className={styles.detailItem}>
                                            <span className={styles.detailHeading}>
                                                Selected Amenities:
                                            </span>
                                            <span className={styles.detailData}>
                                                {flight?.amenities.join(", ")}
                                            </span>
                                        </div>
                                    </>
                                </div>
                            )}
                            <DeleteModal
                                modalVisible={modalVisible}
                                handleCancel={() => setModalVisible(false)}
                                handleDelete={handleDeleteFlight}
                                loading={loading}
                            />
                        </div>
                    ))}
                </>
            )}
            {flights.length > 0 && !formVisible && flightType === "Stay" && (
                <div className={styles.addb}>
                    <button className={styles.addButton} onClick={addForm}>
                        Add More
                    </button>
                </div>

            )}
        </>
    );
};

export default FlightForm;
