import React, { useEffect, useState } from "react";
import classNames from "classnames";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import style from "./hospitalDashboard.module.css";
import Avatar from "@mui/material/Avatar";
import Cross from "../../../assets/images/cross.png";
import Ook from "../../../assets/images/ook.png";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/joy/CircularProgress";
import {
  hospitalGetAllAppointmentRequest,
  hospitalGetTodayAppointmentRequest,
  hospitaldashobardAppointmentStats,
  hospitaldashobardGetCounts,
} from "shared/services/HospitalService";
import {
  setAppointmentStats,
  setDashboardCounts,
  setDashboardhospitalGetTodayAppointment,
  setDashboardhospitalGetallAppointment,
  setHospitaldashboardrenderFlag,
} from "shared/redux";
import { TbRefresh } from "react-icons/tb";
import { LoadingModal, RingLoader } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
const Hospital_Dashboard = () => {
  const [rotation, setRotation] = useState<number>(0);
  const rotationIncrement: number = 90;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    hospital,
    dashboardhospitalGetallAppointment,
    dashboardhospitalGetTodayAppointment,
    appointmentStats,
    dashboardCounts,
    hospitaldashboardrenderFlag,
  } = useSelector((state: any) => state.root.hospital);

  const { user } = useSelector((state: any) => state.root.common);

  const percentageTotalSessions = parseFloat(
    appointmentStats.percentageTotalSessions
  );
  const formattedPercentage = percentageTotalSessions.toFixed(2);
  const percentageVideoSessions = parseFloat(appointmentStats.percentageVideo);
  const formattedVideoPercentage = percentageVideoSessions.toFixed(2);
  useEffect(() => {
    setRotation(rotation - rotationIncrement);
    fetchAllAppointment();
    fetchTodayAppointmentRequest();
    fetchAppointmentStats();
    fetchDashboardCounts();
  }, []);
  const handleRotate = () => {
    setRotation(rotation - rotationIncrement);
    fetchAllAppointment();
    fetchTodayAppointmentRequest();
    fetchAppointmentStats();
    fetchDashboardCounts();
  };
  const fetchAllAppointment = () => {
    setLoading(true);
    hospitalGetAllAppointmentRequest()
      .then((res: any) => {
        // console.log("hospitalGetAllAppointmentRequest RESPONSE", res);
        dispatch(
          setDashboardhospitalGetallAppointment(
            res.data.recentAppointmentRequests
          )
        );
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTodayAppointmentRequest = () => {
    setLoading(true);
    hospitalGetTodayAppointmentRequest()
      .then((res: any) => {
        console.log(
          "hospitalGetTodayAppointmentRequest RESPONSE.........................",
          res
        );
        dispatch(
          setDashboardhospitalGetTodayAppointment(res.data.appointments)
        );
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAppointmentStats = () => {
    setLoading(true);
    hospitaldashobardAppointmentStats()
      .then((res: any) => {
        console.log("hospitaldashobardAppointmentStats RESPONSE", res);
        dispatch(
          setAppointmentStats({
            percentageTotalSessions: res.data.percentageTotalSessions,
            percentageVideo: res.data.percentageVideo,
          })
        );
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDashboardCounts = () => {
    setLoading(true);
    hospitaldashobardGetCounts()
      .then((res: any) => {
        console.log("hospitaldashobardGetCounts", res);
        dispatch(
          setDashboardCounts({
            newPatientsCount: res.data.newPatientsCount,
            totalDoctors: res.data.totalDoctors,
            totalPatients: res.data.totalPatients,
          })
        );
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   fetchAllAppointment();
  //   fetchTodayAppointmentRequest();
  //   fetchAppointmentStats();
  //   fetchDashboardCounts();
  // }, []);

  useEffect(() => {
    if (hospitaldashboardrenderFlag) {
      setLoading(true);
      fetchAllAppointment();
      fetchTodayAppointmentRequest();
      fetchAppointmentStats();
      fetchDashboardCounts();
      dispatch(setHospitaldashboardrenderFlag(false));
    }
  }, []);

  return (
    <>
      <div className={classNames(commonstyles.col12)}>
        <SearchBar />
        <div className={classNames(commonstyles.mr87)}>
          <div className={style.outerContainer}>
            <div className={style.mb24}>
              <div className={style.flexx}>
                <p
                  className={classNames(
                    commonstyles.fs32,
                    commonstyles.semiBold,
                    style.mb4
                  )}
                >
                  Hello {user?.name}!
                </p>

                {loading ? (
                  <div style={{ marginLeft: "16px" }}>
                    <RingLoader color={"#0D47A1"} size={30.8} />
                  </div>
                ) : (
                  <TbRefresh
                    className={style.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={handleRotate}
                  />
                )}
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs12,
                    commonstyles.semiBold,
                    style.gray
                  )}
                >
                  This is what we've got for you today
                </p>
              </div>
            </div>

            <div className={classNames(style.flxb)}>
              <div className={classNames(commonstyles.col9)}>
                <div className={classNames(style.flxb)}>
                  <div className={classNames(style.card1)}>
                    <p
                      className={classNames(
                        commonstyles.fs24,
                        commonstyles.semiBold
                      )}
                    >
                      {dashboardCounts.totalPatients}
                    </p>
                    <p
                      style={{ marginTop: "7px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Total patients
                    </p>
                  </div>
                  <div className={classNames(style.card2)}>
                    <p
                      className={classNames(
                        commonstyles.fs24,
                        commonstyles.semiBold
                      )}
                    >
                      {dashboardCounts.newPatientsCount}
                    </p>
                    <p
                      style={{ marginTop: "7px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      New Patient
                    </p>
                  </div>
                  <div className={classNames(style.card3)}>
                    <p
                      className={classNames(
                        commonstyles.fs24,
                        commonstyles.semiBold
                      )}
                    >
                      {dashboardCounts.totalDoctors}
                    </p>
                    <p
                      style={{ marginTop: "7px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Total Doctor
                    </p>
                  </div>
                  <div className={classNames(style.card4)}>
                    <p
                      className={classNames(
                        commonstyles.fs24,
                        commonstyles.semiBold
                      )}
                    >
                      1,156
                    </p>
                    <p
                      style={{ marginTop: "7px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Total Income
                    </p>
                  </div>
                </div>
                <div className={classNames(style.flx, style.mt40)}>
                  <div className={classNames(commonstyles.col7)}>
                    <p
                      style={{ marginBottom: "16px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Appointments Requests
                    </p>{" "}
                    <div className={style.card5height}>
                      {dashboardhospitalGetallAppointment.map((item: any) => {
                        return (
                          <div className={style.card5}>
                            <div className={classNames(commonstyles.flx)}>
                              <Avatar className={style.avatar}>
                                {" "}
                                <img
                                  src={item.patientId.userImage}
                                  className={commonstyles.col12}
                                />
                              </Avatar>
                              <div>
                                <p
                                  className={classNames(
                                    commonstyles.fs14,
                                    commonstyles.semiBold
                                  )}
                                >
                                  {item.patientId.name}
                                </p>
                                <p
                                  style={{ marginTop: "10px" }}
                                  className={classNames(
                                    commonstyles.fs8,
                                    commonstyles.semiBold
                                  )}
                                >
                                  {new Date(item?.createdAt).toLocaleTimeString(
                                    "en-US",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      hour12: true,
                                    }
                                  )}{" "}
                                  &
                                  {item?.createdAt
                                    ? new Date(item.createdAt)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""}
                                  {/* 12 April , 2023 &10:30 am */}
                                </p>
                              </div>
                              <p
                                style={{ marginLeft: "34px" }}
                                className={classNames(
                                  commonstyles.fs16,
                                  commonstyles.semiBold
                                )}
                              >
                                2 Hour
                              </p>
                              <div style={{ marginLeft: "auto" }}>
                                <img src={Cross} className={style.cross} />
                                <img src={Ook} className={style.cross} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={classNames(commonstyles.col5)}>
                    <p
                      style={{ marginBottom: "16px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Today Appointments
                    </p>

                    {dashboardhospitalGetTodayAppointment.map((item: any) => {
                      return (
                        <div className={style.card8}>
                          <div className={commonstyles.flx}>
                            <Avatar className={style.avatar}>
                              {" "}
                              <img
                                src={item?.doctorId?.doctorImage}
                                className={commonstyles.col12}
                              />
                            </Avatar>
                            <div>
                              <p
                                className={classNames(
                                  commonstyles.fs14,
                                  commonstyles.semiBold
                                )}
                              >
                                {item?.doctorId?.name}
                              </p>
                              <p
                                style={{ marginTop: "10px" }}
                                className={classNames(
                                  commonstyles.fs14,
                                  commonstyles.semiBold
                                )}
                              >
                                {item.appointmentType}
                              </p>
                            </div>
                            <p
                              style={{ marginLeft: "24px" }}
                              className={classNames(
                                commonstyles.fs14,
                                commonstyles.semiBold
                              )}
                            >
                              {item.status}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className={classNames(commonstyles.col3)}>
                <div className={classNames(style.boxx)}>
                  <div className={classNames(commonstyles.flx)}>
                    <p
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Sessions
                    </p>
                    <div className={style.mss}>
                      <select name="cars" id="cars" className={style.selection}>
                        <option value="volvo">To Day</option>
                        <option value="saab">Yestarday</option>
                        <option value="mercedes">Tomorrow</option>
                      </select>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "16px" }}
                    className={classNames(commonstyles.flx)}
                  >
                    <CircularProgress
                      thickness={6}
                      size="lg"
                      determinate
                      value={parseFloat(formattedPercentage)}
                      color="primary"
                    >
                      {formattedPercentage}%
                    </CircularProgress>
                    <p
                      style={{ marginLeft: "32px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Total Sessions
                    </p>
                  </div>
                  {/* <div
                  style={{ marginTop: "16px" }}
                  className={classNames(commonstyles.flx)}
                >
                  <CircularProgress
                    thickness={6}
                    size="lg"
                    determinate
                    value={90}
                    color="warning"
                  >
                    90%
                  </CircularProgress>
                  <p
                    style={{ marginLeft: "32px" }}
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    Audio Sessions
                  </p>
                </div> */}
                  <div
                    style={{ marginTop: "16px" }}
                    className={classNames(commonstyles.flx)}
                  >
                    <CircularProgress
                      thickness={6}
                      size="lg"
                      determinate
                      value={parseFloat(formattedVideoPercentage)}
                      color="success"
                    >
                      {formattedVideoPercentage}%
                    </CircularProgress>
                    <p
                      style={{ marginLeft: "32px" }}
                      className={classNames(
                        commonstyles.fs14,
                        commonstyles.semiBold
                      )}
                    >
                      Video Consultations
                    </p>
                  </div>
                </div>
                {/* <div style={{ marginTop: "28px" }} className={style.boxx3}>
                <div className={classNames(commonstyles.flx)}>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.semiBold
                    )}
                  >
                    Gender
                  </p>
                  <div className={style.mss}>
                    <select name="cars" id="cars" className={style.selection}>
                      <option value="volvo">Monthly</option>
                      <option value="saab">Daily</option>
                      <option value="mercedes">Yearly</option>
                    </select>
                  </div>
                </div>
                <div>
                  <HospitalPieChart />
                </div>
              </div> */}
              </div>
            </div>
            {/* <div style={{ margin: "24px 0" }}>
        <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
          Recent Patients
        </p>
      </div>
      <div className={style.tables}>
        <HospitalDashTable />
      </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Hospital_Dashboard;
