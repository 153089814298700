
import React, { useEffect, useState } from 'react'
import SearchBar from 'shared/components/Searchbar'
import classNames from 'classnames'
import commonstyles from "shared/utils/common.module.css";
import "./shareTicket.css"
import CustomLoader from 'shared/components/New_Loader/Loader';
import paper from "assets/images/upload.png";
import del from "assets/images/del.png";
import { add_File } from 'shared/services';
import { fetchTravel, pushTicket_To } from 'shared/services/TravelAgency';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton, TravelerInformation } from 'shared/components';
import { Alert } from '@mui/material';
const TravelTicketshare = () => {
    const [url, setUrl] = useState<any>('');

    const [loading, setLoading] = useState(false)
    const [travelers, setTraveler] = useState([]);
    const { state } = useLocation();
    const navigate = useNavigate()
    const val = state?.data;

    const onUploadImage = (event: any) => {
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            add_File(formData)
                .then((response: any) => {
                    setUrl(response.data.fileUrl);

                })
                .catch((err: any) => {
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        funGetTravels();
    }, []);
    const funGetTravels = () => {
        const data = {
            bidRequestId: val?.bidIds?.[0]?._id,
        };
        fetchTravel(data)
            .then((res: any) => {
                setTraveler(res?.data?.travellers);
            })

            .catch((err: any) => {
                console.log(err, '........errorDATA');
            })
            .finally(() => { });
    };
    const deleteFile = () => {
        setUrl(null);
    };

    const handleDownload = (file: string | Blob | null) => {
        if (file) {
            const link = document.createElement("a");
            // If the file is a Blob, create an object URL
            const url = typeof file === "string" ? file : URL.createObjectURL(file);

            link.href = url;
            link.download = "policy-document.jpg"; // Specify your desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL if using a Blob
            if (typeof file !== "string") {
                URL.revokeObjectURL(url);
            }
        } else {
            alert("No policy document available.");
        }
    };

    const handleDownloading = (file: string | Blob | null) => {
        if (file) {
            const link = document.createElement("a");
            // If the file is a Blob, create an object URL
            const url = typeof file === "string" ? file : URL.createObjectURL(file);

            link.href = url;
            link.download = "policy-document.jpg"; // Specify your desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL if using a Blob
            if (typeof file !== "string") {
                URL.revokeObjectURL(url);
            }
        } else {
            alert("No policy document available.");
        }
    };
    const submitFile = async () => {
        setLoading(true);
        try {
            const data = {
                bidRequestId: val?.bidIds?.[0]?._id,
            };
            const params = {
                eTicket: url,
            };
            const response = await pushTicket_To(data, params);
            console.log(response, '.....response')
            setLoading(false);
            alert('Your file has been submitted successfully!');
            navigate('/travelAgency/ticketRequests');
        } catch (error) {
            setLoading(false);
            console.error('Error uploading file:', error);
            alert('There was an error submitting the file. Please try again later.');
        }
    };
    return (
        <div className={classNames(commonstyles.col12)}>
            <SearchBar />
            <div style={{ flex: 1, paddingBottom: '100px' }}  >
                <p className="label">E-Ticket</p>
                <p > To proceed, please upload tickets of all Passenger.</p>
                <div>
                    {!url && (
                        <label className="uploadContainer">
                            <img src={paper} className="uploadImage" alt="Upload" />
                            <span style={{ fontSize: '12px', fontFamily: 'SFmedium', color: '#0000FF' }}>
                                <span style={{ color: '#FF7631' }}>Choose file</span> to upload
                            </span>
                            <span
                                style={{ marginLeft: "8px", color: "#00276D", fontSize: "12px" }}
                            >
                                Select jpeg, png, or pdf up to 20MB.
                            </span>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={onUploadImage}
                            />
                        </label>
                    )}

                    {url && (
                        <div className="fileContainer">
                            <img src={url} alt="content" style={{
                                width: '100%',
                                height: '75px',
                                objectFit: 'cover',
                                borderRadius: '16px',
                                margin: "0",
                                padding: "0",



                            }} />
                            <button onClick={deleteFile} className="deleteButton">
                                <img src={del} className="deleteIcon" alt="Delete" />
                            </button>
                        </div>
                    )}
                    <div className='travelerInfo'>
                        <p>
                            Traveler Information
                        </p>
                        <div className='mainView'>
                            {travelers.map((traveler: any, index: any) => (
                                <TravelerInformation
                                    traveler={traveler}
                                    index={index}
                                    onPressVise={() => handleDownload(traveler.visaFile)}
                                    onPressPass={() =>
                                        handleDownloading(traveler.passportFile)
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginTop: "56px", width: "210px" }}>
                            <PrimaryButton
                                onClick={submitFile}
                                colorType={"Linear"}
                                type={'button'}
                                disabled={!url}
                            >
                                Share Ticket
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <CustomLoader />}
        </div>
    )
}

export default TravelTicketshare
