import React, { useState } from "react";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import styles from "../../BookingHotel.module.css";
import Avatar from "assets/logo.png";
import DownloadImagelogo from "assets/images/DownloadImagelogo.png";
import { useNavigate } from "react-router-dom";
const ToursBooking = () => {
  const [bookings, setBookings] = useState([
    {
      id: 1,
      image: Avatar,
      name: "Malam Jabba",
      duration: "Insurance Duration: 1 year",
      amount: "12,504",
      date: "12/12/2024 11:48 AM",
    },
  ]);
  const navigate = useNavigate();
  const handleGoToInsuranceBookingDetail = () => {
    navigate("/services/bookingtravel/BookingTravelDetail");
  };
  return (
    <div>
      <div className={styles.bookingContainer}>
        {bookings.map((booking) => (
          <div key={booking.id} className={styles.bookingCard}>
            <img
              src={booking.image}
              alt="Hotel"
              className={styles.bookingImage}
            />
            <div className={styles.bookingInfo}>
              <div className={styles.bookingDetails}>
                <h3 className={styles.hotelName}>{booking.name}</h3>
                <div className={styles.hotelRoom}>
                  <p> Total Rooms: {booking.duration}</p>
                  <p>Total Amount: {booking.amount}</p>
                </div>
                <div className={styles.hotelStatus}></div>
              </div>
              <div className={styles.bookingActions}>
                <p className={styles.bookingDate}>{booking.date}</p>
                <button
                  className={styles.viewDetailsButton}
                  onClick={handleGoToInsuranceBookingDetail}
                >
                  View Details
                  <img
                    src={DownloadImagelogo}
                    alt="Logo"
                    className={styles.downloadButtonLogo}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToursBooking;
