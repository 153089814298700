import TravelBooking from "pages/Home/HomeNavBar/NavBarr/BookingMenu/Travel";
import BookingTravelDetail from "pages/Home/HomeNavBar/NavBarr/BookingMenu/Travel/TravelDetail";
import BookingTourDetail from "pages/Home/HomeNavBar/NavBarr/Rewquest/TravelandTour/BookingTravelDetail";
import React from "react";
import { Routes, Route } from "react-router-dom";

const MyBookingTravelRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<TravelBooking />} />
      <Route path="/TravelDetail" element={<BookingTravelDetail />} />
      <Route path="/BookingTravelDetail" element={<BookingTourDetail />} />
      BookingTravelDetail
    </Routes>
  );
};

export default MyBookingTravelRoutes;
