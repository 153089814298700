import { useEffect, useState } from "react";
import classNames from "classnames";
import lstyle from "./Labdetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import img from "assets/images/ChughtaiLab logo 1.png";
import Footerr from "pages/Home/HomeNavBar/Footer";
import CardStyless from "../DoctarServices/Cards.module.css";
import { FaRegClock, FaRegCheckCircle } from "react-icons/fa";
import LaboratoriyProduct from "./LaboratoriyProduct";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import {
  AddRemovedFev,
  getAll_AdminTestCategories,
  getAll_Tests,
  getSingle_UserLaboratory,
} from "shared/services/UserService";
import { LoadingModal } from "shared/components";
import toast from "react-hot-toast";
import { set_User } from "shared/redux";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { LABORATORY_DETAILS } from "shared/utils/mainHeaderQuery";

const LabortoryDetail = () => {
  const [activeButton, setActiveButton] = useState("about");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [productData, setProductData] = useState<any>([]);
  const [test, setTest] = useState<any>([]);
  const dispatch = useDispatch();

  const { location, user } = useSelector((state: any) => state?.root?.common);

  const { state }: any = useLocation();

  useEffect(() => {
    setLoading(true);
    getSingleLab();
  }, []);

  const getSingleLab = () => {
    let params = {
      labId: state?.id,
      lat: location?.latitude,
      long: location?.longitude,
    };

    getSingle_UserLaboratory(params)
      .then((res: any) => {
        setData(res?.data);
        allAdmin_Categories();
      })
      .catch((err: any) => {
        console.log("single lab err...", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  const allAdmin_Categories = () => {
    getAll_AdminTestCategories()
      .then((res: any) => {
        setProductData(res?.data?.testCategories);
      })
      .catch((err: any) => {
        console.log("err...", err);
      })
      .finally(() => setLoading(false));
  };

  const get_Test = (cat: any) => {
    let params = {
      labId: state?.id,
      categoryName: cat,
    };
    getAll_Tests(params)
      .then((res: any) => {
        setTest(res?.data?.tests);
      })
      .catch((err: any) => {
        // console.log('err...', err);
      })
      .finally(() => {
        // setLoading(false)
      });
  };

  const handlefavourite = () => {
    const params = {
      type: "laboratory",
      itemId: state?.id,
    };
    console.log(params, "......params");
    AddRemovedFev(params)
      .then((res: any) => {
        dispatch(set_User(res?.data?.user));
        console.log(res?.data?.user, "......res?.data?.user");
        // dispatch(setFavorites(res?.data?.user.favourites));
        toast?.success(res?.data?.message);
      })
      .catch((err: any) => {
        console.log(err, ".....err");
      })
      .finally(() => {});
  };
  const isFavorite = user?.favourites?.some(
    (fav: any) => fav.itemId === state?.id && fav.favModel === "laboratory"
  );
  return (
    <div>
      <NavBreadCrumbs {...LABORATORY_DETAILS} />

      <div className={classNames(commonstyles.container)}>
        <div className={classNames(lstyle.btnConatiner)}>
          <button
            className={classNames(
              lstyle.first,
              activeButton === "about" ? lstyle.active : lstyle.inactive
            )}
            onClick={() => setActiveButton("about")}
          >
            About
          </button>

          <button
            className={classNames(
              lstyle.secound,
              activeButton === "Product" ? lstyle.active : lstyle.inactive
            )}
            onClick={() => setActiveButton("Product")}
          >
            Product
          </button>
        </div>
        {activeButton === "about" && (
          <div style={{ display: "flex", width: "35%" }}>
            <div className={CardStyless.cardWrapper}>
              <div className={CardStyless.cardImageWrapper}>
                <img
                  src={data?.lab?.logo || img}
                  alt="card img"
                  className={CardStyless.cardImage}
                />
              </div>
              <div className={CardStyless.cardBody}>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <div
                    className={classNames(
                      CardStyless.cardName,
                      commonstyles.colorBlue
                    )}
                  >
                    {data?.lab?.name}
                  </div>
                  <div onClick={handlefavourite}>
                    {isFavorite ? <FaHeart color="red" /> : <FaRegHeart />}
                  </div>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FaRegCheckCircle />
                  </span>
                  <span>Avaliable</span>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FaRegClock />
                  </span>
                  <span>
                    {data?.lab?.openTime}
                    {" - "}
                    {data?.lab?.closeTime}
                  </span>
                </div>
                {/* <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FiNavigation />
                  </span>
                  <span>500M from you</span>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {activeButton === "about" && (
          <div>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              Address
            </p>
            <p className={classNames(commonstyles.colorGray)}>
              {data?.lab?.location?.address}
            </p>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              Description
            </p>
            <p className={classNames(commonstyles.colorGray)}>
              {data?.lab?.description}
            </p>
            <div className={lstyle.marginBottomLaboratory}></div>
          </div>
        )}

        {/* {activeButton === "about" && (
                < />
         )} */}

        {activeButton === "Product" &&
          (loading ? (
            <LoadingModal />
          ) : (
            <LaboratoriyProduct
              productData={productData}
              get_Test={get_Test}
              test={test}
              labId={state?.id}
            />
          ))}
      </div>
      <Footerr />
    </div>
  );
};

export default LabortoryDetail;
