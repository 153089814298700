import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./hotelOrder.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import User from "assets/images/user.png";
import Arrow from "assets/images/arrow.png";
import Arrow2 from "assets/images/arrow2.png";
import Doctors from "assets/doctorss.png";
import DashboardTable from "shared/components/MediacalTables/DashboradTable/index";
import { useDispatch, useSelector } from "react-redux";
import PerviousNextButton from "shared/components/Pagination";
import HotelBookingTable from "shared/components/HotelTables/HotelBookingTable";
import Pagination from "shared/components/Pagination/Pagination";
import { hotelGETALLBOOKINGS } from "shared/services/Hotel";
import {
  setHotelBookingLength,
  setHotelBookingRenderFlag,
  setHotelBookings,
} from "shared/redux";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";
import { LoadingModal, RingLoader } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
function HotelBooking() {
  const [loading, setLoading] = useState(false);
  const [length, setLength] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pageno, setPageno] = useState(1);
  const { hotelBookings, hotelBookingRenderFlag, hotelBookingLength } =
    useSelector((state: any) => state.root.hotel);
  console.log("orders length", hotelBookingLength);
  const itemsPerPage = 10;
  const totalItems = hotelBookingLength;
  const fetchOrders = (pageno: number) => {
    setLoading(true);
    hotelGETALLBOOKINGS(pageno)
      .then((res: any) => {
        console.log("pas.............", res);

        if (res?.data?.bookings.length >= 0) {
          dispatch(setHotelBookings(res?.data?.bookings));
          dispatch(setHotelBookingLength(res?.data?.totalUsersBooking));
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (hotelBookingLength > itemTorender) {
      setCurrentPage(currentPage + 1);
      setPageno(pageno + 10);
      dispatch(setHotelBookingRenderFlag(true));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageno(pageno - 10);
      dispatch(setHotelBookingRenderFlag(true));
    }
  };

  const handleStatusChange = () => {
    fetchOrders(currentPage);
  };
  useEffect(() => {
    fetchOrders(currentPage);
  }, []);

  useEffect(() => {
    if (hotelBookingRenderFlag) {
      setLoading(true);
      fetchOrders(currentPage);
      dispatch(setHotelBookingRenderFlag(false));
    }
  }, [hotelBookingRenderFlag, currentPage]);
  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div
            className={classNames(commonstyles.mb24, commonstyles.flxBetween)}
          >
            <div className={classNames(commonstyles.flx)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Bookings
              </p>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={style.RefreshIcon}
                    onClick={() => fetchOrders(pageno)}
                  />
                </div>
              )}
            </div>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
          {/* {loading ? (
            <CustomLoader />
          ) : ( */}
          <HotelBookingTable bookings={hotelBookings} />
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default HotelBooking;
