import React from "react";
import { Routes, Route } from "react-router-dom";
import FullPaymentDetailHotel from "pages/Home/HomeNavBar/NavBarr/BookingMenu/FullPaymentDetailHotel";

const FullPaymentDetailHotelRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<FullPaymentDetailHotel />} />
    </Routes>
  );
};

export default FullPaymentDetailHotelRoute;
