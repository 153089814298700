import React, { useState } from "react";
import classNames from "classnames";
import MainHomeServices from "../../../mainHomeServices.module.css";
import commonStyles from "shared/utils/common.module.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { paraSignup } from "shared/services/Paramedic";
import { PrimaryButton } from "shared/components";
import { paramedicStaffBankSchema } from "shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { GrAttachment } from "react-icons/gr";
import ImgPicker from "shared/components/Img-picker";
import {
  setParamedicUserFormData,
  setParamedicUser_ID,
} from "shared/redux/reducers/paramedic/paramedicReducer";

import Attach from "../../../../../assets/images/attach.png";
import { CustomInput } from "shared/components";
import CustomLoader from "shared/components/New_Loader/Loader";
import { setDocUser_ID } from "shared/redux";

interface Props {
  handleClickNext: any;
}
const ParamedicStaffBankDetail = (props: Partial<Props>) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { paramedicUserFormData } = useSelector(
    (state: any) => state.root.paramedic
  );
  const { fcmToken, } =
    useSelector((state: any) => state.root.common);
  const { handleClickNext } = props;

  const formik = useFormik({
    initialValues: {
      paramedicStaffIncomeTextNumber: "",
      paramedicStaffTaxRegistrationNumber: "",
      paramedicAttachTaxFile: "",
      paramedicStaffBankName: "",
      paramedicStaffAccountHolderName: "",
      paramedicStaffAccountNumber: "",
    },
    validationSchema: Yup.object(paramedicStaffBankSchema),
    onSubmit: (values) => {
      console.log("hey");
      handleSubmit();
    },
  });

  const handleTaxFileUrl = (url: any) => {
    console.log("paramedicAttachTaxFile", url);
    formik.setFieldValue("paramedicAttachTaxFile", url);
  };

  const handleSubmit = async () => {

    dispatch(setParamedicUserFormData({ ...formik.values }));

    setLoading(true);
    let currentData = formik.values;

    let params = {
      name: paramedicUserFormData.name,
      doctorKind: "paramedic",
      cnicOrPassportNo: paramedicUserFormData.cnicOrPassportNo,
      cnicImage: paramedicUserFormData.cnicImage,
      qualifications: paramedicUserFormData.qualification,
      location: {
        lat: paramedicUserFormData?.lat,
        lng: paramedicUserFormData?.lng,
        address: paramedicUserFormData?.address,
        city: paramedicUserFormData?.city,
      },
      ...(paramedicUserFormData.nutritionistwebUrl && {
        website: paramedicUserFormData.nutritionistwebUrl,
      }),
      ...(paramedicUserFormData.nutritionisttwitterUrl && {
        twitter: paramedicUserFormData.nutritionisttwitterUrl,
      }),
      ...(paramedicUserFormData.nutritionistinstaUrl && {
        instagram: paramedicUserFormData.nutritionistinstaUrl,
      }),
      ...(paramedicUserFormData.nutritionistfbUrl && {
        facebook: paramedicUserFormData.nutritionistfbUrl,
      }),
      incomeTaxNo: currentData.paramedicStaffIncomeTextNumber,
      salesTaxNo: currentData.paramedicStaffTaxRegistrationNumber,
      taxFileImage: currentData.paramedicAttachTaxFile,
      bankName: currentData.paramedicStaffBankName,
      accountHolderName: currentData.paramedicStaffAccountHolderName,
      accountNumber: currentData.paramedicStaffAccountNumber,
      fcmToken,
    };
    console.log(params, '......params')
    paraSignup(params)
      .then((res: any) => {
        console.log("response.....", res?.data);
        handleClickNext();
        dispatch(setParamedicUserFormData({}));
        // dispatch(setParamedicUser_ID(res.data.paramedic._id));
        dispatch(setDocUser_ID(res.data.doctor._id));
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb28, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={classNames(commonStyles.mr32)}>
              <CustomInput
                placeholder="Income Text Number"
                id="paramedicStaffIncomeTextNumber"
                name="paramedicStaffIncomeTextNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.paramedicStaffIncomeTextNumber}
              />
              {formik.touched.paramedicStaffIncomeTextNumber &&
                formik.errors.paramedicStaffIncomeTextNumber ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.paramedicStaffIncomeTextNumber}
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mr32,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Sales Tax Registration Number"
              id="paramedicStaffTaxRegistrationNumber"
              name="paramedicStaffTaxRegistrationNumber"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStaffTaxRegistrationNumber}
            />
            {formik.touched.paramedicStaffTaxRegistrationNumber &&
              formik.errors.paramedicStaffTaxRegistrationNumber ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStaffTaxRegistrationNumber}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <ImgPicker
              placeholder="Attach Tax File"
              setData={handleTaxFileUrl}
            />
            {formik.touched.paramedicAttachTaxFile &&
              formik.errors.paramedicAttachTaxFile ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicAttachTaxFile}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(commonStyles.mb28, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.mr32,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Bank Name"
              id="paramedicStaffBankName"
              name="paramedicStaffBankName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStaffBankName}
            />
            {formik.touched.paramedicStaffBankName &&
              formik.errors.paramedicStaffBankName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStaffBankName}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Account Holder Name"
              id="paramedicStaffAccountHolderName"
              name="paramedicStaffAccountHolderName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStaffAccountHolderName}
            />
            {formik.touched.paramedicStaffAccountHolderName &&
              formik.errors.paramedicStaffAccountHolderName ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStaffAccountHolderName}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classNames(MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <CustomInput
              placeholder="Account Number for Amount Received  "
              id="paramedicStaffAccountNumber"
              name="paramedicStaffAccountNumber"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.paramedicStaffAccountNumber}
            />
            {formik.touched.paramedicStaffAccountNumber &&
              formik.errors.paramedicStaffAccountNumber ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.paramedicStaffAccountNumber}
              </div>
            ) : null}
          </div>
        </div>
        <div className={MainHomeServices.buttonWidth}>
          {" "}
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"ParamedicStaff"}
          />
        </div>
      </form>
      {loading && <CustomLoader />}
    </div>
  );
};

export default ParamedicStaffBankDetail;
