import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../../../assets/logo.png';
import styles from './BookingHotel.module.css';
import DownloadImagelogo from '../../../../../assets/images/DownloadImagelogo.png';
import NavigationBar from "./NavigateBar";

const BookingRentACar = React.memo((props) => {
  const navigate = useNavigate();
  type PaymentStatus = 'Full Payment' | 'Pending Payment' | 'The remaining amount is pending for booking.';

const handleGoToBookingDetail = (paymentStatus: PaymentStatus) => {
  if (paymentStatus === 'Full Payment') {
    navigate("/services/fullPaymentRentAcarDetail");
  } else {
    navigate("/services/fullPaymentDetailRentAcar");
  }
};

   const [bookings, setBookings] = useState([
        {
            id: 1,
            image: Avatar, 
            name: "Car Name",
            pickUp: "Pick Up date and time",
            amount: "12,504",
            paymentStatus: "Partial Amount",
            date: "12/12/2024 11:48 AM"
        },
        {
            id: 2,
            image: Avatar,
            name: "Car Name ",
            pickUp: "Pick Up date and time",
            amount: "12,504",
            paymentStatus: "Full Payment",
            date: "12/12/2024 11:48 AM"
        }
    ]);


return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Booking
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            My Booking
          </p>
        </div>
      </div>
      <div>
        <NavigationBar />
      </div>


      <div className={styles.bookingContainer}>
    {bookings.map(booking => (
        <div key={booking.id} className={styles.bookingCard}>
            <img src={booking.image} alt="Rent A Car" className={styles.bookingImage} />
            <div className={styles.bookingInfo}>
                <div className={styles.bookingDetails}>
                    <h3 className={styles.hotelName}>{booking.name}</h3>
                    <div  className={styles.hotelRoom}>
                    <p > {booking.pickUp}</p>
                    <p>{booking.amount}</p>
                    </div>
                    <div className={styles.hotelStatus}>
                    <p className={booking.paymentStatus === 'Full Payment' ? styles.fullPayment : styles.pendingPayment}>
                            {booking.paymentStatus}
                        </p>
                    </div>
                </div>
                <div className={styles.bookingActions}>
                    <p className={styles.bookingDate}>{booking.date}</p>
                    <button className={styles.viewDetailsButton}  onClick={() => handleGoToBookingDetail(booking.paymentStatus as PaymentStatus)}>View Details
                          <img src={DownloadImagelogo} alt="Logo" className={styles.downloadButtonLogo} />
                    </button>
                </div>
            </div>
        </div>
    ))}
</div>



      



       <Footerr />
    </div>
  );
});

export default BookingRentACar;