import React, { useState, useEffect } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./Style.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import NavigationBar from "../NavigateBar";
import logo from "assets/images/Edhilogo.png";

const BookingAmbulance = () => {
  // Heading data array
  const headingData = [
    { title: "From", detail: "154A Architect Society, Punjab, LHR" },
    { title: "To", detail: "Jinnah Hospital, Punjab, LHR" },
  ];

  // Detail data array
  const detailDataArray = [
    {
      logo: logo,
      name: "Edhi Ambulance",
      price: "PKR 9,000",
      status: "Your ambulance process is in running",
    },
    {
      logo: logo,
      name: "Edhi Ambulance",
      price: "PKR 9,000",
      status: "Your ambulance process is in running",
    },
  ];

  return (
    <div>
      <MainHeader
        mainHeading="Travel Agency"
        breadcrumb={["Home", "My Booking"]}
      />
      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <div>
          <NavigationBar />
        </div>

        <div className={style.maincontainer}>
          {/* Heading Container */}
          <div className={style.headingcontainer}>
            {headingData.map((item, index) => (
              <div key={index}>
                <p className={style.title}>{item.title}</p>
                <p className={style.detail}>{item.detail}</p>
              </div>
            ))}
          </div>

          {/* Detail Container */}
          {detailDataArray.map((detailData, index) => (
            <>
              <div key={index} className={style.detailcontainer}>
                <div className={classNames(commonstyle.flx)}>
                  <div className={style.imgcontainer}>
                    <img
                      src={detailData.logo}
                      alt=""
                      className={style.logoimg}
                    />
                  </div>
                  <div>
                    <p className={style.name}>{detailData.name}</p>
                  </div>
                </div>

                <div>
                  <p className={style.detaildata}>Ambulance Name</p>
                </div>
                <div>
                  <p className={style.detaildata}>{detailData.price}</p>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <p className={style.detail}>{detailData.status}</p>
              </div>
            </>
          ))}
        </div>
      </div>
      <Footerr />
    </div>
  );
};

export default BookingAmbulance;
