import DoctorAppoinmentPay from "pages/Services/DoctarServices/DoctorAppoinment";
import DoctorDetails from "pages/Services/DoctarServices/DoctorDetails";
import Nav_SelectionCards from "pages/Services/Nav_SelectionCard/Nav_SelectionCard";
import { Routes, Route } from "react-router-dom";

const HomeService_Cards = [
  {
    img: "image2.jpg",
    title: "Doctor",
    content: "Verified Doctors",
    color: "#FF8A8A",
    icon: "🩺",
  },

  {
    img: "image2.jpg",
    title: "Ambulance",
    content: "Prompt Aid, Trusted Care",
    color: "#FF8A8A",
    icon: "🚑",
  },
  {
    img: "image3.jpg",
    title: "Psychologist",
    content: "Empowering You to Thrive",
    color: "#DE987C",
    icon: "🧠",
  },
  {
    img: "image4.jpg",
    title: "Nutritionist",
    content: "Your Path to Vibrant Health",
    color: "#BCC3A0",
    icon: "🥗",
  },
  {
    img: "image5.jpg",
    title: "Physiotherapist",
    content: "Your Recovery, Our Priority",
    color: "#A2968E",
    icon: "💪",
  },
  {
    img: "image6.jpg",
    title: "Paramedic",
    content: "Verified Nurses",
    color: "#50B4C3",
    icon: "👩‍⚕️",
  },
];

const HomeServicesRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Nav_SelectionCards data={HomeService_Cards} />}
      />
      <Route path="/DoctorDetail" element={<DoctorDetails />} />
      <Route
        path="/DoctorAppoinmentPay/:id"
        element={<DoctorAppoinmentPay />}
      />
    </Routes>
  );
};

export default HomeServicesRoutes;
