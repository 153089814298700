import HotelAvability from "pages/Services/HotelServices/HotelAvability";
import HotelBooking from "pages/Services/HotelServices/HotelBooking";
import HotelDetails from "pages/Services/HotelServices/HotelDetails";
import HotelProperty from "pages/Services/HotelServices/HotelProperty";
import HotelService from "pages/Services/HotelServices/HotelServic";
import React from "react";
import { Routes, Route } from "react-router-dom";

const HotelRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HotelService />} />
      <Route path="/HotelDetails" element={<HotelDetails />} />
      <Route path="/HotelProperty" element={<HotelProperty />} />

      <Route path="/HotelAvability" element={<HotelAvability />} />
      <Route path="/HotelBooking" element={<HotelBooking />} />
    </Routes>
  );
};

export default HotelRoutes;
