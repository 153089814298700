import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { addParamedic } from "shared/services";
import styles from "./paramedicStaff.module.css";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "shared/components/DatePicker";
import DownloadImagelogo from "../../../assets/images/DownloadImagelogo.png";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";

import ThankyouModel from "shared/components/ThankyouModel";
import dayjs from "dayjs";
import { LoadingModal } from "shared/components";
import { PARAMEDIC_STAFF } from "shared/utils/mainHeaderQuery";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import CustomLoader from "shared/components/New_Loader/Loader";

const ParamedicStaff: React.FC = () => {
  // State management for form inputs
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contact, setContact] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [gender, setGender] = useState<string>("male");
  const [preferredDate, setPreferredDate] = useState<any>("");
  const [preferredTime, setAptTime] = useState<any>("");
  const [userArea, setUserArea] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [remarks, setRemarks] = useState<string>("");
  const [schedule, setSchedule] = useState<string>("");
  const [customSchedule, setCustomSchedule] = useState<string>("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSchedule(event.target.value);
  };
  const resetForm = () => {
    setName("");
    setEmail("");
    setContact("");
    setAddress("");
    setGender("male");
    setPreferredDate("");
    setAptTime("");
    setUserArea("");
    setCity("");
    setRemarks("");
    setSchedule("");
    setCustomSchedule("");
  };
  const submitForm = () => {
    setLoading(true);
    if (!preferredDate) {
      setDateError("Please select a valid date.");
      setLoading(false);
      return;
    } else {
      setDateError("");
    }

    if (!preferredTime) {
      setTimeError("Please select a valid time.");
    } else {
      setTimeError("");
    }
    const data = {
      name,
      email,
      contact,
      gender,
      preferredDate,
      preferredTime,
      userArea: {
        area: userArea,
        address: address,
        city: city,
      },
      schedule,
      customSchedule: schedule === "other" ? customSchedule : undefined,
      remarks,
    };
    console.log(data, "...data");
    addParamedic(data)
      .then((res: any) => {
        console.log(res?.data, "true");
        resetForm();
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  console.log(preferredTime, "..............preferredTime");
  return (
    <div>
      <NavBreadCrumbs {...PARAMEDIC_STAFF} />

      <div className={styles.paramedicFormContainer}>
        <h2 className={styles.paramedicFormHeading}>Paramedic Form</h2>
        <form
          className={styles.paramedicForm}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <div className={styles.formSectionsContainer}>
            <div className={styles.leftFormSection}>
              <h3 className={styles.patientDetailsHeading}>
                Add Patient Details
              </h3>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Name</label>
                <input
                  type="text"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Email</label>
                <input
                  type="email"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Contact
                </label>
                <input
                  type="tel"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your contact number"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  required
                />
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Address
                </label>
                <input
                  type="text"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className={styles.rightFormSection}>
              <div className={styles.inputGroupContainerParamedicForm}>
                <h4 className={styles.requirementsHeading}>
                  Paramedic Requirements
                </h4>
                <label className={styles.inputLabelParamedicForm}>Gender</label>
                <div className={styles.radioOptionsContainerParamedicForm}>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={gender === "male"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    Male
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={gender === "female"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    Female
                  </label>
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicFormDate}>
                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Preferred Date
                  </label>
                  <Datepicker
                    className={styles.timeInputParamedicForm}
                    placeholder="Select a date"
                    setData={setPreferredDate}
                  />
                  {dateError && (
                    <span style={{ color: "red" }}>{dateError}</span>
                  )}
                </div>

                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Appointment Time
                  </label>
                  <CustomTimePicker
                    placeholder="Time"
                    value={preferredTime}
                    setData={setAptTime}
                  />
                  {timeError && (
                    <span className={styles.errorTextParamedicForm}>
                      {timeError}
                    </span>
                  )}
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Area</label>
                <div className={styles.areaCityContainerParamedicForm}>
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your area"
                    value={userArea}
                    onChange={(e) => setUserArea(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Schedule
                </label>
                <div className={styles.radioOptionsContainerParamedicForm}>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="day"
                      checked={schedule === "day"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={handleScheduleChange}
                      required
                    />
                    Day
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="night"
                      checked={schedule === "night"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={handleScheduleChange}
                      required
                    />
                    Night
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="other"
                      checked={schedule === "other"}
                      className={styles.radioInputParamedicFormRadioRadio}
                      onChange={handleScheduleChange}
                      required
                    />
                    Other
                  </label>
                </div>
              </div>

              {schedule === "other" && (
                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Specify Schedule
                  </label>
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your schedule"
                    value={customSchedule}
                    onChange={(e) => setCustomSchedule(e.target.value)}
                    required
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.reviewContainerParamedicForm}>
            <label className={styles.inputLabelParamedicForm}>Remarks</label>
            <textarea
              className={styles.reviewTextAreaParamedicForm}
              placeholder="Write your remarks here..."
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              required
            />
          </div>

          <button type="submit" className={styles.submitButtonParamedicForm}>
            Submit
            <img
              src={DownloadImagelogo}
              alt="Logo"
              className={styles.downloadButtonLogo}
            />
          </button>
        </form>
        {loading && <CustomLoader />}
      </div>
      {isModalVisible && (
        <ThankyouModel
          mainMessageLine1="Your request has been"
          mainMessageLine2="successfully shared"
          subMessage="We will notify you in a few minutes."
          footerMessage="Thank you."
          onClose={handleCloseModal}
        />
      )}
      <Footerr />
    </div>
  );
};

export default ParamedicStaff;
