import React, { useState } from 'react';
import moment from 'moment';
const FlightDetailsCard = ({ details, itemIndex, show }: { details?: any, itemIndex?: any, show?: any }) => {


    return (

        <>
            {show && <span style={styles.subTitle}>Return Flights Details</span>}
            <div key={itemIndex}>
                {details?.length > 0 ? (
                    details.map((user: any, index: any) => (
                        <div key={index} style={{ gap: '4px', marginTop: '8px' }}>
                            <div style={styles.section}>
                                <span style={styles.labelText}>Company Logo</span>
                                <img
                                    src={user?.companyLogo}
                                    alt="Company Logo"
                                    style={{
                                        width: '38px',
                                        height: '38px',
                                        objectFit: 'contain',
                                        borderRadius: '32px',
                                    }}
                                />
                            </div>
                            <div style={styles.section}>
                                <span style={styles.labelText}>Company Name</span>
                                <span style={styles.valueText}>{user?.companyName}</span>
                            </div>
                            <span style={styles.valueText}>Direct</span>
                            <div style={styles.section}>
                                <span style={styles.labelText}>From</span>
                                <span style={styles.valueText}>{user?.from}</span>
                            </div>
                            <div style={styles.section}>
                                <span style={styles.labelText}>To</span>
                                <span style={styles.valueText}>{user?.to}</span>
                            </div>
                            <div style={styles.section}>
                                <span style={styles.labelText}>Departure Date</span>
                                <span style={styles.valueText}>
                                    {moment(user?.departureDate).format('MM-DD-YYYY')}
                                </span>
                            </div>
                            <div style={styles.section}>
                                <span style={styles.labelText}>Departure Time</span>
                                <span style={styles.valueText}>
                                    {moment(user?.departureTime).format('hh:mm A')}
                                </span>
                            </div>
                            <div style={styles.section}>
                                <span style={styles.labelText}>Arrival Date</span>
                                <span style={styles.valueText}>{user?.arrivalDate}</span>
                            </div>
                            <div style={styles.section}>
                                <span style={styles.labelText}>Arrival Time</span>
                                <span style={styles.valueText}>
                                    {moment(user?.arrivalTime).format('hh:mm A')}
                                </span>
                            </div>
                            <span style={styles.subTitle}>Flight Amenities</span>
                            {user?.amenities?.map((amenity: any, index: any) => (
                                <div key={index} style={styles.section}>
                                    <span style={styles.valueText}>{amenity}</span>
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <span>No flight details found</span>
                )}

            </div>
        </>
    );
};

export default FlightDetailsCard;

// Example styling (can be customized or added to CSS file)
const styles = {
    card1: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: '16px',
    },
    drop: {
        width: '20px',
        height: '20px',
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '8px',
    },
    labelText: {
        fontSize: '12px',
        color: '#7D7D7D',
    },
    valueText: {
        fontSize: '14px',
        color: '#0E54A3',
    },
    subTitle: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#0E54A3',
        marginTop: '8px',
    },
    totalPrice: {
        fontWeight: 'bold',
        color: '#333',
        marginTop: '16px',
    },
    ticketPrice: {
        fontSize: '14px',
        color: '#0E54A3',
        marginBottom: '16px',
    },
};
