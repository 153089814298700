import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import styles from "./style.module.css";
import TravelCards from "./TravelCards";
import Footerr from "pages/Home/HomeNavBar/Footer";
import TravelFlight from "./TravelFlight";
import { AddRemovedFev, getUpcomingTours } from "shared/services";
import { useDispatch } from "react-redux";
import { set_User } from "shared/redux";
import toast from "react-hot-toast";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { TRAVEL_AGENCY } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";

const TravelServices: React.FC = () => {
  const [activeTab, setActiveTab] = useState("tour");
  const [data, setdata] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (activeTab === "tour") {
      allUpcoming();
    }
  }, [activeTab]);
  const allUpcoming = () => {
    setLoading(true);
    getUpcomingTours(1)
      .then((res: any) => {
        console.log(res?.data);
        setdata(res?.data?.upcomingSchedules);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false once the request is completed
      });
  };

  const handlefavourite = (id: any) => {
    const params = {
      type: activeTab,
      itemId: id,
    };

    console.log(params, "......params");
    AddRemovedFev(params)
      .then((res: any) => {
        dispatch(set_User(res?.data?.user));
        console.log(res?.data?.user, "......res?.data?.user");
        // dispatch(setFavorites(res?.data?.user.favourites));
        toast?.success(res?.data?.message);
      })
      .catch((err: any) => {
        console.log(err, ".....err");
      })
      .finally(() => {});
  };

  return (
    <div>
      <NavBreadCrumbs {...TRAVEL_AGENCY} />

      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <div className={styles.tabContainer}>
          <div
            className={classNames(styles.tab, {
              [styles.tabActive]: activeTab === "tour",
              [styles.tabInactive]: activeTab !== "tour",
            })}
            onClick={() => handleTabClick("tour")}
          >
            Tour
          </div>
          <div
            className={classNames(styles.tab, {
              [styles.tabActive]: activeTab === "flight",
              [styles.tabInactive]: activeTab !== "flight",
            })}
            onClick={() => handleTabClick("flight")}
          >
            Flight
          </div>
        </div>

        {activeTab === "tour" ? (
          <TravelCards
            data={data}
            handlefavourite={handlefavourite}
            activeTab={activeTab}
          />
        ) : (
          <div>
            <TravelFlight />
          </div>
        )}
      </div>
      {loading && <CustomLoader />}
      <Footerr />
    </div>
  );
};

export default TravelServices;
