import toast from "react-hot-toast";
import { confirmEmail, updatePassword } from "shared/services";

export const initialValues = {
  email: "",
  newPassword: "",
  confirmPassword: "",
  verificationCode: "",
};

export const onSumbit = (
  setLoading: any,
  values: any,
  state: any,
  navigate: any
) => {
  setLoading(true);
  let params: any = {
    code: values?.verificationCode,
    email: state.email,
  };
  confirmEmail(params)
    .then(() => {
      let params = {
        email: state.email,
        newPassword: values?.newPassword,
        verificationCode: values?.verificationCode,
      };
      updatePassword(params)
        .then((res: any) => {
          toast?.success(res?.data?.message);
          navigate("/user/login", {
            state: { type: "forgot" },
          });
        })
        .catch((err: any) => {
          if (err?.response?.data?.message) {
            toast?.error(err?.response.data?.message);
          }
        })
        .finally(() => setLoading(false));
    })
    .catch((err: any) => {
      toast?.error(err?.response?.data?.message);
    })
    .finally(() => setLoading(false));
};
