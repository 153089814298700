
import MyOrderDetail from "pages/Home/HomeNavBar/NavBarr/MyOrder.tsx/MyOrderDetail";
import React from "react";
import { Routes, Route } from "react-router-dom";

const MyOrderDetailRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<MyOrderDetail />} />
    </Routes>
  );
};

export default MyOrderDetailRoute;
