import React, { useEffect, useRef, useState } from "react";
import styles from "./navBarr.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import Logo from "assets/images/whiteLogo.png";
import { Avatar } from "@mui/material";
import { FaBars } from "react-icons/fa6";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, set_User } from "shared/redux";
import { IoCartOutline } from "react-icons/io5";
import { logoutAll } from "shared/services/UserService";
import { MenuData, onOpenMenuData, TopMenuList } from "shared/utils";
import { LoadingModal } from "shared/components";
import { FaUser } from "react-icons/fa";
import toast from "react-hot-toast";
import CustomLoader from "shared/components/New_Loader/Loader";

export default function NavBarr() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch: any = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cart, isLoggedIn, user } = useSelector(
    (state: any) => state.root.common
  );
  console.log("🚀 ~ NavBarr ~ isLoggedIn:", isLoggedIn);

  console.log("name:", user?.name);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  useEffect(() => {
    console.log("🚀 ~ NavBarr ~ isLoggedIn changed:", isLoggedIn);
  }, [isLoggedIn]);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClick = (item: any) => {
    if (item?.title == "Logout") {
      handleLogout();
    } else {
      navigate(item?.screen);
    }
  };

  const handleLogout = () => {
    setLoading(true);
    logoutAll()
      .then(async () => {
        await dispatch(set_User(null));
        await dispatch(setIsLoggedIn(false));
        localStorage.clear();
        navigate("/");
      })
      .catch((err: any) => {
        console.log(err, "...error");
        // toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const isActive = (path: string) => location.pathname === path;

  return (
    <div>
      <div className={styles.navContainer}>
        <div>
          <img src={Logo} className={styles.logo} alt="Logo" />
        </div>
        <div className={classNames(commonstyles.flx, styles.NavBar)}>
          {TopMenuList?.map((item: any) => (
            <li
              onClick={() => navigate(item?.active)}
              className={classNames(commonstyles.semiBold, {
                [styles.activeLink]: isActive(item?.active),
              })}
            >
              {item?.title}
            </li>
          ))}
        </div>

        {isLoggedIn ? (
          <>
            <li className={classNames(commonstyles.semiBold)}>
              <div
                onClick={() => navigate("/services/pharmacy/PharmacyCart")}
                style={{ position: "relative", display: "inline-block" }}
              >
                <IoCartOutline
                  style={{
                    fontSize: "24px",
                    color: "#f3f3f3",
                    cursor: "pointer",
                  }}
                />
                {cart?.length > 0 && (
                  <span className={styles.cart}>{cart?.length}</span>
                )}
              </div>
            </li>
            <div
              className={styles.profile}
              ref={dropdownRef}
              onClick={toggleDropdown}
            >
              <p className={classNames(styles.user_name)}>{user?.name}</p>
              <Avatar style={{ height: 30, width: 30 }} src={user?.userImage} />
              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <ul className={styles.dropdownMenu}>
                  <div>
                    <img src={Logo} className={styles.logo} alt="Logo" />
                    {MenuData?.map((item) => (
                      <MenuItem
                        key={item.title} // Adding a unique key for each item
                        icon={item.icon}
                        text={item.title}
                        onClick={() => handleClick(item)}
                      />
                    ))}
                  </div>
                </ul>
              )}
            </div>
          </>
        ) : (
          <>
            <p
              onClick={() => navigate("/joinVender")}
              className={classNames(styles.join, {
                [styles.joinactive]: isActive("/joinVender"),
              })}
            >
              Join As a Provider
            </p>
            <div
              className={styles.login_button}
              onClick={() => navigate("/user/login")}
            >
              <p>Log In</p>
            </div>
          </>
        )}

        <div>
          {isLoggedIn && (
            <div style={{ position: "relative", display: "inline-block" }}>
              <FaUser
                className={styles.userIcon}
                onClick={toggleDropdown}
                style={{ cursor: "pointer" }}
              />
              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <ul className={styles.dropdownMenuMobile}>
                  <div>
                    <img src={Logo} className={styles.logo} alt="Logo" />
                    {MenuData?.map((item: any) => (
                      <MenuItem
                        key={item?.title} // Added key for menu items
                        icon={item?.icon}
                        text={item?.title}
                        onClick={() => handleClick(item)}
                      />
                    ))}
                  </div>
                </ul>
              )}
            </div>
          )}
          <FaBars
            className={styles.barbtn}
            style={{ color: "black" }}
            onClick={toggleMenu}
          />
          {isMenuOpen && (
            <>
              <div
                className={`${styles.mobileMenu} ${isMenuOpen ? "open" : ""}`}
              >
                <div className={styles.menuHeader}>
                  <img src={Logo} className={styles.Headerlogo} alt="Logo" />
                  <IoClose
                    className={styles.Headerclose}
                    onClick={() => {
                      setIsMenuOpen(false);
                    }}
                  />
                </div>
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {onOpenMenuData?.map((item: any) => (
                    <Link
                      className={styles.menulist}
                      to={item?.to}
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                    >
                      {item?.title}
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
}

const MenuItem = ({
  icon: Icon,
  text,
  onClick,
}: {
  icon: any;
  text: any;
  onClick: any;
}) => {
  return (
    <li onClick={onClick}>
      <div className={styles.menuItem}>
        <Icon className={styles.menuIcon} />
        <p>{text}</p>
      </div>
    </li>
  );
};
