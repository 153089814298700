import React, { useEffect, useState } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./Appointment.module.css";
import Footerr from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../../../assets/logo.png";
import PaymentMyAppointMent from "./PaymentMyAppointMent";
import DownloadImagelogo from "../../../../assets/images/DownloadImagelogo.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import Checkout from "shared/services/stripe/checkout";
import { setPaymentParams } from "shared/redux";
import toast from "react-hot-toast";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { MY_APPOINTMENT_DETAILS } from "shared/utils/mainHeaderQuery";

const MyAppointmentDetail = React.memo((props) => {
  const [partialAmountPaid, setPartialAmountPaid] = useState<any>(null);
  const [processFeeUSD, setProcessFeeUSD] = useState<any>(null);
  const [stripeOpen, setStripeOpen] = useState(false);

  const [totalAmountUSD, setTotalAmountUSD] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { user, paymentParams } = useSelector(
    (state: any) => state?.root?.common
  );
  let gateWay = state?.data?.gatewayName === "stripe" ? true : false;
  let calculateAmount =
    state?.data?.paidByUserAmount - state?.data?.processingFee;
  let remainingAmountUSD = totalAmountUSD - partialAmountPaid;
  let remainingAmountPKR = state?.data?.amount - calculateAmount;

  let payableRemainingAmount = remainingAmountUSD + state?.data?.processingFee;

  // let remainingAmount=

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get(
        `https://v6.exchangerate-api.com/v6/20d8f762235d76eb03fc8d3b/pair/PKR/USD`
      );
      const rate = response.data.conversion_rate;
      await setPartialAmountPaid(calculateAmount * rate);
      await setTotalAmountUSD(state?.data?.amount * rate);
      await setProcessFeeUSD(state?.data?.processingFee * rate);
    } catch (err: any) {}
  };
  console.log(".....paymentParams....", state?.data?.status);

  const handlePayment = async () => {
    if (state?.data?.gatewayName == "stripe") {
      await dispatch(
        setPaymentParams({
          appointmentId: state?.data?._id,
          paidByUserAmount: payableRemainingAmount,
          processingFee: state?.data?.processingFee,
        })
      );
      setStripeOpen(true);
    } else {
      toast.error("Payment with Pakistan is coming Soon");
    }
  };

  return (
    <div>
      <NavBreadCrumbs {...MY_APPOINTMENT_DETAILS(stripeOpen)} />
      <div style={{ marginTop: "40px" }}></div>

      {stripeOpen ? (
        <Checkout
          serviceName={"remainingDoctor"}
          convertedAmount={payableRemainingAmount}
        />
      ) : (
        <div className={styles.containerDetail}>
          <div className={styles.appointmentSectionDetail}>
            <h1 className={styles.headingMainDetail}>Your Appointment ID </h1>
            <span className={styles.appointmentIdDetail}>APT1234</span>
            <div className={styles.dashedLineDetail}></div>
            <div className={styles.infoDetail}>
              <div className={styles.rowDetail}>Patient: {user?.name}</div>
              <div className={styles.rowDetail}>
                Doctor: {state?.data?.doctorId?.name}
              </div>
              <div className={styles.rowDetail}>
                Clinic: {state?.data?.doctorId?.clinicName}
              </div>
              <div className={styles.rowDetail}>
                Address: {state?.data?.doctorId?.location?.address}
              </div>
              <div className={styles.rowDetail}>
                Date:{" "}
                {moment(state?.data?.appointmentDateAndTime).format(
                  "DD/MM/YYYY, hh:mm A"
                )}
              </div>
              <div className={styles.rowDetail}>
                Payment:{" "}
                <span
                  style={{
                    color: state?.data?.isPaidFull == false ? "red" : "green",
                    marginLeft: "5px",
                  }}
                >
                  {state?.data?.isPaidFull == false
                    ? "Outstanding"
                    : "Completed"}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.paymentSectionDetail}>
            {state?.data?.isPaidFull == false && (
              <>
                <div className={styles.dashedLineDetailPayment}></div>
                <h2 className={styles.paymentHeading}>Payment</h2>
                <div className={styles.paymentInfoDetail}>
                  <div className={styles.rowDetail}>
                    <span>Total Amount:</span>
                    <span className={styles.amountRight}>
                      {gateWay
                        ? `$${totalAmountUSD}`
                        : `PKR ${state?.data?.amount}`}
                    </span>
                  </div>

                  <div className={styles.rowDetail}>
                    <span>Partial Amount:</span>
                    <span className={styles.amountRight}>
                      {gateWay
                        ? `$${partialAmountPaid?.toFixed(2)}`
                        : `PKR ${calculateAmount}`}
                    </span>
                  </div>
                  {gateWay && (
                    <div className={styles.rowDetail}>
                      <span>Remaining Amount:</span>
                      <span className={styles.amountRight}>
                        {gateWay
                          ? `$${remainingAmountUSD?.toFixed(2)}`
                          : `PKR ${remainingAmountPKR}`}
                      </span>
                    </div>
                  )}
                  {gateWay && (
                    <div className={styles.rowDetailAmount}>
                      <span> Processing Fee:</span>
                      <span
                        className={styles.amountRightAmount}
                      >{`$${state?.data?.processingFee}`}</span>
                    </div>
                  )}
                  <div className={styles.rowDetailAmount}>
                    <span> Payable Amount:</span>
                    <span className={styles.amountRightAmount}>
                      {gateWay
                        ? `$${payableRemainingAmount.toFixed(2)}`
                        : `PKR ${remainingAmountPKR}`}
                    </span>
                  </div>

                  <div className={styles.rowDetailAmount}>
                    <span>Due Date:</span>
                    <span className={styles.amountRightAmount}>
                      {moment(state?.data?.appointmentDateAndTime).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                  </div>
                </div>
              </>
            )}

            <div>
              <button
                className={styles.paymentButtonDetail}
                style={{
                  backgroundColor:
                    state?.data?.isPaidFull == false ? "#0056b3" : "green",
                }}
                disabled={state?.data?.isPaidFull == false ? false : true}
                onClick={handlePayment}
              >
                {state?.data?.isPaidFull == false ? "Payment" : "Completed"}

                {state?.data?.isPaidFull == false && (
                  <img
                    src={DownloadImagelogo}
                    alt="Logo"
                    className={styles.downloadButtonLogo}
                  />
                )}
              </button>
              {/* <PaymentMyAppointMent
                isOpen={isModalOpen}
                onClose={handlePayment}
              /> */}
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "40px" }}></div>
      <Footerr />
    </div>
  );
});

export default MyAppointmentDetail;
