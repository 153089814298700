import React, { useState } from "react";
import classNames from "classnames";
import MainHomeServices from "../../../mainHomeServices.module.css";
import commonStyles from "shared/utils/common.module.css";
import { CustomInput } from "shared/components";
import { paramedicStaffBasicInfoSchema } from "shared/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PrimaryButton } from "shared/components";
import { setParamedicUserFormData } from "shared/redux/reducers/paramedic/paramedicReducer";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "shared/components/DatePicker";
import LocationInput from "shared/components/LocationInput";
import dayjs from "dayjs";
import ImgPicker from "shared/components/Img-picker";

interface Props {
  handleClickNext: any;
}

const ParamedicStaffBasicInfo = (props: Partial<Props>) => {
  const dispatch = useDispatch();

  const { handleClickNext } = props;
  const formik: any = useFormik({
    initialValues: {
      name: "",
      cnicOrPassportNo: "",
      qualification: "",
      address: "",
      lat: "",
      lng: "",
      city: "",
      cnicExpiry: '',
      cnicImage: "",
    },
    validationSchema: Yup.object(paramedicStaffBasicInfoSchema),
    onSubmit: (values) => {
      console.log("hey");
      handleSubmit();
    },
  });
  const [cnicExpiryDate, setCnicExpiryDate] = useState(
    formik?.cnicExpiry
      ? dayjs(formik?.cnicExpiry)
      : null
  );
  const handleSelect = (value: any) => {
    console.log(value, '..............value')
    formik.setFieldValue("address", value.label);
    formik.setFieldValue("lat", value.lat);
    formik.setFieldValue("lng", value.lng);
    formik.setFieldValue("city", value.city);
  };
  const handleCnicUrl = (url: any) => {
    formik.setFieldValue("cnicImage", url);
  };

  const handleSubmit = async () => {
    await dispatch(setParamedicUserFormData(formik.values));
    handleClickNext();
  };
  const handleCnicExpiry = (date: any) => {
    const selectedDate = dayjs(date);
    setCnicExpiryDate(selectedDate);
    const formattedDate = date.toISOString().split("T")[0];
    formik.setFieldValue("cnicExpiry", formattedDate);
  };
  console.log(formik?.values?.cnicExpiry)
  const cnicImageUrl = formik?.cnicImage
    ? formik?.cnicImage?.split("/")?.pop()
    : "";
  return (
    <div className={classNames(commonStyles.col12, commonStyles.mt56)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Name"
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name &&
                formik.errors.name ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.name}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <CustomInput
                placeholder="CNIC / Passport Number (Optinal)"
                id="cnicOrPassportNo"
                name="cnicOrPassportNo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.cnicOrPassportNo}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <Datepicker
                placeholder="CNIC / Passport Expiry (Optional)"
                setData={handleCnicExpiry}
                value={cnicExpiryDate}
              />
            </div>
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              <ImgPicker
                placeholder="CNIC / Passport Image (Optional)"
                setData={handleCnicUrl}
                initialValue={cnicImageUrl}
              />
            </div>
          </div>
        </div>
        <div className={classNames(commonStyles.mb16, MainHomeServices.flx)}>
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            {" "}
            <div className={commonStyles.mr32}>
              {" "}
              <CustomInput
                placeholder="Qualification"
                id="qualification"
                name="qualification"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.qualification}
              />
            </div>
            {formik.touched.qualification &&
              formik.errors.qualification ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.qualification}
              </div>
            ) : null}
          </div>
          <div
            className={classNames(
              commonStyles.col4,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
          </div>
        </div>


        <div
          className={classNames(
            MainHomeServices.flx,
            commonStyles.col12,
            commonStyles.colsm12
          )}
        >
          <div
            className={classNames(
              commonStyles.col12,
              commonStyles.colsm12,
              commonStyles.mtsm28
            )}
          >
            <div className={commonStyles.mr32}>
              <LocationInput
                placeholder="Clinic Address"
                setData={handleSelect}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className={classNames(commonStyles.error)}>
                  *{formik.errors.address}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={classNames(MainHomeServices.buttonWidth)}>
          <PrimaryButton
            children={"Next"}
            type="submit"
            colorType={"ParamedicStaff"}
          />
        </div>
      </form>
    </div>
  );
};

export default ParamedicStaffBasicInfo;
