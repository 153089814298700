
import BookingRentACarFullPayment from "pages/Home/HomeNavBar/NavBarr/BookingMenu/BookingRentACarFullPayment";
import React from "react";
import { Routes, Route } from "react-router-dom";

const BookingRentACarFullPaymentRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<BookingRentACarFullPayment />} />
    </Routes>
  );
};

export default BookingRentACarFullPaymentRoute;
