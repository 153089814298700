import { useEffect } from "react";
import classNames from "classnames";
import styles from "./patientguide.module.css";
import commonstyles from "shared/utils/common.module.css";
import Footerr from "../Footer";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { BsThreads, BsTwitterX } from "react-icons/bs";
import { FaSnapchatGhost } from "react-icons/fa";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { PATEINT_GUIDE } from "shared/utils/mainHeaderQuery";

export default function PatientGuide() {
  useEffect(() => {
    document.title = "MediTour Global | Patient Guide";
    window.scrollTo(0, 0);
  });

  const openGmail = () => {
    const sender = encodeURIComponent("info@meditour.global");
    const url = `https://mail.google.com/mail/?view=cm&fs=1&su=${sender}`;
    window.open(url, "_blank");
  };
  return (
    <div>
      <NavBreadCrumbs {...PATEINT_GUIDE} />
      <div className={styles.container} style={{ marginTop: "70px" }}>
        <div className={styles.flxBetween}>
          <div
            className={classNames(
              commonstyles.col6,
              commonstyles.colsm12,
              commonstyles.colmd12
            )}
          >
            <p
              className={classNames(
                commonstyles.fs16,
                styles.mt16,
                styles.textcolor
              )}
              style={{ textAlign: "justify" }}
            >
              Welcome to MediTour Global! We provide you with a seamless and
              supportive experience throughout your medical journey. Our patient
              guide is designed to help you understand the process, from initial
              contact to post-treatment follow-up, ensuring you have all the
              information you need for a successful and stress-free experience.
            </p>
            <div>
              <path
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Getting </span>
                <span className={styles.colorOrange}>Started</span>{" "}
              </path>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Contact Us: Reach out to us via our website, email, or phone.
                Our friendly customer service team will assist you in starting
                your medical tourism journey.
                <br />
                Initial Consultation: We will arrange an initial consultation to
                understand your medical needs, preferences, and any specific
                requirements you may have.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}>
                  {" "}
                  Choosing Your Healthcare{" "}
                </span>
                <span className={styles.colorOrange}>Provider</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Medical Assessment: Our team will review your medical history
                and current health status to recommend the best healthcare
                providers for your needs.
                <br />
                Provider Selection: Choose from a list of internationally
                accredited hospitals and leading specialists in Pakistan,
                tailored to your specific medical condition.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Planning Your </span>
                <span className={styles.colorOrange}>Trip</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Travel Arrangements: We assist with booking flights, arranging
                visas, and coordinating travel schedules to ensure a smooth
                journey.
                <br />
                Accommodation: Select from a range of comfortable and convenient
                accommodation options near your treatment facility.
                <br />
                Itinerary: Receive a detailed itinerary outlining your medical
                appointments, travel plans, and any additional services you may
                require.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Before Your </span>
                <span className={styles.colorOrange}>Departure</span>{" "}
              </p>
              <p
                className={classNames(
                  styles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Pre-Travel Instructions: Receive comprehensive pre-travel
                guidelines, including medical records to bring, necessary
                medications, and travel tips.
                <br />
                Support: Our team is available to answer any questions and
                provide support as you prepare for your trip.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> During Your </span>
                <span className={styles.colorOrange}>Treatment</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Arrival Assistance: Enjoy a warm welcome and assistance upon
                arrival, including airport transfers to your accommodation.
                <br />
                Medical Consultations: Attend scheduled consultations and
                treatments with your selected healthcare providers.
                <br />
                Local Support: Our local representatives are available to assist
                you with any needs or concerns during your stay.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Post-Treatment </span>
                <span className={styles.colorOrange}>Care</span>{" "}
              </p>
              <p
                className={classNames(
                  styles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Recovery Plan: Receive a detailed post-treatment recovery plan,
                including follow-up consultations and care instructions.
                <br />
                Follow-Up Appointments: Schedule follow-up video consultations
                with your healthcare provider to monitor your progress and
                address any concerns.
                <br />
                Ongoing Support: Our team remains available to assist with any
                post-treatment needs and ensure a smooth recovery process.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Additional </span>
                <span className={styles.colorOrange}>Services</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Translation Services: Access professional translation services
                to facilitate clear communication with healthcare providers.
                <br />
                Personalized Assistance: Enjoy personalized assistance for
                special requirements, dietary needs, and mobility support.
                <br />
                Leisure Activities: Explore optional leisure activities and
                cultural experiences during your stay, making your medical trip
                a holistic and enjoyable experience.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Emergency </span>
                <span className={styles.colorOrange}>Assistance</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                In case of any emergencies, our 24/7 support team is ready to
                provide immediate assistance and coordinate necessary medical
                care.
              </p>
            </div>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Contact </span>
                <span className={styles.colorOrange}>US</span>{" "}
              </p>{" "}
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                For any questions or to begin your medical tourism journey,
                please contact us:
                <br />
                <br />
                Website:
                <a
                  href="https://meditour.global/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.mailBtn}
                >
                  {" "}
                  www.meditour.global
                </a>
                <br />
                Email:{" "}
                <button className={styles.mailBtn} onClick={openGmail}>
                  info@meditour.global
                </button>
              </p>
            </div>{" "}
            <br />
            <p
              className={classNames(
                commonstyles.fs16,
                styles.mt16,
                styles.textcolor
              )}
              style={{ textAlign: "justify" }}
            >
              At MediTour Global, your health and well-being are our top
              priorities. We are committed to providing you with exceptional
              medical care and a supportive, stress-free experience. Let us
              guide you on your path to better health.
            </p>
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}> Get in Touch with </span>
                <span className={styles.colorOrange}>Us</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Welcome to MediTour! We’re here to answer all your questions and
                assist you in planning your medical tourism journey. We’re here
                to make your medical journey as smooth and stress-free as
                possible. Don’t hesitate to get in touch with us for any
                assistance.
              </p>
            </div>{" "}
            <div>
              <p
                className={classNames(
                  styles.fs24,
                  styles.mt16,
                  styles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                <span className={styles.colorBlue}>Privacy </span>
                <span className={styles.colorOrange}>Note:</span>{" "}
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.mt16,
                  styles.textcolor
                )}
                style={{ textAlign: "justify" }}
              >
                Your privacy is important to us. All your personal information
                will be kept confidential and used solely for the purpose of
                assisting you with your inquiry.
                <br /> <strong> Email:</strong>
                <button className={styles.mailBtn} onClick={openGmail}>
                  info@meditour.global
                </button>
                <a
                  style={{ cursor: "pointer" }}
                  href="mailto:"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                </a>
                <br />
                <strong> Call us at: USA: </strong>
                813-550-4999
                <br /> <strong>Canada: </strong> 437-259-5662
                <br /> <strong> Pakistan: </strong>
                0092-42-37885101-4, 0092-42-35191168
                <br />
                <strong>Timing </strong> 24/7
                <br />
              </p>
            </div>
            <div className={classNames(commonstyles.flx, styles.gap16)}>
              <a
                href="https://www.facebook.com/profile.php?id=61560461618333&viewas=&show_switched_toast=false&show_switched_tooltip=false&is_tour_dismissed=false&is_tour_completed=false&show_podcast_settings=false&show_community_review_changes=false&should_open_composer=false&badge_type=NEW_MEMBER&show_community_rollback_toast=false&show_community_rollback=false&show_follower_visibility_disclosure=false&bypass_exit_warning=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className={styles.socialIcons} />
              </a>
              <a
                href="https://www.instagram.com/meditour.global/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram className={styles.socialIcons} />
              </a>
              <a
                href="https://twitter.com/meditourglobal"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <BsTwitterX className={styles.socialIcons} />{" "}
              </a>

              <a
                href="https://www.tiktok.com/@themeditour.global"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok className={styles.socialIcons} />{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/themeditourglobal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className={styles.socialIcons} />{" "}
              </a>
              <a
                href="https://www.youtube.com/@themeditour.global"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className={styles.socialIcons} />
              </a>
              <a>
                <BsThreads className={styles.socialIcons} />
              </a>
              <a>
                <FaSnapchatGhost className={styles.socialIcons} />
              </a>
            </div>
          </div>
          <div
            className={classNames(
              commonstyles.col5,
              styles.mtsm24,
              styles.mtmd32,
              commonstyles.colsm12,
              commonstyles.colmd12
            )}
          >
            <div className={classNames(styles.ConsultancyBox, styles.mtsm32)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.medium,
                  styles.colorBlue
                )}
              >
                Get Free OPD
              </p>
              <p
                className={classNames(
                  commonstyles.fs16,
                  styles.colorGray,
                  styles.mt8
                )}
              >
                Please fill out the form below and we will contact you shortly
              </p>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Message:*</label>
                <input className={styles.inputs} />
              </div>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Phone Number:*</label>
                <input className={styles.inputs} />
              </div>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Email:*</label>
                <input className={styles.inputs} />
              </div>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Treatment:*</label>
                <select className={styles.select}>
                  <option></option> <option>ABCD</option>
                  <option>ABCD</option>
                </select>
              </div>
              <div className={classNames(styles.flexCol, styles.mt24)}>
                <label className={styles.labels}>Name:*</label>
                <textarea className={styles.textaera} />
              </div>
              <button className={styles.btnSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
      <Footerr />
    </div>
  );
}
