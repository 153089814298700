import classNames from "classnames";
import { useEffect, useState } from "react";
import React from "react";
import style from "./PhysioDashboard.module.css";
import SearchBar from "shared/components/Searchbar";
import commonstyles from "shared/utils/common.module.css";
import { Avatar } from "@mui/material";
import Vector from "assets/images/doctoVecttors.png";
import { RingLoader } from "shared/components";
import CommonDoctorsChart from "shared/components/DoctorsCommomChart";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { TbRefresh } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";

import { DoctGraphDETAILSUpperPortion } from "shared/services/DoctorService";
import moment from "moment";
import ActivationCard from "../ActivationCard";
const data = [
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
  { name: "", value1: 0, value2: 0 },
];

function Doctor_Dashboard() {
  const { user } = useSelector((state: any) => state.root.common);
  const [dash_data, set_dash_data] = useState<any>({});
  const [upcoming_appointment, set_upcoming_appointment] = useState<any>({});

  const [selectedDur, setSelectedDur] = useState("today");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  let parsedAppointmentPercentageChange = null;
  let parsedPatientPercentageChange = null;
  let parsedWaitingPercentageChange = null;
  let parsedCuredPercentageChange = null;

  let upcoming_apt_time = new Date(
    upcoming_appointment?.appointmentDateAndTime
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  let upcoming_apt_date = moment(
    upcoming_appointment?.appointmentDateAndTime
  ).format("MM-DD-YYYY");

  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDur(event.target.value);
    fetch_dashboard_details(event.target.value);
  };

  const handleRotate = () => {
    fetch_dashboard_details(selectedDur);
  };
  const fetch_dashboard_details = (dur: string) => {
    setLoading(true);
    DoctGraphDETAILSUpperPortion(dur)
      .then((res: any) => {
        console.log("upper part", res?.data);

        set_upcoming_appointment(res?.data?.upcomingAppointment);

        set_dash_data(res?.data);
        parsedAppointmentPercentageChange = parseFloat(
          res?.data?.appointmentPercentageChange?.replace("%", "")
        );
        parsedPatientPercentageChange = parseFloat(
          res?.data?.patientPercentageChange?.replace("%", "")
        );
        parsedWaitingPercentageChange = parseFloat(
          res?.data?.waitingPercentageChange?.replace("%", "")
        );
        parsedCuredPercentageChange = parseFloat(
          res?.data?.curedPercentageChange?.replace("%", "")
        );
      })
      .catch((err: any) => {
        console.log("API Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  interface WeekDataItem {
    date: string;
    appointmentCount: number;
  }
  // const GraphOnly = () => {
  //   setLoading(true);
  //   physiotherapistGRAPH()
  //     .then((res: any) => {
  //       console.log("Graph part", res);
  //       if (res?.status === 200) {
  //         const currentWeekData: WeekDataItem[] = res.data.currentWeekData;
  //         const previousWeekData = res.data.previousWeekData;
  //         Use map to convert date to day in currentWeekData
  //         const dayArray = currentWeekData.map((item: WeekDataItem) => {
  //           const dateObject = new Date(item.date);
  //           const dayOfWeek = dateObject.toLocaleDateString("en-US", {
  //             weekday: "long",
  //           });
  //           return { day: dayOfWeek, appointmentCount: item.appointmentCount };
  //         });
  //         let popped = dayArray.pop();
  //         dispatch(setPhysiotherapistdayArray(dayArray));
  //           const dayArray2 = previousWeekData.map((item: WeekDataItem) => {
  //             const dateObject = new Date(item.date);
  //             const dayOfWeek = dateObject.toLocaleDateString("en-US", {
  //               weekday: "short",
  //             });
  //             return { day: dayOfWeek, appointmentCount: item.appointmentCount };
  //           }
  //         );
  //         dispatch(setPhysiotherapistdayArray2(dayArray2));
  //         dayArray2.forEach(
  //           (
  //             item: { day: string; appointmentCount: number },
  //             index: number
  //           ) => {
  //             data[index + 1].name = item.day;
  //             data[index + 1].value2 = item.appointmentCount;
  //           }
  //         );
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.log("API Error:", err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // console.log(data);

  useEffect(() => {
    fetch_dashboard_details(selectedDur);
  }, []);

  return (
    <>
      <div className={classNames(commonstyles.col12)}>
        <SearchBar />
        <div className={commonstyles.mr87}>
          <div className={commonstyles.flx}>
            <select
              className={style.selections}
              onChange={handleSelectionChange}
              value={selectedDur}
            >
              <option value="today">Today</option>
              <option value="week">Week</option>
            </select>
            <div>
              {loading ? (
                <div style={{ marginLeft: "16px" }}>
                  <RingLoader color={"#0D47A1"} size={30.8} />
                </div>
              ) : (
                <TbRefresh
                  className={style.Refresh}
                  // style={{ transform: `rotate(${rotation}deg)` }}
                  onClick={handleRotate}
                />
              )}
            </div>
          </div>

          {/* <ActivationCard /> */}

          <div className={classNames(commonstyles.flx)}>
            <div className={style.Cardaa}>
              <div className={classNames(style.flxBetween)}>
                <div className={classNames(commonstyles.col7)}>
                  <p
                    className={classNames(
                      commonstyles.fs32,
                      commonstyles.semiBold
                    )}
                  >
                    Hello Dr.
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.semiBold
                    )}
                  >
                    {user?.name}
                  </p>
                </div>
                <div>
                  <img src={Vector} className={style.vecttors} />
                </div>
              </div>
            </div>
            <div className={style.Cardbb}>
              <div
                className={classNames(commonstyles.flx, commonstyles.semiBold)}
              >
                <div className={classNames(commonstyles.col10)}>
                  <p
                    className={classNames(commonstyles.fs24, commonstyles.mb16)}
                  >
                    Upcoming Appointment
                  </p>
                  <div className={classNames(commonstyles.flx)}>
                    <div className={classNames(commonstyles.col2)}>
                      <Avatar
                        sx={{ width: "50px", height: "50px" }}
                        src={upcoming_appointment?.patientId?.userImage}
                      />
                    </div>
                    <div className={classNames(commonstyles.fcol12)}>
                      <p
                        className={classNames(
                          commonstyles.fs16,
                          commonstyles.semiBold
                        )}
                        style={{ textTransform: "capitalize" }}
                      >
                        {upcoming_appointment?.patientId?.name}
                      </p>
                      <p
                        className={classNames(
                          commonstyles.fs12,
                          commonstyles.medium,
                          commonstyles.col8,
                          style.mt8
                        )}
                        style={{ textTransform: "capitalize" }}
                      >
                        {upcoming_appointment?.appointmentType}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classNames(commonstyles.col2)}>
                  <p
                    className={classNames(commonstyles.fs16, commonstyles.mb16)}
                  >
                    {upcoming_apt_date}
                  </p>
                  <p>
                    {upcoming_apt_time !== "Invalid Date"
                      ? upcoming_apt_time
                      : "00:00:00"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classNames(commonstyles.flxBetween, style.mt24)}>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Total Patient
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.patientCount ? dash_data?.patientCount : 0}
                    </p>
                  </div>
                  <div>
                    {Number(parsedPatientPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorOrange
                      )}
                    >
                      {dash_data?.patientPercentageChange}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Total Appointments
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.appointmentCount
                        ? dash_data?.appointmentCount
                        : 0}
                    </p>
                  </div>

                  <div>
                    {Number(parsedAppointmentPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorOrange
                      )}
                    >
                      {dash_data.appointmentPercentageChange
                        ? dash_data.appointmentPercentageChange
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Waiting Patients
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.waitingPatients}
                    </p>
                  </div>
                  <div className={style.iconsOuterOrange}>
                    {Number(parsedWaitingPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorOrange
                      )}
                    >
                      {dash_data?.waitingPercentageChange
                        ? dash_data?.waitingPercentageChange
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(style.mianCards)}>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.semiBold,
                    commonstyles.mb8
                  )}
                >
                  Cured Patients
                </p>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs32,
                        commonstyles.semiBold
                      )}
                    >
                      {dash_data?.curedPatientCount}
                    </p>
                  </div>{" "}
                  <div className={style.iconsOuterGreen}>
                    {Number(parsedCuredPercentageChange) > 0 ? (
                      <div className={style.iconsOuterGreen}>
                        <FaArrowUp className={classNames(style.arrowUp)} />
                      </div>
                    ) : (
                      <div className={style.iconsOuterOrange}>
                        <FaArrowDown className={classNames(style.arrowdown)} />
                      </div>
                    )}
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyles.fs18,
                        commonstyles.semiBold,
                        style.colorGreen
                      )}
                    >
                      {dash_data?.curedPercentageChange
                        ? dash_data?.curedPercentageChange
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(style.flx, style.mt24)}>
              <div className={classNames(commonstyles.col9)}>
                <div className={style.graph} style={{ width: "133%" }}>
                  <CommonDoctorsChart data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Doctor_Dashboard;
