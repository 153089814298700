import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import CardStyless from "./Cards.module.css";
import CommonStyless from "shared/utils/common.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import dstyle from "../DonationServices/DonationPackeg.module.css";
import { cityData } from "shared/utils";
import { IoClose, IoSearchSharp } from "react-icons/io5";
import {
  getAllDoctor_SubCategory,
  getAll_Doctors,
} from "shared/services/UserService";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import CustomLoader from "shared/components/New_Loader/Loader";
import DoctorCard from "shared/components/A_New_Components/DoctorCard";
import {
  GetAllDoctorsAvailable,
  getSpecialtiesDoctor,
} from "shared/services/DoctorService";
import toast from "react-hot-toast";
import { ALL_DOC_PARAMAS } from "./functionProps";
import { useDispatch } from "react-redux";
import { setTreatmentId } from "shared/redux";

const DoctorCards: React.FC<{ serviceName: string; speciality: any }> = ({
  serviceName,
  speciality,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [modelVisible, setModelVisible] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("ALL");
  const [activeSpeciality, setActiveSpeciality] = useState<string>("Fertility");
  const [doctors, setDoctors] = useState<any>([]);
  const [splecialist, setSpecialist] = useState<any>([]);
  const [applyCity, setApplyCity] = useState<any>(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
    error: null,
  });
  const locations = useLocation();
  const dispatch = useDispatch();
  const showTreatment = locations.state?.showTreatment || false;

  useEffect(() => {
    window.scrollTo(0, 0);

    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  }, []);

  // console.log("....doctors....", doctors);

  useEffect(() => {
    if (state?.type === "speciality") {
      allTreatments();
    } else if (activeTab === "Speciality") {
      fetch_speciality();
    } else {
      console.log("chala.....");
      fetch_All_Doc();
    }
  }, [activeTab, applyCity]);

  const allTreatments = () => {
    let paging = {
      page: 1,
    };
    let params = {
      treatmentId: state?.item?._id,
    };
    getAllDoctor_SubCategory(paging, params)
      .then((res: any) => {
        setDoctors(res.data?.doctors);
      })
      .catch((err: any) => {
        console.log(err, "........errors");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetch_All_Doc = async () => {
    try {
      const res = await getAll_Doctors(
        ALL_DOC_PARAMAS(
          location,
          activeTab,
          searchValue,
          serviceName,
          selectedIndex
        )
      );
      setDoctors(res?.data?.doctors);
    } catch (err: any) {
      console.log("err all doctor Api......", err?.response);
    } finally {
      setLoading(false);
      setApplyCity(false);
    }
  };

  const handleViewMoreClick = (doc: any) => {
    dispatch(setTreatmentId(doc?._id));
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName, speciality, doc },
    });
  };

  const handleTabClick = (tab: string) => {
    if (tab == "Search by city") {
      setModelVisible(true);
    }
    setActiveTab(tab);
  };

  const handleApply = () => {
    setApplyCity(true);
    setModelVisible(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      fetch_All_Doc();
    }
  };

  const fetch_speciality = () => {
    setLoading(true);
    getSpecialtiesDoctor(1)
      .then((res: any) => {
        setSpecialist(res?.data?.specialities);
        getAllDocAvailable();
      })
      .catch((err: any) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (activeTab === "Speciality") {
      getAllDocAvailable();
    }
  }, [activeSpeciality]);

  const getAllDocAvailable = () => {
    setLoading(true);

    let params = {
      speciality: activeSpeciality,
    };

    GetAllDoctorsAvailable(params)
      .then((res: any) => {
        setDoctors(res?.data?.doctors);
      })

      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const filteredCities = cityData?.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className={classNames(CommonStyless.container, CommonStyless.mb32)}
      style={{ backgroundColor: "#FDFDFD" }}
    >
      <div className={CommonStyless.mt32}>
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxWrap,
            CommonStyless.flxBetween,
            CardStyless.main
          )}
        >
          {state.type !== "speciality" ? (
            <div
              className={classNames(
                CardStyless.tabContainer,
                CommonStyless.flx
              )}
            >
              {[
                "ALL",
                "Near by me",
                "Search by city",
                "Recommended",
                !state?.type ? "Speciality" : null,
              ]
                ?.filter(Boolean)
                .map((tab: any) => (
                  <button
                    key={tab}
                    className={classNames(
                      CardStyless.tab,
                      activeTab === tab && CardStyless.activeTab
                    )}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </button>
                ))}
            </div>
          ) : (
            <div
              className={classNames(
                CommonStyless.colorBlue,
                CommonStyless.fs24,
                CommonStyless.semiBold
              )}
            >
              Available Doctors
            </div>
          )}

          {modelVisible && (
            <div className={classNames(dstyle.modal)} style={{ zIndex: 1000 }}>
              <div className={classNames(dstyle.drmodalContent)}>
                <div
                  className={classNames(
                    CommonStyless.flx,
                    CommonStyless.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiB
                    )}
                  >
                    Search by city
                  </p>
                  <IoClose
                    onClick={() => setModelVisible(false)}
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiB
                    )}
                  />
                </div>
                <div>
                  <input
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid black",
                      outline: "none",
                      padding: "8px",
                      boxSizing: "border-box",
                    }}
                    placeholder="Search here"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <ul>
                  {filteredCities?.map((item, index) => {
                    const isSelected = item === selectedIndex;
                    return (
                      <li
                        key={index}
                        onClick={() => setSelectedIndex(index)}
                        style={{
                          color: isSelected ? "white" : "#1e5da3",
                          backgroundColor: isSelected
                            ? "#1e5da3"
                            : "transparent",
                          padding: "10px",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <div className={dstyle.showMoreContainer}>
                  <button
                    onClick={() => {
                      handleApply();
                      // handleShowMore();
                      // handleContinueClick();
                    }}
                    className={dstyle.showMoreButton}
                    style={{ marginTop: "10px" }}
                  >
                    Apply
                    <span className={dstyle.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Content to show only when navigated with onPress */}

          {showTreatment && (
            <div
              className={classNames(
                CommonStyless.colorBlue,
                CommonStyless.fs24,
                CommonStyless.semiBold
              )}
            >
              Available Doctors
            </div>
          )}
          {!modelVisible && (
            <div
              className={classNames(
                CommonStyless.col2,
                CommonStyless.colsm12,
                CardStyless.searchBarContainer
              )}
            >
              <IoSearchSharp className={CardStyless.searchIcon} />
              <input
                type="text"
                placeholder="Search...."
                className={CardStyless.searchInput}
                value={searchValue} // Controlled input
                onChange={(event: any) => setSearchValue(event?.target?.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
        </div>

        {activeTab === "Speciality" && (
          <div
            className={classNames(
              CardStyless.tabContainer_2,
              CommonStyless.flx
            )}
          >
            {splecialist.map((item: any, index: any) => (
              <button
                key={index}
                className={classNames(
                  CardStyless.tab,
                  activeSpeciality === item?.specialityTitle &&
                    CardStyless.activeTab
                )}
                onClick={() => setActiveSpeciality(item?.specialityTitle)}
              >
                <span>{item?.specialityTitle}</span>
              </button>
            ))}
          </div>
        )}

        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          <div className={CardStyless.cardContainer}>
            {doctors && doctors.length > 0 ? (
              doctors.map((docs: any, index: number) => {
                return (
                  <DoctorCard
                    item={docs}
                    onClick={handleViewMoreClick}
                    type={state?.type}
                  />
                );
              })
            ) : (
              <div
                className={classNames(
                  CommonStyless.flx,
                  CommonStyless.flxCenter
                )}
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                {" "}
                {!loading && (
                  <div className={classNames(CommonStyless.flx)}>
                    <PhysiotheristsEmpty />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default DoctorCards;
