
import BookingRentACar from "pages/Home/HomeNavBar/NavBarr/BookingMenu/BookingRentACar";
import React from "react";
import { Routes, Route } from "react-router-dom";

const BookingRentACarRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<BookingRentACar />} />
    </Routes>
  );
};

export default BookingRentACarRoute;
