import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import style from "./donors.module.css";
import DonorsTable from "shared/components/DonationTables/Donors";
import PerviousNextButton from "shared/components/Pagination";
import { donationGETALLDonations } from "shared/services/Donation";
import { LoadingModal, RingLoader } from "shared/components";
import Pagination from "shared/components/Pagination/Pagination";
import CustomLoader from "shared/components/New_Loader/Loader";
import { TbRefresh } from "react-icons/tb";
function Donors() {
  const [loading, setLoading] = useState(false);
  const [donors, setdonors] = useState([]);
  const [rotation, setRotation] = useState(0);
  const rotationIncrement = 90;
  const FetchAllDonations = () => {
    setLoading(true);
    donationGETALLDonations()
      .then((res: any) => {
        if (res.data.auth === true) {
          console.log("..........", res);
          setdonors(res.data.donations);
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        // setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    FetchAllDonations();
  }, []);

  return (
    <div className={classNames(commonstyles.col12, style.outer)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          {/* <div className={classNames(commonstyles.fs12, commonstyles.flx)}>
          <p>Today|</p>
          <p>This week|</p>
          <p>This Month|</p>
          <p>This Year</p>
        </div> */}
          <div
            className={classNames(commonstyles.mb32, commonstyles.flxBetween)}
          >
            <div
              className={classNames(
                commonstyles.flx,
                commonstyles.alignItemsCenter
              )}
            >
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Listing
              </p>

              {loading ? (
                <div style={{ marginLeft: "8px" }}>
                  {" "}
                  {/* Reduce margin for a smaller gap */}
                  <RingLoader color={"#0D47A1"} size={30} />
                </div>
              ) : (
                <div className={style.outerRefresh}>
                  <TbRefresh
                    className={style.RefreshIcon}
                    style={{ transform: `rotate(${rotation}deg)` }}
                    onClick={() => {
                      FetchAllDonations();
                    }}
                  />
                </div>
              )}
            </div>

            <Pagination />
          </div>

          {/* {loading ? (
            <CustomLoader />
          ) : ( */}
          <>
            <DonorsTable donors={donors} />
          </>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default Donors;
