import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Wrapper } from "shared/components";
import { Home } from "pages";
import LaboratoryAuthRoutes from "./Laboratory/authRoutes";
import LaboratoryMainRoutes from "./Laboratory/mainRoutes";
import PharmacyAuthRoutes from "./Pharmacy/authRoutes";
import PharmacyMainRoutes from "./Pharmacy/MainRoutes";
import HomeServicesAuthRoutes from "./HomeServices";
import TravelTourismAuthRoutes from "./TravelTourism/traveltourism";
import DonationAuthRoutes from "./Donation/Donationauthroutes";
import InsuranceAuthRoutes from "./Insurance/InsuranAuthRoutes";
import { useDispatch, useSelector } from "react-redux";
import HospitalMainRoutes from "./Hospital_Routes/hospital_main";
import AmbulanceMainRoutes from "./HomeServices/AmbulanceRoutes/AmbulanceMainRoutes";
import ParamedicStaffMainRoutes from "./HomeServices/ParamedicStaffRoutes/ParamedicStaffMain";
import InsuranceMainRoutes from "./Insurance/InsuranceMianRoutes";
import DonationMainRoutes from "./Donation/DonationMainRoutes";
import RentACarMainRoutes from "./TravelTourism/RentCar/RentCarMainRoutes";
import HotelMainRoutes from "./TravelTourism/Hotel/HotelMainRoutes";
import TravelAgencyMainRoutes from "./TravelTourism/TravelAgency/TravelAgencyMain";
import Vender from "pages/Home/HomeNavBar/JoinVender";
import AdminLogin from "pages/AdminPanel/Login";
import AdminMianRoutes from "pages/AdminPanel";
import AboutUs from "pages/Home/HomeNavBar/AboutUs";
import ContactUs from "pages/Home/ContactUs";
import Treatment from "pages/Home/HomeNavBar/Treatment";
import PatientGuide from "pages/Home/HomeNavBar/PatientGuide";
import TreatmentDetails from "pages/Home/HomeNavBar/Treatment/TreatmentDetails";
import FreeOpd from "pages/Home/FreeOpd";
import PrivactPolicys from "pages/Home/HomeNavBar/PrivactPolicy";
import FAQpage from "pages/Home/HomeNavBar/FAQ'Spage";
import Doc_Auth_Routes from "./Doctor_Routes/doc_auth";
import Doc_Main_Routes from "./Doctor_Routes/doc_main";
import {
  set_User,
  setIsLoggedIn,
  setToken,
  setUser,
  setExchangeRate,
} from "shared/redux";
import Hospital_Auth_Routes from "./Hospital_Routes/hospital_auth";
import PharmaceuticalAuthRoutes from "./Pharmaceutical/authRoutes";
import Pharmaceutical_MainRoutes from "./Pharmaceutical/MainRoutes";
import UserAuthRoutes from "./UserLogin/UserLoginAuth";
import ServiceRoutes from "./Services_Routes/ServiceRoutes";
import CenteredLayout from "pages/Home/HomeNavBar/OurServices/NewServicesCarts";
import axios from "axios";
import CongratsScreen from "pages/CongratsScreen";

const AppRoutes = (props: any) => {
  const { isLoggedIn, systemType } = useSelector(
    (state: any) => state.root.common
  );
  const { logOutFlag, setLogOutFlag } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Router>
      {isLoggedIn ? (
        <>
          {systemType == "user" ? (
            <BeforLogin logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
          ) : (
            <AfterLogin logOutFlag={logOutFlag} setLogOutFlag={setLogOutFlag} />
          )}
        </>
      ) : (
        <BeforLogin />
      )}
    </Router>
  );
};

export default AppRoutes;
const BeforLogin = (props: any) => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { logOutFlag, setLogOutFlag } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAndStoreExchangeRate = async () => {
    try {
      const response = await axios.get(
        `https://v6.exchangerate-api.com/v6/437c285364c166fcafc883c0/pair/PKR/USD`
      );
      const rate = response.data.conversion_rate;

      // Store in localStorage and state
      localStorage.setItem("exchangeRate", JSON.stringify(rate));
      localStorage.setItem("lastFetched", new Date().toISOString());
      dispatch(setExchangeRate(JSON.stringify(rate)));

      console.log("🚀 ~ One Time Exchange Rate Hit", rate);
    } catch (err: any) {
      console.log("Error fetching exchange rate:", err?.response?.data);
    }
  };

  useEffect(() => {
    const checkAndFetchExchangeRate = () => {
      try {
        const lastFetched = localStorage.getItem("lastFetched");
        const currentDate = new Date();
        let fetchNewRate = true;

        if (lastFetched) {
          const lastFetchedDate = new Date(lastFetched);
          const hoursDifference =
            (currentDate.getTime() - lastFetchedDate.getTime()) /
            (1000 * 60 * 60);

          // Fetch only if more than 24 hours have passed
          if (hoursDifference < 24) {
            fetchNewRate = false;
          }
        }

        if (fetchNewRate) {
          fetchAndStoreExchangeRate();
        }
      } catch (err) {
        console.log("Error checking exchange rate fetch time:", err);
      }
    };

    checkAndFetchExchangeRate();
  }, []);

  useEffect(() => {
    if (logOutFlag && systemType == "user") {
      handleLogout();
    }
  }, [logOutFlag]);

  const handleLogout = () => {
    dispatch(setIsLoggedIn(false));
    dispatch(setToken(null));
    dispatch(set_User(null));
    setLogOutFlag(false);
    navigate("/");
  };
  return (
    <Wrapper>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="laboratory/*" element={<LaboratoryAuthRoutes />} />
        <Route path="pharmacy/*" element={<PharmacyAuthRoutes />} />
        <Route path="pharmaceutical/*" element={<PharmaceuticalAuthRoutes />} />

        {systemType === "doctor" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}
        {systemType === "physiotherapist" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}
        {systemType === "nutritionist" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}

        {systemType === "psychologist" && (
          <Route path={`${systemType}/*`} element={<Doc_Auth_Routes />} />
        )}
        {systemType === "hospital" && (
          <Route path={`hospital/*`} element={<Hospital_Auth_Routes />} />
        )}

        <Route path="homeservices/*" element={<HomeServicesAuthRoutes />} />
        <Route path="traveltourism/*" element={<TravelTourismAuthRoutes />} />
        <Route path="donation/*" element={<DonationAuthRoutes />} />
        {systemType == "insurance" && (
          <Route path="insurance/*" element={<InsuranceAuthRoutes />} />
        )}
        <Route path="joinVender/" element={<Vender />} />
        <Route path="ourServices/*" element={<CenteredLayout />} />
        <Route path="services/*" element={<ServiceRoutes />} />
        <Route path="aboutUs/" element={<AboutUs />} />
        <Route path="FAQs/" element={<FAQpage />} />
        <Route path="contactUs/" element={<ContactUs />} />
        <Route path="congratsScreen/" element={<CongratsScreen />} />

        <Route path="user/*" element={<UserAuthRoutes />} />
        <Route path="treatment/" element={<Treatment />} />
        <Route path="privactpolicys/" element={<PrivactPolicys />} />
        <Route path="freeOPD/" element={<FreeOpd />} />
        <Route path="treatment/Details" element={<TreatmentDetails />} />
        <Route path="patientGuide/" element={<PatientGuide />} />
        <Route path="admin/login" element={<AdminLogin />} />
      </Routes>
    </Wrapper>
  );
};

const AfterLogin = (props: any) => {
  const { systemType } = useSelector((state: any) => state.root.common);
  const { logOutFlag, setLogOutFlag } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (logOutFlag) {
      handleLogout();
    }
  }, [logOutFlag]);

  const handleLogout = () => {
    dispatch(setIsLoggedIn(false));
    dispatch(setToken(null));
    dispatch(setUser(null));
    navigate(`/${systemType}/login`);
    setLogOutFlag(false);
  };
  return (
    <>
      {/* <Routes>
        <Route path="/" element={<Home />} />
      </Routes> */}
      {systemType === "laboratory" && <LaboratoryMainRoutes />}
      {systemType === "pharmacy" && <PharmacyMainRoutes />}
      {systemType === "hospital" && <HospitalMainRoutes />}
      {systemType === "doctor" && <Doc_Main_Routes systemType={systemType} />}
      {systemType === "ambulance" && <AmbulanceMainRoutes />}
      {systemType === "physiotherapist" && (
        <Doc_Main_Routes systemType={systemType} />
      )}
      {systemType === "nutritionist" && (
        <Doc_Main_Routes systemType={systemType} />
      )}
      {systemType === "paramedic" && <ParamedicStaffMainRoutes />}
      {systemType === "psychologist" && (
        <Doc_Main_Routes systemType={systemType} />
      )}
      {systemType === "hotel" && <HotelMainRoutes />}
      {systemType === "rentacar" && <RentACarMainRoutes />}
      {systemType === "travelagency" && <TravelAgencyMainRoutes />}
      {systemType === "donation" && <DonationMainRoutes />}
      {systemType === "insurance" && <InsuranceMainRoutes />}
      {systemType === "admin" && <AdminMianRoutes />}
      {systemType === "pharmaceutical" && <Pharmaceutical_MainRoutes />}
    </>
  );
};
