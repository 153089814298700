import React from "react";
import styles from "./ModelTermsAndCondition.module.css"; // Create a CSS module for styles

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          &times; {/* Close button in the corner */}
        </button>
        {children}

        {/* Close button at the bottom */}
        <div className={styles.closeButtonContainer}>
          <button className={styles.closeButtonClose} onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
