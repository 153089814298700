import React, { useEffect } from "react";
import classNames from "classnames";
import style from "./Ambulance.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import LocationInput from "shared/components/LocationInput";
import img from "assets/images/ambulancebackground.png";
import { FaCircleCheck } from "react-icons/fa6";
import { Ambulance_Flow } from "shared/services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "shared/components";
import toast from "react-hot-toast";
import Download from "../../../assets/images/DownloadImagelogo.png";
import { FaTimes } from "react-icons/fa";
import TermsAndConditions from "shared/components/ModelTermsAndCondition";

const AmbulanceServices = () => {
  const [location, setLocation] = React.useState<any>("");
  const [dropLocation, setDropLocation] = React.useState<any>("");
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [err, setErr] = React.useState("");
  const [err2, setErr2] = React.useState("");

  const { isLoggedIn } = useSelector((state: any) => state?.root?.common);
  // console.log("......isLoggedIn", isLoggedIn);
  console.log(".location", location);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const savedPickupLocation = localStorage.getItem("pickupLocation");
    const savedDropLocation = localStorage.getItem("dropLocation");
    if (savedPickupLocation) {
      setLocation(JSON.parse(savedPickupLocation));
    }
    if (savedDropLocation) {
      setDropLocation(JSON.parse(savedDropLocation));
    }
  }, []);

  const handleLocationChange = (newLocation: string) => {
    setErr("");
    setLocation(newLocation);
    // localStorage.setItem("pickupLocation", JSON.stringify(newLocation));
  };

  const handleDropLocationChange = (newLocation: string) => {
    setErr2("");
    setDropLocation(newLocation);
    // localStorage.setItem("dropLocation", JSON.stringify(newLocation));
  };

  // console.log("....location", location);

  const navigate = useNavigate();

  const handleShowMore = () => {
    if (isLoggedIn) {
      pushAmbulance();
    } else {
      navigate("/user/login");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const pushAmbulance = () => {
    if (location && dropLocation) {
      setLoading(true);
      const data = {
        pickUp: {
          lng: location.lng,
          lat: location.lat,
          address: location?.label,
          city: location.city,
        },
        dropOff: {
          lng: dropLocation.lng,
          lat: dropLocation.lat,
          address: dropLocation?.label,
          city: dropLocation.city,
        },
      };
      Ambulance_Flow(data)
        .then((res: any) => {
          setIsModalOpen(true);
          localStorage.removeItem("pickupLocation");
          localStorage.removeItem("dropLocation");
        })
        .catch((err: any) => {
          // console.log(err?.response?.data, "..........err");
          // alert(err?.response?.data?.message);
          toast.error(err?.response?.data?.message);
        })
        .finally(() => setLoading(false));
    } else {
      if (location == "") {
        setErr("Please select pickup location");
      }
      if (dropLocation == "") {
        setErr2("Please select dropoff location");
      }
      // toast.error("Please select pick and drop location");
      // alert("Please select pick and drop location");
    }
  };

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Ambulance
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Ambulance
          </p>
        </div>
        <div className={classNames(commonstyles.container)}>
          <div
            className={classNames(
              commonstyles.mt50,
              commonstyles.mb32,
              style.main
            )}
          >
            <div className={classNames(style.location)}>
              <div
                className={classNames(commonstyles.col5, commonstyles.colsm12)}
              >
                <LocationInput
                  placeholder="Pickup Location"
                  setData={handleLocationChange}

                  // initialValue={location}
                />
                <div className={classNames(style.err)}>{err}</div>
              </div>
              <div
                className={classNames(commonstyles.col5, commonstyles.colsm12)}
              >
                <LocationInput
                  placeholder="Dropoff Location"
                  setData={handleDropLocationChange}
                  // initialValue={dropLocation}
                />
                <div className={classNames(style.err)}>{err2}</div>
              </div>
            </div>
            <div className={style.showMoreContainer}>
              <button onClick={handleShowMore} className={style.showMoreButton}>
                Request
                <img src={Download} alt="icon" className={style.buttonIcon} />
                {loading && <RingLoader size={20} color={"#fff"} />}
              </button>
            </div>
          </div>
          <div className={classNames(style.imgconatiner)}>
            <img src={img} alt="Ambulance Background" />
          </div>
        </div>
        <Footerr />
      </div>

      {/* Modal */}
      <div
        className={classNames(style.modalOverlay, {
          [style.open]: isModalOpen,
        })}
        onClick={handleCloseModal}
      >
        <div
          className={classNames(style.modalContent, commonstyles.colorBlue)}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FaTimes className={style.closeIcon} onClick={handleCloseModal} />
          </div>
          <div>
            <FaCircleCheck className={style.iconStyle} />
          </div>
          <p>Your request has been</p>
          <p>successfully shared</p>
          <p>We will notify you in a few minutes</p>
          <p>Notify</p>
          <p className={style.thankYouText}>Thank you</p>
        </div>
      </div>
    </div>
  );
};

export default AmbulanceServices;
