import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { Height } from "@mui/icons-material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "16px!important",
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "80vh",
  p: 4,
  // Hides the scrollbar
  scrollbarWidth: "none", // For Firefox
  "-ms-overflow-style": "none", // For Internet Explorer and Edge
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
};

interface Props {
  showModal: any;
  children: any;
  close: any;
}

export default function CustomModal(props: Partial<Props>) {
  const { showModal, children, close } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Modal
        open={showModal}
        onClose={close ? close : () => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {/* <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold,
                      commonstyles.colorBlue
                    )}
                  >
                    Appointment
                  </p> */}
          <Box sx={style}>{children}</Box>
        </>
      </Modal>
    </div>
  );
}
