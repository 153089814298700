import classNames from "classnames";
import React, { useEffect, useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "shared/components/CustomSelect";
import { addTestToLab, getAllTests } from "shared/services";
import { labAddTestInfo } from "shared/utils";
import CustomModalCustom from "./CustomModalCustom";
import toast from "react-hot-toast";

function AddTest() {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [newTestName, setNewTestName] = useState("");
  const formik = useFormik({
    initialValues: {
      testName: "",
      //   testCategory: "",
      // testAddTest_TestCode: "",
      testDuration: "",
      testDescription: "",
      testPrice: "",
      priceForMeditour: "",
    },
    validationSchema: Yup.object({
      testName: newTestName
        ? Yup.string()
        : Yup.string()
          .trim()
          .min(2, "Please enter at least 2 characters.")
          .max(64, "Test name should not exceed 64 characters.")
          .required("Required"),
      testDescription: Yup.string()
        .trim()
        .min(2, "Please enter at least 2 characters.")
        .max(1000, "Description should not exceed 1000 characters.")
        .required("Required"),
      testPrice: Yup.string()
        // .min(2, "Must be at least 2 characters long")
        .matches(/^\d+$/, "Please enter a valid number")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      testDuration: Yup.string()
        // .min(2, "Must be at least 2 characters long")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      priceForMeditour: Yup.string()
        // .min(2, "Must be at least 2 characters long")
        .max(15, "Must be 15 characters or less")
        .required("Required")
        .test(
          "is-less-than-actualPrice",
          "MediTour price should be less ",
          // "MediTour price",
          function (value) {
            const { testPrice } = this.parent;
            return value < testPrice;
          }
        ),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    const currentData = formik.values;
    setLoading(true);

    let params = {
      testDescription: currentData?.testDescription,
      price: currentData?.testPrice,
      duration: currentData?.testDuration,
      priceForMeditour: currentData?.priceForMeditour,
      ...(newTestName
        ? { name: newTestName }
        : { testNameId: currentData?.testName }),
    };
    console.log(params, "......params");
    addTestToLab(params)
      .then((res: any) => {
        if (res.data.auth) {
          navigate("/laboratory/test");
        }
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGoback = () => {
    navigate(`/laboratory/test`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    setNewTestName('');
    if (query) {
      fetchAllTests(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    setSearchQuery(suggestion?.name);
    formik.setFieldValue("testName", suggestion?._id);

    setSuggestions([]);
  };
  // useEffect(() => {
  //   if (newTestName) {
  //     setSearchQuery(newTestName);
  //     setSuggestions([]);
  //   }
  // }, [newTestName]);
  // useEffect(() => {
  //   if (newTestName && newTestName !== searchQuery) {
  //     setSearchQuery(newTestName);
  //     setSuggestions([]);
  //     formik.setFieldTouched("testName", false);
  //     formik.setFieldError("testName", undefined);
  //   }
  // }, [newTestName]);

  console.log(newTestName, "....newTestName");
  const handleValueChange = (name: string, value?: string) => {
    // setCost(text);
    formik.setFieldValue(name, value);
  };

  const fetchAllTests = (search: string) => {
    getAllTests(1, search)
      .then((res: any) => {
        // console.log("res....", res?.data?.data);
        setSuggestions(res?.data?.data);
      })
      .catch((err: any) => { });
  };
  const handleNewTestName = (testName: any) => {
    setNewTestName(testName);
    setSearchQuery(testName);
  };
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
        <div className={classNames(commonstyles.flx)}>
          <div className={styles.backOuter}>
            <IoArrowBack className={styles.addIcon} onClick={handleGoback} />
          </div>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Add Test
          </p>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={commonstyles.mr87}>
          <div className={classNames(commonstyles.col6)}>
            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Available Tests
              </p>
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorOrange
                )}
                onClick={() => setShowAddModal(true)}
                style={{
                  marginLeft: "16px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Add Custom
              </p>
            </div>

            <div style={{ position: "relative" }}>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search Here"
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "0",
                  border: "2px solid rgb(0, 39, 109)",
                  borderRadius: "3px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />

              {formik.touched.testName && formik.errors.testName && (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.testName}
                </div>
              )}

              {suggestions?.length > 0 && (
                <div
                  className={styles.searchResults}
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    backgroundColor: "white",
                    zIndex: 10,
                    maxHeight: "200px",
                    overflow: "auto",
                    padding: "0",
                    margin: "0",
                    width: "100%",
                  }}
                >
                  <ul
                    style={{
                      padding: "0",
                      margin: "0",
                      listStyleType: "none",
                    }}
                  >
                    {suggestions.map((suggestion: any, index) => (
                      <li
                        key={index}
                        className={styles.suggestionItem}
                        onClick={() => handleSuggestionClick(suggestion)}
                        style={{
                          padding: "2px",
                        }}
                      >
                        {suggestion?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className={classNames(commonstyles.flx)}>
            {/* <div className={classNames(commonstyles.col2)}>
            <CustomInput
              placeholder="Test Code"
              id="cost"
              name="cost"
              type="text"
              onChange={(e: any) => handleCostChange(e.target.value)}
              value={cost}
            />

            
          </div> */}

            <div className={classNames(commonstyles.col6)}>
              <CustomInput
                placeholder="Test Duration"
                id="testDuration"
                name="testDuration"
                type="text"
                onChange={(e: any) =>
                  handleValueChange("testDuration", e.target.value)
                }
                value={formik.values.testDuration}
              />

              {formik.touched.testDuration && formik.errors.testDuration ? (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.testDuration}
                </div>
              ) : null}
            </div>
          </div>

          <div className={classNames(commonstyles.col6)}>
            <CustomInput
              placeholder="Test Description"
              id="testDescription"
              name="testDescription"
              type="text"
              onChange={(e: any) =>
                handleValueChange("testDescription", e.target.value)
              }
              value={formik.values.testDescription}
            />

            {formik.touched.testDescription && formik.errors.testDescription ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.testDescription}
              </div>
            ) : null}
          </div>

          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold,
              commonstyles.mt32
            )}
          >
            Test Price?
          </p>
          <div
            className={classNames(commonstyles.flx)}
            style={{
              gap: "10px ",
            }}
          >
            <div className={classNames(commonstyles.col3)}>
              <CustomInput
                placeholder="Actual Price"
                id="testPrice"
                name="testPrice"
                type="text"
                onChange={(e: any) =>
                  handleValueChange("testPrice", e.target.value)
                }
                value={formik.values.testPrice}
              />

              {formik.touched.testPrice && formik.errors.testPrice ? (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.testPrice}
                </div>
              ) : null}
            </div>

            <div className={classNames(commonstyles.col3)}>
              <CustomInput
                placeholder="MediTour Price"
                id="priceForMeditour"
                name="priceForMeditour"
                type="text"
                onChange={(e: any) =>
                  handleValueChange("priceForMeditour", e.target.value)
                }
                value={formik.values.priceForMeditour}
              />

              {formik.touched.priceForMeditour &&
                formik.errors.priceForMeditour ? (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.priceForMeditour}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "56px", width: "210px" }}>
          <PrimaryButton
            disabled={loading}
            children={loading ? "loading..." : "Save"}
            type="submit"
            colorType={"Linear"}
          />
        </div>
      </form>
      <CustomModal
        showModal={showAddModal}
        children={
          <CustomModalCustom
            onClose={() => setShowAddModal(false)}
            fetchAllTests={fetchAllTests}
            setloading={setLoading}
            loading={loading}
            onAddTestName={handleNewTestName}
          />
        }
      />
    </div>
  );
}

export default AddTest;