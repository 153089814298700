import React, { useEffect, useState } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import styles from './MyAppointmentRecord.module.css'
import Footerr from "../Footer";
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../../assets/logo.png';
import DetailRecordImage from '../../../../assets/images/DetailImageRecord123.png';
import DownloadImagelogo from '../../../../assets/images/DownloadImagelogo.png';

interface Appointment {
    id: number;
    name: string;
    specialization: string;
    qualifications: string[];
    clinic: string;
    date: string;
    imageUrl: string;
}

const appointments: Appointment[] = [
    {
        id: 1,
        name: "Dr. Wajahat Khan",
        specialization: "Gastroenterologist",
        qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
        clinic: "Fatima Memorial Clinic",
        date: "12/12/2023 11:48AM",
        imageUrl: Avatar,
    },
     {
        id: 1,
        name: "Dr. Wajahat Khan",
        specialization: "Gastroenterologist",
        qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
        clinic: "Fatima Memorial Clinic",
        date: "12/12/2023 11:48AM",
        imageUrl: Avatar,
    },
     {
        id: 1,
        name: "Dr. Wajahat Khan",
        specialization: "Gastroenterologist",
        qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
        clinic: "Fatima Memorial Clinic",
        date: "12/12/2023 11:48AM",
        imageUrl: Avatar,
    },
     {
        id: 1,
        name: "Dr. Wajahat Khan",
        specialization: "Gastroenterologist",
        qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
        clinic: "Fatima Memorial Clinic",
        date: "12/12/2023 11:48AM",
        imageUrl: Avatar,
    },
    // Add more appointments as needed
];



const MyAppointmentRecord = React.memo((props) => {
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(2);
  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);
  const loadMore = () => {
        setVisibleCount(prevCount => prevCount + 2);  
    };
    const handleGoToAppointmentRecord = () => {
    navigate("/services/Record");
  };
  const handleGoToAppointment = () => {
    navigate("/services/myappointment");
  };
    const handleGoToDetailAppointmentPrescription = () => {
    navigate("/services/Prescription");
  };

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Appointments
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            My Appointments
          </p>
        </div>
      </div>
      <div className={styles.buttonContainerRecord}>
    <button className={`${styles.buttonRecord} ${styles.buttonUpcomingRecord}`} onClick={handleGoToAppointment} style={{ cursor: 'pointer' }}>Upcoming</button>
    <button className={`${styles.buttonRecord} ${styles.buttonRecordRecord}`} onClick={handleGoToAppointmentRecord}  style={{ cursor: 'pointer' }}>Record</button>
</div>

<div className={styles.containerAppointmentRecord}>
    {appointments.slice(0, visibleCount).map(appointment => (
        <div key={appointment.id} className={styles.cardAppointmentRecord}  >
                    <img src={appointment.imageUrl} alt={appointment.name} className={styles.photoAppointmentRecord}/>
            <div className={styles.infoAppointmentRecord}>
              <div className={styles.topInfoRecord}>
                    <div className={styles.nameAppointmentRecord}>{appointment.name}</div>
                    <div className={styles.dateAppointmentRecord}>{appointment.date}</div>
                </div>
                <div className={styles.specializationAppointmentRecord}>{appointment.specialization}</div>
                <div className={styles.qualificationsAppointmentRecord}>{appointment.qualifications.join(", ")}</div>
                <div className={styles.clinicButtonContainer}>
          <div className={styles.clinicAppointmentRecord}>{appointment.clinic}</div>
          <button className={styles.buttonWithIcon}>
            <img
              src={DetailRecordImage}
              alt="Menu Icon"
              className={styles.menuIconDetailButtonRecordDetail}
              onClick={handleGoToDetailAppointmentPrescription}
              style={{ cursor: 'pointer' }}
            />
          </button>
        </div>

            </div>
            
        </div>
    ))}
</div>
{/* <button className={styles.buttonWithIcon}>
    <img src={DetailRecordImage} alt="Menu Icon" className={styles.menuIconDetailButtonRecordDetail} onClick={handleGoToDetailAppointmentPrescription}
                    style={{ cursor: 'pointer' }} />
</button> */}
 {visibleCount < appointments.length && (
                <button onClick={loadMore} className={styles.loadMoreButtonRecord}>Load More
                <img src={DownloadImagelogo} alt="Logo" className={styles.downloadButtonLogo} />
                </button>
            )}


      
      <Footerr />
    </div>
  );
});

export default MyAppointmentRecord;
