import classNames from "classnames";
import style from "./ActivationCardStyle.module.css";
import commonStyles from "shared/utils/common.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ActivationCard = () => {
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);

  const handleActivation = () => {
    // navigate("/congratsScreen");
    navigate(`/${systemType}/paymentDetail`, {
      state: {
        actualAmount: 100,
      },
    });
  };

  return (
    <div className={classNames(style?.activation_container)}>
      <div className={classNames(style?.activation_content)}>
        <h2>Activate Your Account!</h2>
        <p>
          Your services are currently not visible to users. Please activate your
          account first.
        </p>
        <div className={classNames(commonStyles?.flx, style.gap)}>
          <small>Account activation fee is </small>
          <small className={classNames(style.text)}>RS 100/-</small>
        </div>
      </div>
      <button
        className={classNames(style?.pay_button)}
        onClick={handleActivation}
      >
        Pay Now
      </button>
    </div>
  );
};

export default ActivationCard;
