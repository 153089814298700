import Nav_SelectionCards from "pages/Services/Nav_SelectionCard/Nav_SelectionCard";
import DonationPayment from "pages/Services/DonationServices/DonationPayment";
import RentaCarBook from "pages/Services/RentaCar/RentaCarBook";
import RentaCarBooking from "pages/Services/RentaCar/RentaCarBooking";
import RentaCarDetail from "pages/Services/RentaCar/RentaCarDetail";
import RentaCarmoreDetail from "pages/Services/RentaCar/RentaCarmoreDetail";
import UserInfo from "pages/Services/RentaCar/UserInfo";
import TravelServices from "pages/Services/TravelAgency";
import TravelBooking from "pages/Services/TravelAgency/TravelBooking";
import TravelDetail from "pages/Services/TravelAgency/TravelDetail";
import React from "react";
import { Routes, Route } from "react-router-dom";
import DonationPaymodule from "shared/components/DonationServices/DonationPaymodule";

const Travel_Cards = [
  {
    title: "Travel Agency",
    content: "Visa Services",
    color: "#FF8A8A",
    icon: "✈️",
  },

  {
    title: "Rent A Car",
    content: "24/7  Service Available",
    color: "#FF8A8A",
    icon: "🚗",
  },
];

const TravelRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<TravelServices />} />
      <Route
        path="/Selection"
        element={<Nav_SelectionCards data={Travel_Cards} />}
      />
      <Route path="/TravelDetail" element={<TravelDetail />} />
      <Route path="/TravelBooking" element={<TravelBooking />} />
      <Route path="/Travelpayment" element={<DonationPayment />} />
      <Route path="/RentaCarDetail/:item" element={<RentaCarDetail />} />
      <Route path="/RentaCarmoreDetail" element={<RentaCarmoreDetail />} />
      <Route path="/RentaCarBooking" element={<RentaCarBooking />} />
      <Route path="/DonationPay" element={<DonationPaymodule />} />
      <Route path="/RentaCarBook" element={<RentaCarBook />} />
      <Route path="/UserInfo" element={<UserInfo />} />
      <Route path="/DonationPay" element={<DonationPayment />} />
    </Routes>
  );
};

export default TravelRoutes;
