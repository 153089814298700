import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import styles from "./ticketRequest.module.css";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { FlightDetailsCard, PrimaryButton } from "shared/components";
import moment from "moment";
import { get_requestFetch } from "shared/services/TravelAgency";
import Dropdown from "assets/images/arrow.png"
export default function BidsDetail() {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [expandedSection, setExpandedSection] = useState(null);
  const data = state?.val;
  const toggleSection = (section: any) => {
    setExpandedSection(expandedSection === section ? null : section);
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  const fetchDetails = async () => {
    const params = {
      requestId: data?._id,
    };
    try {
      const res = await get_requestFetch(params);
      setDetails(res?.data?.request?.bidIds);
    } catch (err) {
      console.log(err, '.......error');
    }
  };

  const handleAddTicket = () => {
    navigate('/travelAgency/ticketRequests/TravelTicketshare', { state: { data } })
  }
  console.log(details, '.............details')
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={styles.outerContainer}>
          <div className={commonstyles.flx}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Request detail
            </p>
          </div>
          <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              User
            </p>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              {data?.requestType}
            </p>
          </div>
          <div className={styles.DetailCard}>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.regular,
                styles.colorGray
              )}
            >
              {data?.userId?.name}
            </p>

            {data?.flights?.map((user: any, index: any) => (
              <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    From
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {user?.from}
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    To
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {user?.to}
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    Departure
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {user?.departure
                      ? new Date(user?.departure).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                      : "__"}
                  </p>
                </div>
              </div>
            ))}
            <div className={classNames(commonstyles.flxBetween, styles.mt24)}>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Class
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  {data?.flightClass}
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.regular,
                    styles.colorGray
                  )}
                >
                  Traveler
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.regular,
                    commonstyles.colorBlue
                  )}
                >
                  Adults: {data?.adult} children: {data?.children} Infant:{" "}
                  {data?.infant}
                </p>
              </div>
              {data?.requestType === "round" && (
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs14,
                      commonstyles.regular,
                      styles.colorGray
                    )}
                  >
                    Return
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.regular,
                      commonstyles.colorBlue
                    )}
                  >
                    {moment(data?.returnFlight).format("DD/MM/YYYY")}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.card1}>
            <button
              onClick={() => toggleSection('flightDetails')}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <span>Flight Details</span>
              <img src={Dropdown} alt="Dropdown icon" className={styles.drop} />
            </button>
            {expandedSection === 'flightDetails' && (
              details.map((item: any, itemIndex: any) => (
                <>
                  <FlightDetailsCard details={item?.flightDetails} itemIndex={itemIndex} />
                  {item?.returnFlights?.length > 0 && <FlightDetailsCard show details={item?.returnFlights} itemIndex={itemIndex} />}
                  <span className={styles.subTitle}>Flight Policies</span>
                  {Object.entries(item.flightPolicies).map(([key, value]: any, index) => (
                    <div key={index} style={{ gap: '4px' }}>
                      <span className={styles.labelText}>{key}</span>
                      <span className={styles.valueText}>{value}</span>
                    </div>
                  ))}
                  <span className={styles.totalPrice}>Total Price for Traveler</span>
                  <p className={styles.ticketPrice}>
                    Ticket Price: {item.ticketPrice} PKR
                  </p>
                </>
              ))
            )}
          </div>

          {data?.status === 'approved' && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ marginTop: "56px", width: "210px" }}>
                <PrimaryButton
                  onClick={handleAddTicket}
                  colorType={"Linear"}
                  type={'button'}
                >
                  Share Ticket
                </PrimaryButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
