import React from "react";
import { Routes, Route } from "react-router-dom";
import RentaCarBooking from "pages/Services/RentaCar/RentaCarBooking";

import RentaCarDetail from "pages/Services/RentaCar/RentaCarDetail";
import RentaCarmoreDetail from "pages/Services/RentaCar/RentaCarmoreDetail";
import RentaCarService from "pages/Services/RentaCar/RentaCarService";

import DonationPaymodule from "shared/components/DonationServices/DonationPaymodule";
import UserInfo from "pages/Services/RentaCar/UserInfo";
import RentaCarBook from "pages/Services/RentaCar/RentaCarBook";
import DonationPayment from "pages/Services/DonationServices/DonationPayment";

const RentaCarRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RentaCarService />} />
    </Routes>
  );
};

export default RentaCarRoutes;
