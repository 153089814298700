import React from "react";
import CardStyless from "./styles.module.css";
import CommonStyles from "shared/utils/common.module.css";
import RatingStar from "shared/RatingStar";
import { IoMdArrowRoundForward } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Vector from "assets/images/Vector.png";

const DoctorCard = (props: any) => {
  const { item, onClick, type } = props;

  console.log(".....item....item...", item);

  let speciality = item?.doctorId?.speciality || item?.speciality;
  return (
    <div className={CardStyless.cardWrapper} onClick={() => onClick(item)}>
      <div
        className={CardStyless.cardImageWrapper}
        style={{ backgroundColor: "gray" }}
      >
        <img
          src={
            item?.doctorImage ||
            item?.doctorId?.doctorImage ||
            "https://path/to/default/image.jpg"
          }
          alt={item?.name || item?.doctorId?.name || "Doctor"}
          className={CardStyless.cardImage}
        />
      </div>
      <div className={CardStyless.cardBody}>
        <div className={`${CommonStyles.flx} ${CommonStyles.flxBetween}`}>
          <div className={`${CardStyless.cardName} ${CommonStyles.colorBlue}`}>
            {item?.name || item?.doctorId?.name || "Unavailable"}
          </div>
          <div>
            <img
              src={Vector}
              alt="Vector icon"
              className={CardStyless.vectorIcon}
            />
          </div>
        </div>
        <div className={`${CommonStyles.flx} ${CardStyless.cardSpecialities}`}>
          {speciality && speciality?.length > 0 ? (
            speciality?.map((s: any, ind: number) => (
              <span key={ind} className={CardStyless.specialityTag}>
                {s}
              </span>
            ))
          ) : (
            <span className={CardStyless.specialityPlaceholder}>
              Specialty info missing
            </span>
          )}
        </div>

        <div className={CardStyless.cardDetails}>
          <div className={CardStyless.cardDetail}>
            {item?.qualifications ||
              item?.doctorId?.qualifications ||
              "Qualifications unavailable"}
          </div>
          <div className={CardStyless.cardExperience}>
            {item?.clinicExperience ||
              item?.doctorId?.clinicExperience ||
              "Experience info missing"}
          </div>
        </div>
        {type && type == "speciality" && (
          <div className={`${CommonStyles.flx} ${CommonStyles.flxBetween}`}>
            <div>
              <p>Package Price</p>
            </div>
            <div>
              <p>{item?.totalAmount} PKR</p>
            </div>
          </div>
        )}

        <div className={`${CommonStyles.flx} ${CommonStyles.flxBetween}`}>
          <RatingStar rating={item?.rating || item?.doctorId?.rating || 2} />
          <div className={`${CommonStyles.flx} ${CardStyless.viewMore}`}>
            <div className={CardStyless.cardViewMore}>Details</div>
            <IoMdArrowRoundForward className={CardStyless.cardArrowIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
