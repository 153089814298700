import React, { useState } from "react";
import styles from "./Direct.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import flightPlane from "../../../../assets/images/Illustration.png";
import { useLocation, useNavigate } from "react-router-dom";
import { addBidFlight } from "shared/services/TravelAgency";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import edit from "assets/images/edit2.png";
import del from "assets/images/del.png";
import clock from "assets/images/clock.png";
import {
  DeleteModal,
  LogoComponent,
  TimeSelctionComponent,
} from "shared/components";
import { formatISO } from "date-fns";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";
import moment from "moment";
// import CustomTimePicker from "../TimePicker/TimePICKER2";
const initialValues: any = {
  companyName: "",
  departureDate: "",
  departureTime: "",
  arrivalDate: "",
  arrivalTime: "",
  amenities: [],
  from: "",
  to: "",
  flightNo: "",
  noOfHandbag: "",
  baggageWeight: "",
};
const validationSchema = Yup.object({
  companyName: Yup.string().required("CompanyName is required"),
  companyLogo: Yup.string().required("companyLogo is required"),
  from: Yup.string().required("From is required"),
  to: Yup.string().required("to is required"),
  departureDate: Yup.string().required("departureDate is required"),
  departureTime: Yup.string().required("departureTime is required"),
  arrivalDate: Yup.string().required("ArrivalDate is required"),
  arrivalTime: Yup.string().required("ArrivalTime is required"),
  flightNo: Yup.string().required("FlightNo is required"),
  noOfHandbag: Yup.string().required("noOfHandbag is required"),
  baggageWeight: Yup.string().required("Baggage is required"),
  amenities: Yup.array().min(1, "Select at least one amenities"),
});
const Direct: React.FC = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [inputsVisible, setInputsVisible] = useState(true);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [showImageAndButton, setShowImageAndButton] = useState(false);
  const [showDetailsReturn, setShowDetailsReturn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const location = useLocation();
  const [isBidShared, setIsBidShared] = useState(false);

  const [flights, setFlights] = useState<any[]>([]);
  const navigate = useNavigate();
  const formatTime = (time: string): string => {
    return new Date(time).toISOString();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: any, { resetForm }: FormikHelpers<any>) => {
      const formattedDepartureTime = values.departureTime
        ? formatISO(new Date(values.departureTime))
        : null;
      const formattedarrivalTime = values.arrivalTime
        ? formatISO(new Date(values.arrivalTime))
        : null;
      const formattedValues = {
        ...values,
        departureTime: formattedDepartureTime,
        arrivalTime: formattedarrivalTime,
      };

      if (editMode) {
        const updatedFlights: any = flights.map((flight: any, index: any) =>
          index === currentIndex ? formattedValues : flight
        );
        setFlights(updatedFlights);
        setEditMode(false);
      } else {
        setFlights([...flights, formattedValues]);
      }
      resetForm();
      setShowImageAndButton(true);
      setInputsVisible(false);
    },
  });

  const { flightType } = location.state || { flightType: "Default" };
  const { val } = location.state;
  console.log(val, ".....valval");
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const polForm = useFormik({
    initialValues: {
      cancelationDuration: "",
      cancelationDeduction: "",
      TicketPrice: "",
    },
    validationSchema: Yup.object({
      cancelationDuration: Yup.string().required(
        "cancelationDuration is required"
      ),
      cancelationDeduction: Yup.string().required(
        "cancelationDeduction is required"
      ),
      TicketPrice: Yup.string().required("TicketPrice is required"),
    }),
    onSubmit: (values) => {
      addBid(values);
    },
  });
  const addBid = (values: any) => {
    setLoading(true);
    let params: any = {
      requestId: val?._id,
      flightDetails: [...flights],
      flightPolicies: {
        cancelationDuration: values?.cancelationDuration,
        cancelationDeduction: values?.cancelationDeduction,
      },
      ticketPrice: parseInt(values?.TicketPrice, 10),
    };
    if (flightType === "round") {
      params = {
        ...params,
        // returnFlights: [...flightsReturn],
      };
    } else {
      params = {
        ...params,
      };
    }
    console.log(params, "....params");
    addBidFlight(params)
      .then(() => {
        // setVisible(true);
        toast.success("Success");
        setTimeout(() => {
          // setVisible(false);
          // navigate('TravelAgencyRequests');
        }, 500);
      })
      .catch((err) => {
        // showToast(err, 'error', err);
        console.log(err, "...");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSave = () => {
    if (!formik?.isValid && formik?.submitCount > 0) {
      let values: any = Object.values(formik?.errors)[0];
      console.log(values, "....values");
      toast.success(values);
    } else {
      formik.handleSubmit();
    }
  };
  console.log(flights, "........flights");
  const handleAmenityChange = (amenity: any, checked: any) => {
    if (checked) {
      formik.setFieldValue("amenities", [...formik.values.amenities, amenity]);
    } else {
      formik.setFieldValue(
        "amenities",
        formik.values.amenities.filter((item: any) => item !== amenity)
      );
    }
  };
  const addForm = () => {
    formik.resetForm();
    setInputsVisible(true);
  };
  const handleEditFlight = (index: number) => {
    const flightToEdit = flights[index];
    formik.setValues(flightToEdit);
    // console.log(flightToEdit, '.........flightToEdit');
    setCurrentIndex(index);
    setEditMode(true);
    setInputsVisible(true);
  };
  const confirmDeleteFlight = (index: any) => {
    setCurrentIndex(index);
    // if (flights?.length > 0) {
    //   // ToastAndroid.show(
    //   //   'Please delete the return flight before deleting the flight.',
    //   //   ToastAndroid.SHORT,
    //   // );
    //   setModalVisible(false);
    // } else {
    setModalVisible(true);
    // }
  };
  const handleShareBid = () => {
    setIsBidShared(true); // Set bid shared status
    setTimeout(() => {
      navigate("/travelAgency/ticketRequests");
    }, 3000); // Wait for 3 seconds
  };
  const handleDeleteFlight = () => {
    const updatedFlights = flights.filter(
      (_: any, i: any) => i !== currentIndex
    );
    setFlights(updatedFlights);
    setModalVisible(false);
    if (updatedFlights.length === 0) {
      // setFlightTypeSelected('');
      navigate("/flights/Bid");
      formik.resetForm();
    }
  };
  console.log(
    formik?.values?.departureTime,
    "........formik?.values?.departureTime"
  );
  const onPress = () => {
    if (!polForm.isValid && polForm.submitCount > 0) {
      const firstError = Object.values(polForm.errors)[0];
      // showToast('Error', firstError, false);
      toast.error(firstError);
    }
    //  else if (flightType === 'round' && flightsReturn.length === 0) {
    //   showToast(
    //     'Error',
    //     'Please add a return flight before sharing the bid',
    //     false,
    //   );
    // }
    else {
      polForm.handleSubmit();
    }
  };

  return (
    <div className={styles.containerFlight}>
      {inputsVisible ? (
        <>
          <h2 className={styles.heading}>{flightType} Flight</h2>
          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Company Name:</label>
              <input
                type="text"
                value={formik?.values?.companyName}
                onChange={(e) =>
                  formik.setFieldValue("companyName", e.target.value)
                }
                className={styles.input}
                placeholder="Enter Company Name *"
              />
            </div>
            <div className={styles.inputGroup}>
              <LogoComponent
                setLoading={setLoading}
                fileName={fileName}
                setFileName={setFileName}
                url={formik?.values?.companyLogo}
                setUrl={(value: any) =>
                  formik.setFieldValue("companyLogo", value)
                }
              />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>From:</label>
              <input
                type="text"
                value={formik?.values?.from}
                onChange={(e) => formik.setFieldValue("from", e.target.value)}
                className={styles.input}
                placeholder="From *"
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.label}>To:</label>
              <input
                type="text"
                value={formik?.values?.to}
                onChange={(e) => formik.setFieldValue("to", e.target.value)}
                className={styles.input}
                placeholder="To *"
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Departure Date:</label>
              <input
                type="date"
                className={styles.input}
                value={formik?.values?.departureDate}
                onChange={(e) =>
                  formik.setFieldValue("departureDate", e.target.value)
                }
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Departure Time</label>
              <TimeSelctionComponent
                selectedTime={formik.values.departureTime}
                setTime={(time: any) =>
                  formik.setFieldValue("departureTime", time)
                }
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Arrival Date:</label>
              <input
                type="date"
                className={styles.input}
                value={formik?.values?.arrivalDate}
                onChange={(e) =>
                  formik.setFieldValue("arrivalDate", e.target.value)
                }
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Arrival Time:</label>
              <TimeSelctionComponent
                selectedTime={formik?.values?.arrivalTime}
                setTime={(time: any) =>
                  formik.setFieldValue("arrivalTime", time)
                }
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Flight No:</label>
              <input
                type="text"
                value={formik?.values?.flightNo}
                onChange={(e) =>
                  formik.setFieldValue("flightNo", e.target.value)
                }
                className={styles.input}
                placeholder="Enter Flight Number"
              />
            </div>
          </div>

          {/* Flight Amenities Section */}
          <h3 className={styles.amenitiesHeading}>Flight Amenities</h3>
          <div className={styles.amenitiesContainer}>
            <div className={styles.leftGroup}>
              {[
                "Reading Material",
                "Wi-Fi",
                "In-Flight Entertainment",
                "Beverage",
                "Comfort Items",
                "Basic Toiletries",
                "Light Meal",
              ].map((amenity, index) => (
                <div className={styles.radioGroup} key={index}>
                  <input
                    type="checkbox"
                    id={amenity}
                    name="flightAmenities"
                    checked={formik.values.amenities.includes(amenity)}
                    className={styles.radioInput}
                    value={formik.values.amenities.includes(amenity)}
                    onChange={(checked: any) =>
                      handleAmenityChange(amenity, checked)
                    }
                  />
                  <label
                    htmlFor={amenity}
                    className={
                      formik.values.amenities.includes(amenity)
                        ? styles.orangeLabel
                        : styles.blueLabel
                    }
                  >
                    {amenity}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>No of Bags:</label>
              <input
                type="number"
                value={formik?.values?.noOfHandbag}
                onChange={(e) =>
                  formik.setFieldValue("noOfHandbag", e.target.value)
                }
                className={styles.input}
                placeholder="Number of Bags *"
              />
            </div>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Baggage Weight:</label>
              <input
                type="text"
                value={formik?.values?.baggageWeight}
                onChange={(e) =>
                  formik.setFieldValue("baggageWeight", e.target.value)
                }
                className={styles.input}
                placeholder="Baggage Weight (kg) *"
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.saveButton} onClick={handleSave}>
              Save
            </button>
          </div>
        </>
      ) : (
        <div className={styles.flightSummary}>
          <div className={styles.header}>
            <h2 className={styles.flightHeading}> Flights</h2>

            <button onClick={toggleDetails} className={styles.detailsButton}>
              {showDetails ? <FaChevronUp /> : <FaChevronDown />}
            </button>
          </div>

          {showDetails &&
            flights.map((flight: any, index: any) => (
              <>
                <div className={styles.detailsContainer}>
                  <img
                    src={edit}
                    className={styles.editButton}
                    alt="Upload"
                    onClick={() => handleEditFlight(index)}
                  />
                  <button
                    onClick={() => confirmDeleteFlight(index)}
                    className="deleteButton"
                  >
                    <img src={del} className="deleteIcon" alt="Delete" />
                  </button>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>Company Name:</span>
                    <span className={styles.detailData}>
                      {flight?.companyName}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>Company logo:</span>
                    <span className={styles.detailData}>{fileName}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>Flight No:</span>
                    <span className={styles.detailData}>
                      {flight?.flightNo}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>From:</span>
                    <span className={styles.detailData}>{flight?.from}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>To:</span>
                    <span className={styles.detailData}> {flight?.to}</span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>
                      Departure Date:
                    </span>
                    <span className={styles.detailData}>
                      {flight?.departureDate}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>
                      Departure Time:
                    </span>
                    <span className={styles.detailData}>
                      {moment(flight?.departureTime).format("hh:mm A")}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>Arrival Date:</span>
                    <span className={styles.detailData}>
                      {moment(flight?.arrivalTime).format("hh:mm A")}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>Arrival Time:</span>
                    <span className={styles.detailData}>
                      {flight?.arrivalTime}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>No of HandBag:</span>
                    <span className={styles.detailData}>
                      {flight?.noOfHandbag}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>
                      Baggage Weight:
                    </span>
                    <span className={styles.detailData}>
                      {flight?.baggageWeight}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.detailHeading}>
                      Selected Amenities:
                    </span>
                    <span className={styles.detailData}>
                      {flight?.amenities.join(", ")}
                    </span>
                  </div>
                </div>
                <DeleteModal
                  modalVisible={modalVisible}
                  handleCancel={() => setModalVisible(false)}
                  handleDelete={handleDeleteFlight}
                  loading={loading}
                />
              </>
            ))}

          {showImageAndButton && (
            <div className={styles.imageAndButtonContainer}>
              <div className={styles.buttonContainer}>
                <button className={styles.returnButton} onClick={addForm}>
                  Add More
                </button>
              </div>
              <div className={styles.imageContainer}>
                <img
                  src={flightPlane}
                  alt="Flight"
                  className={styles.companyImage}
                />
              </div>
            </div>
          )}

          <div className={styles.cancellationInputContainer}>
            <input
              type="text"
              placeholder="Cancellation Duration *"
              value={polForm.values.cancelationDuration}
              onChange={(e: any) =>
                polForm.setFieldValue("cancelationDuration", e.target.value)
              }
              className={styles.cancellationInput}
            />
            <input
              type="text"
              placeholder="Cancellation Percentage *"
              value={polForm.values.cancelationDeduction}
              onChange={(e: any) =>
                polForm.setFieldValue("cancelationDeduction", e.target.value)
              }
              className={styles.cancellationInput}
            />
            <input
              type="text"
              placeholder="Total Price *"
              value={polForm.values.TicketPrice}
              onChange={(e: any) =>
                polForm.setFieldValue("TicketPrice", e.target.value)
              }
              className={styles.cancellationInput}
            />
          </div>

          {/* Add Bid Button */}
          <div className={styles.buttonContainer} onClick={onPress}>
            <button
              className={styles.addBidButton}
              onClick={handleShareBid}
              disabled={isBidShared} // Optionally disable button after click
            >
              Share Bid
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Direct;
