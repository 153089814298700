import React, { useState } from "react";
import SearchBar from "shared/components/Searchbar";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import style from "./InsuranceAA.module.css";
import Health from "assets/images/Health Insurance.png";
import Travel from "assets/images/Travel Insurance 2.png";
import { Navigate, useNavigate } from "react-router-dom";
import { CustomModal, PrimaryButton } from "shared/components";
import Empty from "assets/images/empty2.png";
import { IoClose } from "react-icons/io5";
import Checkbox from "@mui/joy/Checkbox";
import { TbRefresh } from "react-icons/tb";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import InsuranceEmpty from "shared/components/InsuranceEmpty";

const InsuranceInsurances: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("0");
  // const [showInsuranceCategory, setShowInsuranceCategory] = useState(false);

  // const handleCardClick = () => {
  //   setShowInsuranceCategory(true);
  // };

  const navigate = useNavigate();

  const handleGoToHealth = () => {
    navigate("/insurance/Health");
  };

  const handleGoToTravel = () => {
    navigate("/insurance/Travel");
  };

  // const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedOption(event.target.value);
  //   // setShowInsuranceCategory(false);
  // };

  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={commonstyles.outerContainer}>
          <div className={commonstyles.flx}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Insurance Plan
            </p>
            {/* <div className={style.outerRefresh}>
              <TbRefresh className={style.RefreshIcon} />
            </div> */}
            {/* <div className={style.outerRefresh} onClick={handleCardClick}>
              <BiSolidMessageSquareAdd className={style.RefreshIcon} />
            </div> */}
          </div>
          {/* <CustomModal
            showModal={showInsuranceCategory}
            children={
              <InsuranceCategory
                setShowInsuranceCategory={setShowInsuranceCategory}
                selectedOption={selectedOption}
                handleSelectChange={handleSelectChange}
              />
            }
          /> */}

          <div className={commonstyles.flx}>
            <div className={style.healthCard} onClick={handleGoToHealth}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Health
              </p>
              <div className={style.textcontainer}>
                <p className={classNames(commonstyles.fs16, style.textdata)}>
                  Health insurance helps cover medical expenses, providing
                  financial protection against high healthcare costs.
                </p>
              </div>
              <div className={style.healthimgconatiner}>
                <img src={Health} className={style.healthImg} alt="Health" />
              </div>
            </div>

            <div className={style.healthCard} onClick={handleGoToTravel}>
              <p
                className={classNames(
                  commonstyles.fs20,
                  commonstyles.semiBold,
                  commonstyles.colorBlue
                )}
              >
                Travel
              </p>
              <div className={style.textcontainer}>
                <p className={classNames(commonstyles.fs16, style.textdata)}>
                  Travel insurance provides coverage for unexpected events such
                  as trip cancellations, medical emergencies, lost luggage, and
                  other travel-related incidents
                </p>
              </div>
              <div className={style.healthimgconatiner}>
                <img src={Travel} className={style.healthImg} alt="Travel" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceInsurances;

// const InsuranceCategory = (props: any) => {
//   const { setShowInsuranceCategory, selectedOption, handleSelectChange } =
//     props;
//   const [healthChecked, setHealthChecked] = useState(false);
//   const handleCloseModal = () => {
//     setShowInsuranceCategory(false);
//   };
//   const handleHealthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setHealthChecked(event.target.checked);
//     handleSelectChange(event);
//   };
//   return (
//     <div style={{ width: "400px" }}>
//       <div className={style.header}>
//         <p
//           className={classNames(
//             commonstyles.fs20,
//             commonstyles.semiBold,
//             commonstyles.colorBlue
//           )}
//         >
//           My self Package
//         </p>
//         <IoClose className={style.close} onClick={handleCloseModal} />
//       </div>
//       <div className={commonstyles.flx}>
//         <div className={style.heathCard}>
//           <div className={commonstyles.flxBetween}>
//             <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
//               Health
//             </p>
//             <Checkbox
//               color="success"
//               onChange={handleSelectChange}
//               value="1"
//               checked={healthChecked}
//             />
//           </div>
//           <img src={Health} className={style.healthImgmodel} alt="Health" />
//         </div>
//         <div className={style.TravelCard}>
//           <div className={commonstyles.flxBetween}>
//             <p className={classNames(commonstyles.fs16, commonstyles.semiBold)}>
//               Travel
//             </p>
//             <Checkbox color="success" onChange={handleSelectChange} value="2" />
//           </div>
//           <img src={Travel} className={style.healthImgmodel} alt="Travel" />
//         </div>
//       </div>
//       <div className={commonstyles.mt56}>
//         <PrimaryButton
//           children={"Continue"}
//           colorType={"green"}
//           onClick={handleCloseModal}
//         />
//       </div>
//     </div>
//   );
// };
