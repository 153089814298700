import React, { useState } from "react";
import style from "./Product.module.css";
import classNames from "classnames";
import commonStyle from "shared/utils/common.module.css";
import { IoMdArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";
import { TestCard } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";

const LaboratoriyProduct = ({
  productData,
  get_Test,
  test,

  labId,
}: {
  productData?: any;
  get_Test?: any;
  test?: any;
  labId?: any;
}) => {
  // console.log("agya productData", productData);

  const [selectedTestIndex, setSelectedTestIndex] = useState<number | null>(
    null
  );
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const { isLoggedIn } = useSelector((state: any) => state?.root?.common);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTestClick = (cat: number) => {
    get_Test(cat);
    setSelectedTestIndex(cat);
  };

  console.log("......test", test);

  const handleCardClick = (item: any) => {
    // console.log("item......", item);

    setSelectedCards(
      (prevSelected) =>
        prevSelected.includes(item)
          ? prevSelected.filter((id) => id !== item) // Deselect if already selected
          : [...prevSelected, item] // Select the card
    );
  };
  const handlePayment = async () => {
    const totalMeditourPrice = selectedCards.reduce(
      (total: any, card: any) => total + card.priceForMeditour,
      0
    );

    if (isLoggedIn) {
      await dispatch(setObj(selectedCards));
      navigate("/services/paymentDetail", {
        state: {
          actualAmount: totalMeditourPrice,
          serviceName: "Lab",
          labId: labId,
        },
      });
    } else {
      navigate("/user/login");
    }
  };

  return (
    <div
      className={style.cardContainer}
      style={{
        marginBottom: "32px",
      }}
    >
      {selectedTestIndex === null ? (
        productData.map((test: any, index: any) => (
          <div
            key={index}
            className={classNames(style.card)}
            onClick={() => handleTestClick(test?.categoryName)}
          >
            <p className={classNames(style.text)}>{test?.categoryName}</p>
          </div>
        ))
      ) : (
        <div className={classNames(commonStyle.container)}>
          <div
            className={classNames(commonStyle.flx, commonStyle.flxStart)}
            style={{ border: "2px solid" }}
          >
            <p
              className={classNames(
                commonStyle.colorBlue,
                commonStyle.fs32,
                commonStyle.semiBold
              )}
            >
              {/* {cardData[selectedTestIndex]?.title} */}
            </p>
            {test?.length > 0 && (
              <p>{`${selectedCards.length} test${
                selectedCards.length > 0 ? "s" : ""
              } selected`}</p>
            )}
          </div>

          {test?.length > 0 ? (
            <TestCard
              onPress={handleCardClick}
              selectedCards={selectedCards}
              item={test}
            />
          ) : (
            <div>
              {" "}
              <div>
                <PhysiotheristsEmpty />
              </div>
            </div>
          )}

          {/* <div className={style.cardContainer}>
            {test?.map((card: any, index: any) => (
              <div
                key={index}
                className={classNames(style.dcard, {
                  [style.selected]: selectedCards.includes(card), // Highlight selected cards
                })}
                // style={{ border: "1px solid #000" }}
                onClick={() => handleCardClick(card)}
              >
                <div className={style.dtext}>
                  <p
                    className={classNames(
                      commonStyle.fs24,
                      commonStyle.colorBlue,
                      commonStyle.semiBold
                    )}
                  >
                    {`${card?.testNameId?.name} (${card?.testCode}) `}
                  </p>

                  <p
                    className={classNames(
                      commonStyle.fs14,
                      commonStyle.colorGray
                    )}
                  >
                    {card?.testDescription}
                  </p>

                  <p
                    className={classNames(
                      commonStyle.fs14,
                      commonStyle.colorBlue
                    )}
                  >
                    {card?.priceForMeditour} PKR
                  </p>
                </div>
              </div>
            ))}
          </div> */}

          <div className={style.showMoreContainer}>
            {test?.length > 0 ? (
              <button
                className={classNames(style.showMoreButton, {
                  [style.gray]: selectedCards.length === 0,
                })}
                disabled={selectedCards.length === 0}
                onClick={handlePayment}
              >
                Continue
                <span className={style.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default LaboratoriyProduct;
