import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styles from "./PhoneNumberInputNew.module.css";

interface Props {
  value: string;
  setValue: any;
  onCountryCodeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const PhoneNumberInputNew = (props: Partial<Props>) => {
  const { value, setValue, onCountryCodeChange } = props;

  return (
    <div className={styles.container}>
      <PhoneInput
        placeholder="Enter phone number*"
        value={value}
        onChange={(text) => setValue(text)}
        numberInputProps={{
          className: styles.phone_input,
        }}
      />
    </div>
  );
};

export default PhoneNumberInputNew;
