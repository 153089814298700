import React from "react";
import { MdArrowForwardIos } from "react-icons/md";
import styles from "./styles.module.css";
import commonstyles from "shared/utils/common.module.css";
import classNames from "classnames";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";

const NavBreadCrumbs = ({
  data,
  heading,
  backgroundImage,
}: {
  data?: any[];
  heading?: any;
  backgroundImage?: any;
}) => {
  const breadcrumbsData = data?.filter((item) => item);

  return (
    <div
      className={styles.navIMG}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <NavBarr />
      <div
        className={classNames(
          styles.responsiveContainer,
          styles.fs48,
          commonstyles?.semiBold
        )}
      >
        {heading}
      </div>

      <div className={styles.flxcenter}>
        {breadcrumbsData?.map((item: any, index) => {
          if (!item) return null;
          return (
            <React.Fragment key={index}>
              <p
                className={
                  index === breadcrumbsData?.length - 1
                    ? styles.headingpath
                    : styles.headingpathOrange
                }
              >
                {item}
              </p>
              {index !== breadcrumbsData?.length - 1 && (
                <MdArrowForwardIos className={styles.headingpath} />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default NavBreadCrumbs;
