import React, { useEffect, useMemo, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import Footerr from "../../Footer";
import { useNavigate } from "react-router-dom";
import styles from "./BookingHotel.module.css";
import DownloadImagelogo from "../../../../../assets/images/DownloadImagelogo.png";
import Styles from "./NavigateBar.module.css";
import { getAllBookings } from "shared/services";
import { LoadingModal } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import moment from "moment";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import NewPagination from "shared/components/NewPagination/NewPagination";
import TravelRequest from "../Rewquest/TravelandTour";
import FlightContent from "./FlightContent";
import BidDetailsComponent from "./BidDetailsComponent";
import MainHeader from "shared/components/MainScreen/Index";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { MY_BOOKING } from "shared/utils/mainHeaderQuery";
import CustomLoader from "shared/components/New_Loader/Loader";
const MyBookingHotel = React.memo((props) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(() => {
    return localStorage.getItem("selectedTab") || "Hotel";
  });
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [shift, setShift] = useState(() => {
    return localStorage.getItem("shiftTab") || "FLIGHTS";
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const onsSelectCard = async (title: any) => {
    setList([]);
    setSelected(title);
  };
  useEffect(() => {
    setLoading(true);
    fetchOrders(currentPage);
  }, [selected, shift]);

  const fetchOrders = (pagenum: number) => {
    setLoading(true);
    let type =
      selected === "Travel" && shift === "FLIGHTS"
        ? "flight"
        : selected === "Travel" && shift === "TOURS"
        ? "tour"
        : selected === "Rent a car"
        ? "rentcar"
        : selected?.toLowerCase();
    getAllBookings(type, pagenum)
      .then((res: any) => {
        console.log(res?.data?.bookings, "......res?.data?.bookings");
        setList(res?.data?.bookings);
        setTotalItems(res?.data?.totalBookings);
      })
      .catch((err: any) => console.log("er......", err.response?.data))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTabChange = (tab: any) => {
    setList([]);
    setShift(tab);
  };
  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    const savedShift = localStorage.getItem("shiftTab");

    if (savedTab) {
      setSelected(savedTab);
    }

    if (savedShift) {
      setShift(savedShift);
    }

    setLoading(true);
    fetchOrders(currentPage);
  }, [currentPage]);

  const viewDetails = (item?: any, checkTraveler?: any) => {
    localStorage.setItem("selectedTab", selected);
    localStorage.setItem("shiftTab", shift);
    if (selected == "Insurance") {
      navigate("/services/insuranceBookingDetail", { state: { item: item } });
    } else if (selected == "Rent a car") {
      let pickupDate = moment
        .utc(item?.pickupDateTime)
        .format("MM/DD/YYYY, hh:mm A");
      let dropofDate = moment
        .utc(item?.dropoffDateTime)
        .format("MM/DD/YYYY, hh:mm A");
      navigate("/services/rentacar/RentaCarBooking/", {
        state: {
          items: item,
          type: "BookingCar",
          pickupLocation: item?.pickupLocation,
          dropoffLocation: item?.dropoffLocation,
          pickDate: pickupDate,
          dropDate: dropofDate,
        },
      });
    } else if (selected == "Hotel") {
      navigate("/services/bookingHotelDetail", {
        state: { data: item, type: "hotelRemaining" },
      });
    } else if (selected == "Travel" && shift == "TOURS") {
      navigate("/services/travel/TravelDetail", {
        state: { item: item, type: "TourBooking" },
      });
    } else if (selected == "Travel" && shift == "FLIGHTS") {
      navigate("/services/myRequest/TravellDetail", {
        state: {
          data: item,
          totalTravelers: checkTraveler,
          type: "booking",
        },
      });
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchOrders(currentPage + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    fetchOrders(currentPage - 1);
  };

  return (
    <div>
      <NavBreadCrumbs {...MY_BOOKING} />
      <div>
        <HeaderCard
          Styles={Styles}
          setSelected={onsSelectCard}
          selected={selected}
        />
        {selected == "Travel" && (
          <div style={{ margin: "20px 0" }}>
            <DoubleButton
              tab1Label="FLIGHTS"
              tab2Label="TOURS"
              shift={shift}
              onTabChange={handleTabChange}
            />
          </div>
        )}
      </div>
      {list?.length > 0 ? (
        <div className={styles.bookingContainer}>
          {list.map((item: any) => {
            const totalTravelers = item?.adult + item?.children + item?.infant;
            const inBooking =
              item.requestId?.adult +
              item?.requestId?.children +
              item?.requestId?.infant;
            const checkTraveler = totalTravelers || inBooking;
            console.log(checkTraveler);
            return (
              <>
                {selected === "Travel" && shift == "FLIGHTS" ? (
                  <>
                    <FlightContent item={item} />
                    <BidDetailsComponent
                      type={"booking"}
                      item={item}
                      totalTravelers={checkTraveler}
                      handledetail={() => viewDetails(item, checkTraveler)}
                    />
                  </>
                ) : selected == "Ambulance" ? (
                  <AmbCard item={item} style={styles} />
                ) : (
                  <GenericCard
                    styles={styles}
                    item={item}
                    clickDetail={() => viewDetails(item)}
                  />
                )}
              </>
            );
          })}
          <NewPagination
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
            startItem={(currentPage - 1) * itemsPerPage + 1}
            endItem={Math.min(currentPage * itemsPerPage, totalItems)}
            totalItems={totalItems}
          />
        </div>
      ) : (
        <div>{!loading && <PhysiotheristsEmpty />}</div>
      )}

      {loading && <CustomLoader />}
      <Footerr />
    </div>
  );
});
const GenericCard = ({
  item,
  styles,
  clickDetail,
}: {
  item?: any;
  styles?: any;
  clickDetail?: any;
}) => {
  console.log(item, "...warning");
  const logo =
    item?.insuranceCompanyId?.logo ||
    item?.vehicleId?.vehicleImages[0] ||
    item?.tourId?.images[0] ||
    item?.serviceId?.propertyphoto[0];

  const name =
    item?.insuranceCompanyId?.name ||
    item?.vehicleId?.vehicleName ||
    item?.packageName ||
    item?.serviceId?.propertyName;

  const subText_1 =
    (item?.insuranceId?.perYear &&
      `Duration:  ${item?.insuranceId?.perYear}`) ||
    (item?.pickupDateTime &&
      `Pickup Date & Time: ${moment
        .utc(item?.pickupDateTime)
        .format("M/D/YYYY, h:mmA")}`) ||
    (item?.totalUser && `Total Seats: ${item?.totalUser}`);

  const formattedDateTime = useMemo(() => {
    return moment(item?.createdAt).format("M/D/YYYY, h:mmA");
  }, [item?.createdAt]);
  let totalAmount;

  if (item?.gatewayName === "blinq") {
    if (item?.amount) {
      totalAmount = `PKR ${item.amount.toFixed(2)}`;
    } else if (item?.totalAmount) {
      totalAmount = `PKR ${item.totalAmount.toFixed(2)}`;
    } else if (item?.actualPrice) {
      totalAmount = `PKR ${item.actualPrice.toFixed(2)}`;
    }
  } else {
    if (item?.amount) {
      totalAmount = `$ ${item.amount.toFixed(2)}`;
    } else if (item?.totalAmount) {
      totalAmount = `$ ${item.totalAmount.toFixed(2)}`;
    } else if (item?.actualPrice) {
      totalAmount = `$ ${item.actualPrice.toFixed(2)}`;
    }
  }

  console.log(totalAmount, ".............................");
  return (
    <>
      <div className={styles.bookingCard}>
        <div className={styles.bookingImageconatiner}>
          <img src={logo} alt="Hotel" className={styles.bookingImage} />
        </div>
        <div className={styles.bookingInfo}>
          <div className={styles.bookingDetails}>
            <h3 className={styles.hotelName}>{name}</h3>

            <div className={styles.hotelRoom}>
              <p>{subText_1}</p>
              <p>Total Amount: {totalAmount}</p>
            </div>

            <div>
              {item?.isPaidFull === false && (
                <p
                  style={{
                    color: "rgba(234, 2, 52, 1)",
                    fontSize: "14px",
                    marginTop: "8px",
                  }}
                >
                  The full payment is due.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.bookingActions}>
          <p className={styles.bookingDate}>{formattedDateTime}</p>
          <button className={styles.viewDetailsButton} onClick={clickDetail}>
            View Details
            <img
              src={DownloadImagelogo}
              alt="Logo"
              className={styles.downloadButtonLogo}
            />
          </button>
        </div>
      </div>
    </>
  );
};

const HeaderCard = ({
  Styles,
  setSelected,
  selected,
}: {
  Styles?: any;
  setSelected?: any;
  selected?: any;
}) => {
  const data = [
    { id: 0, title: "Hotel" },
    { id: 4, title: "Rent a car" },
    { id: 1, title: "Insurance" },
    { id: 3, title: "Travel" },
    { id: 2, title: "Ambulance" },
  ];
  return (
    <div className={Styles.navContainer}>
      {data.map((item: any) => (
        <button
          className={`${Styles.navButton} ${
            selected === item?.title ? Styles.active : ""
          }`}
          onClick={() => setSelected(item?.title)}
        >
          {item?.title}
        </button>
      ))}
    </div>
  );
};

const AmbCard = ({ item, style }: { item?: any; style?: any }) => {
  return (
    <>
      <div className={style.maincontainer}>
        {/* Heading Container */}
        <div className={style.headingcontainer}>
          <div>
            <p className={style.title}>From</p>
            <p className={style.detail}>{item?.requestId?.pickUp?.address}</p>
            <p className={style.title}>To</p>
            <p className={style.detail}>{item?.requestId?.dropOff?.address}</p>
          </div>
        </div>

        <>
          <div className={style.detailcontainer}>
            <div className={classNames(commonstyles.flx)}>
              <div className={style.imgcontainer}>
                <img
                  src={item?.ambulanceId?.logo}
                  alt=""
                  className={style.logoimg}
                />
              </div>
              <div>
                <p className={style.name}>{item?.ambulanceId?.name}</p>
              </div>
            </div>

            <div>
              <p className={style.detaildata}>
                {" "}
                {item?.bidRequestId?.ambulanceName}
              </p>
            </div>
            <div>
              <p className={style.detaildata}>PKR {item?.totalAmount}</p>
            </div>

            <p
              style={{
                margin: "0 24px",
                marginTop: "16px",
                color:
                  item?.status === "in-progress"
                    ? "rgba(0, 39, 109, 1)"
                    : "rgba(0, 123, 27, 1)",
              }}
            >
              {item?.status === "in-progress"
                ? "Your ambulance process is in running"
                : "Your ambulance process has been completed"}
            </p>
          </div>
        </>
      </div>
    </>
  );
};

export default MyBookingHotel;
