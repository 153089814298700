import React, { useState, useEffect } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./TravelDetail.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import img1 from "assets/images/TourSlide1.png";
import img2 from "assets/images/Tourslide2.png";
import img3 from "assets/images/TourSlide1.png";
import img4 from "assets/images/Tourslide2.png";
import PaymentMyAppointMent from "../../PaymentMyAppointMent";
import DownloadImagelogo from "assets/images/DownloadImagelogo.png";
import ContinueButton from "shared/components/ContinueButton";
import { IoLocationOutline } from "react-icons/io5";

const BookingTourDetail: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const images = [img1, img2, img3, img4];
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const imagesPerSlide = 3;
  const totalDots = Math.ceil(images.length / imagesPerSlide);

  const visibleImages = images.slice(
    currentIndex,
    currentIndex + imagesPerSlide
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex + imagesPerSlide >= images.length ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  const handleContinue = () => {
    console.log("Button clicked!");
  };
  return (
    <div>
      <MainHeader
        mainHeading="Travel Agency"
        breadcrumb={["Home", "My Booking"]}
      />

      <div className={classNames(commonstyle.container)}>
        <div className={classNames(style.slidercontainer)}>
          {visibleImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className={style.sliderimg}
            />
          ))}
        </div>

        <div className={style.dotsContainer}>
          {Array.from({ length: totalDots }).map((_, index) => (
            <span
              key={index}
              className={classNames(style.dot, {
                [style.active]: currentIndex === index * imagesPerSlide,
              })}
              onClick={() => setCurrentIndex(index * imagesPerSlide)}
            ></span>
          ))}
        </div>

        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div
            className={classNames(
              commonstyle.col5,
              commonstyle.colsm12,
              style.dataconatiner
            )}
          >
            <div className={style.textconatiner}>
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.colorfs24,
                  commonstyle.semiBold
                )}
              >
                Travel Summary
              </p>
              <div className={commonstyle.flx}>
                <div>
                  <IoLocationOutline
                    className={classNames(commonstyle.colorBlue)}
                    style={{
                      margin: "5px",
                      fontSize: "18px",
                    }}
                  />
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.colorfs14,
                      commonstyle.semiBold
                    )}
                  >
                    {" "}
                    From
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.colorfs14,
                      commonstyle.semiBold
                    )}
                  >
                    MM Alam Road, Gulburg3, Lahore, Punjab
                  </p>
                </div>
              </div>

              <div className={commonstyle.flx}>
                <div>
                  <IoLocationOutline
                    className={classNames(commonstyle.colorBlue)}
                    style={{
                      margin: "5px",
                      fontSize: "18px",
                    }}
                  />
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.colorfs14,
                      commonstyle.semiBold
                    )}
                  >
                    {" "}
                    To
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.colorfs14,
                      commonstyle.semiBold
                    )}
                  >
                    PC Hotel, Swat, KPK
                  </p>
                </div>
              </div>

              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    Duration
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    5 days
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    Departure
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    23-03-2024
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    11 : 40 PM
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    Return
                  </p>

                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    23-03-2024
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    11 : 40 PM
                  </p>
                </div>
              </div>

              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <p className={classNames(style.paytext)}>
                  250 Seats - Business Class
                </p>
                <p className={classNames(style.paytext)}>Remains - 50</p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyle.colorBlue,
                    commonstyle.fs18,
                    commonstyle.semiBold
                  )}
                >
                  Plans
                </p>

                <div
                  className={classNames(
                    commonstyle.flx,
                    commonstyle.flxBetween
                  )}
                >
                  <div>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs12,
                        commonstyle.semiBold
                      )}
                    >
                      BreakFast
                    </p>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs12,
                        commonstyle.semiBold
                      )}
                    >
                      5
                    </p>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs12,
                        commonstyle.semiBold
                      )}
                    >
                      Lunch
                    </p>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs12,
                        commonstyle.semiBold
                      )}
                    >
                      4
                    </p>
                  </div>
                  <div>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs12,
                        commonstyle.semiBold
                      )}
                    >
                      Dinner
                    </p>

                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs12,
                        commonstyle.semiBold
                      )}
                    >
                      23-03-2024
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyle.colorGray,
                    commonstyle.fs14
                  )}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                  fuga deserunt sequi expedita sapiente. Tempore cupiditate
                  consequuntur earum placeat assumenda? Corporis, odio a.
                  Reprehenderit, ipsa quisquam odio corrupti quod assumenda.
                </p>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyle.colorBlue,
                    commonstyle.fs18,
                    commonstyle.semiBold
                  )}
                >
                  Polices
                </p>
                <p
                  className={classNames(
                    commonstyle.colorGray,
                    commonstyle.fs14
                  )}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                  fuga deserunt sequi expedita sapiente. Tempore cupiditate
                  consequuntur earum placeat assumenda? Corporis, odio a.
                  Reprehenderit, ipsa quisquam odio corrupti quod assumenda.
                </p>
              </div>

              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    Per Head
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    5
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    Per Couple
                  </p>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    4
                  </p>
                </div>
                <div>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    Total price
                  </p>

                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs12,
                      commonstyle.semiBold
                    )}
                  >
                    55555
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              commonstyle.col5,
              commonstyle.colsm12,
              style.dataconatiner
            )}
          >
            <div className={classNames(style.textconatiner)}>
              <div className={style.payment}>Payment</div>
              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <p className={style.paytext}>Total Amount</p>
                <p className={style.paytext}>12054</p>
              </div>
              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <p className={style.paytext}>Paid Amount</p>
                <p className={style.paytext}>12054</p>
              </div>

              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <p className={style.pandingtext}>Pending Amount Date</p>
                <p className={style.pandingtext}>07/13/2024</p>
              </div>

              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <p className={style.pandingtext}>Pending Amount</p>
                <p className={style.pandingtext}>9000</p>
              </div>
            </div>

            <div style={{ margin: "15px  0" }}>
              <ContinueButton
                buttonText="Payment"
                onClick={() => setModalOpen(true)}
                backgroundColor="#ff5733"
              />
            </div>
          </div>
        </div>

        <PaymentMyAppointMent
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      </div>

      <Footerr />
    </div>
  );
};

export default BookingTourDetail;
