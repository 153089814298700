import React, { useState } from "react";
import CustomInput from "../Input";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import { useNavigate } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import LocationInput from "../LocationInput";
import mStyle from "./styleGenric.module.css";
import Datepicker from "../DatePicker";
import CustomTimePicker from "../TimePicker/TimePICKER2";
import { Checkbox } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { useSelector } from "react-redux";
const OtherForm = ({ items, type }: { items?: any; type?: any }) => {
  const navigate = useNavigate();
  const [name, setName] = useState<any>("");
  const [phoneNo, setPhoneNo] = useState<any>("");
  const [age, setAge] = useState<any>("");
  const [nameError, setNameError] = useState<any>("");
  const [phoneNoError, setPhoneNoError] = useState<any>("");
  const [ageError, setAgeError] = useState<any>("");
  const [pickupLocation, setPickupLocation] = useState<any>("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [pickupDate, setPickupDate] = useState<any>("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [dropOffDate, setdropOffDate] = useState<any>("");
  const [picKupTime, setpicKupTime] = useState<any>("");
  const [DropOffTime, setDropOffTime] = useState<any>("");
  const [withDriver, setWithDriver] = useState(false);
  const [pickLocError, setPickLocError] = useState<any>("");
  const [dropLocError, setDropLocError] = useState<any>("");
  const [pickDateError, setPickDateError] = useState<any>("");
  const [dropDateError, setDropDateError] = useState<any>("");
  const [pickTimeError, setPickTimeError] = useState<any>("");
  const [dropTimeError, setDropTimeError] = useState<any>("");
  const { user } = useSelector((state: any) => state.root.common);
  const handleSelect = (newLocation: string) => {
    setPickupLocation(newLocation);
  };
  const handleAptDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setPickupDate(formattedDate);
  };
  const handleCheckboxChange = (event: any) => {
    setWithDriver(event.target.checked);
  };
  const handleDropOff = (newLocation: string) => {
    setDropoffLocation(newLocation);
  };

  const calculateDays = () => {
    const start = moment(pickupDate);
    const end = moment(dropOffDate);
    return end.diff(start, "days") + 1;
  };

  const calculate_amountPerDay = calculateDays() * items?.actualPricePerDay;

  const handleDropOffDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setdropOffDate(formattedDate);
  };
  const validateFields = () => {
    let isValid = true;

    if (!name.trim()) {
      setNameError("Please enter your name");
      isValid = type === "otherPerson" ? false : true;
    } else {
      setNameError("");
    }
    if (!phoneNo.trim()) {
      setPhoneNoError("Please enter your phone number");
      isValid = type === "otherPerson" ? false : true;
    } else {
      setPhoneNoError("");
    }
    if (!age.trim()) {
      setAgeError("Please enter your age");
      isValid = type === "otherPerson" ? false : true;
    } else {
      setAgeError("");
    }
    if (!pickupLocation) {
      setPickLocError("Please enter Pickup Location");
      isValid = false;
    } else {
      setPickLocError("");
    }

    if (!isEnabled && !dropoffLocation) {
      setDropLocError("Please enter Dropoff Location");
      isValid = false;
    } else {
      setDropLocError("");
    }

    if (!pickupDate) {
      setPickDateError("Please select Pickup Date");
      isValid = false;
    } else {
      setPickDateError("");
    }

    if (!dropOffDate) {
      setDropDateError("Please select Dropoff Date");
      isValid = false;
    }
    // else if (moment(dropOffDate).isSameOrBefore(moment(pickupDate))) {
    //     setDropDateError('Dropoff Date must be greater than Pickup Date');
    //     isValid = false;
    // }
    else {
      setDropDateError("");
    }

    if (!picKupTime) {
      setPickTimeError("Please select Pickup Time");
      isValid = false;
    } else {
      setPickTimeError("");
    }

    if (!DropOffTime) {
      setDropTimeError("Please select Dropoff Time");
      isValid = false;
    } else {
      setDropTimeError("");
    }

    return isValid;
  };
  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return "Date of birth not provided";
    const [day, month, year] = dateOfBirthString.split("/").map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }

  const handleNext = () => {
    const newData = {
      name: type == "otherPerson" ? name : user?.name,
      phoneNo: type == "otherPerson" ? phoneNo : user?.phone,
      age:
        type == "otherPerson"
          ? age
          : calculateAge(user?.dateOfBirth)?.toLocaleString(),
      items,
      pickupLocation,
      isEnabled,
      dropoffLocation: isEnabled ? pickupLocation : dropoffLocation,
      pickDate: pickupDate,
      dropDate: dropOffDate,
      withDriver,
      DropOffTime,
      picKupTime,
      type,
    };
    if (!validateFields()) return;
    navigate("/services/rentacar/RentaCarBooking/", {
      state: { newData, calculate_amountPerDay, items },
    });
  };
  return (
    <div className={mStyle.containerFormRentACar}>
      {type === "otherPerson" && (
        <>
          <CustomInput
            placeholder="Name"
            value={name}
            type="text"
            onChange={(text: any) => setName(text.target.value)}
          />
          {nameError ? (
            <div
              className={classNames(commonstyles.error)}
              style={{ fontSize: "12px" }}
            >
              *{nameError}
            </div>
          ) : null}
          <CustomInput
            placeholder="Phone Number"
            value={phoneNo}
            type="text"
            onChange={(text: any) => setPhoneNo(text.target.value)}
          />
          {phoneNoError ? (
            <div
              className={classNames(commonstyles.error)}
              style={{ fontSize: "12px" }}
            >
              *{phoneNoError}
            </div>
          ) : null}
          <CustomInput
            placeholder="Age"
            type="text"
            value={age}
            onChange={(text: any) => setAge(text.target.value)}
          />
          {ageError ? (
            <div
              className={classNames(commonstyles.error)}
              style={{ fontSize: "12px" }}
            >
              *{ageError}
            </div>
          ) : null}
        </>
      )}
      <div style={{}}>
        <LocationInput placeholder="Pickup location" setData={handleSelect} />
      </div>

      {pickLocError ? (
        <div
          className={classNames(commonstyles.error)}
          style={{ fontSize: "12px" }}
        >
          *{pickLocError}
        </div>
      ) : null}
      {!isEnabled && (
        <div
          style={{
            minWidth: "500px",
            position: "relative",
            // zIndex: "10",
          }}
        >
          <LocationInput
            placeholder="Drop-off Location"
            setData={handleDropOff}
          />
        </div>
      )}

      {!isEnabled && dropLocError ? (
        <div
          className={classNames(commonstyles.error)}
          style={{ fontSize: "12px" }}
        >
          *{dropLocError}
        </div>
      ) : null}
      <div style={{ marginBottom: "15px", position: "relative", zIndex: 0 }}>
        <Datepicker placeholder="Pickup date" setData={handleAptDate} />

        {pickDateError ? (
          <div
            className={classNames(commonstyles.error)}
            style={{ fontSize: "12px" }}
          >
            *{pickDateError}
          </div>
        ) : null}
      </div>

      <CustomTimePicker
        placeholder="Pickup Time"
        setData={setpicKupTime}
        value={picKupTime}
      />
      {pickTimeError ? (
        <div
          className={classNames(commonstyles.error)}
          style={{ fontSize: "12px" }}
        >
          *{pickTimeError}
        </div>
      ) : null}

      <div
        style={{
          position: "relative",
          zIndex: 0,
        }}
      >
        <Datepicker placeholder="Dropoff date" setData={handleDropOffDate} />
        {dropDateError ? (
          <div
            className={classNames(commonstyles.error)}
            style={{ fontSize: "12px" }}
          >
            *{dropDateError}
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: "15px" }}>
        <CustomTimePicker
          placeholder="Dropoff Time"
          setData={setDropOffTime}
          value={DropOffTime}
        />
      </div>
      {dropTimeError ? (
        <div
          className={classNames(commonstyles.error)}
          style={{ fontSize: "12px" }}
        >
          *{dropTimeError}
        </div>
      ) : null}
      <div className={mStyle.checkboxcontainer}>
        <Checkbox
          sx={{ width: "24px", height: "24px" }}
          className={mStyle.checkBoxx}
          checked={withDriver}
          onChange={handleCheckboxChange}
        />
        <p> With Driver </p>
      </div>
      <div
        className={mStyle.samelocationmain}
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: "500px",
          marginTop: "15px",
        }}
      >
        <div className={mStyle.locationColor}>
          <p>Return to same location</p>
        </div>
        <div>
          <label className={mStyle.switch}>
            <input
              type="checkbox"
              checked={isEnabled}
              onChange={() => setIsEnabled(!isEnabled)}
            />
            <span className={mStyle.slider}></span>
          </label>
        </div>
      </div>

      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 20px",
              backgroundColor: "#0E54A3",
              color: "white",
              border: "none",
              borderRadius: "24px",
              cursor: "pointer",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              fontSize: "16px",
              gap: "8px",
            }}
            onClick={handleNext}
          >
            Continue
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IoMdArrowForward />
            </span>
          </button>
        </div>
        <div className={mStyle.marginTopRentACar}></div>
      </div>
    </div>
  );
};

export default OtherForm;
