import React from "react";
import styles from "./ModelTermsAndCondition.module.css";
import logo from "../../../assets/images/smallLogo.png";

const UserAgreement: React.FC = () => {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.logoContainer}>
        <img
          src={logo} // Replace with your logo path
          alt="The TMG Private Limited Logo"
          className={styles.logo}
        />
      </div>
      <h1 className={styles.heading}>E-AGREEMENT</h1>
      <h2 className={styles.companyName}>
        THE MEDITOUR GLOBAL PRIVATE LIMITED
      </h2>
      <p className={styles.paragraph}>
        This E-Agreement ("Agreement") is a binding contract between you
        ("Patient" or "User") and The MediTour Global Private Limited
        ("MediTour"), a service company that facilitates the coordination of
        medical, travel, and other ancillary services for patients. By creating
        an account and using MediTour’s platform, you agree to the following
        terms and conditions:
      </p>

      <h3 className={styles.subheading}>1. Scope of Services</h3>
      <p className={styles.paragraph}>
        MediTour provides the following services to facilitate your medical
        journey:
      </p>
      <ol className={styles.list}>
        <li>
          Coordination of medical treatments, consultations, surgeries, and
          related services through third-party hospitals, clinics, laboratory,
          pharmacy, hotel and tourism companies, and other healthcare providers.
        </li>
        <li>Arrangements for all the services related to Medical Tourism.</li>
        <li>
          Assistance with travel arrangements, including flights,
          accommodations, and local transportation in connection with medical
          services.
        </li>
        <li>
          Support in procuring prescribed medications from third-party
          suppliers.
        </li>
        <li>Coordination of other ancillary services related to the above.</li>
      </ol>

      <h3 className={styles.subheading}>2. Acknowledgement and Disclaimer</h3>
      <p className={styles.paragraph}>
        By creating an account, you acknowledge and agree that:
      </p>
      <ul className={styles.list}>
        <li>
          The MediTour Global is a service facilitator only and does not provide
          any medical treatment, advice, or care.
        </li>
        <li>
          All medical services, treatments, consultations, and medications are
          provided by independent third-party Healthcare Providers, and The
          MediTour Global is not responsible for the quality, outcome, or
          success of any treatment or medical service.
        </li>
        <li>
          The MediTour Global does not endorse or guarantee the accuracy,
          expertise, or outcomes of the services provided by any Healthcare
          Provider or any third-party service provider.
        </li>
        <li>
          You understand and assume all risks associated with any medical
          treatment or healthcare service coordinated through The MediTour
          Global.
        </li>
      </ul>

      <h3 className={styles.subheading}>3. Patient Responsibilities</h3>
      <ul className={styles.list}>
        <li>
          You agree to provide accurate and complete personal, medical, and
          financial information necessary for arranging the services.
        </li>
        <li>
          You agree to comply with all instructions, terms, and conditions
          provided by the Healthcare Providers, laboratories, travel agencies,
          and other third-party service providers.
        </li>
        <li>
          You understand and accept that medical treatments carry inherent
          risks, and you consent to receive medical care at your own risk.
        </li>
      </ul>

      <h3 className={styles.subheading}>4. Limitation of Liability</h3>
      <ul className={styles.list}>
        <li>
          The MediTour Global is not liable for any injury, illness,
          complication, or death resulting from any medical treatment,
          procedure, laboratory test, or service provided by third-party
          Healthcare Providers.
        </li>
        <li>
          The MediTour Global shall not be responsible for any delays,
          cancellations, or errors in travel arrangements or other ancillary
          services, nor for any costs, expenses, or damages resulting therefrom.
        </li>
        <li>
          By using The MediTour Global's services, you agree to release,
          indemnify, and hold harmless The MediTour Global, its directors,
          officers, employees, and agents from any and all claims, liabilities,
          losses, damages, or expenses arising out of or in connection with any
          medical services or other services facilitated by The MediTour Global.
        </li>
      </ul>

      <h3 className={styles.subheading}>5. Privacy and Data Protection</h3>
      <ul className={styles.list}>
        <li>
          The MediTour Global will collect, use, and store your personal and
          medical information in accordance with its Privacy Policy, which you
          agree to upon creating an account.
        </li>
        <li>
          You consent to the sharing of your personal and medical information
          with third-party Healthcare Providers and service providers for the
          purpose of coordinating the services requested.
        </li>
      </ul>

      <h3 className={styles.subheading}>
        6. Governing Law and Dispute Resolution
      </h3>
      <ul className={styles.list}>
        <li>
          This Agreement shall be governed by and construed in accordance with
          the laws of the Islamic Republic of Pakistan.
        </li>
        <li>
          Any disputes arising out of or relating to this Agreement shall be
          resolved through amicable negotiation. If such negotiation fails, the
          dispute shall be resolved through binding arbitration in Pakistan.
        </li>
      </ul>

      <h3 className={styles.subheading}>7. Modification of Terms</h3>
      <p className={styles.paragraph}>
        The MediTour Global reserves the right to update or modify this
        Agreement at any time. Any changes will be effective immediately upon
        posting on the The MediTour Global platform. Your continued use of the
        platform following any changes constitutes your acceptance of the
        modified Agreement.
      </p>

      <h3 className={styles.subheading}>8. Acceptance of Agreement</h3>
      <p className={styles.paragraph}>
        By clicking "I Agree" during the account creation process or by
        continuing to use the The MediTour Global platform, you confirm that you
        have read, understood, and agreed to be bound by the terms and
        conditions of this Agreement.
      </p>

      <h3 className={styles.footer}>THE MEDITOUR GLOBAL PRIVATE LIMITED</h3>
      <p className={styles.contactInfo}>
        If you have any questions or concerns about this Agreement, please
        contact us at info@meditour.global.
      </p>
    </div>
  );
};

export default UserAgreement;
