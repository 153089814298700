import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Style from "./Profile.module.css";
import CommonStyles from "shared/utils/common.module.css";
import LinearProgressWithLabel from "shared/components/LinearProgressWithLabel";
import { FaPencilAlt } from "react-icons/fa";
import commonStyles from "shared/utils/common.module.css";
import { Input } from "@mui/material";
import Datepicker from "shared/components/DatePicker";
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Chip,
  Box,
  IconButton,
} from "@mui/material";
import SearchBar from "shared/components/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { Cancel as CancelIcon, ArrowDropDown } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import { SlLocationPin } from "react-icons/sl";
import dayjs from "dayjs";
import { updateDoctorProfile } from "shared/services/TravelAgency";
import StyledImagePicker from "shared/components/Img-picker/StyledImagePicker";
import ProfileImagePicker from "shared/components/Img-picker/ProfileImagePicker";
import { set_User, setUser } from "shared/redux";

const DoctorProfile = () => {
  const { user } = useSelector((state: any) => state.root.common);
  console.log("🚀 ~ DoctorProfile ~ user:", user);

  const dispatch = useDispatch();

  const [editableSections, setEditableSections] = useState({
    ProfileInfo: false,
    Qualifications: false,
    Specialties: false,
    Certifications: false,
    BankDetails: false,
    SocialMedia: false,
    Security: false,
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = useRef(null);

  const [formData, setFormData] = useState({
    name: user?.name || "",
    cnicOrPassportNo: user?.cnicOrPassportNo || "",
    qualifications: user?.qualifications || "",
    location: {
      lng: user?.location?.lng || "",
      lat: user?.location?.lat || "",
      address: user?.location?.address || "",
      city: user?.location?.city || "",
    },
    speciality: user?.speciality || [],
    pmdcNumber: user?.pmdcNumber || "",
    pmdcImage: user?.pmdcImage || "",
    pmdcExpiry: user?.pmdcExpiry ? dayjs(user.pmdcExpiry) : null,
    cnicOrPassportExpiry: user?.cnicOrPassportExpiry
      ? dayjs(user.cnicOrPassportExpiry)
      : null,
    incomeTaxNo: user?.incomeTaxNo || "",
    profilePercentage: user?.profilePercentage || "",
    salesTaxNo: user?.salesTaxNo || "",
    taxFileImage: user?.taxFileImage || "",
    cnicImage: user?.cnicImage || "",
    doctorImage: user?.doctorImage || "",
    accountHolderName: user?.accountHolderName || "",
    accountNumber: user?.accountNumber || "",
    website: user?.website || "",
    bankName: user?.bankName || "",
    instagram: user?.instagram || "",
    twitter: user?.twitter || "",
    facebook: user?.facebook || "",
    password: user?.password || "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  type Section =
    | "ProfileInfo"
    | "Qualifications"
    | "Specialties"
    | "Certifications"
    | "BankDetails"
    | "SocialMedia"
    | "Security";

  const handleEditClick = (section: Section) => {
    setEditableSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name in formData.location) {
      setFormData((prevState) => ({
        ...prevState,
        location: {
          ...prevState.location,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const validatePassword = (password: string) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };
  const [passwordErrors, setPasswordErrors] = useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const filterPayload = (data: any) => {
    const allowedFields = [
      "name",
      "cnicOrPassportNo",
      "location",
      "qualifications",
      "speciality",
      "pmdcNumber",
      "pmdcImage",
      "pmdcExpiry",
      "incomeTaxNo",
      "salesTaxNo",
      "taxFileImage",
      "accountHolderName",
      "accountNumber",
      "website",
      "bankName",
      "instagram",
      "twitter",
      "facebook",
      "password",
      "cnicImage",
      "cnicOrPassportExpiry",
      "doctorImage",
    ];

    const filteredData: any = {};
    Object.keys(data).forEach((key) => {
      if (allowedFields.includes(key)) {
        filteredData[key] = data[key];
      }
    });

    return filteredData;
  };
  const handleDispatchData = (data: any) => {
    dispatch(set_User(data));
  };

  const handleSaveClick = async (section: Section) => {
    try {
      if (section === "Security") {
        if (formData.currentPassword !== user.password) {
          setPasswordErrors((prevState) => ({
            ...prevState,
            currentPasswordError: "Current password is incorrect.",
          }));
          return;
        }

        if (!validatePassword(formData.newPassword)) {
          setPasswordErrors((prevState) => ({
            ...prevState,
            newPasswordError:
              "New password must be at least 8 characters long, include 1 uppercase letter, 1 number, and 1 special character.",
          }));
          return;
        }

        if (formData.newPassword !== formData.confirmPassword) {
          setPasswordErrors((prevState) => ({
            ...prevState,
            confirmPasswordError: "Passwords do not match.",
          }));
          return;
        }
      }

      setLoading(true);
      const formattedData = {
        ...formData,
        pmdcExpiry: formData.pmdcExpiry
          ? formData.pmdcExpiry.format("YYYY-MM-DD")
          : null,
        cnicOrPassportExpiry: formData.cnicOrPassportExpiry
          ? formData.cnicOrPassportExpiry.format("YYYY-MM-DD")
          : null,
      };

      const filteredData = filterPayload(formattedData);
      const response = await updateDoctorProfile(filteredData);
      console.log("🚀 ~ handleSaveClick ~ response:", response);

      if (response?.data?.message === "Doctor updated successfully") {
        dispatch(setUser(response?.data?.doctor));
        handleDispatchData(response?.data?.doctor);
        alert("Profile updated successfully!");
        setEditableSections((prevState) => ({
          ...prevState,
          [section]: false,
        }));
      }
    } catch (error: any) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePmdcExpiry = (date: dayjs.Dayjs | null) => {
    setFormData((prevState) => ({
      ...prevState,
      pmdcExpiry: date,
    }));
  };

  const handleCnicOrPassportExpiry = (date: dayjs.Dayjs | null) => {
    setFormData((prevState) => ({
      ...prevState,
      cnicOrPassportExpiry: date,
    }));
  };

  const handleCancelClick = (section: Section) => {
    if (section === "Security") {
      setFormData((prevState) => ({
        ...prevState,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));
      setPasswordErrors({
        currentPasswordError: "",
        newPasswordError: "",
        confirmPasswordError: "",
      });
    } else {
      setFormData({
        name: user?.name || "",
        cnicOrPassportNo: user?.cnicOrPassportNo || "",
        qualifications: user?.qualifications || "",
        location: {
          lng: user?.location?.lng || "",
          lat: user?.location?.lat || "",
          address: user?.location?.address || "",
          city: user?.location?.city || "",
        },
        speciality: user?.speciality || [],
        pmdcNumber: user?.pmdcNumber || "",
        pmdcImage: user?.pmdcImage || "",
        pmdcExpiry: user?.pmdcExpiry ? dayjs(user.pmdcExpiry) : null,
        cnicOrPassportExpiry: user?.cnicOrPassportExpiry
          ? dayjs(user.cnicOrPassportExpiry)
          : null,
        incomeTaxNo: user?.incomeTaxNo || "",
        profilePercentage: user?.profilePercentage || "",
        salesTaxNo: user?.salesTaxNo || "",
        taxFileImage: user?.taxFileImage || "",
        cnicImage: user?.cnicImage || "",
        doctorImage: user?.doctorImage || "",
        accountHolderName: user?.accountHolderName || "",
        accountNumber: user?.accountNumber || "",
        website: user?.website || "",
        bankName: user?.bankName || "",
        instagram: user?.instagram || "",
        twitter: user?.twitter || "",
        facebook: user?.facebook || "",
        password: user?.password || "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }

    setEditableSections((prevState) => ({
      ...prevState,
      [section]: false,
    }));
  };

  const handleSpecialityChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setFormData((prevState) => ({
      ...prevState,
      speciality: typeof value === "string" ? value.split(",") : value,
    }));
  };
  const handleDeleteSpecialty = (
    event: React.MouseEvent,
    specialtyToDelete: string
  ) => {
    event.stopPropagation();

    setFormData((prevState) => ({
      ...prevState,
      speciality: prevState.speciality.filter(
        (specialty: string) => specialty !== specialtyToDelete
      ),
    }));
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const specialtiesOptions = [
    "Cardiology",
    "Dermatology",
    "Neurology",
    "Orthopedics",
    "Pediatrics",
    "Radiology",
    // Add more specialties as needed
  ];
  useEffect(() => {
    console.log("User data updated:", user);
    setFormData({
      name: user?.name || "",
      cnicOrPassportNo: user?.cnicOrPassportNo || "",
      qualifications: user?.qualifications || "",
      location: {
        lng: user?.location?.lng || "",
        lat: user?.location?.lat || "",
        address: user?.location?.address || "",
        city: user?.location?.city || "",
      },
      speciality: user?.speciality || [],
      pmdcNumber: user?.pmdcNumber || "",
      pmdcImage: user?.pmdcImage || "",
      pmdcExpiry: user?.pmdcExpiry ? dayjs(user.pmdcExpiry) : null,
      cnicOrPassportExpiry: user?.cnicOrPassportExpiry
        ? dayjs(user.cnicOrPassportExpiry)
        : null,
      incomeTaxNo: user?.incomeTaxNo || "",
      profilePercentage: user?.profilePercentage || "",
      salesTaxNo: user?.salesTaxNo || "",
      taxFileImage: user?.taxFileImage || "",
      cnicImage: user?.cnicImage || "",
      doctorImage: user?.doctorImage || "",
      accountHolderName: user?.accountHolderName || "",
      accountNumber: user?.accountNumber || "",
      website: user?.website || "",
      bankName: user?.bankName || "",
      instagram: user?.instagram || "",
      twitter: user?.twitter || "",
      facebook: user?.facebook || "",
      password: user?.password || "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [user]); // This will ensure that formData is updated whenever user changes

  return (
    <div className={classNames(CommonStyles.col12, Style.parent)}>
      <SearchBar />
      <div className={classNames(CommonStyles.col11, Style.main)}>
        <div className={classNames(CommonStyles.flx, CommonStyles.flxBetween)}>
          <div
            className={classNames(
              CommonStyles.colorBlue,
              CommonStyles.bold,
              CommonStyles.fs24
            )}
          >
            <p>Profile</p>
          </div>
          <div>
            <div style={{ padding: "20px" }}>
              <div>
                <LinearProgressWithLabel
                  value={formData.profilePercentage || 0}
                />
              </div>
              <p
                style={{ color: "#FF7631", marginTop: 4 }}
                className={classNames(CommonStyles.regular, CommonStyles.fs12)}
              >
                Complete Your Profile for better business
              </p>
            </div>
          </div>
        </div>

        {/* Profile Info Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col6}>
            <p className={classNames(Style.title)}>Profile Info</p>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <Input
                  placeholder="Name"
                  value={formData.name || ""}
                  name="name"
                  onChange={handleInputChange}
                  disabled={!editableSections.ProfileInfo}
                />
              </div>
              <div>
                <Input
                  placeholder="Contact"
                  value={user.phoneNumber || ""}
                  name="phoneNumber"
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div className={Style.inputContainer}>
                <Input
                  placeholder="Email"
                  value={user.email || ""}
                  name="email"
                  onChange={handleInputChange}
                  disabled
                  fullWidth
                />
              </div>
              <div>
                <Input
                  placeholder="CNIC"
                  value={formData.cnicOrPassportNo}
                  name="cnicOrPassportNo"
                  onChange={handleInputChange}
                  disabled={!editableSections.ProfileInfo}
                />
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <StyledImagePicker
                  placeholder="CNIC Image"
                  setData={(url: string) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      pmdcImage: url,
                    }));
                  }}
                  value={formData.cnicImage || ""}
                  disabled={!editableSections.ProfileInfo}
                />
              </div>
              <div>
                <div
                  className={classNames(commonStyles.mr32, commonStyles.mt16)}
                >
                  <Datepicker
                    placeholder="CNIC EXPIRY"
                    setData={handleCnicOrPassportExpiry}
                    value={formData.cnicOrPassportExpiry}
                    disabled={!editableSections.ProfileInfo}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(Style.addressField, CommonStyles.col12)}>
              <div
                className={classNames(CommonStyles.flx, Style.addressWrapper)}
              >
                <SlLocationPin className={Style.locationIcon} />
                <Input
                  placeholder="Address"
                  value={`${formData.location.address || ""}, ${
                    formData.location.city || ""
                  } `}
                  name="address"
                  className={classNames(CommonStyles.col12)}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>
            {editableSections.ProfileInfo && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("ProfileInfo")}
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("ProfileInfo")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("ProfileInfo")}
              className={CommonStyles.cursor}
            />
            <div style={{ marginTop: "40px", textAlign: "center" }}>
              <ProfileImagePicker
                value={formData.doctorImage || ""}
                setData={(url: string) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    doctorImage: url,
                  }))
                }
                disabled={!editableSections.ProfileInfo}
              />
              <p className={Style.editText}>
                {editableSections.ProfileInfo
                  ? "Click to change image"
                  : "Edit image"}
              </p>
            </div>
          </div>
        </div>
        {/* Qualifications Section */}
        <div
          className={classNames(
            CommonStyles.mt16,
            CommonStyles.flx,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col6}>
            <p className={classNames(Style.title)}>Qualifications</p>
            <div className={Style.qualificationContainer}>
              <Input
                placeholder="Qualifications"
                value={formData.qualifications || ""}
                name="qualifications"
                onChange={handleInputChange}
                disabled={!editableSections.Qualifications}
                fullWidth
              />
            </div>
            {editableSections.Qualifications && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("Qualifications")}
                >
                  Save
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("Qualifications")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("Qualifications")}
              className={CommonStyles.cursor}
            />
          </div>
        </div>

        {/* Specialties Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.mt16,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col6}>
            <p className={classNames(Style.title)}>Specialties</p>
            <FormControl fullWidth sx={{ position: "relative" }}>
              <Select
                multiple
                value={formData.speciality}
                onChange={handleSpecialityChange}
                open={isDropdownOpen}
                onClose={handleDropdownClose}
                ref={selectRef}
                disabled={!editableSections.Specialties}
                renderValue={(selected: string[]) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value: string) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={
                          editableSections.Specialties
                            ? (event) => handleDeleteSpecialty(event, value)
                            : undefined
                        }
                        deleteIcon={
                          editableSections.Specialties ? (
                            <CancelIcon />
                          ) : undefined
                        }
                      />
                    ))}
                  </Box>
                )}
                IconComponent={() => null}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                {specialtiesOptions.map((speciality) => (
                  <MenuItem key={speciality} value={speciality}>
                    {speciality}
                  </MenuItem>
                ))}
              </Select>

              {editableSections.Specialties && (
                <IconButton
                  onClick={handleDropdownOpen}
                  className={Style.dropdownIcon}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "#f0f0f0",
                    padding: "8px",
                    borderRadius: "50%",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",

                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    },
                    "&:active": {
                      backgroundColor: "#d0d0d0",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <ArrowDropDown
                    sx={{
                      fontSize: "1.5rem",
                      color: "#555",
                      transition: "color 0.3s ease",

                      "&:hover": {
                        color: "#333",
                      },
                    }}
                  />
                </IconButton>
              )}
            </FormControl>

            {editableSections.Specialties && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("Specialties")}
                >
                  Save
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("Specialties")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("Specialties")}
              className={CommonStyles.cursor}
            />
          </div>
        </div>

        {/* Certifications Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.mt16,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col6}>
            <p className={classNames(Style.title)}>Certifications</p>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <Input
                  placeholder="PMDC Number"
                  value={formData.pmdcNumber || ""}
                  name="pmdcNumber"
                  onChange={handleInputChange}
                  disabled={!editableSections.Certifications}
                />
              </div>
              <div>
                <StyledImagePicker
                  placeholder="PMDC Image"
                  setData={(url: string) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      pmdcImage: url,
                    }));
                  }}
                  value={formData.pmdcImage || ""}
                  disabled={!editableSections.Certifications}
                />
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div className={classNames(commonStyles.mr32, commonStyles.mt16)}>
                <Datepicker
                  placeholder="PMDC Expiry"
                  setData={handlePmdcExpiry}
                  value={
                    formData.pmdcExpiry ? dayjs(formData.pmdcExpiry) : null
                  } // Display from Redux user data
                  disabled={!editableSections.Certifications}
                />
              </div>
            </div>
            {editableSections.Certifications && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("Certifications")}
                >
                  Save
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("Certifications")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("Certifications")}
              className={CommonStyles.cursor}
            />
          </div>
        </div>

        {/* Bank Details Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.mt16,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col6}>
            <p className={classNames(Style.title)}>Bank Details</p>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <Input
                  placeholder="Income Tax Number"
                  value={formData.incomeTaxNo || ""}
                  name="incomeTaxNo"
                  onChange={handleInputChange}
                  disabled={!editableSections.BankDetails}
                />
              </div>
              <div>
                <Input
                  placeholder="Sales Tax No."
                  value={formData.salesTaxNo || ""}
                  name="salesTaxNo"
                  onChange={handleInputChange}
                  disabled={!editableSections.BankDetails}
                />
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <StyledImagePicker
                  placeholder="Attach Tax File"
                  setData={(url: string) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      taxFileImage: url,
                    }));
                  }}
                  value={formData.taxFileImage || ""}
                  disabled={!editableSections.BankDetails}
                />
              </div>
              <div>
                <Input
                  placeholder="Account Holder Name"
                  value={formData.accountHolderName || ""}
                  name="accountHolderName"
                  onChange={handleInputChange}
                  disabled={!editableSections.BankDetails}
                />
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <Input
                  placeholder="Bank Name"
                  value={formData.bankName || ""}
                  name="accountHolderName"
                  onChange={handleInputChange}
                  disabled={!editableSections.BankDetails}
                />
              </div>
              <div>
                <Input
                  placeholder="Account Holder Number"
                  value={formData.accountNumber}
                  name="accountNumber"
                  onChange={handleInputChange}
                  disabled={!editableSections.BankDetails}
                />
              </div>
            </div>
            {editableSections.BankDetails && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("BankDetails")}
                >
                  Save
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("BankDetails")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("BankDetails")}
              className={CommonStyles.cursor}
            />
          </div>
        </div>
        {/* Social Media Links Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.mt16,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col6}>
            <p className={classNames(Style.title)}>Social Media Links</p>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <Input
                  placeholder="Web (optional)"
                  value={formData.website || ""}
                  name="website"
                  onChange={handleInputChange}
                  disabled={!editableSections.SocialMedia}
                />
              </div>
              <div>
                <Input
                  placeholder="Instagram"
                  value={formData.instagram || ""}
                  name="instagram"
                  onChange={handleInputChange}
                  disabled={!editableSections.SocialMedia}
                />
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div>
                <Input
                  placeholder="Twitter"
                  value={formData.twitter}
                  name="twitter"
                  onChange={handleInputChange}
                  disabled={!editableSections.SocialMedia}
                />
              </div>
              <div>
                <Input
                  placeholder="Facebook"
                  value={formData.facebook || ""}
                  name="facebook"
                  onChange={handleInputChange}
                  disabled={!editableSections.SocialMedia}
                />
              </div>
            </div>
            {editableSections.SocialMedia && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("SocialMedia")}
                >
                  Save
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("SocialMedia")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("SocialMedia")}
              className={CommonStyles.cursor}
            />
          </div>
        </div>

        {/* Security Section */}
        <div
          className={classNames(
            CommonStyles.flx,
            CommonStyles.mt16,
            CommonStyles.flxBetween,
            CommonStyles.col12
          )}
        >
          <div className={CommonStyles.col12}>
            <p className={classNames(Style.title)}>Security</p>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12
              )}
            >
              <div className={CommonStyles.col4}>
                <FormControl
                  fullWidth
                  error={Boolean(passwordErrors.currentPasswordError)}
                >
                  <Input
                    type="password"
                    placeholder="Current Password"
                    value={formData.currentPassword}
                    name="currentPassword"
                    onChange={handleInputChange}
                    disabled={!editableSections.Security}
                    fullWidth
                  />
                  {passwordErrors.currentPasswordError && (
                    <FormHelperText>
                      {passwordErrors.currentPasswordError}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12,
                CommonStyles.mt16
              )}
            >
              <div className={CommonStyles.col4}>
                <FormControl
                  fullWidth
                  error={Boolean(passwordErrors.newPasswordError)}
                >
                  <Input
                    type="password"
                    placeholder="New Password"
                    value={formData.newPassword}
                    name="newPassword"
                    onChange={handleInputChange}
                    disabled={!editableSections.Security}
                    fullWidth
                  />
                  {passwordErrors.newPasswordError && (
                    <FormHelperText>
                      {passwordErrors.newPasswordError}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div
              className={classNames(
                CommonStyles.flx,
                CommonStyles.flxBetween,
                CommonStyles.col12,
                CommonStyles.mt16
              )}
            >
              <div className={CommonStyles.col4}>
                <FormControl
                  fullWidth
                  error={Boolean(passwordErrors.confirmPasswordError)}
                >
                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    name="confirmPassword"
                    onChange={handleInputChange}
                    disabled={!editableSections.Security}
                    fullWidth
                  />
                  {passwordErrors.confirmPasswordError && (
                    <FormHelperText>
                      {passwordErrors.confirmPasswordError}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            {editableSections.Security && (
              <div
                className={classNames(
                  CommonStyles.centerButtons,
                  CommonStyles.mt16
                )}
              >
                <button
                  className={classNames(Style.button, Style.SaveButton)}
                  onClick={() => handleSaveClick("Security")}
                >
                  Save
                </button>
                <button
                  className={classNames(Style.button, Style.cancelButton)}
                  onClick={() => handleCancelClick("Security")}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          <div className={classNames(Style.profileClick, CommonStyles.col3)}>
            <FaPencilAlt
              onClick={() => handleEditClick("Security")}
              className={CommonStyles.cursor}
              style={{ marginRight: "51px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfile;
