import React from "react";
import style from "./PhyAvailability.module.css";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import SearchBar from "shared/components/Searchbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaClinicMedical } from "react-icons/fa";
import { FaHospitalSymbol } from "react-icons/fa";
import { IoVideocam } from "react-icons/io5";
import { FaHouseChimney } from "react-icons/fa6";

function DoctorAvailability() {
  const navigate = useNavigate();
  const { systemType } = useSelector((state: any) => state.root.common);

  interface CardData {
    type: string;
    icon: JSX.Element;
    heading: string;
    subHeading: string;
  }
  const cardData: CardData[] = [
    {
      type: "Clinic",
      icon: <FaClinicMedical className={style.cardIcon} />,
      heading: "Clinic",
      subHeading: "Add your clinic availability",
    },
    {
      type: "inHouse",
      icon: <FaHouseChimney className={style.cardIcon} />,
      heading: "In-House",
      subHeading: "Add your In-House availability",
    },
    {
      type: "hospital",
      icon: <FaHospitalSymbol className={style.cardIcon} />,
      heading: "Hospital",
      subHeading: "Add your Hospital availability",
    },
    {
      type: "videoConsultancy",
      icon: <IoVideocam className={style.cardIcon} />,
      heading: "Video-Consultancy",
      subHeading: "Add your availability for Video calls",
    },
  ];
  const handleGoToDetail = (type: any) => {
    let availabilityType =
      type === "Clinic"
        ? "clinic"
        : type === "inHouse"
        ? "in-house"
        : type === "hospital"
        ? "hospital"
        : type === "videoConsultancy"
        ? "video"
        : "";

    let availabilityTitle =
      type === "Clinic"
        ? "Clinic"
        : type === "inHouse"
        ? "In-house"
        : type === "hospital"
        ? "Hospital"
        : type === "videoConsultancy"
        ? "Video Consultancy"
        : "";
    navigate(`/${systemType}/availability/${type}`, {
      state: { availabilityType, availabilityTitle },
    });
  };

  return (
    <div className={classNames(commonstyles.col12, style.doctorss)}>
      <SearchBar />
      <div className={commonstyles.mr87}>
        <div className={style.outerContainer}>
          <div className={commonstyles.flx}>
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Availability category
            </p>
          </div>
          <div className={style.cardWrapper}>
            {cardData.map((card) => (
              <div
                key={card.type}
                className={style.cardContainer}
                onClick={() => handleGoToDetail(card.type)}
              >
                <div className={style.cardIconContainer}>{card.icon}</div>
                <div className={style.textContainer}>
                  <p
                    className={classNames(
                      commonstyles.fs28,
                      commonstyles.semiBold,
                      style.cardHeading
                    )}
                  >
                    {card.heading}
                  </p>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.medium,
                      style.cardSubHeading
                    )}
                  >
                    {card.subHeading}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorAvailability;
