import React, { useState } from "react";
import { FormikProps } from "formik";
import styles from "./BasicInfo.module.css";
import Styles from "./styles.module.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../../../assets/images/Calender.png";
import LocationInput from "shared/components/LocationInput";
import toast from "react-hot-toast";
import Modal from "shared/components/ModelTermsAndCondition/Model";
import TermsAndConditions from "shared/components/ModelTermsAndCondition";
import UserAgreement from "shared/components/ModelTermsAndCondition/UserAgreement";
import { PhoneNumberInput } from "shared/components";
import PhoneNumberInputNew from "./PhoneNumberInputNew";
import CustomInput from "shared/components/A_New_Components/CustomInput";

interface Props {
  formik?: any;
  onAgreementChange: (agreed: boolean) => void;
}

const BasicInfo = (props: Props) => {
  const { formik, onAgreementChange } = props;
  const [isAgreed, setAgree] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleLocationChange = async (newLocation: any) => {
    console.log(".....formik.....", formik);

    const labelParts: any = newLocation?.label.split(", ");
    const country = labelParts[labelParts.length - 1];
    formik?.setFieldValue("address", newLocation?.label);
    formik?.setFieldValue("city", newLocation?.city);
    formik?.setFieldValue("country", country);
    const address = newLocation?.label;
    const apiKey = "AIzaSyBrNjsUsrJ0Mmjhe-WUKDKVaIsMkZ8iQ4A"; // Replace with your actual API key
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        formik?.setFieldValue("lat", location.lat);
        formik?.setFieldValue("long", location.lng);
      } else {
        console.error("Geocoding error: ", data.status);
      }
    } catch (error) {
      console.error("Error fetching geocoding data: ", error);
    }
  };
  const handleAgreementChange = () => {
    setAgree((prev) => {
      const newAgreeState = !prev;
      onAgreementChange(newAgreeState);
      return newAgreeState;
    });
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModalTerms = () => {
    setModalOpen(false);
  };
  console.log(formik.values.dob, '...........formik.values.dob')
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="fullName"
            type="text"
            placeholder="Full Name*"
            value={formik.values.fullName}
            onChange={formik.handleChange("fullName")}
            error={
              formik.touched.fullName && formik.errors.fullName
                ? formik.errors.fullName
                : ""
            }
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="fatherName"
            type="text"
            placeholder="Father Name*"
            value={formik.values.fatherName}
            onChange={formik.handleChange("fatherName")}
            error={
              formik.touched.fatherName && formik.errors.fatherName
                ? formik.errors.fatherName
                : ""
            }
          />
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <div className={Styles.selectContainer}>
            <CustomSelector
              options={["Male", "Female", "Confidential"]}
              placeholder="Select Gender*"
              onChange={(v: any) => {
                formik.setFieldValue("gender", v);
              }}
              value={formik.values.gender}
            />

            {formik.touched.gender && formik.errors.gender ? (
              <div className={Styles.error}>{formik.errors.gender}</div>
            ) : null}
          </div>
        </div>
        <div className={Styles.inputGroupBasic}>
          <div className={Styles.datePickerContainer}>
            <DatePicker
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              showMonthDropdown
              scrollableMonthYearDropdown
              id="dob"
              selected={formik.values.dob}
              onChange={(date) => formik.setFieldValue("dob", date)}
              onBlur={formik.handleBlur}
              className={Styles.dateInput}
              placeholderText="Date of Birth*"
            />
            <img
              src={calendarIcon}
              alt="Calendar Icon"
              className={Styles.datePickerIcon}
            />
          </div>
          {formik.touched.dob && formik.errors.dob ? (
            <div className={Styles.error}>{formik.errors.dob}</div>
          ) : null}
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="bloodGroup"
            type="text"
            placeholder="Blood Group e.g O+"
            value={formik.values.bloodGroup}
            onChange={formik.handleChange("bloodGroup")}
            error={
              formik.touched.bloodGroup && formik.errors.bloodGroup
                ? formik.errors.bloodGroup
                : ""
            }
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="passport"
            type="text"
            placeholder="NIC / Passport*"
            value={formik.values.passport}
            onChange={formik.handleChange("passport")}
            error={
              formik.touched.passport && formik.errors.passport
                ? formik.errors.passport
                : ""
            }
          />
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="childrenNumber"
            type="text"
            placeholder="No of Children (If Any)"
            value={formik.values.childrenNumber}
            onChange={formik.handleChange("childrenNumber")}
            error={
              formik.touched.childrenNumber && formik.errors.childrenNumber
                ? formik.errors.childrenNumber
                : ""
            }
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <PhoneNumberInputNew
            value={formik.values.phoneNumber}
            setValue={(newValue: string) => {
              formik.setFieldValue("phoneNumber", newValue);
            }}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className={Styles.error}>{formik.errors.phoneNumber}</div>
          ) : null}
        </div>
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="email"
            type="text"
            placeholder="Email*"
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="qualification"
            type="text"
            placeholder="Qualification*"
            value={formik.values.qualification}
            onChange={formik.handleChange("qualification")}
            error={
              formik.touched.qualification && formik.errors.qualification
                ? formik.errors.qualification
                : ""
            }
          />
        </div>
      </div>
      <div className={Styles.rowBasic} style={{ width: "100%" }}>
        <LocationInput
          placeholder="Residential Address*"
          type={"box"}
          setData={handleLocationChange}
        />
        {formik.touched.address && formik.errors.address ? (
          <div className={Styles.error}>{formik.errors.address}</div>
        ) : null}
      </div>

      <div className={Styles.rowBasic}>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="city"
            type="text"
            placeholder="City*"
            value={formik.values.city}
            onChange={formik.handleChange("city")}
            error={
              formik.touched.city && formik.errors.city
                ? formik.errors.city
                : ""
            }
          />
        </div>
        <div className={Styles.inputGroupBasic}>
          <CustomInput
            id="country"
            type="text"
            placeholder="Country*"
            value={formik.values.country}
            onChange={formik.handleChange("country")}
            error={
              formik.touched.country && formik.errors.country
                ? formik.errors.country
                : ""
            }
          />
        </div>
      </div>

      <div className={styles.agreementContainer}>
        <label className={styles.radioContainer}>
          <input
            type="checkbox"
            checked={isAgreed}
            onChange={handleAgreementChange}
            className={styles.radioInput}
          />
          <span className={styles.radioMark}></span>{" "}
          <span className={styles.agreementText}>
            This I agree to MediTour{" "}
            <span
              className={styles.linkText}
              onClick={handleOpenModal}
              style={{ cursor: "pointer" }}
            >
              Terms & Conditions
            </span>{" "}
            and{" "}
            <a
              className={styles.linkText}
              href="/privactpolicys"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>
        </label>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.continueButton} type="submit">
          Continue
        </button>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModalTerms}>
        <UserAgreement />
      </Modal>
    </form>
  );
};

export default BasicInfo;

const CustomSelector = ({ options, placeholder, value, onChange }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSelectedOption(value);

    setFilteredOptions(
      options.filter((option: any) =>
        option.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className={Styles.custom_selector}>
      <div className={Styles.input_container} onClick={toggleDropdown}>
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          className={Styles.select_input}
          onChange={handleInputChange}
          onClick={toggleDropdown}
        />
        <span className={`${Styles.dropdown_icon} ${isOpen ? "open" : ""}`}>
          ▼
        </span>
      </div>
      {isOpen && (
        <div className={Styles.options}>
          {filteredOptions.map((option: any, index: number) => (
            <div
              key={index}
              className={Styles.option}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
