import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import hstyle from "./Hotel.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import LocationInput from "shared/components/LocationInput";
import Datepicker from "shared/components/DatePicker";
import { CustomInput, CustomModal } from "shared/components";
import { Radio } from "@mui/material";
import Hotelfirstpage from "./Hotelfirstpage";
import HotelProperty from "./HotelProperty";
import MainHeader from "shared/components/MainScreen/Index";
import { Navigate, useNavigate } from "react-router-dom";
import { setHotelDetail } from "shared/redux";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { HOTEL } from "shared/utils/mainHeaderQuery";

const HotelServic = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [fromDate, setFromDate] = useState("");
  const [roomsQuantity, setRoomsQuantity] = useState(0);
  const [adultsQuantity, setAdultsQuantity] = useState(0);
  const [selectCity, setSelectCity] = useState("");
  const [todate, setToData] = useState("");
  const dispatch = useDispatch();
  const [childrenQuantity, setChildrenQuantity] = useState(0);

  const [error, setError] = useState<any>({
    city: "",
    fromDate: "",
    toDate: "",
    rooms: "",
    adults: "",
    guests: "",
    selectedOption: "",
  });

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
  };
  const validateFields = () => {
    const newError = {
      city: selectCity ? "" : "Select a city",
      fromDate: fromDate ? "" : "Choose check-in date",
      toDate: todate ? "" : "Choose check-out date",
      rooms: roomsQuantity > 0 ? "" : "Select at least 1 room and 1 adult",
      // guests: adultsQuantity > 0 ? "" : "Please select at least 1 adult",
      selectedOption: selectedOption ? "" : "Select a lodging type",
    };
    setError(newError);
    return Object.values(newError).every((error: any) => !error);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleShowMoreClick = () => {
    if (validateFields()) {
      let noOfGuest = adultsQuantity + childrenQuantity;
      dispatch(
        setHotelDetail({
          noOfGuest,
          selectedOption,
          selectCity,
          roomsQuantity,
          adultsQuantity,
          childrenQuantity,
          fromDate,
          todate,
        })
      );
      navigate("/services/hotel/HotelProperty");
    }
  };

  const handleInputClick = () => {
    setShowDropdown(!showDropdown);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAptDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setFromDate(formattedDate);
  };
  const handleTo = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setToData(formattedDate);
  };
  const handleDecrement = (type: string) => {
    if (type === "rooms" && roomsQuantity > 1) {
      setRoomsQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        if (newQuantity >= 1) {
          setError((prevError: any) => ({ ...prevError, rooms: "" }));
        }
        return newQuantity;
      });
    } else if (type === "adults" && adultsQuantity > 1) {
      setAdultsQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        if (newQuantity >= 1) {
          setError((prevError: any) => ({ ...prevError, adults: "" }));
        }
        return newQuantity;
      });
    } else if (type === "children" && childrenQuantity > 0) {
      setChildrenQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        return newQuantity;
      });
    }
  };

  const handleIncrement = (type: string) => {
    if (type === "rooms") {
      setRoomsQuantity(roomsQuantity + 1);
    } else if (type === "adults") {
      setAdultsQuantity(adultsQuantity + 1);
    } else if (type === "children") {
      setChildrenQuantity(childrenQuantity + 1);
    }
  };
  interface AppliedValues {
    rooms: number;
    adults: number;
    children: number;
  }
  const handleApply = () => {
    // setAppliedValues({
    //   rooms: roomsQuantity,
    //   adults: adultsQuantity,
    //   children: childrenQuantity,
    // });
    setShowDropdown(false);
  };
  const handleCitySelction = (value: any) => {
    setSelectCity(value?.city);
  };
  return (
    <div>
      <NavBreadCrumbs {...HOTEL} />

      <div
        className={classNames(commonstyles.container, commonstyles.mb40)}
        style={{ marginBottom: "48px" }}
      >
        {/* {!showHotelProperty && ( */}
        <div className={classNames(hstyle.firstContainer)}>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxBetween,
              commonstyles.flxWrap
            )}
            style={{ marginTop: "20px", width: "100%" }}
          >
            <div
              className={classNames(
                commonstyles.colsm12,
                commonstyles.col3,
                commonstyles.colmd12
              )}
              style={{
                marginTop: "3px",
              }}
            >
              <LocationInput placeholder=" City" setData={handleCitySelction} />
              {error.city && (
                <span className={hstyle.errorText}>{error.city}</span>
              )}
            </div>

            <div
              className={classNames(commonstyles.colsm12, commonstyles.colmd12)}
            >
              <Datepicker
                placeholder="Check-in date "
                setData={handleAptDate}
              />
              {error.fromDate && (
                <span className={hstyle.errorText}>{error.fromDate}</span>
              )}
            </div>

            <div
              className={classNames(commonstyles.colsm12, commonstyles.colmd12)}
            >
              <Datepicker placeholder="Check-out date " setData={handleTo} />
              {error.toDate && (
                <span className={hstyle.errorText}>{error.toDate}</span>
              )}
            </div>
            <div
              className={classNames(
                commonstyles.colsm12,
                commonstyles.col3,
                commonstyles.colmd12
              )}
              ref={dropdownRef}
            >
              <CustomInput
                placeholder={"Guest's Room"}
                value={`adult ${adultsQuantity} room ${roomsQuantity} children ${childrenQuantity} `}
                onClick={handleInputClick}
              />
              {/* {appliedValues && (
                <span className={hstyle.appliedvaluestext}>
                  {appliedValues.rooms} Room(s), {appliedValues.adults}{" "}
                  Adult(s),
                  {appliedValues.children} Children
                </span>
              )} */}
              {error.rooms && <p className={hstyle.errorText}>{error.rooms}</p>}
              {error.adults && (
                <p className={hstyle.errorText}>{error.adults}</p>
              )}
              {error.guests && (
                <p className={hstyle.errorText}>{error.guests}</p>
              )}

              {showDropdown && (
                <div className={hstyle.dropdown}>
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs14,
                      commonstyles.demiBold
                    )}
                  >
                    Select rooms and guests
                  </p>
                  <div>
                    {/* Rooms section */}
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs14,
                            commonstyles.demiBold
                          )}
                        >
                          Rooms
                        </p>
                      </div>
                      <div className={classNames(hstyle.viewQuantitybox)}>
                        <button
                          className={hstyle.decrementButton}
                          onClick={() => handleDecrement("rooms")}
                        >
                          -
                        </button>
                        <span className={hstyle.quantity}>{roomsQuantity}</span>
                        <button
                          className={hstyle.incrementButton}
                          onClick={() => handleIncrement("rooms")}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Adults section */}
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs14,
                            commonstyles.demiBold
                          )}
                        >
                          Adults
                        </p>
                      </div>
                      <div className={classNames(hstyle.viewQuantitybox)}>
                        <button
                          className={hstyle.decrementButton}
                          onClick={() => handleDecrement("adults")}
                        >
                          -
                        </button>
                        <span className={hstyle.quantity}>
                          {adultsQuantity}
                        </span>
                        <button
                          className={hstyle.incrementButton}
                          onClick={() => handleIncrement("adults")}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Children section */}
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs14,
                            commonstyles.demiBold
                          )}
                        >
                          Children
                        </p>
                        <p
                          className={classNames(
                            commonstyles.colorGray,
                            commonstyles.fs10
                          )}
                          style={{ lineHeight: "0" }}
                        >
                          Age 0 - 17
                        </p>
                      </div>
                      <div className={classNames(hstyle.viewQuantitybox)}>
                        <button
                          className={hstyle.decrementButton}
                          onClick={() => handleDecrement("children")}
                        >
                          -
                        </button>
                        <span className={hstyle.quantity}>
                          {childrenQuantity}
                        </span>
                        <button
                          className={hstyle.incrementButton}
                          onClick={() => handleIncrement("children")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxCenter
                      )}
                    >
                      <button
                        className={classNames(hstyle.applybtn)}
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxCenter,
              commonstyles.mt16
            )}
            style={{
              width: "100%",
            }}
          >
            <div className={classNames(commonstyles.flxCol)}>
              <div
                className={classNames(commonstyles.flx, commonstyles.flxCenter)}
              >
                <div className={classNames()}>
                  <Radio
                    checked={selectedOption === "Hotel"}
                    onChange={() => handleOptionChange("Hotel")}
                  />
                  <span> Hotel</span>
                </div>
                <div className={classNames()}>
                  <Radio
                    checked={selectedOption === "Home"}
                    onChange={() => handleOptionChange("Home")}
                  />
                  <span> Home</span>
                </div>
                <div className={classNames()}>
                  <Radio
                    checked={selectedOption === "Apartment"}
                    onChange={() => handleOptionChange("Apartment")}
                  />
                  <span>Apartment</span>
                </div>
              </div>
              {error.selectedOption && (
                <p className={hstyle.errorText} style={{ alignSelf: "center" }}>
                  {error.selectedOption}
                </p>
              )}
            </div>
          </div>

          <div className={hstyle.showMoreContainer}>
            <button
              className={hstyle.showMoreButton}
              onClick={handleShowMoreClick}
            >
              Search
              <span className={hstyle.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        </div>
        {/* )} */}
        {/* {!showHotelProperty &&  */}

        <div style={{ marginBottom: "70px" }}>
          <Hotelfirstpage />
        </div>

        {/* {showHotelProperty &&  */}

        {/* <HotelProperty /> */}

        {/* } */}
      </div>
      <Footerr />
    </div>
  );
};

export default HotelServic;
