import React, { useEffect } from "react";
import Footerr from "../Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLocation } from "shared/redux";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { SERVICES } from "shared/utils/mainHeaderQuery";
import styles from "./NewServicesCarts.module.css";
import secondImage from "../../../../assets/images/secondImage.png";
import rightimage from "../../../../assets/images/Untitled-6 1.png";
import leftImage from "../../../../assets/images/012-medicine.png";
import leftImage2 from "../../../../assets/images/Vector.png";
import thirdRightImage from "../../../../assets/images/QYS_8063 c 1.png";
import ForthImage from "../../../../assets/images/Untitled-5 2.png";
import leftImage3 from "../../../../assets/images/Group.png";
import imageLeftLaboratory from "../../../../assets/images/Vector (1).png";
import TravelImage from "../../../../assets/images/TourService.png";
import HotelImage from "../../../../assets/images/HotelServices.png";
import FlightVector from "../../../../assets/images/FlightVector.png";
import HotelVector from "../../../../assets/images/HotelVector.png";
import InsuranceVector from "../../../../assets/images/InsuranceVector.png";
import DonationVector from "../../../../assets/images/DonationVector.png";
import TravelImage12 from "../../../../assets/images/TravelImage12.png";
const CenteredLayout = () => {
  useEffect(() => {
    document.title = "MediTour Global | Services";
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigate = (name: string) => {
    switch (name) {
      case "Donation":
        navigate("/services/donation");
        break;

      case "RentACar":
        // navigate("/services/rentacar/");
        // navigate("/services/rentacar/Selection");

        break;

      case "Pharmacy":
        navigate("/services/pharmacy");
        break;
      case "Hotel":
        navigate("/services/hotel");
        break;
      case "Laboratory":
        navigate("/services/laboratory");
        break;
      case "Paramedic Staff":
        navigate("/services/paramedicstaff");
        break;
      case "Travel Agency":
        // navigate("/services/travel");
        navigate("/services/travel/Selection", {
          state: { serviceName: name },
        });
        break;
      case "Insurance":
        navigate("/services/insurance");
        break;
      case "HomeServices":
        navigate("/services/homeService");
        break;
      default:
        navigate("/services/doctor/Selection", {
          state: { serviceName: name },
        });
        break;
    }
  };

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            setLocation({
              latitude: position?.coords?.latitude,
              longitude: position?.coords?.longitude,
              error: null,
            })
          );
        },
        (error) => {
          dispatch(
            setLocation({
              latitude: null,
              longitude: null,
              error: error.message,
            })
          );
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  }, []);
  return (
    <div>
      <NavBreadCrumbs {...SERVICES} />
      {/* <div className={styles.allContent}> */}
      <div className={styles.container}>
        <div>
          <div
            onClick={() => handleNavigate("Doctor")}
            className={styles.imageContainer}
          >
            <div className={styles.leftImageContainer}>
              <div className={styles.leftBackgroundColor}>
                <img
                  src={leftImage}
                  alt="Left Side Image"
                  className={styles.leftSideImage}
                />
              </div>
              <div className={styles.textContainer}>
                <h2 className={styles.mainHeading}>Doctors</h2>
                <p className={styles.subHeading}>Verified Doctors & Hospital</p>
              </div>
            </div>
            <div className={styles.backgroundColor} />
            <img
              src={rightimage}
              alt="Side Image"
              className={styles.sideImage}
            />
          </div>

          <div className={styles.wrapperContainer}>
            <div
              onClick={() => handleNavigate("HomeServices")}
              className={styles.secondImageContainer}
            >
              <div className={styles.leftImageContainerSecond}>
                <div className={styles.leftBackgroundColorSecond}>
                  <img
                    src={leftImage2}
                    alt="Left Side Image"
                    className={styles.leftSideImageSecond}
                  />
                </div>
                <div className={styles.serviceTextContainer}>
                  <h2 className={styles.heading}>Home Services</h2>
                  <div className={styles.serviceList}>
                    <p>Doctors</p>
                    <p>Ambulance</p>
                    <p>Psychology</p>
                    <p>Physiotherapy</p>
                    <p>Nutrition</p>
                    <p>Nurses</p>
                  </div>
                </div>
              </div>
              <div className={styles.secondBackgroundColor} />
              <img
                src={secondImage}
                alt="Second Side Image"
                className={styles.secondSideImage}
              />
            </div>
            <div
              onClick={() => handleNavigate("Laboratory")}
              className={styles.ThirdImageContainer}
            >
              <div className={styles.leftWhiteBox}>
                <img
                  src={imageLeftLaboratory}
                  alt="Center Icon"
                  className={styles.centerIcon}
                />
              </div>
              <h3 className={styles.belowHeading}>Laboratory</h3>
              <img
                src={thirdRightImage}
                alt="Right Side Image"
                className={styles.rightSideImage}
              />
            </div>
            <div
              onClick={() => handleNavigate("Pharmacy")}
              className={styles.FourthImageContainer}
            >
              <div className={styles.leftWhiteBox}>
                <img
                  src={leftImage3}
                  alt="Center Icon"
                  className={styles.centerIconPharmacy}
                />
              </div>
              <h3 className={styles.belowHeading}>Pharmacy</h3>
              <img
                src={ForthImage}
                alt="Right Side Image"
                className={styles.rightSideImage}
              />
            </div>
          </div>
          <div className={styles.newContainerSection}>
            <div
              onClick={() => handleNavigate("Travel Agency")}
              className={styles.newContainer}
            >
              <div className={styles.leftWhiteBoxTravel}>
                <img
                  src={FlightVector}
                  alt="Center Icon"
                  className={styles.leftSideImageFlight}
                />
              </div>
              <div className={styles.textContainerNew}>
                <h2 className={styles.headingNew}>Travel and Tourism</h2>
                <div className={styles.subHeadingContainerNew}>
                  <p className={styles.subHeadingNew}>Visa Services</p>
                  <p className={styles.subHeadingNew}>Rent a Car</p>
                </div>
              </div>
              <img
                src={TravelImage}
                alt="Right Side Fixed Image"
                className={styles.fixedImage}
              />
            </div>
            <div
              onClick={() => handleNavigate("Hotel")}
              className={styles.newContainerSecond}
            >
              <div className={styles.leftWhiteBoxTravel}>
                <img
                  src={HotelVector}
                  alt="Center Icon"
                  className={styles.leftSideImageHotel}
                />
              </div>
              <div className={styles.textContainerHotel}>
                <h3 className={styles.headingHotel}>Hotel</h3>
                <div className={styles.subHeadingContainerHotel}>
                  <p className={styles.subHeadingHotel}>Stay at best place</p>
                </div>
              </div>

              <img
                src={HotelImage}
                alt="Right Side Fixed Image"
                className={styles.fixedImage}
              />
            </div>

            {/* New vertical container for additional boxes */}
            <div className={styles.verticalContainer}>
              <div
                onClick={() => handleNavigate("Insurance")}
                className={styles.additionalContainerFirst}
              >
                <div className={styles.leftWhiteBoxTravel}>
                  <img
                    src={InsuranceVector}
                    alt="Center Icon"
                    className={styles.centerIcon}
                  />
                </div>
                <div className={styles.textContainerNew}>
                  <h2 className={styles.headingNew}>Insurance</h2>
                  <div className={styles.subHeadingContainerNew}>
                    <p className={styles.subHeadingNew}>Health & Travel</p>
                  </div>
                </div>
                <div className={styles.imageContainerVertical}>
                  <img
                    src={TravelImage12}
                    alt="Fixed Right Side Image"
                    className={styles.fixedRightImage}
                  />
                </div>
              </div>
              <div
                onClick={() => handleNavigate("Donation")}
                className={styles.additionalContainerSecond}
              >
                <div className={styles.leftWhiteBoxTravel}>
                  <img
                    src={DonationVector}
                    alt="Center Icon"
                    className={styles.centerIcon}
                  />
                </div>
                <div className={styles.textContainerNew}>
                  <h2 className={styles.headingNew}>Donation</h2>
                  <div className={styles.subHeadingContainerNew}>
                    <p className={styles.subHeadingNew}>Help People</p>
                  </div>
                </div>
                <div className={styles.imageContainerVertical}>
                  <img
                    src={TravelImage12}
                    alt="Fixed Right Side Image"
                    className={styles.fixedRightImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Footerr />
    </div>
  );
};

export default CenteredLayout;
