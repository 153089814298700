import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import { CarARentMenu } from "shared/utils";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import VehicleDetail from "pages/TravelTourism/Rent a Car/VehicleDetail";
import RentAcarDashBoard from "pages/TravelTourism/Rent a Car/RentAcarDashBoard";
import RentAcarOrder from "pages/TravelTourism/Rent a Car/RentAcarOrder";
import RentAcarPayment from "pages/TravelTourism/Rent a Car/RentAcarPayment";
import RentAcarSetting from "pages/TravelTourism/Rent a Car/RentAcarSetting";
import VehicleDescription from "pages/TravelTourism/Rent a Car/VehicleDetail/VehicleDescription";
import Chat from "shared/components/Chat";
import RentAcarRequest from "pages/TravelTourism/Rent a Car/Request";
import CustomerDescription from "pages/TravelTourism/Rent a Car/RentAcarOrder/CustomerDetailDes";
import { RentACar_Payments_Details } from "pages";
import RentAcarHistory from "pages/TravelTourism/Rent a Car/History";
import PaymentNavbarRoutes from "routes/Services_Routes/PaymentNavbar_Routes/PaymentNavbarRoute";

const RentACarMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/rentacar/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={CarARentMenu} />
      </div>
      <Routes>
        <Route path="rentacar/dashboard" element={<RentAcarDashBoard />} />
        <Route path="rentacar/vehicleDetail" element={<VehicleDetail />} />
        <Route
          path="/vehicleDescription/:id"
          element={<VehicleDescription />}
        />
        <Route path="rentacar/Request" element={<RentAcarRequest />} />
        <Route path="rentacar/order" element={<RentAcarOrder />} />
        <Route path="rentacar/orderDetails" element={<CustomerDescription />} />
        <Route path="rentacar/history" element={<RentAcarHistory />} />
        <Route path="rentacar/payment" element={<RentAcarPayment />} />
        <Route
          path="rentacar/paymentDetails"
          element={<RentACar_Payments_Details />}
        />
        <Route path="rentacar/setting" element={<RentAcarSetting />} />
        <Route path="rentacar/privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
        <Route
          path={`/rentacar/paymentDetail`}
          element={<PaymentNavbarRoutes />}
        />
      </Routes>
    </div>
  );
};

export default RentACarMainRoutes;
