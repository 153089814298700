import classNames from "classnames";
import React, { useEffect, useState } from "react";
import SearchBar from "shared/components/Searchbar";
import commonStyles from "shared/utils/common.module.css";
import Styles from "./styles.module.css";
import {
  CustomInput,
  CustomModal,
  DeleteModal,
  PrimaryButton,
  RingLoader,
} from "shared/components";
import ActionButtons from "shared/components/ConfirmDeleteModal/ActionButtonProps";

import { TbRefresh } from "react-icons/tb";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { BiSolidMessageSquareAdd } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddFormulaSchema } from "shared/utils";
import {
  pharmaceutical_AddMed,
  pharmaceutical_DeleteMed,
  pharmaceutical_getAllMedicines,
} from "shared/services";
import { MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const medicineTypeData = [
  { title: "Tablet", value: "Tablet" },
  { title: "Capsule", value: "Capsule" },
  { title: "Syrup", value: "Syrup" },
  { title: "Injection", value: "Injection" },
];

export const Pharmaceutical_Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const itemsPerPage = 10;
  const totalItems = length;
  const [deleteItem, setDeleteItem] = useState<{
    val: any;
    index: number;
  } | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const { user } = useSelector((state: any) => state?.root?.common);
  console.log("🚀 ~ user:", user);

  const handleFetch_Meds = (pageno: number) => {
    setLoading(true);
    pharmaceutical_getAllMedicines(pageno)
      .then((res: any) => {
        setMedicines(res?.data?.medicines);
        setLength(res?.data?.totalMeds);
      })
      .catch((err: any) => {
        // console.log("send error.nnnnn..", err?.response?.data);
        toast.error(err?.respons?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleFetch_Meds(1);
  }, []);

  const handleRefresh = () => {
    setSearch("");
    setCurrentPage(1);
    handleFetch_Meds(1);
  };

  const handleSearch = () => {
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    let itemTorender = currentPage * 10;

    if (length > itemTorender) {
      setCurrentPage(currentPage + 1);
      handleFetch_Meds(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handleFetch_Meds(currentPage - 1);
    }
  };

  const initiateDelete = (val: any, index: number) => {
    setDeleteItem({ val, index });
    setShowConfirmDelete(true);
  };

  const handleDelete = () => {
    if (deleteItem) {
      pharmaceutical_DeleteMed(deleteItem.val?._id)
        .then((res: any) => {
          const newItems = medicines.filter(
            (_: any, i: number) => i !== deleteItem.index
          );
          setMedicines(newItems);
          setDeleteItem(null);
        })
        .catch((err: any) => {
          console.log("🚀 ~ handleDelete ~ err:", err?.response?.data);
        });
    }
    setShowConfirmDelete(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
    setDeleteItem(null);
  };

  return (
    <div className={classNames(commonStyles.col12)}>
      <div className={Styles.Navouter}>
        <SearchBar />
      </div>
      <div className={classNames(Styles.mainOuter)}>
        <div className={classNames(Styles.flxBetween)}>
          <div className={classNames(commonStyles.flx)}>
            <p
              className={classNames(
                commonStyles.fs22,
                Styles.primarycolor,
                commonStyles.semiBold
              )}
            >
              All Medicines
            </p>
            <div
              className={Styles.outerRefresh}
              onClick={() => setShowAddModal(true)}
            >
              <BiSolidMessageSquareAdd className={Styles.addIcon} />
            </div>
            {loading ? (
              <div className={Styles.loader}>
                <RingLoader color={"#0D47A1"} size={30} />
              </div>
            ) : (
              <TbRefresh className={Styles.refresh} onClick={handleRefresh} />
            )}
            {/* <SearchFilter
              vender={false}
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
            /> */}
          </div>
          <div className={classNames(commonStyles.flx)}>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, length)}
              totalItems={totalItems}
            />
          </div>
        </div>

        <div>
          <div className={Styles.payment}>
            <div className={classNames(Styles.headerOuter, commonStyles.bold)}>
              <p className={Styles.headerclass}>Product Name</p>
              <p className={Styles.headerclass}>GENERIC</p>
              <p className={Styles.headerclass}>BRAND</p>
              <p className={Styles.headerclass}>STRENGTH</p>
              <p className={Styles.headerclass}>PACK SIZE</p>
              <p className={Styles.headerclass}>T.P PRICE</p>
              <p className={Styles.headerclass}>M.R.P PRICE</p>
              <p className={Styles.headerclass}>Action</p>
            </div>
            <div className={Styles.tableData}>
              <table
                style={{
                  margin: "0px",
                  borderCollapse: "separate",
                  borderSpacing: "0 4px",
                  tableLayout: "fixed",
                }}
              >
                <tbody className={Styles.wapper}>
                  {medicines?.map((val: any, rowIndex: any) => {
                    return (
                      <tr className={Styles.tableRow} key={rowIndex}>
                        <td className={Styles.w20}>{val?.productName}</td>
                        <td className={Styles.w20}>{val?.generic}</td>
                        <td className={Styles.w20}>{val?.brand}</td>
                        <td className={Styles.w20}>{val?.strength}</td>
                        <td className={Styles.w20}>{val?.packSize}</td>
                        <td className={Styles.w20}>{val?.tpPrice}</td>
                        <td className={Styles.w20}>{val?.mrpPrice}</td>
                        <td className={Styles.w20}>
                          <MdDeleteForever
                            className={Styles.deleteIcon}
                            onClick={() => initiateDelete(val, rowIndex)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* {showConfirmDelete && (
        <ActionButtons
          title="Are you sure you want to delete?"
          onDelete={handleDelete}
          onCancel={handleCancelDelete}
          item={deleteItem!}
        />
      )} */}

      {showConfirmDelete && (
        <DeleteModal
          title="medicines?"
          modalVisible={showConfirmDelete}
          handleCancel={handleCancelDelete}
          handleDelete={handleDelete}
          loading={loading}
        />
      )}
      <CustomModal
        showModal={showAddModal}
        children={
          <AddFormula
            setShowAddModal={setShowAddModal}
            setLoading={setLoading}
            loading={loading}
            medicines={medicines}
            setMedicines={setMedicines}
          />
        }
      />
    </div>
  );
};

export default Pharmaceutical_Dashboard;

const AddFormula = (props: any) => {
  const { setShowAddModal, setLoading, loading, medicines, setMedicines } =
    props;
  const formik = useFormik({
    initialValues: {
      generic: "",
      brand: "",
      strength: "",
      packSize: "",
      content: "",
      tpPrice: "",
      mrpPrice: "",
      productType: "",
    },
    validationSchema: Yup.object(AddFormulaSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    let med_clone = JSON.parse(JSON.stringify(medicines));

    setLoading(true);
    pharmaceutical_AddMed(formik.values)
      .then(async (res: any) => {
        med_clone.unshift(res?.data?.med);
        await setMedicines(med_clone);
        setShowAddModal(false);
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err?.response?.data);
        toast.error(err?.response?.data?.message);

        // setError(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };
  return (
    <div style={{ width: "500px" }}>
      <div style={{ alignItems: "center" }} className={commonStyles.flxBetween}>
        <h1>Add Details</h1>
        <div
          className={Styles.crossOuter}
          onClick={() => setShowAddModal(false)}
        >
          <ImCross className={Styles.cross} />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ marginTop: "32px", width: "100%" }}>
          <CustomInput
            placeholder="Generic"
            id="generic"
            name="generic"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.generic}
          />
          {formik.touched.generic && formik.errors.generic && (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.generic}
            </div>
          )}
        </div>
        <div style={{ marginTop: "32px" }} className={commonStyles.flxBetween}>
          <div style={{ width: "45%" }}>
            <CustomInput
              placeholder="Brand"
              id="brand"
              name="brand"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.brand}
            />
            {formik.touched.brand && formik.errors.brand && (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.brand}
              </div>
            )}
          </div>
          <div style={{ width: "48%" }}>
            <select
              id="productType"
              name="productType"
              onChange={formik.handleChange}
              value={formik.values.productType}
              // className={classNames(styles.customSelect)}
              style={{
                borderBottom: "1px solid rgb(0, 39, 109)",
                // borderRadius: "3px",
                padding: "12.5px",
                // backgroundColor: "red",
                width: "100%",
              }}
            >
              <option value="">Select Product Type</option>
              {medicineTypeData.map((v: any, ind: number) => (
                <option key={ind} value={v.value}>
                  {v.title}
                </option>
              ))}
            </select>
            {formik.touched.productType && formik.errors.productType ? (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.productType}
              </div>
            ) : null}
          </div>
        </div>

        <div style={{ marginTop: "32px" }} className={commonStyles.flxBetween}>
          <div style={{ width: "45%" }}>
            <CustomInput
              placeholder="Strength"
              id="strength"
              name="strength"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.strength}
            />
            {formik.touched.strength && formik.errors.strength && (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.strength}
              </div>
            )}
          </div>
          <div style={{ width: "48%" }}>
            <CustomInput
              placeholder="Pack Size"
              id="packSize"
              name="packSize"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.packSize}
            />
            {formik.touched.packSize && formik.errors.packSize && (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.packSize}
              </div>
            )}
          </div>
        </div>

        <div style={{ marginTop: "32px", width: "100%" }}>
          <CustomInput
            placeholder="Content"
            id="content"
            name="content"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.content}
          />
          {formik.touched.content && formik.errors.content && (
            <div className={classNames(commonStyles.error)}>
              *{formik.errors.content}
            </div>
          )}
        </div>

        <div style={{ marginTop: "32px" }} className={commonStyles.flxBetween}>
          <div style={{ width: "45%" }}>
            <CustomInput
              placeholder="T.P Price"
              id="tpPrice"
              name="tpPrice"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.tpPrice}
            />
            {formik.touched.tpPrice && formik.errors.tpPrice && (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.tpPrice}
              </div>
            )}
          </div>
          <div style={{ width: "48%" }}>
            <CustomInput
              placeholder="M.R.P Price"
              id="mrpPrice"
              name="mrpPrice"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.mrpPrice}
            />
            {formik.touched.mrpPrice && formik.errors.mrpPrice && (
              <div className={classNames(commonStyles.error)}>
                *{formik.errors.mrpPrice}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            marginTop: "56px",
            display: "flex",
          }}
        >
          <PrimaryButton
            children={
              loading ? <RingLoader size={35} color={"#fff"} /> : "Save"
            }
            type="submit"
            colorType={"Linear"}
          />
        </div>
      </form>
    </div>
  );
};
