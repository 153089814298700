import React, { useState } from "react";
import classNames from "classnames";
import styles from "./RentCar.module.css";
import MainTravaelMainStyles from "../../mainTravel.module.css";
import commonStyles from "shared/utils/common.module.css";
import { rentCarLogin } from "shared/services/RentaCar";
import { SuccessModal, LoadingModal } from "shared/components";
import { setRentCar, setSystemType } from "shared/redux";
import {
  setIsLoggedIn,
  setToken,
  set_User,
} from "shared/redux/reducers/commonReducer";
import { rentACarLoginSchema } from "shared/utils";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { CustomInput, PrimaryButton } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PasswordInput from "shared/components/PasswordInput";
import { FaChevronLeft } from "react-icons/fa";
import Logo from "assets/images/Rent a Car 1.png";
import CustomLoader from "shared/components/New_Loader/Loader";
const RentCarLogin = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [notificationError] = useState("");
  const [error, setError] = React.useState("");
  const { fcmToken, user } = useSelector((state: any) => state.root.common);
  console.log("🚀 ~ RentCarLogin ~ user:", user);

  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate("/joinVender");
  };
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: Yup.object(rentACarLoginSchema),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      const permission = await checkNotificationPermission();

      if (permission === "denied") {
        alert(
          "To continue with the login process, please enable notifications."
        );
        setLoading(false);
        return;
      }

      // Proceed with login process
      let params = {
        email: formik.values.Email,
        password: formik.values.Password,
        fcmToken,
      };

      rentCarLogin(params)
        .then((res: any) => {
          console.log("Response received:", res);
          if (res.status === 200 && res.statusText === "OK") {
            if (res?.data?.auth) {
              dispatch(setToken(res?.data?.token));
              dispatch(setIsLoggedIn(true));
              dispatch(setRentCar(res?.data?.rentCar));
              dispatch(set_User(res?.data?.rentCar));
              setShowSuccessModal(true);
              navigate("/rentacar/dashboard");
            }
          }
        })
        .catch((err: any) => {
          console.log("Error response:", err?.response?.data);
          setError(err?.response?.data?.message);
          window.location.reload();
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err: any) {
      console.error("Error during notification permission check:", err);
      // You can handle any additional errors here if needed
    }
  };

  // Function to check notification permission
  const checkNotificationPermission = () => {
    return new Promise((resolve) => {
      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          resolve(permission);
        });
      } else {
        resolve(Notification.permission);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  const inputType = passwordVisible ? "text" : "password";
  return (
    <div className={classNames(commonStyles.container)}>
      <div className={classNames(MainTravaelMainStyles.flx)}>
        <div
          className={classNames(
            commonStyles.col7,
            commonStyles.colmd7,
            commonStyles.colsm12
          )}
        >
          <div
            className={classNames(
              commonStyles.col8,
              commonStyles.colmd12,
              commonStyles.colsm12
            )}
          >
            <div
              style={{ cursor: "pointer" }}
              className={classNames(commonStyles.flx)}
              onClick={handleGoToHome}
            >
              <FaChevronLeft className={styles.BackIcon} />
              <p
                className={classNames(
                  commonStyles.colorBlue,
                  commonStyles.fs14,
                  commonStyles.semiBold
                )}
              >
                Back to Home
              </p>
            </div>
            <p
              className={classNames(
                commonStyles.fs40,
                styles.mt100,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
            >
              Login
            </p>
            <p
              className={classNames(
                commonStyles.fs16,
                styles.mt16,
                commonStyles.semiBold,
                commonStyles.colorBlue
              )}
            >
              Login to access at Rent A Car account
            </p>
            {error && (
              <div className={classNames(commonStyles.error)}>*{error}</div>
            )}
            {notificationError && (
              <div className={classNames(commonStyles.error)}>
                *{notificationError}
              </div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div className={classNames(styles.mt50)}>
                <CustomInput
                  placeholder="Please enter Email"
                  id="Email"
                  name="Email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Email}
                />
                {formik.touched.Email && formik.errors.Email ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.Email}
                  </div>
                ) : null}
              </div>
              <div className={classNames(styles.mt24)}>
                <PasswordInput
                  placeholder="Enter Your Password"
                  id="Password"
                  name="Password"
                  type={inputType}
                  onChange={formik.handleChange}
                  value={formik.values.Password}
                  onKeyPress={handleKeyPress}
                />
                {formik.touched.Password && formik.errors.Password ? (
                  <div className={classNames(commonStyles.error)}>
                    *{formik.errors.Password}
                  </div>
                ) : null}

                <div
                  className={classNames(
                    styles.flxBetween,
                    commonStyles.mb40,
                    styles.mt8
                  )}
                >
                  <div className={classNames(commonStyles.flx)}>
                    <Checkbox className={styles.checkBoxx} />
                    <p
                      className={classNames(
                        commonStyles.colorBlue,
                        commonStyles.fs14
                      )}
                    >
                      Remember me
                    </p>
                  </div>
                  <a
                    className={classNames(
                      commonStyles.colorOrange,
                      commonStyles.fs14,
                      commonStyles.flxEnd,
                      styles.cursor
                    )}
                    onClick={() =>
                      navigate("/traveltourism/rentAcar/forgot-password")
                    }
                  >
                    Forgot Password
                  </a>
                </div>
                <div className={classNames(commonStyles.mb16)}>
                  <PrimaryButton children={"Sign In"} colorType={"Travel"} />
                </div>
                <div>
                  <p
                    className={classNames(
                      commonStyles.regular,
                      commonStyles.fs14,
                      commonStyles.textCenter
                    )}
                  >
                    If you haven't account{" "}
                    <a
                      className={classNames(
                        commonStyles.colorOrange,
                        MainTravaelMainStyles.cursor
                      )}
                      onClick={() => navigate("/traveltourism/rentAcar/signup")}
                    >
                      Sign Up
                    </a>{" "}
                  </p>
                </div>
              </div>
            </form>
            <SuccessModal
              showModal={showSuccessModal}
              successMessage={"LogIn Successful!"}
              hanldeCloseModal={handleCloseSuccessModal}
            />
            {loading && <CustomLoader />}
          </div>
        </div>
        <div className={classNames(commonStyles.colsm12, commonStyles.col4)}>
          <img src={Logo} className={commonStyles.loginVector} />
        </div>
      </div>
    </div>
  );
};

export default RentCarLogin;
