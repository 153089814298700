import { useEffect } from "react";
import Footerr from "pages/Home/HomeNavBar/Footer";
import LaboratoryCards from "./LaboratoryCards";
import NavBreadCrumbs from "shared/components/NavBreadCrumbs";
import { LABORATORY } from "shared/utils/mainHeaderQuery";

const LaboratoriesServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBreadCrumbs {...LABORATORY} />
      <LaboratoryCards />
      <Footerr />
    </div>
  );
};

export default LaboratoriesServices;
