import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useNavigate } from 'react-router-dom';
import Avatar from '../../../../../assets/logo.png';
import styles from './BookigHotelDetail.module.css';
import DownloadImagelogo from '../../../../../assets/images/DownloadImagelogo.png';
import LocationIcon from '../../../../../assets/images/LocationIcon12.png';
import PaymentMyAppointMent from "../PaymentMyAppointMent";
const FullPaymentDetailHotel = React.memo((props) => {
          const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const [hotelDetails, setHotelDetails] = useState({
    image: Avatar,
    name: 'Park Lane Hotel Lahore',
    stars: 4, // Number of stars
    distance: 'Gulberg 4.3 miles from center',
    plan: 'Breakfast included',
    dates: 'Mon, Jan 13 - Fri, Jan 24',
    roomDetails: '10 nights, 1 room for adults',
    beds: '2 Single Beds',
    roomSize: 'Size: 269 ft',
    guests: '1 room, 1 adult, 0 children'
  });

  const [paymentDetails, setPaymentDetails] = useState({
    totalAmount: '12,504',
    partialAmount: '3,504',
    pendingAmount: '8,000',
    pendingAmountDate: '07/12/2024'
  });




return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Booking
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            My Booking
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Detail
          </p>
        </div>
      </div>

       <div className={styles.bookingHotelDetailContainer}>
    <div className={styles.hotelDetail}>
        <div className={styles.hotelImageWrapper}>
            <img src={hotelDetails.image} alt={hotelDetails.name} className={styles.hotelImage} />
            <p className={styles.planOverlay}>{hotelDetails.plan}</p>
        </div>
        <div className={styles.hotelInfo}>
            <h2 className={styles.hotelDetailName}>{hotelDetails.name}</h2>
            <p className={styles.ratingAndDistance}>
         {Array.from({ length: hotelDetails.stars }, () => '⭐').join(' ')} 
         </p>
            <div className={styles.distanceContainer}>
                 <img src={LocationIcon} alt="Icon" className={styles.distanceIcon} />
    <p className={styles.distanceText}>{hotelDetails.distance}</p>
</div> 
              <div className={styles.horizontalLine}></div>

            <div className={styles.bookingDetails}>
                <div className={styles.bookingDetailsNameHeading}>
                    <p>Your Plan</p>
                </div>
                <div className={styles.bookingDetailsNameHeadingData}>
                <p> {hotelDetails.dates}</p>
                </div>
                <div className={styles.bookingDetailsNameHeading}>
                    <p>You selected</p>
                </div>
                <div className={styles.bookingDetailsNameHeadingData}>
                <p> {hotelDetails.roomDetails}</p>
                </div>
                <div  className={styles.bookingDetailsNameHeading}>
                <p>1 Executive king room</p>
                </div>
                <div className={styles.roomDetailsContainer}>
        <p className={styles.beds}>{hotelDetails.beds}</p>
        <p className={styles.roomSize}>{hotelDetails.roomSize}</p>
    </div>
                <div className={styles.bookingDetailsNameHeading}>
                    <p>Room and guests</p>
                </div>
               <div className={styles.bookingDetailsNameHeadingData}>
                <p> {hotelDetails.guests}</p>
                </div>
            </div>
        </div>
    </div>
   <div className={styles.paymentDetail}>
    <h3 className={styles.paymentHeader}>Payment</h3>
    <div className={styles.totalAmount}>
    <span className={styles.labelTotal}>Total Amount:</span>
    <span className={styles.amountValue}>{paymentDetails.totalAmount}</span>
</div>

    <div className={styles.totalAmount}>
    <span className={styles.labelTotal}>Partial Amount:</span>
    <span className={styles.amountValue}>{paymentDetails.partialAmount}</span>
</div>
{/* <div className={styles.pendingAmount}>
    <span className={styles.label}>Pending Amount:</span>
    <span className={styles.amountValue}>{paymentDetails.pendingAmount}</span>
</div>
<div className={styles.pendingAmountDate}>
    <span className={styles.label}>Pending Amount Date:</span>
    <span className={styles.dateValue}>{paymentDetails.pendingAmountDate}</span>
</div> */}

    {/* <div className={styles.paymentButtonContainer}>
        <button className={styles.paymentButton} onClick={() => setModalOpen(true)}>Payment
            <img src={DownloadImagelogo} alt="Logo" className={styles.downloadButtonLogo} />
        </button>
        <PaymentMyAppointMent isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div> */}
</div>

</div>

     




         <Footerr />
    </div>
  );
});

export default FullPaymentDetailHotel;